import styled from 'styled-components';

import { colors, breakpoints, lightBlueGrey } from 'styles/global';

import { Button } from 'components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 34px;
  padding-bottom: 50px;

  background-color: ${({
    withDarkerBackground
  }: {
    withDarkerBackground: boolean;
  }) => (withDarkerBackground ? lightBlueGrey : 'white')};
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media ${breakpoints.desktop} {
    padding: 0 15px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 36px;
`;

export const Title = styled.h5`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: ${colors.text[1]};
`;

export const EventCount = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.text[2]};
`;

export const Events = styled.div`
  display: grid;
  grid-gap: 16px;
  justify-content: space-between;
  grid-template-columns: repeat(
    ${({ maximumColumnCount }: { maximumColumnCount: number }) =>
      maximumColumnCount},
    1fr
  );

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  & > * {
    min-width: 0; // do not allow stretching
  }
`;

export const LoadMoreButton = styled(Button)`
  width: 122px;
  margin-top: 40px;
  align-self: center;
`;
