import styled from 'styled-components';

import { colors, red } from 'styles/global';

import { FormLabel } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 11px;
`;

export const StyledTextArea = styled.textarea`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  width: 100%;
  box-sizing: border-box;
  padding: 18px 15px 21px 15px;

  border-radius: 2px;
  border-style: solid;
  border-width: 0.75px;
  border-color: ${(props: { invalid: boolean }) =>
    props.invalid ? red : 'rgba(123, 124, 129, 0.2)'};
  transition: border-color 0.2s linear;

  &:focus {
    outline: none;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CharacterLimit = styled.div`
  color: ${colors.text[1]};
  font-size: 12px;
  line-height: 22px;

  margin-top: 6px;
  margin-left: 20px;
`;
