import { useRouter } from 'next/router';

import { EVENT_TICKET_PURCHASE_URL } from 'libs/constants';
import { formatPrice } from 'libs/prices';

import { useWithEvent } from 'hooks';

import { Link } from 'components';

import {
  Container,
  Title,
  StartingFrom,
  Description,
  StyledButton
} from './styles';

interface IProps {
  className?: string;
}

const TicketPurchasePrompt = ({ className }: IProps) => {
  const router = useRouter();
  const { event } = useWithEvent();
  const eventIsPaid = event ? parseFloat(event.lowest_ticket_price) > 0 : false;

  return event ? (
    <Container className={className}>
      <Title>Tickets</Title>
      {eventIsPaid && (
        <StartingFrom>
          Starting from £{formatPrice(event.lowest_ticket_price)}
        </StartingFrom>
      )}
      <Description>
        Gain full access to all the sessions, global chat and networking for
        this event.
      </Description>
      <Link
        href={EVENT_TICKET_PURCHASE_URL({
          eventSlug: event.slug,
          next: router.asPath
        })}>
        <StyledButton
          colorScheme={6}
          height="medium"
          fontSize={15}
          fontWeight={500}>
          Get Ticket
        </StyledButton>
      </Link>
    </Container>
  ) : null;
};

export default TicketPurchasePrompt;
