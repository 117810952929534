import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrashAlt as farFaTrashAlt,
  faEye as farEye,
  faClock as farClock,
  faBell as farBell
} from '@fortawesome/free-regular-svg-icons';
import {
  faTicketAlt,
  faCircleNotch,
  faUser,
  faCreditCard,
  faSearch,
  faChair,
  faLock,
  faCoffee,
  faCheck,
  faPlus,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDotCircle,
  faDownload,
  faLink,
  faCheckCircle,
  faExclamationTriangle,
  faVideo,
  faGlobe,
  faTimes,
  faShoppingCart,
  faBars,
  faCog,
  faHome,
  faEdit,
  faPaperPlane,
  faComments,
  faStar,
  faStarHalf,
  faCalendarAlt,
  faShoppingBag,
  faSignOutAlt,
  faUserEdit,
  faUsers,
  faClock,
  faUserFriends,
  faDatabase,
  faShoppingBasket,
  faLongArrowAltRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';

export interface IProps {
  weight?: IconPrefix;
  icon: IconName;
  onClick?: () => void;
  key?: any;
}

// here we add icons we use
// the ones with "fa" are the "solid" ones.
// "far" goes for "regular" ones
library.add(
  // solid icons
  faUser,
  faCircleNotch,
  faTicketAlt,
  faCreditCard,
  faChair,
  faSearch,
  faShoppingBasket,
  faLongArrowAltRight,
  faUserFriends,
  faDatabase,
  faClock,
  faUsers,
  faUserEdit,
  faPlus,
  faLock,
  faCoffee,
  faCheck,
  faDotCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faDownload,
  faLink,
  faExclamationTriangle,
  faCheckCircle,
  faVideo,
  faGlobe,
  faTimes,
  faShoppingCart,
  faBars,
  faPaperPlane,
  faCog,
  faHome,
  faEdit,
  faComments,
  faStar,
  faStarHalf,
  faCalendarAlt,
  faShoppingBag,
  faSignOutAlt,

  //regular icons
  farEye,
  farBell,
  farClock,
  farFaTrashAlt
);

const Icon: FunctionComponent<IProps> = ({
  weight = 'fas',
  icon,
  ...props
}) => (
  // Wrapper component around FontAwesomeIcon. Supports the props FontAwesomeIcon supports.
  // Reference: https://fontawesome.com/how-to-use/on-the-web/using-with/react
  <FontAwesomeIcon icon={[weight, icon]} {...props} />
);

export default Icon;
