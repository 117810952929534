import image from './assets/accepted-invitation.png';
import {
  Container,
  Title,
  Description,
  StyledOneDimensionImage
} from './styles';

const InvitationAccepted = () => (
  <Container>
    <Title>Invitation Accepted</Title>
    <StyledOneDimensionImage width={386} src={image} />
    <Description>You have been add to the list of participants</Description>
  </Container>
);

export default InvitationAccepted;
