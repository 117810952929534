import styled from 'styled-components';

import { red, colors } from 'styles/global';

import { Button } from 'components';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  backdrop-filter: blur(2px);
  background-color: #30596e99;
  z-index: 100; // navigation is 99

  animation: fadeIn 0.5s linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      backdrop-filter: blur(0px);
    }

    100% {
      opacity: 1;
      backdrop-filter: blur(2px);
    }
  }
`;

export const Container = styled.div`
  padding: 27px;
  box-sizing: border-box;

  width: 100%;
  max-width: 472px;
  max-height: 100%;
  overflow-y: auto;

  border-radius: 2px;
  background-color: white;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  animation: zoomIn 0.5s linear;

  @keyframes zoomIn {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const Title = styled.h6`
  color: ${red};
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 24px;
  color: ${colors.text[1]};

  margin: 34px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  width: 100%;
  max-width: 160px;
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  max-width: 225px;
  margin-left: 15px;
`;
