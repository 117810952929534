import type { IconName } from '@fortawesome/fontawesome-svg-core';

interface ITestimonialItem {
  icon: IconName;
  title: string;
  description: string;
}

export const TESTIMONIAL_ITEMS: Array<ITestimonialItem> = [
  {
    icon: 'users',
    title: 'Streaming',
    description:
      'Our platform allows you to deliver live-streamed or pre-recorded content using any tool of your choice.'
  },
  {
    icon: 'clock',
    title: 'Real Time & On Demand',
    description:
      'Attendees can consume your content at any time, meaning your ROI increases over time.'
  },
  {
    icon: 'lock',
    title: 'For All Business Models',
    description:
      "Unissu's events platform is completely customisable. Make your content free to consume, paid for or make it private."
  },
  {
    icon: 'video',
    title: '1-1 Video Calling',
    description:
      'Recreate the spontaneous experience of impromptu meetings at physical events.'
  },
  {
    icon: 'database',
    title: 'Data Analytics',
    description:
      'Unprecedented data access to truly understand how your event performed.'
  },
  {
    icon: 'user-friends',
    title: 'Real Estate Audiences',
    description:
      'The largest aggregation of global real estate professionals engaged in the future of the industry.'
  }
];
