import styled from 'styled-components';

import { colors } from 'styles/global';

import { Link, Icon } from 'components';

export const StyledIcon = styled(Icon)`
  color: inherit;
  font-size: 11px;
  margin-right: 10px;
  opacity: 0.4;
`;

export const StyledLink = styled(Link)`
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  color: ${colors.text[3]};
`;

export const Container = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[3]};
`;
