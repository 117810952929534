import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { colors, lightGrey, darkGrey, breakpoints } from 'styles/global';

import { Icon, Link, Button } from 'components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 15px;
  box-sizing: border-box;
  position: relative;

  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const LogoContainer = styled.div`
  height: 80px;
  width: 80px;

  background-color: white;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-sizing: border-box;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  max-width: 66px;
  max-height: 66px;
  object-fit: contain;
`;

export const Container = styled.div`
  margin: 0 55px;

  flex-grow: 1;
  min-height: 460px;
  max-width: 1170px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  animation: ${({ active }: { active: boolean }) =>
    active ? 'fadeIn 1s ease-out' : 'unset'};

  position: ${({ active }: { active: boolean }) =>
    active ? 'relative' : 'absolute'};
  opacity: ${({ active }: { active: boolean }) => (active ? 1 : 0)};
  z-index: ${({ active }: { active: boolean }) => (active ? 2 : 1)};

  @media ${breakpoints.tablet} {
    margin: 0 15px;
    max-width: calc(100vw - 100px);
  }

  @media ${breakpoints.mobile} {
    height: unset;
    max-width: calc(100vw - 80px);
    flex-direction: column-reverse;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1;
    }
  }
`;

export const Arrow = styled(Icon)`
  z-index: 3;
  font-size: 30px;
  color: ${darkGrey};

  cursor: pointer;

  @media ${breakpoints.mobile} {
    top: 35%;
    position: absolute;

    &:first-of-type {
      left: 10px;
    }

    &:last-of-type {
      right: 10px;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  max-width: 315px;
  margin-right: 80px;

  padding: 30px 0;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    margin-right: 30px;
  }

  @media ${breakpoints.mobile} {
    margin-top: 20px;
    padding-top: unset;
    margin-right: unset;

    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const EventMisc = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${lightGrey};
  letter-spacing: -0.213774px;

  margin-top: 16px;
  margin-bottom: 8px;
`;

export const OrganiserName = styled(Link)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: color 0.2s linear;

  &:hover {
    color: ${colors.text[4]};
  }
`;

export const Title = styled(Link)`
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: ${darkGrey};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media ${breakpoints.mobile} {
    text-align: center;
  }
`;

export const Description = styled.p`
  width: 100%;
  font-size: 18px;
  line-height: 21px;
  color: ${darkGrey};

  margin-top: 21px;
  margin-bottom: 30px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media ${breakpoints.mobile} {
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  align-self: stretch;
`;

export const StyledButton = styled(Button)`
  width: 221px;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const Right = styled.div`
  position: relative;

  width: 100%;
  max-width: 774px;

  @media ${breakpoints.mobile} {
    min-height: 125px;
  }
`;

export const LiveLabel = styled.div`
  height: 28px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 21px;
  left: 20px;
  z-index: 2;
  position: absolute;

  color: white;
  font-weight: 500;
  line-height: 17px;
  font-size: 13.7778px;
  background-color: #ff625c;
`;

export const HeroImageContainer = styled(Link)`
  width: 100%;
  display: block;
  padding-top: 59%;
  position: relative;

  img {
    object-fit: cover;
  }
`;

export const HeroImageSkeleton = styled(Skeleton)`
  top: 0;
  position: absolute;
`;

export const DotIcon = styled(Icon)`
  margin-right: 5px;
`;
