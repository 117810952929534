import { Form } from 'formik';
import styled from 'styled-components';

import { colors, darkBlue, lightBlue } from 'styles/global';

import { ITheme } from 'libs/types';

import { Button, Icon } from 'components';

export const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 20px;
`;

export const SubmitButton = styled(Button)`
  width: 180px;
  margin-top: 10px;

  justify-self: flex-end;
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  z-index: 99; // as much as navigation
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  backdrop-filter: blur(2px);
  background-color: ${darkBlue}99; // 99 is for 0.6 opacity;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: white;

  padding: 15px;
  box-sizing: border-box;
  cursor: wait;
`;

export const CogIcon = styled(Icon)`
  font-size: 50px;

  animation: rotate 3s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const OverlayText = styled.div`
  font-size: 36px;
  text-align: center;

  margin-top: 20px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 2px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  background-color: ${lightBlue};
  animation: spread 0.5s linear;

  &:before {
    content: '';
    top: 0;
    left: 0;
    border-radius: 2px;
    position: absolute;

    height: 100%;
    width: ${({ progress }: { progress: number }) => progress}%;

    background-color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};

    transition: width 0.3s linear;
  }

  &:after {
    z-index: 2;
    position: relative;
    font-size: 20px;
    color: ${colors.text[1]};
    content: '${({ progress }: { progress: number }) => progress}%';
  }

  @keyframes spread {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
`;
