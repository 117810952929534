import _ from 'lodash';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

import { CATEGORY_LIST_URL } from 'libs/constants';
import { ICategoryListCategory } from 'libs/types';

import { useWithEvent, useWithUser } from 'hooks';

import { EventHeader } from 'components';

import { SubCategories } from './components';
import { Tabs, Tab, Content, Container } from './styles';
import { getBreadcrumbs } from './utils';

interface IProps {
  categories: ICategoryListCategory[];
  initialActiveTab: ICategoryListCategory | null;
}

const TabSwitcher = ({ initialActiveTab, categories }: IProps) => {
  const router = useRouter();
  const { user } = useWithUser();
  const [activeTab, setActiveTab] = useState<
    ICategoryListCategory | null | undefined
  >(initialActiveTab);

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  useEffect(() => {
    // we set the slug of the initial active tab if:
    // - there isn't a category slug in the URL
    // - the slug in the URL is not one of the category slugs
    const categorySlugFromPath = router.query.category?.[0];
    const initialActiveTabSlug = initialActiveTab?.category.slug;

    const categorySlugs: Array<string> = categories.map((category) =>
      _.get(category, 'category.slug')
    );

    if (
      eventSlug &&
      initialActiveTabSlug &&
      !_.includes(categorySlugs, categorySlugFromPath)
    ) {
      router.replace(
        CATEGORY_LIST_URL({
          eventSlug,
          category: initialActiveTabSlug
        }),
        undefined,
        { shallow: true }
      );
    }
  }, [router, initialActiveTab?.category.slug, categories, eventSlug]);

  useEffect(() => {
    // change the active tab based on the slug in the URL
    const categorySlugFromPath = router.query.category?.[0];

    if (!categorySlugFromPath) {
      // do nothing if there isn't a slug in the URL Path
      return;
    }

    const activeTab = _.find(
      categories,
      (category) => category.category.slug === categorySlugFromPath
    );

    setActiveTab(activeTab);
  }, [router.query, categories, router]);

  const categoriesToShowAsTabs = categories.filter(
    (category) => !category.is_final_category
  );

  return (
    <>
      {event && eventSlug && (
        <EventHeader
          breadcrumbs={getBreadcrumbs({ event, activeTab })}
          sponsors={activeTab?.category.sponsors || event.headline_sponsors}
          mainSponsor={activeTab?.category.main_sponsor}
          description={
            user
              ? activeTab?.category.authenticated_description
              : activeTab?.category.unauthenticated_description
          }
        />
      )}
      <Container>
        {eventSlug && (
          <Tabs>
            {categoriesToShowAsTabs.map((tab) => {
              const { slug } = tab.category;
              const isTabActive = _.isEqual(tab, activeTab);

              return (
                <Tab
                  shallow
                  key={slug}
                  active={isTabActive}
                  href={CATEGORY_LIST_URL({
                    category: slug,
                    eventSlug
                  })}
                  isTheOnlyTabShown={categories.length === 1}>
                  {tab.category.title}
                </Tab>
              );
            })}
          </Tabs>
        )}
        <Content>
          {_.isEmpty(categoriesToShowAsTabs) ? (
            <SubCategories categories={categories} />
          ) : (
            <SubCategories categories={_.get(activeTab, 'subcategories', [])} />
          )}
        </Content>
      </Container>
    </>
  );
};

export default TabSwitcher;
