import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Link, Image, Breadcrumbs } from 'components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  background-color: white;
  justify-content: center;
`;

export const HeroImageContainer = styled.div`
  position: absolute;
  height: 145px;
  width: 100%;
`;

export const HeroImage = styled(Image)`
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
  border-radius: 2px;
  background-color: white;

  width: 100%;
  margin: 104px 45px 21px 45px;

  @media ${breakpoints.tablet} {
    padding: 0 15px;
    box-sizing: border-box;
    margin: 104px 5px 21px 5px;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  padding: 28px 55px 25px 29px;

  display: grid;
  grid-gap: 0 30px;
  grid-auto-rows: max-content;
  grid-template-columns: 82px 1fr 101px;
  grid-template-areas: 'logo eventMisc mainSponsor' 'logo title mainSponsor' 'logo description mainSponsor' '. sponsors sponsors' 'breadcrumbs breadcrumbs breadcrumbs';

  @media ${breakpoints.tablet} {
    padding: 28px 0 25px 0;
    grid-template-columns: unset;
    grid-template-areas: 'logo' 'title' 'eventMisc' 'description' 'mainSponsor' 'sponsors' 'breadcrumbs';
  }
`;

export const LogoContainer = styled.div`
  grid-area: logo;
`;

export const Logo = styled(Image)`
  object-fit: contain;
`;

export const EventMisc = styled.div`
  grid-area: eventMisc;

  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[2]};
`;

export const OrganiserName = styled(Link)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: color 0.2s linear;

  &:hover {
    color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  }
`;

export const Title = styled.h3`
  grid-area: title;

  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  color: ${colors.text[1]};

  margin-top: 9px;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  grid-area: description;

  font-size: 17px;
  line-height: 22px;
  color: ${colors.text[1]};

  @media ${breakpoints.tablet} {
    margin-top: 15px;
  }
`;

export const MainSponsor = styled.div`
  width: 100%;
  grid-area: mainSponsor;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    margin-top: 15px;
    align-items: flex-start;
  }
`;

export const MainSponsorLogo = styled(Image)`
  object-fit: contain;
`;

export const MainSponsorText = styled.p`
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[2]};

  margin-top: 7px;
`;

export const Sponsors = styled.div`
  grid-area: sponsors;

  margin-top: 22px;
`;

export const SponsorsText = styled.p`
  font-size: 13px;
  line-height: 22px;
  color: ${colors.text[2]};

  margin-bottom: 23px;
`;

export const SponsorLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -20px; // to negate the 20px on top of each logo

  & > * {
    margin-top: 20px;
  }

  & > *:not(:last-of-type) {
    margin-right: 25px;

    @media ${breakpoints.mobile} {
      margin-right: 15px;
    }
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  grid-area: breadcrumbs;
  padding-top: 23px;
  border-top: 1px solid rgba(123, 124, 129, 0.2);
`;
