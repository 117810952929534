import {
  EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER,
  EVENT_LOGO_PLACEHOLDER,
  ORGANISER_DETAIL_URL
} from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import { ICategoryListCategorySponsor } from 'libs/types';

import { useWithEvent } from 'hooks';

import { Link, OneDimensionImage } from 'components';
import { IBreadcrumb, IIconBreadcrumb } from 'components/Breadcrumbs';

import {
  Logo,
  Title,
  Wrapper,
  Sponsors,
  Content,
  Container,
  HeroImage,
  Description,
  MainSponsor,
  SponsorLogos,
  SponsorsText,
  LogoContainer,
  MainSponsorLogo,
  EventMisc,
  MainSponsorText,
  StyledBreadcrumbs,
  HeroImageContainer,
  OrganiserName
} from './styles';

const START_DATE_FORMAT = 'Do MMMM, YYYY';

interface IProps {
  breadcrumbs: Array<IIconBreadcrumb | IBreadcrumb>;
  sponsors?: Array<ICategoryListCategorySponsor>;
  mainSponsor?: ICategoryListCategorySponsor;
  description?: string;
  title?: string;
}

const EventHeader = ({
  title,
  sponsors,
  breadcrumbs,
  mainSponsor,
  description
}: IProps) => {
  const { event } = useWithEvent();

  return event ? (
    <Wrapper>
      <HeroImageContainer>
        <HeroImage
          layout="fill"
          src={event.banner_image || EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER}
        />
      </HeroImageContainer>
      <Container>
        <Content>
          <LogoContainer>
            <Logo
              height={82}
              width={82}
              src={event.light_background_logo || EVENT_LOGO_PLACEHOLDER}
            />
          </LogoContainer>
          <EventMisc>
            {applyFormattingToDateTime({
              date: event.start,
              toFormat: START_DATE_FORMAT
            })}
            , {event.location} ·{' '}
            <OrganiserName
              href={ORGANISER_DETAIL_URL({
                organiserSlug: event.organiser.slug
              })}>
              {event.organiser.name}
            </OrganiserName>
          </EventMisc>
          <Title>{title || event.name}</Title>
          <Description>{description || event.description}</Description>

          {mainSponsor && (
            <MainSponsor>
              <Link external href={mainSponsor.url}>
                <MainSponsorLogo
                  src={mainSponsor.light_background_logo}
                  width={68}
                  height={68}
                />
              </Link>
              <MainSponsorText>{event.category_sponsor_text}</MainSponsorText>
            </MainSponsor>
          )}

          {sponsors && sponsors.length > 0 && (
            <Sponsors>
              <SponsorsText>{event.proudly_sponsored_by_text}</SponsorsText>
              <SponsorLogos>
                {sponsors.map((sponsor, index) => (
                  <Link
                    external
                    href={sponsor.url}
                    key={`sponsor-${sponsor.url}-${index}`}>
                    <OneDimensionImage
                      src={sponsor.light_background_logo}
                      height={sponsor.preferable_logo_height}
                    />
                  </Link>
                ))}
              </SponsorLogos>
            </Sponsors>
          )}
        </Content>
        <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
      </Container>
    </Wrapper>
  ) : null;
};

export default EventHeader;
