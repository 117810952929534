import { EScheduleItemTypes } from 'libs/enums';
import {
  IScheduleRetrieveMeetingItem,
  IScheduleRetrieveSessionItem
} from 'libs/types';

export const getTimestampDividerTime = ({
  currentItem,
  previousItem
}: {
  currentItem: IScheduleRetrieveMeetingItem | IScheduleRetrieveSessionItem;
  previousItem?: IScheduleRetrieveMeetingItem | IScheduleRetrieveSessionItem;
}): string | undefined => {
  // If there isn't a previous item we show the date of the current item.
  // If there is a previous item we take the start it has and compare it to the current item. If they're the same -
  // we shouldn't show the time - thus - we return undefined. Otherwise - we return the current Item start

  const currentItemStart =
    currentItem.item_type === EScheduleItemTypes.MEETING
      ? currentItem.meeting.start
      : currentItem.session.start;

  if (!previousItem) {
    return currentItemStart;
  }

  const previousItemStart =
    previousItem.item_type === EScheduleItemTypes.MEETING
      ? previousItem.meeting.start
      : previousItem.session.start;

  if (previousItemStart === currentItemStart) {
    return undefined;
  }

  return currentItemStart;
};
