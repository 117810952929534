import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

import {
  SESSION_DETAIL_URL,
  SESSION_THUMBNAIL_PLACEHOLDER,
  EVENT_LOGO_PLACEHOLDER
} from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import {
  IVideoLibrarySessionListSession,
  IGlobalSearchSessionListSession
} from 'libs/types';
import { getUserName } from 'libs/users';

import { Image, Avatar } from 'components';

import {
  Container,
  Title,
  Overlay,
  EventLogo,
  EventName,
  SpeakerLine,
  SpeakerName,
  ImageContainer,
  ImageContainerPlaceholder,
  TitlePlaceholder,
  StyledAverageRating
} from './styles';

interface IProps {
  session: IGlobalSearchSessionListSession | IVideoLibrarySessionListSession;
  className?: string;
}

interface IPlaceholderProps {
  className?: string;
  count?: number;
}

const SessionCard = ({ session, className }: IProps) => {
  const sessionDetailUrl = SESSION_DETAIL_URL({
    eventSlug: session.event.slug,
    sessionId: session.id,
    sessionSlug: session.slug
  });

  return (
    <Container className={className}>
      <ImageContainer href={sessionDetailUrl}>
        <Image
          src={session.thumbnail || SESSION_THUMBNAIL_PLACEHOLDER}
          layout="fill"
          objectFit="cover"
        />
        <Overlay>
          <EventLogo
            src={session.event.dark_background_logo || EVENT_LOGO_PLACEHOLDER}
          />
          <SpeakerLine>
            <Avatar size={EAvatarSizes.SMALL} src={session.speaker.avatar} />
            <SpeakerName>{getUserName({ user: session.speaker })}</SpeakerName>
          </SpeakerLine>
        </Overlay>
      </ImageContainer>
      <Title href={sessionDetailUrl}>{session.title}</Title>
      <EventName>{session.event.name}</EventName>
      <StyledAverageRating
        starFontSize="14px"
        withReducedSpacingBetweenStars
        rating={session.average_rating}
      />
    </Container>
  );
};

const Placeholder = ({ className, count = 1 }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container
        className={className}
        key={`session-card-placeholder-${index}`}>
        <ImageContainerPlaceholder>
          <Skeleton />
        </ImageContainerPlaceholder>
        <TitlePlaceholder>
          <Skeleton count={2} />
        </TitlePlaceholder>
        <EventName>
          <Skeleton />
        </EventName>
        <StyledAverageRating rating={0} starFontSize="14px" />
      </Container>
    ))}
  </>
);

SessionCard.Placeholder = Placeholder;

export default SessionCard;
