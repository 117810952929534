import styled from 'styled-components';

import { colors, lightBlue, red } from 'styles/global';

import { UserLine } from 'components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 20px 0 20px;
  cursor: pointer;

  background-color: ${({ seen }: { seen: boolean }) =>
    seen ? 'transparent' : lightBlue};
`;

export const Container = styled.div`
  padding: 0 10px 12px 10px;
  margin-bottom: -1px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(123, 124, 129, 0.2);
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledUserLine = styled(UserLine)`
  max-width: calc(100% - 60px); // removing 60px to leave space for the date
`;

export const CreationDate = styled.div`
  font-size: 12px;
  line-height: 21px;
  color: ${colors.text[2]};
`;

export const Bottom = styled.div`
  margin-top: 4px;

  display: flex;
  align-items: center;
`;

export const Message = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: ${colors.text[1]};

  width: 100%;
  margin-right: 36px;
  padding-left: 48px;
  box-sizing: border-box;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UnreadMessagesCount = styled.div`
  height: 15px;
  min-width: 15px;
  padding: 0 5.25px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};

  color: white;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  border-radius: 50%;
  background-color: ${red};
`;
