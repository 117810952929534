import { ESessionStatuses } from 'libs/enums';
import { IMeUser, IEventDetailEvent } from 'libs/types';

import { SESSION_PLAYER_CONTENT_CHOICES } from './constants';

const getSessionPlayerContentForAdminPage = ({
  isSpeaker,
  status,
  user,
  event,
  isReceivingStream = false
}: {
  status: ESessionStatuses;
  isSpeaker: boolean;
  event: IEventDetailEvent;
  user?: IMeUser;
  isReceivingStream?: boolean;
}) => {
  if (!isSpeaker || !user) {
    return;
  }

  if (!event?.user_has_ticket) {
    return SESSION_PLAYER_CONTENT_CHOICES.EVENT_TICKET_PURCHASE_PROMPT;
  }

  if (
    status === ESessionStatuses.LIVE ||
    (status === ESessionStatuses.UPCOMING && isReceivingStream)
  ) {
    return SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_WITH_LIVE_INDICATOR_AND_VIEWER_COUNT;
  }

  if (status === ESessionStatuses.COMPLETED) {
    return SESSION_PLAYER_CONTENT_CHOICES.RECORDING_COMING_SOON;
  }

  if (status === ESessionStatuses.UPCOMING && !isReceivingStream) {
    return SESSION_PLAYER_CONTENT_CHOICES.COUNTDOWN_FOR_LOGGED_USER;
  }

  if (status === ESessionStatuses.NOT_COMPLETED) {
    return SESSION_PLAYER_CONTENT_CHOICES.THERE_IS_NO_RECORDING;
  }

  if (user && status === ESessionStatuses.VIDEO_AVAILABLE) {
    return SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_FROM_THE_PAST;
  }
};

const getSessionPlayerContentForLivePage = ({
  isSpeaker,
  status,
  user,
  event
}: {
  status: ESessionStatuses;
  isSpeaker: boolean;
  event: IEventDetailEvent;
  user?: IMeUser;
}) => {
  if (isSpeaker && !user) {
    return;
  }

  if (!user && status === ESessionStatuses.UPCOMING) {
    return SESSION_PLAYER_CONTENT_CHOICES.LOGIN_PROMPT_WITH_COUNTDOWN;
  }

  if (!user && status !== ESessionStatuses.UPCOMING) {
    return SESSION_PLAYER_CONTENT_CHOICES.LOGIN_PROMPT;
  }

  if (!event?.user_has_ticket) {
    return SESSION_PLAYER_CONTENT_CHOICES.EVENT_TICKET_PURCHASE_PROMPT;
  }

  if (user && status === ESessionStatuses.UPCOMING) {
    return SESSION_PLAYER_CONTENT_CHOICES.COUNTDOWN_FOR_LOGGED_USER;
  }

  if (user && status === ESessionStatuses.LIVE) {
    return SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_WITH_LIVE_INDICATOR_AND_VIEWER_COUNT;
  }

  if (user && status === ESessionStatuses.COMPLETED) {
    return SESSION_PLAYER_CONTENT_CHOICES.RECORDING_COMING_SOON;
  }

  if (user && status === ESessionStatuses.NOT_COMPLETED) {
    return SESSION_PLAYER_CONTENT_CHOICES.THERE_IS_NO_RECORDING;
  }

  if (user && status === ESessionStatuses.VIDEO_AVAILABLE) {
    return SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_FROM_THE_PAST;
  }
};

export const getSessionPlayerContent = ({
  isOnAdminPage = false,
  event,
  ...data
}: {
  isSpeaker: boolean;
  status: ESessionStatuses;
  event?: IEventDetailEvent;
  user?: IMeUser;
  isOnAdminPage?: boolean;
  isReceivingStream?: boolean;
}) => {
  if (!event) {
    // we can't proceed without an event
    return;
  }

  if (isOnAdminPage) {
    return getSessionPlayerContentForAdminPage({ ...data, event });
  }

  return getSessionPlayerContentForLivePage({ ...data, event });
};
