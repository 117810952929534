import _ from 'lodash';
import { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

import { lightBlueGrey } from 'styles/global';

import { isSameDay } from 'libs/datetime';
import { applyFormattingToDateTime } from 'libs/datetime';
import { ISessionPurchaseFormSlot } from 'libs/types';

import { Slot, ReservedSlot } from 'components/SessionPurchaseForm/components';

import {
  Container,
  HeaderRow,
  Category,
  SubCategory,
  SponsorLogo,
  Content,
  DateDivider,
  CategoryAndSubCategoryContainer
} from './styles';

interface IPlaceholderProps {
  className?: string;
}

interface IProps {
  className?: string;
  sponsorLogo?: string;
  slots: Array<ISessionPurchaseFormSlot>;
  selectedSlots: Array<ISessionPurchaseFormSlot>;
  setSelectedSlots: (arg0: Array<ISessionPurchaseFormSlot>) => void;
  categoryTitle: string;
  subCategoryTitle: string;
}

const Placeholder = ({ className }: IPlaceholderProps) => (
  <Container className={className}>
    <HeaderRow>
      <CategoryAndSubCategoryContainer>
        <Category>
          <Skeleton width="25%" />
        </Category>
        <SubCategory>
          <Skeleton width="50%" />
        </SubCategory>
      </CategoryAndSubCategoryContainer>
      <div />
    </HeaderRow>
    <Content>
      <DateDivider>
        <Skeleton width={80} />
      </DateDivider>
      <Slot.Placeholder count={10} />
    </Content>
  </Container>
);

const SlotPicker: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({
  slots,
  selectedSlots,
  setSelectedSlots,
  className,
  sponsorLogo,
  categoryTitle,
  subCategoryTitle
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleSlot = (slot: ISessionPurchaseFormSlot) => {
    const newSlots = _.xorBy(selectedSlots, [slot], 'id');

    setSelectedSlots(newSlots);
  };

  useEffect(() => {
    // new slots available
    if (contentRef.current !== null) {
      contentRef.current.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [slots]);

  return (
    <Container className={className}>
      <HeaderRow>
        <CategoryAndSubCategoryContainer>
          <Category>{categoryTitle}</Category>
          <SubCategory>{subCategoryTitle}</SubCategory>
        </CategoryAndSubCategoryContainer>
        {sponsorLogo && (
          <SponsorLogo
            height={55}
            width={200}
            src={sponsorLogo}
            additionalURLArgs={{ fit: 'fillmax', fillColor: lightBlueGrey }}
          />
        )}
      </HeaderRow>
      <Content ref={contentRef}>
        {slots.map((slot, index) => {
          const isHostedAtSameDayAsPreviousSlot = isSameDay({
            date: slot.start,
            otherDate: _.get(slots, `${index - 1}.start`)
          });
          const shouldDisplayDateDivider =
            index === 0 || !isHostedAtSameDayAsPreviousSlot;

          const selected =
            _.findIndex(
              selectedSlots,
              (selectedSlot) => selectedSlot.id === slot.id
            ) > -1;
          const dateDivider = shouldDisplayDateDivider && (
            <DateDivider>
              {applyFormattingToDateTime({
                date: slot.start,
                toFormat: 'Do MMM'
              })}
            </DateDivider>
          );

          if (slot.session) {
            return (
              <Fragment key={`session-${slot.session.id}`}>
                {dateDivider}
                <ReservedSlot session={slot.session} />
              </Fragment>
            );
          }

          return (
            <Fragment key={`slot-${slot.id}`}>
              {dateDivider}
              <Slot
                slot={slot}
                selected={selected}
                onClick={() => toggleSlot(slot)}
              />
            </Fragment>
          );
        })}
      </Content>
    </Container>
  );
};

SlotPicker.Placeholder = Placeholder;

export default SlotPicker;
