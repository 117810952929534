import styled from 'styled-components';

import { colors } from 'styles/global';

import { Link } from 'components';

export const StyledLink = styled(Link)`
  color: ${colors.text[4]};
  text-decoration: underline;
`;
