import { StyledHR } from './styles';

interface IProps {
  dashed?: boolean;
  className?: string;
}

const Divider = ({ className, dashed }: IProps) => (
  <StyledHR dashed={dashed} className={className} />
);

export default Divider;
