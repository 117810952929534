import styled from 'styled-components';

import { colors } from 'styles/global';

import { Button } from 'components';

export const InputContainer = styled.div`
  width: 100%;
  height: 55px;

  padding-left: 20px;
  padding-right: 10px;

  display: flex;
  align-items: center;

  background: #ffffff;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
`;

export const StyledInput = styled.input`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[1]};

  border: 0;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

export const StyledButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin-left: 10px;
`;
