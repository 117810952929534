import * as Sentry from '@sentry/browser';

import {
  IMeUser,
  IEventListEvent,
  IFeaturedEventListEvent,
  IOrganiserEventListEvent,
  IGlobalSearchEventListEvent
} from 'libs/types';

import { EEventStatusValues } from './enums';

export const getEventButtonProps = ({
  user,
  event
}: {
  user: IMeUser | undefined;
  event:
    | IEventListEvent
    | IFeaturedEventListEvent
    | IOrganiserEventListEvent
    | IGlobalSearchEventListEvent;
}): { colorScheme: number; children: string } => {
  let colorScheme = 1;
  let children = '';

  const isLive = event.status.value === EEventStatusValues.LIVE;
  const isPast = event.status.value === EEventStatusValues.PREVIOUS;
  const isUpcoming = !isLive && !isPast;
  const userHasTicket = event.user_has_ticket;
  const userIsLoggedIn = user ? true : false;

  // If the event is "Live" we're usint the red color scheme. Otherwise - the Primary color one.
  if (isLive) {
    colorScheme = 4;
  }

  const captureInvalidCase = () =>
    Sentry.captureMessage(
      `The getEventButtonProps util has entered an invalid state. ${JSON.stringify(
        { isLive, isPast, isUpcoming, userHasTicket, userIsLoggedIn }
      )}`
    );

  if (userIsLoggedIn) {
    if (userHasTicket) {
      if (isPast) {
        children = event.past_event_logged_user_has_ticket_text;
      } else if (isLive) {
        children = event.live_event_logged_user_has_ticket_text;
      } else if (isUpcoming) {
        children = event.upcoming_event_logged_user_has_ticket_text;
      } else {
        captureInvalidCase();
      }
    } else {
      if (isPast) {
        children = event.past_event_logged_user_no_ticket_text;
      } else if (isLive) {
        children = event.live_event_logged_user_no_ticket_text;
      } else if (isUpcoming) {
        children = event.upcoming_event_logged_user_no_ticket_text;
      } else {
        captureInvalidCase();
      }
    }
  } else {
    if (isPast) {
      children = event.past_event_unlogged_user_text;
    } else if (isLive) {
      children = event.live_event_unlogged_user_text;
    } else if (isUpcoming) {
      children = event.upcoming_event_unlogged_user_text;
    } else {
      captureInvalidCase();
    }
  }

  return { colorScheme, children };
};
