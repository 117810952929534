import styled from 'styled-components';

import { colors, lightBlue, red } from 'styles/global';

import { Icon, Button, Link } from 'components';

export const Container = styled.div`
  width: 100%;
  max-width: 445px;
  box-sizing: border-box;
  padding: 40px 30px 50px 30px;

  margin: 61px auto;

  border-radius: 2px;
  background-color: white;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  height: 42px;
  min-width: 42px;
  margin-bottom: 15px;

  border-radius: 50%;
  background-color: ${lightBlue};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(Icon)`
  font-size: 18px;
  color: ${colors.text[2]};
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  color: ${({ isSuccess }: { isSuccess: boolean }) =>
    isSuccess ? '#84B513' : red};
`;

export const Description = styled.p`
  margin-top: 22px;
  margin-bottom: 41px;

  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const ButtonWrapperLink = styled(Link)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const LandingScreenLink = styled(Link)`
  cursor: pointer;
  margin-top: 18px;

  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: ${colors.text[3]};
`;
