import { SESSIONS_URL } from 'libs/constants';

import { useWithEvent } from 'hooks';

import image from './assets/image.png';
import { STATUS_CODE_TO_MESSAGE_MAPPING } from './constants';
import {
  Container,
  StyledLink,
  Title,
  Description,
  StyledPage,
  StyledOneDimensionImage
} from './styles';

export interface IProps {
  statusCode: 403 | 404 | 500;
}

const EventSpecificErrorTemplate = ({ statusCode }: IProps) => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  return (
    <StyledPage>
      <Container>
        <StyledOneDimensionImage src={image} />
        <Title>{statusCode}</Title>
        <Description>{STATUS_CODE_TO_MESSAGE_MAPPING[statusCode]}</Description>
        {eventSlug && (
          <StyledLink href={SESSIONS_URL({ eventSlug })}>
            Return to landing page
          </StyledLink>
        )}
      </Container>
    </StyledPage>
  );
};

export default EventSpecificErrorTemplate;
