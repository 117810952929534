import { applyFormattingToDateTime } from 'libs/datetime';

import { Icon } from 'components';

import { Container, LiveIndicator } from './styles';

export interface IProps {
  className?: string;
  isPast: boolean;
  showIndicator: boolean;
  start: string;
  end: string;
}

const TimeWithLiveIndicator = ({
  showIndicator,
  isPast,
  start,
  end,
  className
}: IProps) => (
  <Container className={className}>
    {showIndicator && (
      <LiveIndicator>
        <Icon icon="dot-circle" /> LIVE
      </LiveIndicator>
    )}

    {/*
	if it's live or future - "<start> - <end>, <date>"
	if it's past - "Session took place <date>"
      */}
    {isPast
      ? 'Session took place '
      : `${applyFormattingToDateTime({
          date: start,
          toFormat: 'HH:mm'
        })} - ${applyFormattingToDateTime({
          date: end,
          toFormat: 'HH:mm, '
        })}`}
    {applyFormattingToDateTime({ date: end, toFormat: 'Do MMM YYYY' })}
  </Container>
);

export default TimeWithLiveIndicator;
