import { FunctionComponent } from 'react';

import type { IChatTabProps } from 'components/Chat';
import {
  PrivateChatDetail,
  PrivateChatPreviewList
} from 'components/Chat/components';

const DirectMessages: FunctionComponent<IChatTabProps> = ({
  openedPrivateChat,
  unreadMessagesCount
}) =>
  openedPrivateChat.chat || openedPrivateChat.isFetchingChat ? (
    <PrivateChatDetail openedPrivateChat={openedPrivateChat} />
  ) : (
    <PrivateChatPreviewList
      unreadMessagesCount={unreadMessagesCount}
      setOpenedPrivateChat={openedPrivateChat.setChat}
    />
  );

export default DirectMessages;
