import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { TextArea, Icon, Link } from 'components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 12px 21px 47px 22px;

  background-color: white;

  border-top: 1px solid rgba(123, 124, 129, 0.2);

  @media ${breakpoints.mobile} {
    padding-bottom: 20px;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: auto;
  margin: 6px 0 15px 38px;

  textarea {
    resize: none;

    height: unset;
    padding: 0 0 6px 0;
    border: 0; // remove all the default borders
    border-bottom: 1px solid rgba(123, 124, 129, 0.2);

    font-size: 15px;
    line-height: 21px;

    &:focus {
      border: 0; // remove all the default borders
      border-bottom: 1px solid rgba(123, 124, 129, 0.2);
    }
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FAQLink = styled(Link)`
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};

  margin-left: 38px;
`;

export const Right = styled.div`
  margin-left: 20px;

  display: flex;
  align-items: center;
`;

export const CharacterLimit = styled.div`
  font-size: 13px;
  line-height: 21px;
  color: ${colors.text[2]};

  margin-right: 26px;
`;

export const Submit = styled(Icon)`
  color: ${({ theme }: { theme: ITheme; disabled: boolean }) =>
    theme.primaryColor};
  opacity: ${({ disabled }: { disabled: boolean; theme: ITheme }) =>
    disabled ? '0.5' : '1'};
  cursor: ${({ disabled }: { disabled: boolean; theme: ITheme }) =>
    disabled ? 'not-allowed' : 'pointer'};
`;
