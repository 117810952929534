import _ from 'lodash';
import { FunctionComponent } from 'react';

import { EAvatarSizes } from 'libs/enums';
import { ISessionDetailSessionAttendee } from 'libs/types';

import { useWithUser } from 'hooks';

import {
  Actions,
  Container,
  StyledIcon,
  StyledUserLine,
  IconContainer,
  StyledUserLinePlaceholder
} from './styles';

interface IProps {
  openBookAMeetingModal: (attendeeId: number) => void;
  openNewPrivateChat: (receivers: number[]) => void;
  attendee: ISessionDetailSessionAttendee;
  onClick: () => void;
}

interface IPlaceholderProps {
  count?: number;
}

const Placeholder = ({ count = 1 }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container key={`attendee-line-placeholder-${index}`}>
        <StyledUserLinePlaceholder withOccupation size={EAvatarSizes.XSMALL} />
      </Container>
    ))}
  </>
);

const AttendeeLine: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({
  attendee,
  openNewPrivateChat,
  openBookAMeetingModal,
  onClick
}: IProps) => {
  const { user } = useWithUser();

  return (
    <Container onClick={onClick}>
      <StyledUserLine user={attendee} size={EAvatarSizes.XSMALL} />
      {user?.id !== attendee.id && (
        <Actions>
          <IconContainer
            onClick={(event) => {
              event.stopPropagation();
              openBookAMeetingModal(attendee.id);
            }}>
            <StyledIcon icon="video" />
          </IconContainer>
          <IconContainer
            onClick={(event) => {
              event.stopPropagation();
              openNewPrivateChat([attendee.id]);
            }}>
            <StyledIcon icon="edit" />
          </IconContainer>
        </Actions>
      )}
    </Container>
  );
};

AttendeeLine.Placeholder = Placeholder;

export default AttendeeLine;
