import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { EventLine, Button } from 'components';

export const Wrapper = styled.div`
  padding-top: 150px;
  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    padding: 50px 15px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  color: ${colors.text[1]};

  margin-bottom: 38px;
`;

export const StyledEventLine = styled(EventLine)`
  width: 100%;
  margin-bottom: 16px;
`;

export const TicketName = styled.p`
  margin-bottom: 36px;

  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const PaymentStatusTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  color: ${({ isRed }: { isRed: boolean }) =>
    isRed ? colors.text[6] : colors.text[5]};

  margin-top: 32px;
  margin-bottom: 6px;
`;

export const PaymentStatusDescription = styled.p`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 39px;
`;
