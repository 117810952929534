import styled from 'styled-components';

import { red } from 'styles/global';

export const Container = styled.div`
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 2px;
`;

export const LiveIndicator = styled.span`
  color: ${red};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  margin-right: 7px;
`;
