import { CATEGORY_LIST_URL, HOME_URL, SESSIONS_URL } from 'libs/constants';
import type { IEventDetailEvent, ICategoryListCategory } from 'libs/types';

import type { IIconBreadcrumb, IBreadcrumb } from 'components/Breadcrumbs';

export const getBreadcrumbs = ({
  event,
  activeTab
}: {
  event: IEventDetailEvent;
  activeTab: ICategoryListCategory | null | undefined;
}): Array<IIconBreadcrumb | IBreadcrumb> => {
  const eventSlug = event.slug;

  const breadcrumbs: Array<IIconBreadcrumb | IBreadcrumb> = [
    { isIcon: true, title: 'home', href: HOME_URL },
    {
      isIcon: false,
      title: event.name,
      href: SESSIONS_URL({
        eventSlug
      })
    }
  ];

  if (activeTab) {
    breadcrumbs.push({
      isIcon: false,
      title: activeTab.category.title,
      href: CATEGORY_LIST_URL({
        category: activeTab.category.slug,
        eventSlug
      })
    });
  }

  return breadcrumbs;
};
