import { Image } from 'components';

import image from './assets/image.png';
import { Container, Title, Description } from './styles';

interface IProps {
  className?: string;
}

const EmptyPrivateChats = ({ className }: IProps) => (
  <Container className={className}>
    <Image src={image} width={224.41} height={203.5} />
    <Title>No Messages</Title>
    <Description>You do not have any private DMs.</Description>
  </Container>
);

export default EmptyPrivateChats;
