import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon } from 'components';

export const Container = styled.div`
  color: white;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledIcon = styled(Icon)`
  color: inherit;
  font-size: 24px;

  margin-bottom: 10px;

  animation: rotateClockWise 20s infinite;

  @keyframes rotateClockWise {
    0%,
    90% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(720deg);
    }
  }
`;

export const Text = styled.p`
  color: inherit;
  font-size: 18px;
  line-height: 22px;
`;

export const Time = styled.div`
  margin-top: 15px;

  width: 230px;
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Bold = styled.span`
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
`;

export const Regular = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text[2]};
  text-align: center;

  margin-top: 2px;
`;
