import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { OneDimensionImage } from 'components';

export const Container = styled.div`
  width: 100%;
  padding: 51px 0;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    padding: 30px 15px;
    box-sizing: border-box;
    max-width: 100vw;
  }
`;

export const StyledOneDimensionImage = styled(OneDimensionImage)`
  max-width: 100%;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  margin-top: 41px;

  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${colors.text[2]};
`;
