import styled from 'styled-components';

import { Label } from 'components';

export const Container = styled.div`
  grid-area: participants;

  display: grid;
  grid-gap: 10px;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
`;

export const ParticipantsLabel = styled(Label)`
  width: 90px;
  grid-column: 1 / -1;
`;
