import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Link, Icon, Button, EventLine } from 'components';

import { TicketTypeLine } from './components';

export const Wrapper = styled.div`
  padding-top: 54px;
  padding-left: 73px;
  padding-right: 73px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;

  @media ${breakpoints.mobile} {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const BackLink = styled(Link)`
  align-self: flex-start;

  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 14px;
`;

export const Container = styled.div`
  margin-top: 76px;

  width: 100%;
  max-width: 503px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  margin-bottom: 38px;
`;

export const StyledEventLine = styled(EventLine)`
  margin-bottom: 20px;
`;

export const StyledTicketTypeLine = styled(TicketTypeLine)`
  margin-bottom: 12px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 28px;
`;
