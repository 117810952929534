import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Link, OneDimensionImage } from 'components';

export const Wrapper = styled.div`
  padding-top: 55px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media ${breakpoints.mobile} {
    padding: 0 15px;
    box-sizing: border-box;
  }
`;

export const StyledOneDimensionImage = styled(OneDimensionImage)`
  max-width: 100%;
`;

export const Title = styled.h2`
  margin-top: 36px;
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${colors.text[2]};
`;

export const StyledLink = styled(Link)`
  margin-top: 31px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  cursor: pointer;
`;
