import { useField, FormikProps, withFormik, Form } from 'formik';
import _ from 'lodash';
import { ChangeEvent, FunctionComponent } from 'react';
import { toast } from 'react-toastify';

import { emailIsValid } from 'libs/common';
import { formatFormErrors } from 'libs/common';
import { sessionParticipantsUpdate } from 'libs/requests';
import type {
  ISessionSpeakerDetailSession,
  ISessionUpdateSessionParticipant
} from 'libs/types';
import { getUserName } from 'libs/users';

import { FormLabel, FieldError } from 'components';

import {
  Container,
  Description,
  StyledCustomInput,
  TrashIcon,
  Status,
  SubmitButton,
  InputContainer,
  InputPlaceholder,
  ParticipantAndInvitationLine
} from './styles';

const DELIMITERS = [',', ' '];
const INVITATION_FIELD_NAME = 'invite_emails';
const PARTICIPANT_FIELD_NAME = 'participants';

interface IProps {
  session: ISessionSpeakerDetailSession;
  setSession: (session: ISessionSpeakerDetailSession) => void;
}

interface IFormValues {
  invite_emails: Array<string>;
  participants: Array<ISessionUpdateSessionParticipant>;
}

interface IPreparedFormValues extends Omit<IFormValues, 'participants'> {
  participants: Array<number>;
}

const SessionParticipantManager: FunctionComponent<
  IProps & FormikProps<IFormValues>
> = ({ dirty, isValid, isSubmitting }) => {
  const [invitationField, , invitationHelpers] = useField<Array<string>>({
    name: INVITATION_FIELD_NAME
  });

  const [participantField, , participantHelpers] = useField<
    Array<ISessionUpdateSessionParticipant>
  >({
    name: PARTICIPANT_FIELD_NAME
  });

  const emailInputHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const lastSymbol = _.last(value);

    if (_.includes(DELIMITERS, lastSymbol)) {
      const email = value.slice(0, value.length - 1);

      if (_.isEmpty(email)) {
        event.target.value = '';
        return;
      }

      const participantEmails = _.map(
        participantField.value,
        (participant: ISessionUpdateSessionParticipant) => participant.email
      );
      const invitationEmails = _.get(invitationField, 'value', []);

      const usedEmails = [...participantEmails, ...invitationEmails];

      if (_.includes(usedEmails, email)) {
        toast(`${email} has already been added.`, { type: 'error' });
      } else if (!emailIsValid(email)) {
        toast(`${email} is not a valid email.`, { type: 'error' });
      } else {
        invitationHelpers.setValue(
          [email, ..._.get(invitationField, 'value', [])],
          false
        );
      }

      event.target.value = '';
    }
  };

  const removeInvitation = (email: string) => {
    const previousValue = invitationField.value;
    const newValue = previousValue.filter((item) => item !== email);

    invitationHelpers.setValue(newValue);
  };

  const removeParticipant = (participant: ISessionUpdateSessionParticipant) => {
    const previousValue = participantField.value;
    const newValue = previousValue.filter((item) => item !== participant);

    participantHelpers.setValue(newValue);
  };

  return (
    <Form>
      <FormLabel>Invite Participants</FormLabel>
      <Container>
        <Description>Invite via email address</Description>
        <InputContainer>
          <StyledCustomInput
            onChange={emailInputHandleChange}
            placeholder=" "
          />
          <InputPlaceholder>add using spaces or commas</InputPlaceholder>
        </InputContainer>
        {_.map(invitationField.value, (email) => (
          <ParticipantAndInvitationLine key={`invitation-${email}`}>
            <TrashIcon
              icon="trash-alt"
              weight="far"
              onClick={() => removeInvitation(email)}
            />
            {email}
            <Status accepted={false}>Pending</Status>
          </ParticipantAndInvitationLine>
        ))}
        {participantField.value.map((participant) => (
          <ParticipantAndInvitationLine
            key={`participant-${participant.email}`}>
            <TrashIcon
              icon="trash-alt"
              weight="far"
              onClick={() => removeParticipant(participant)}
            />
            {getUserName({ user: participant })}
            <Status accepted={true}>Accepted</Status>
          </ParticipantAndInvitationLine>
        ))}
      </Container>
      <FieldError name={INVITATION_FIELD_NAME} />
      <FieldError name={PARTICIPANT_FIELD_NAME} />
      <SubmitButton
        type="submit"
        colorScheme={1}
        height="medium"
        fontSize={16}
        fontWeight={600}
        disabled={!dirty || isSubmitting || !isValid}>
        Save
      </SubmitButton>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  enableReinitialize: true,

  mapPropsToValues: ({ session }) =>
    _.pick(session, [INVITATION_FIELD_NAME, PARTICIPANT_FIELD_NAME]),

  handleSubmit: async (
    formData,
    { props: { session, setSession }, resetForm, setErrors }
  ) => {
    const preparedFormData: IPreparedFormValues = {
      ...formData,
      participants: formData.participants.map((participant) => participant.id)
    };

    const { success, data, error } = await sessionParticipantsUpdate({
      sessionId: session.id,
      data: preparedFormData
    });

    if (success && data) {
      toast('Participants have been updated successfully!', {
        type: 'success'
      });
      resetForm({ values: data });
      setSession({ ...session, ...data });
    } else if (error.status === 400) {
      const formattedErrors = formatFormErrors(error.data);

      setErrors(formattedErrors);
    }
  }
})(SessionParticipantManager);
