import _ from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { sessionAddToSchedule, sessionRemoveFromSchedule } from 'libs/requests';
import { unissuLogin } from 'libs/unissuAuth';

import { useWithUser } from 'hooks';

import Button, { IButtonProps } from 'components/Button';

interface IProps
  extends Omit<
    IButtonProps,
    'children' | 'icon' | 'disabled' | 'onClick' | 'colorScheme'
  > {
  sessionId: number;
  initialIsAddedToSchedule: boolean;
}

const ScheduleSessionToggleButton = ({
  sessionId,
  initialIsAddedToSchedule,
  ...props
}: IProps) => {
  const { user } = useWithUser();
  const [isAddedToSchedule, setIsAddedToSchedule] = useState(
    initialIsAddedToSchedule
  ); // we use this to be able to modify the value without passing a state modifier from the outside.
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const toggleSessionIsAddedToSchedule = async () => {
    if (!user) {
      return unissuLogin();
    }

    setIsButtonDisabled(true);

    const request = isAddedToSchedule
      ? sessionRemoveFromSchedule
      : sessionAddToSchedule;

    const { success, error } = await request({ sessionId });

    if (success) {
      setIsAddedToSchedule(!isAddedToSchedule);
    } else {
      const errorMessage = _.get(
        error,
        'data.0',
        'Something went wrong. Please try again later.'
      );
      toast(errorMessage, { type: 'error' });
    }

    setIsButtonDisabled(false);
  };

  const commonProps = {
    ...props,
    disabled: isButtonDisabled,
    onClick: toggleSessionIsAddedToSchedule
  };

  return isAddedToSchedule ? (
    <Button {...commonProps} colorScheme={2} icon="check">
      Added to Schedule
    </Button>
  ) : (
    <Button {...commonProps} colorScheme={1} icon="plus">
      Add to Schedule
    </Button>
  );
};

export default ScheduleSessionToggleButton;
