import { useField } from 'formik';
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { ISessionSpeakerDetailSessionPrerecordedVideo } from 'libs/types';

import {
  FieldError,
  File as FileLine //to avoid confusion with the File interface
} from 'components';
import { PrerecordedVideoRemovalConfirmationModal } from 'components/SessionControlCenter/components';

import {
  Root,
  StyledLabel,
  Content,
  UploadButton,
  FileContainer,
  StyledIcon,
  FileStatus,
  UploadTimeDisclaimer
} from './styles';

interface IProps {
  name: string;
  disabled: boolean;
  description?: string;
  [key: string]: any;
}

const PrerecordedVideoManager = ({
  description,
  disabled,
  ...props
}: IProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // Errors for this field must be in external validation schema.
  const [field, , { setTouched, setValue }] = useField(props);
  const file: ISessionSpeakerDetailSessionPrerecordedVideo | null | File =
    field.value;

  const addFile = (acceptedFiles: Array<File>) => {
    if (disabled) {
      return;
    }

    setTouched(true);
    setValue(acceptedFiles[0]);
  };

  const openFileRemovalConfirmationModal = () => {
    if (disabled) {
      return;
    }

    setIsConfirmationModalOpen(true);
  };

  const removeFile = () => {
    if (disabled) {
      return;
    }

    setTouched(true);
    setValue(null);
  };

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: addFile,
    noClick: true,
    ...props
  });

  const filename: string = file
    ? ('name' in file && file?.name) ||
      ('filename' in file && file?.filename) ||
      ''
    : '';

  useEffect(() => {
    if (file && 'status' in file && file?.status === 'Failed') {
      toast(
        'Something has went wrong with your prerecorded video upload. Please try again.',
        { type: 'error' }
      );
    }
  }, [file]);

  return (
    <div>
      <StyledLabel>Pre Recorded Video Content</StyledLabel>
      <UploadTimeDisclaimer>
        <span role="img" aria-label="warning">
          ⚠️
        </span>{' '}
        Please upload your content in good time to ensure a smooth experience.
      </UploadTimeDisclaimer>
      <Root {...getRootProps()}>
        <input {...getInputProps()} />
        <Content>
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p>Drag and drop a single video file here to upload, or</p>
          )}
          <UploadButton
            type="button"
            onClick={open}
            colorScheme={2}
            height="short"
            fontSize={15}
            fontWeight={500}
            disabled={disabled}>
            Upload
          </UploadButton>
        </Content>
        {file && (
          <div>
            <FileContainer>
              <StyledIcon
                icon="trash-alt"
                weight="far"
                onClick={openFileRemovalConfirmationModal}
                disabled={disabled}
              />
              <FileLine withIcon={false} filename={filename} />
              {'status' in file ? (
                <FileStatus redColor={file.status === 'Failed'}>
                  {file.status || 'Pending'}
                </FileStatus>
              ) : (
                <FileStatus redColor={true}>Save to upload</FileStatus>
              )}
            </FileContainer>
          </div>
        )}
      </Root>
      <FieldError name={props.name} />
      {isConfirmationModalOpen && (
        <PrerecordedVideoRemovalConfirmationModal
          confirmAction={removeFile}
          close={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </div>
  );
};

PrerecordedVideoManager.defaultProps = {
  accept: 'video/*',
  multiple: false
};

export default PrerecordedVideoManager;
