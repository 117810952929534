import styled from 'styled-components';

import { darkGrey, colors, breakpoints } from 'styles/global';

import {
  Link,
  Button,
  Icon,
  Label,
  AverageRating,
  SessionParticipants,
  TimeWithLiveIndicator
} from 'components';

export const Container = styled.div`
  background: white;
  // we do this magic so that we can keep the animation but have the tooltip appear outside the box
  overflow: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'visible' : 'hidden'};
  animation: ${({ isExpanded }: { isExpanded: boolean }) =>
      isExpanded ? 'swapOverflow' : ''}
    0.3s linear;

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  padding: 23px 30px;
  box-sizing: border-box;

  &:not(:first-of-type) {
    margin-top: 21px;
  }

  @keyframes swapOverflow {
    0% {
      overflow: hidden;
    }

    99% {
      overflow: hidden;
    }

    100% {
      overflow: visible;
    }
  }
`;

export const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 31px;

  grid-template-areas:
    'time . .'
    'title buttonAndCapacityLeft toggler'
    'userLine buttonAndCapacityLeft .';

  @media ${breakpoints.tablet} {
    grid-gap: 15px 0;
    align-items: center;
    grid-template-columns: 1fr 31px;
    grid-template-areas: 'time toggler' 'title title' 'userLine userLine' 'buttonAndCapacityLeft buttonAndCapacityLeft';
  }
`;

export const StyledTimeWithLiveIndicator = styled(TimeWithLiveIndicator)`
  grid-area: time;
`;

export const TitleWrapper = styled.div`
  grid-area: title;
  padding-right: 20px;
  padding-bottom: 20px;

  @media ${breakpoints.tablet} {
    padding-right: unset;
  }
`;

export const TitleLink = styled(Link)`
  display: inline;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const Title = styled.div`
  display: inline;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const ButtonAndCapacityLeft = styled.div`
  grid-area: buttonAndCapacityLeft;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const CapacityLeft = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[3]};

  margin-top: 16px;
`;

export const Toggler = styled(Icon)`
  grid-area: toggler;

  cursor: pointer;
  margin-top: 13px;
  margin-left: 15px;

  @media ${breakpoints.tablet} {
    margin-top: unset;
  }
`;

export const HostContainer = styled.div`
  grid-area: userLine;
  display: flex;
`;

export const HostLabel = styled(Label)`
  margin-top: 3px;
  margin-left: 8px;
`;

export const Bottom = styled.div`
  margin-top: 15px;
  overflow: visible;
  animation: expand 0.4s ease;

  display: grid;
  grid-template-columns: 490px 1fr 207px;
  grid-template-areas:
    'participants . whoIsAttending'
    'description . whoIsAttending'
    'sponsors . whoIsAttending';

  @media ${breakpoints.desktop} {
    grid-template-columns: 2fr 1fr 207px;
    grid-template-areas:
      'participants participants .'
      'description . whoIsAttending'
      'sponsors . whoIsAttending';
  }

  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'participants participants' 'description description' 'whoIsAttending whoIsAttending' 'sponsors sponsors';
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: unset;
    grid-template-areas: 'participants' 'description' 'whoIsAttending' 'sponsors';
  }

  @keyframes expand {
    0% {
      max-height: 0px;
    }

    99% {
      max-height: 500px;
    }

    100% {
      max-height: unset;
    }
  }
`;

export const StyledAverageRating = styled(AverageRating)`
  grid-area: averageRating;
  padding-bottom: 10px;

  @media ${breakpoints.tablet} {
    align-self: flex-end;
    justify-self: flex-end;
  }

  @media ${breakpoints.mobile} {
    align-self: unset;
    justify-self: unset;
    margin-top: 28px;
  }
`;

export const StyledSessionParticipants = styled(SessionParticipants)`
  margin-bottom: 28px;
  grid-area: participants;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-bottom: 11px;
`;

export const Description = styled.div`
  grid-area: description;

  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
`;

export const WhoIsAttending = styled.div`
  grid-area: whoIsAttending;

  margin-right: 50px;

  @media ${breakpoints.tablet} {
    margin-top: 28px;
    margin-right: unset;
  }
`;

export const Attendees = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 42px);
`;

export const AttendeeTooltip = styled.div`
  padding: 4px 10px;
  background-color: ${darkGrey};
  border: 1px solid rgba(123, 124, 129, 0.2);
  border-radius: 2px;

  width: 165px;
  box-sizing: border-box;

  top: calc(100% + 12px);
  position: absolute;
  z-index: 2;

  display: none;
  animation: fadeIn 0.2s linear;

  &:before {
    left: calc(50% - 5px);
    top: -6px;
    position: absolute;

    transform: rotate(-45deg);
    content: '';
    height: 0;
    width: 0;

    border-width: 6px;
    border-style: solid;
    border-radius: 2px;
    border-color: ${darkGrey} ${darkGrey} transparent transparent;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const AttendeeCircle = styled.div`
  width: 42px;
  height: 42px;

  position: relative;
  overflow-x: visible;

  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover > ${AttendeeTooltip} {
    display: block;
  }
`;

export const AttendeeName = styled.p`
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AttendeeOccupation = styled.p`
  color: white;
  font-size: 12px;
  line-height: 21px;
  opacity: 0.6;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AttendeeCirclePlaceholder = styled.div`
  width: 42px;
  height: 42px;

  border-radius: 50%;
  background: #f5f5f5;

  border: 0.993056px dashed rgba(123, 124, 129, 0.2);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChairIcon = styled(Icon)`
  color: #afafaf;
  font-size: 18px;
`;

export const SponsorsContainer = styled.div`
  grid-area: sponsors;
  margin-top: 24px;
`;

export const Sponsors = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 27px;
  margin-right: -25px; // to negate the right margin of the last logo
  margin-bottom: -30px; // to negate the bottom margin of the last row of logos

  img {
    max-width: 150px;
    max-height: 50px;
  }
`;

export const SponsorLink = styled(Link)`
  margin-right: 25px;
  margin-bottom: 30px;
`;
