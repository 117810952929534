import { Form } from 'formik';
import styled from 'styled-components';

import { red, colors, breakpoints } from 'styles/global';

export const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 13px;

  padding-bottom: 110px;
`;

export const FieldsContainer = styled.div`
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);

  box-sizing: border-box;
  padding: 16px 25px 20px 16px;

  display: grid;
  grid-gap: 18px;

  position: relative;

  @media ${breakpoints.mobile} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[1]};
`;

export const PrerecordedVideoDisclaimer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  color: ${red};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: right;

  padding: 15px 24px;
  box-sizing: border-box;

  backdrop-filter: blur(2px);

  @media ${breakpoints.mobile} {
    padding: 15px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(4px);
  }
`;
