import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Icon, Button } from 'components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  top: 0;
  left: 0;
  z-index: 3;
  position: fixed;
  background-color: #30596e99;

  overflow: hidden;

  opacity: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)};
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};

  animation: ${({ visible }: { visible: boolean }) =>
      visible ? 'fadeIn' : 'fadeOut'}
    0.5s linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      visibility: visible;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      visibility: visible;
    }

    99% {
      opacity: 0;
      visibility: visible;
    }

    100% {
      visibility: hidden;
    }
  }
`;

export const Container = styled.div`
  width: 643px;
  height: 100%;
  max-width: 100vw;
  background: #ffffff;
  box-shadow: -10px 0px 15px rgba(0, 0, 0, 0.05);

  padding-top: 55px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  position: absolute;
  right: ${({ visible }: { visible: boolean }) => (visible ? '0px' : '-700px')};

  transition: right 0.5s linear;
`;

export const Header = styled.div`
  box-sizing: border-box;
  padding: 55px 28px 17px 39px;

  display: flex;
  justify-content: space-between;

  color: ${colors.text[1]};

  @media ${breakpoints.mobile} {
    padding: 15px;
    flex-shrink: 0;
  }
`;

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: inherit;

  margin-bottom: 7px;
`;

export const ItemCount = styled.div`
  font-size: 22px;
  line-height: 26px;
  color: inherit;
`;

export const CloseIcon = styled(Icon)`
  color: inherit;
  font-size: 16px;

  cursor: pointer;
  margin-top: 9px;
`;

export const Content = styled.div`
  flex-grow: 1;
  border: 1px solid rgba(123, 124, 129, 0.2);

  box-sizing: border-box;
  padding: 30px 28px 9px 40px;

  overflow-y: auto;

  @media ${breakpoints.mobile} {
    padding: 30px 15px;
  }

  & > * {
    // every child
    margin-bottom: 21px;
  }
`;

export const Footer = styled.div`
  box-sizing: border-box;
  padding: 18px 39px 30px 40px;

  color: ${colors.text[1]};

  @media ${breakpoints.mobile} {
    padding: 15px;
  }
`;

export const Total = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: inherit;

  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;
