import { FunctionComponent } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share';

import facebook from './assets/facebook.png';
import linkedin from './assets/linkedin.png';
import twitter from './assets/twitter.png';
import whatsapp from './assets/whatsapp.png';
import { Icon } from './styles';

const ShareIcons: FunctionComponent = () => {
  const url = process.browser ? window.location.href : '';

  return (
    <>
      <TwitterShareButton url={url}>
        <Icon background={twitter} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <Icon background={linkedin} />
      </LinkedinShareButton>
      <FacebookShareButton url={url}>
        <Icon background={facebook} />
      </FacebookShareButton>
      <WhatsappShareButton url={url}>
        <Icon background={whatsapp} />
      </WhatsappShareButton>
    </>
  );
};

export default ShareIcons;
