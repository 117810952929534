import _ from 'lodash';
import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

import { SESSION_DETAIL_URL } from 'libs/constants';
import { getTimeDifference } from 'libs/datetime';
import { EAvatarSizes, ESessionStatuses } from 'libs/enums';
import { makeSessionLive } from 'libs/requests';
import { ISessionSpeakerDetailSession } from 'libs/types';
import { getUserName } from 'libs/users';

import { useWithEvent } from 'hooks';

import { Link, Avatar, TimeWithLiveIndicator } from 'components';

import type { ITab } from './components/TabView';
import { TABS } from './constants';
import {
  Container,
  StyledTabView,
  Header,
  Left,
  HeaderTitle,
  HeaderDescription,
  Bold,
  Slash,
  AvatarContainer,
  Right,
  GoLiveButton,
  HoverablePopup,
  HoverablePopupTitle,
  HoverablePopupItem,
  LiveViewButton,
  VisibleOnTabletAndBelow,
  GoLiveButtonContainer,
  HoverablePopupItemIcon,
  PrerecordedVideoGoLiveDisclaimer
} from './styles';

interface IProps {
  session: ISessionSpeakerDetailSession;
  setSession: Dispatch<
    SetStateAction<ISessionSpeakerDetailSession | undefined>
  >;
}

const SessionControlCenter = ({ session, setSession }: IProps) => {
  const [activeTab, setActiveTab] = useState<ITab>(TABS[0]);

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const markSessionAsLive = useCallback(() => {
    setSession((oldSessionData) =>
      oldSessionData
        ? {
            ...oldSessionData,
            status: ESessionStatuses.LIVE
          }
        : oldSessionData
    );
  }, [setSession]);

  const handleGoLiveButtonClick = useCallback(async () => {
    const { success, error } = await makeSessionLive({ sessionId: session.id });

    if (success) {
      toast('You are now live!', { type: 'success' });
      markSessionAsLive();
    } else {
      let message = _.reduce(
        error.data,
        (result, value) => `${result} ${value}`,
        ''
      );

      if (!message) {
        message = 'Something went wrong. Please try again later.';
      }

      toast(message, { type: 'error' });
    }
  }, [session.id, markSessionAsLive]);

  const sessionIsScheduledInLessThan5Minutes =
    getTimeDifference({ time: session.start }).minutes < 5;

  return (
    <Container>
      <TimeWithLiveIndicator
        start={session.start}
        end={session.end}
        isPast={
          session.status === ESessionStatuses.COMPLETED ||
          session.status === ESessionStatuses.NOT_COMPLETED
        }
        showIndicator={session.status === ESessionStatuses.LIVE}
      />
      <Header>
        <Left>
          <AvatarContainer>
            <Avatar size={EAvatarSizes.XLARGE} src={session.speaker.avatar} />
          </AvatarContainer>
          <HeaderTitle>
            {getUserName({ user: session.speaker })}
            <Slash> / </Slash>
            <Bold>Admin Panel</Bold>
          </HeaderTitle>
          <HeaderDescription>
            Fill out your session details for a better viewing experience for
            your audience
          </HeaderDescription>
        </Left>

        <Right>
          {session.session_type === 'Stream' ? (
            <GoLiveButtonContainer>
              <GoLiveButton
                colorScheme={4}
                fontSize={15}
                fontWeight={600}
                height="tall"
                disabled={!session.can_make_live}
                onClick={handleGoLiveButtonClick}>
                Go Live
              </GoLiveButton>
              {!session.can_make_live && (
                <HoverablePopup>
                  <HoverablePopupTitle>
                    This button will become active when:
                  </HoverablePopupTitle>
                  <HoverablePopupItem active={session.is_receiving_stream}>
                    <HoverablePopupItemIcon icon="check" />
                    Your stream has been connected for 30 seconds
                  </HoverablePopupItem>
                  <HoverablePopupItem
                    active={sessionIsScheduledInLessThan5Minutes}>
                    <HoverablePopupItemIcon icon="check" />5 minutes or less
                    remain before your booked start time
                  </HoverablePopupItem>
                </HoverablePopup>
              )}
            </GoLiveButtonContainer>
          ) : (
            <PrerecordedVideoGoLiveDisclaimer>
              Your session will go Live at the booked start time
            </PrerecordedVideoGoLiveDisclaimer>
          )}
          <VisibleOnTabletAndBelow>
            {eventSlug && (
              <Link
                href={SESSION_DETAIL_URL({
                  sessionId: session.id,
                  sessionSlug: session.slug,
                  eventSlug
                })}>
                <LiveViewButton
                  outline
                  colorScheme={1}
                  fontSize={15}
                  fontWeight={600}
                  height="tall">
                  Live View
                </LiveViewButton>
              </Link>
            )}{' '}
          </VisibleOnTabletAndBelow>
        </Right>
      </Header>
      <StyledTabView
        tabs={TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeComponentProps={{ session, setSession }}
      />
    </Container>
  );
};

export default SessionControlCenter;
