import { formatPrice } from 'libs/prices';
import { IEventTicketTypeListTicketType } from 'libs/types';

import { Container, Indicator, Name, Description, Price } from './styles';

interface IProps {
  selected: boolean;
  onClick: () => void;
  ticketType: IEventTicketTypeListTicketType;
  className?: string;
}

const TicketTypeLine = ({
  className,
  onClick,
  ticketType,
  selected
}: IProps) => (
  <Container className={className} onClick={onClick} selected={selected}>
    <Indicator selected={selected} />
    <Name>{ticketType.name}</Name>
    <Description>{ticketType.description}</Description>
    <Price>
      {ticketType.is_free ? 'Free' : `£${formatPrice(ticketType.price)}`}
    </Price>
  </Container>
);

export default TicketTypeLine;
