import styled from 'styled-components';

import { colors } from 'styles/global';

import { FormLabel, Button, Icon } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 11px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[1]};

  margin-top: -5px;
  margin-bottom: 20px;
`;

export const Root = styled.div`
  outline: none;
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.text[1]};

  box-sizing: border-box;
  padding: 11px 24px 11px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadButton = styled(Button)`
  width: 96px;
`;

export const FileContainer = styled.div`
  min-height: 36px;

  display: flex;
  align-items: center;

  padding: 0 16px;
  box-sizing: border-box;

  border-top: 1px solid rgba(123, 124, 129, 0.2);
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  font-size: 12px;
  margin-right: 11px;
`;
