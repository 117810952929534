import {
  SESSIONS_URL,
  SCHEDULE_URL,
  SESSION_PURCHASE_URL
} from 'libs/constants';

import { useWithEvent } from 'hooks';

import {
  Container,
  StyledIcon,
  IconWrapper,
  Title,
  Description,
  StyledButton,
  LandingScreenLink,
  ButtonWrapperLink
} from './styles';

interface IProps {
  status: 'success' | 'cancel';
}

const PaymentStatusBlock = ({ status }: IProps) => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const isSuccess = status === 'success';

  return (
    <Container>
      <IconWrapper>
        <StyledIcon icon={isSuccess ? 'check' : 'times'} />
      </IconWrapper>
      <Title isSuccess={isSuccess}>
        Your Payment was {isSuccess ? 'Successful' : 'Unsuccessful'}!
      </Title>

      <Description>
        {isSuccess ? (
          <>
            Enter session details through your admin panel.
            <br />
            You can provide this information any time before the event.
          </>
        ) : (
          <>
            Something has went wrong.
            <br />
            Please try again.
          </>
        )}
      </Description>
      {eventSlug && (
        <>
          <ButtonWrapperLink
            href={
              isSuccess
                ? SCHEDULE_URL({ eventSlug })
                : SESSION_PURCHASE_URL({ eventSlug })
            }>
            <StyledButton
              height="tall"
              fontSize={16}
              fontWeight={600}
              colorScheme={isSuccess ? 1 : 4}>
              {isSuccess ? 'View Schedule' : 'Try Again'}
            </StyledButton>
          </ButtonWrapperLink>
          <LandingScreenLink href={SESSIONS_URL({ eventSlug })}>
            Return to Landing Screen
          </LandingScreenLink>
        </>
      )}
    </Container>
  );
};

export default PaymentStatusBlock;
