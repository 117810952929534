import { EAvatarSizes } from 'libs/enums';

export const getAvatarDimensions = ({ size }: { size: EAvatarSizes }) => {
  switch (size) {
    case EAvatarSizes.XSMALL:
      return {
        height: 28,
        width: 28
      };
    case EAvatarSizes.SMALL:
      return {
        height: 32,
        width: 32
      };
    case EAvatarSizes.MEDIUM:
      return {
        height: 38,
        width: 38
      };
    case EAvatarSizes.LARGE:
      return {
        height: 42,
        width: 42
      };
    case EAvatarSizes.XLARGE:
      return {
        height: 48,
        width: 48
      };
    case EAvatarSizes.XXLARGE:
      return {
        height: 80,
        width: 80
      };
    case EAvatarSizes.XXXLARGE:
      return {
        height: 92,
        width: 92
      };
  }
};
