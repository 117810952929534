import { unissuLogin, unissuSignUp } from 'libs/unissuAuth';

import {
  Container,
  Description,
  SignUpButton,
  LoginText,
  BroughtToYou,
  BoldLink,
  StyledIcon,
  FeatureExplanation
} from './styles';

const ExplanatorySignUpPrompt = () => (
  <Container>
    <Description>
      Join Unissu Events and access inspiring real estate events and videos from
      around the world
    </Description>
    <SignUpButton
      height="short"
      colorScheme={1}
      fontSize={14}
      onClick={unissuSignUp}>
      Sign Up
    </SignUpButton>
    <LoginText
      colorScheme={5}
      fontSize={13}
      fontWeight={600}
      onClick={unissuLogin}>
      Log in
    </LoginText>

    <StyledIcon icon="video" />
    <FeatureExplanation>
      Insightful, thought provoking content from the most innovative real estate
      organistaions
    </FeatureExplanation>

    <StyledIcon icon="globe" />
    <FeatureExplanation>
      Network and engage with our global online community
    </FeatureExplanation>

    <BroughtToYou>
      Brought to you by:{' '}
      <BoldLink external href="https://unissu.com">
        Unissu
      </BoldLink>
    </BroughtToYou>
  </Container>
);

export default ExplanatorySignUpPrompt;
