import _ from 'lodash';

import { ESessionStatuses, ERoundTableStatuses } from 'libs/enums';
import {
  ICategoryDetailAndLiveListSessionItem,
  ICategoryDetailAndLiveListRoundTableItem
} from 'libs/types';

const COMPLETED_STATUSES = [
  ESessionStatuses.COMPLETED,
  ESessionStatuses.NOT_COMPLETED,
  ESessionStatuses.VIDEO_AVAILABLE
];

const LIVE_STATUSES = [ESessionStatuses.LIVE, ERoundTableStatuses.LIVE];

export const groupItems = ({
  items
}: {
  items: Array<
    | ICategoryDetailAndLiveListSessionItem
    | ICategoryDetailAndLiveListRoundTableItem
  >;
}) => {
  const result: {
    past: Array<
      | ICategoryDetailAndLiveListSessionItem
      | ICategoryDetailAndLiveListRoundTableItem
    >;
    live: Array<
      | ICategoryDetailAndLiveListSessionItem
      | ICategoryDetailAndLiveListRoundTableItem
    >;
    upcoming: Array<
      | ICategoryDetailAndLiveListSessionItem
      | ICategoryDetailAndLiveListRoundTableItem
    >;
  } = {
    past: [],
    live: [],
    upcoming: []
  };

  items.forEach((item) => {
    const itemInstance = item.session || item.round_table;
    const itemStatus = itemInstance.status;

    if (_.includes(COMPLETED_STATUSES, itemStatus)) {
      result.past.push(item);
    } else if (_.includes(LIVE_STATUSES, itemStatus)) {
      result.live.push(item);
    } else {
      result.upcoming.push(item);
    }
  });

  return result;
};
