import {
  Attendees,
  DirectMessages,
  GlobalChat,
  SessionChat
} from './components';

export const DIRECT_MESSAGES_TAB = {
  title: 'DMs',
  index: 1,
  component: DirectMessages,
  disabled: false,
  withUnreadCount: true
};
const ATTENDEES_TAB = {
  title: 'Attendees',
  index: 2,
  component: Attendees,
  disabled: false,
  withUnreadCount: false
};
const GLOBAL_CHAT_TAB = {
  title: 'Global Chat',
  index: 0,
  component: GlobalChat,
  disabled: false,
  withUnreadCount: false
};
const SESSION_CHAT_TAB = {
  title: 'Session Chat',
  index: 0,
  component: SessionChat,
  disabled: false,
  withUnreadCount: false
};

export const GLOBAL_TABS = [
  GLOBAL_CHAT_TAB,
  DIRECT_MESSAGES_TAB,
  { ...ATTENDEES_TAB, disabled: true }
];

export const SESSION_TABS = [
  SESSION_CHAT_TAB,
  DIRECT_MESSAGES_TAB,
  ATTENDEES_TAB
];
