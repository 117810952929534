import _ from 'lodash';
import NextHead from 'next/head';

interface IProps {
  title: string;
  description: string;
  image?: string | null;
}

// This is the component used to inject
// meta data into the HTML "head" tag.
const Head = ({ title, description, image }: IProps) => {
  if (_.isNil(image)) {
    image = '/images/defaultMetaImage.jpg';
  }

  return (
    <NextHead>
      <title>{title}</title>
      <meta property="og:image" content={image} />
      <meta property="og:type" content="summary" />
      <meta property="og:title" content={title} key="title" />
      <meta name="description" content={description} key="description" />
    </NextHead>
  );
};

export default Head;
