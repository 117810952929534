export enum ESessionTypeValues {
  STREAM = 'STREAM',
  PRERECORDED = 'PRERECORDED'
}

export enum ESessionTypeLabels {
  STREAM = 'Stream',
  PRERECORDED = 'Prerecorded'
}

export enum ESessionStatuses {
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  NOT_COMPLETED = 'NOT_COMPLETED',
  VIDEO_AVAILABLE = 'VIDEO_AVAILABLE'
}

export enum ERoundTableStatuses {
  LIVE = 'LIVE',
  PREVOUS = 'PREVIOUS',
  UPCOMING = 'UPCOMING'
}

export enum EAvatarSizes {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  XXXLARGE = 'xxxlarge'
}

export enum EPrerecordedVideoStatuses {
  PROCESSING = 'Processing',
  READY = 'Ready',
  FAILED = 'Failed'
}

export enum EScheduleItemTypes {
  MEETING = 'meeting',
  SESSION = 'session'
}

export enum ECategoryDetailAndLiveListItemTypes {
  SESSION = 'session',
  ROUND_TABLE = 'round_table'
}

export enum ENotificationTypes {
  SESSION_IS_STARTING = 'session_is_starting',
  MEETING_IS_STARTING = 'meeting_is_starting',
  SESSION_IS_ABOUT_TO_START = 'session_is_about_to_start',
  MEETING_IS_ABOUT_TO_START = 'meeting_is_about_to_start',
  YOUR_SESSION_IS_ADDED_TO_SCHEDULE = 'your_session_is_added_to_schedule',
  SOMEONE_SCHEDULED_A_MEETING_WITH_YOU = 'someone_scheduled_a_meeting_with_you',
  MEETING_CANCELLED = 'meeting_cancelled',
  SOMEONE_VIEWED_YOU_PROFILE = 'someone_viewed_you_profile'
}

export enum EPlayerPageTypes {
  IFRAME = 'iframe',
  WATCHPAGE = 'watchpage'
}

export enum EPlayerVideoLanguageCodes {
  SHORT = 'short',
  MOVIE = 'movie',
  EPISODE = 'episode',
  CLIP = 'clip',
  TRAILER = 'trailer',
  EVENT = 'event'
}

export enum EPlayerVideoStreamTypes {
  LIVE = 'live',
  ON_DEMAND = 'on-demand'
}

export enum EEventStatusValues {
  UPCOMING = 'UPCOMING',
  LIVE = 'LIVE',
  PREVIOUS = 'PREVIOUS'
}

export enum ESessionListFilterNames {
  ORDER = 'order',
  SEARCH = 'search'
}

export enum EPaymentStatuses {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED'
}
