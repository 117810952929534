import styled from 'styled-components';

import { breakpoints } from 'styles/global';

import { Image, Container } from 'components';

import background from './assets/background.jpg';

export const StyledContainer = styled(Container.Large)`
  color: white;
  background-size: contain;
  background: url(${background}) no-repeat;
  background-position: right;

  min-height: 259px;
  padding: 56px 157px 25px 77px;
  box-sizing: border-box;

  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  @media ${breakpoints.tablet} {
    padding: 30px 77px;

    display: flex;
    flex-direction: column;
    background-size: cover;
  }

  @media ${breakpoints.mobile} {
    background-position: center;
    background-size: cover;

    padding-left: 21px;
    padding-right: 24px;
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
`;

export const Description = styled.p`
  max-width: 527px;
  margin-top: 11px;

  font-size: 15px;
  line-height: 22px;
`;

export const StreamingPlatforms = styled.div`
  color: white;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    margin-top: 30px;
  }

  @media ${breakpoints.mobile} {
    margin-top: 10px;
  }
`;

export const StreamingPlatformsTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;

  margin-bottom: 14px;
`;

export const StyledImage = styled(Image)`
  max-width: 100%;
`;

export const StreamingPlatformsAddition = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;

  margin-top: -5px;
`;
