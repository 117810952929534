import styled from 'styled-components';

import { colors, yellow, darkGrey, lightBlueGrey } from 'styles/global';

import { Icon } from 'components';

export const Wrapper = styled.div`
  background: ${lightBlueGrey};
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
`;

export const Container = styled.div`
  box-sizing: border-box;
  padding: 7px 9px 0 29px;

  color: ${colors.text[1]};
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Date = styled.div`
  font-size: 14px;
  line-height: 32px;
`;

export const TrashIcon = styled(Icon)`
  opacity: 0.4;
  cursor: pointer;
  font-size: 14px;
`;

export const TitleRow = styled(SpaceBetween)`
  margin-top: 5px;
  margin-bottom: 29px;

  display: flex;
  align-items: flex-start;
`;

export const Left = styled.div``;

export const Right = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Title = styled.h4`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;

  margin-bottom: 9px;
`;

export const SlotPrice = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const DataPackageIndicator = styled.div`
  font-size: 12px;
  line-height: 32px;
  white-space: nowrap;
  color: ${colors.text[1]};

  margin-top: -4px;

  cursor: default;
  transition: opacity 0.3s linear;
  opacity: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 1 : 0)};
`;

export const Duration = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[2]};
`;

export const DataPackageToggler = styled(SpaceBetween)`
  height: 30px;
  align-items: center;
  background-color: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? `${yellow}33` : yellow}; // the + 33 is for 0.2 opacity

  padding: 0 30px;
  box-sizing: border-box;

  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  cursor: pointer;
  border-radius: 2px 2px 0 0;

  transition: background-color 0.3s ease;
`;

export const HideDataPackage = styled.span`
  width: 9px;
  border-bottom: 2px solid ${darkGrey};
`;
