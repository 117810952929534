import styled from 'styled-components';

import { darkBlue, breakpoints } from 'styles/global';

import { Icon, Button } from 'components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  z-index: 100; // navigation is 99

  background-color: ${darkBlue}99; // 99 is for 0.6 opacity;

  opacity: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)};
  display: ${({ visible }: { visible: boolean }) =>
    visible ? 'flex' : 'none'};

  animation: appear 0.5s linear;

  @keyframes appear {
    0% {
      opacity: 0;
      display: flex;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  width: 1064px;
  max-width: 100vw;
  max-height: 100vh;

  overflow-y: auto;
  border-radius: 2px;
  background-color: ${({ background }: { background: string }) => background};
`;

export const Header = styled.div`
  color: white;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;

  position: relative;

  padding: 27px 0;
  box-sizing: border-box;

  background-color: ${({ background }: { background: string }) => background};
`;

export const Close = styled(Icon)`
  color: white;
  font-size: 16px;

  cursor: pointer;

  top: 14px;
  right: 19px;
  position: absolute;
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 37px 65px 54px 65px;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    padding: 30px 15px;
  }
`;

export const Content = styled.div`
  line-height: 26px;
  word-break: break-word;

  * {
    word-break: break-word;
  }

  b {
    font-weight: bold;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export const StyledButton = styled(Button)`
  width: 305px;

  margin-top: 40px;

  @media ${breakpoints.tablet} {
    width: 100%;
  }
`;
