import styled from 'styled-components';

import { lightBlueGrey } from 'styles/global';

export const Icon = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 0.89655px solid rgba(123, 124, 129, 0.2);
  background: url(${(props: { background: string }) => props.background})
    no-repeat;
  background-position: center center;

  &:hover {
    background-color: ${lightBlueGrey};
  }
`;
