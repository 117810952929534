import styled from 'styled-components';

import { colors, lightBlue, lightBlueGrey } from 'styles/global';

import { Icon } from 'components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 12px 20px 19px 20px;
  border-bottom: 1px solid rgba(123, 124, 129, 0.2);

  cursor: pointer;
  transition: background-color 0.1s linear;

  background-color: ${({
    lightBlueBackground
  }: {
    lightBlueBackground: boolean;
  }) => (lightBlueBackground ? lightBlue : 'white')};

  &:hover {
    background-color: ${lightBlueGrey}99; // 99 is for 0.6 opacity;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.text[4]};
`;

export const CreationDateDelta = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text[2]};
`;

export const AdditionalText = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[1]};

  margin-top: 4px;

  padding-left: 43px;
  box-sizing: border-box;
`;

export const FooterRow = styled.p`
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  color: ${colors.text[3]};

  margin-top: 12px;

  padding-left: 43px;
  box-sizing: border-box;
`;

export const SessionNotificationContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SessionNotificationIconWrapper = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ebecee;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.text[3]};

  margin-right: 10px;
`;

export const SessionNotificationIcon = styled(Icon)`
  font-size: 14px;
`;

export const SessionNotificationTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: ${colors.text[1]};
`;

export const SessionNotificationCategory = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-top: 5px;
`;
