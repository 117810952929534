import styled from 'styled-components';

import { red, darkGrey, colors, breakpoints } from 'styles/global';

import { Button } from 'components';

export const Wrapper = styled.div`
  position: relative;

  @media ${breakpoints.tablet} {
    position: unset;
  }
`;

export const ToggleContainer = styled.div`
  font-size: 20px;
  color: ${darkGrey};

  cursor: pointer;
  position: relative;
`;

export const NewNotificationsCount = styled.div`
  width: 20px;
  height: 20px;
  padding-bottom: 1px;
  box-sizing: border-box;

  top: -5px;
  right: -10px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid white;
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};

  color: white;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  border-radius: 50%;
  background-color: ${red};

  transform: scale(${({ visible }: { visible: boolean }) => (visible ? 1 : 0)});
  animation: ${({ visible }: { visible: boolean }) =>
      visible ? 'pop' : 'disappear'}
    0.5s linear;

  @keyframes pop {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes disappear {
    0%,
    99% {
      font-size: 0; // this is in order to hide the text(in this case - a zero)
      visibility: visible;
    }

    100% {
      visibility: hidden;
      transform: scale(0);
    }
  }
`;

export const Container = styled.div`
  right: -76px;
  top: calc(100% + 15px);
  position: absolute;

  width: 375px;
  height: 730px;
  max-width: 100vw;
  max-height: calc(100vh - 100px);

  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  border-radius: 2px;
  border-style: solid;
  border-color: rgba(123, 124, 129, 0.2);

  overflow-y: auto;
  background-color: white;

  transition: max-height 0.3s linear, border 0.3s linear;
  max-height: ${({ visible }: { visible: boolean }) => (visible ? 730 : 0)}px;
  border-width: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)}px;

  @media ${breakpoints.tablet} {
    left: 0px;
    top: 55px;
    right: unset;

    border: unset;

    width: 100vw;
    height: calc(100vh - 55px);
    max-height: ${({ visible }: { visible: boolean }) =>
      visible ? 'calc(100vh - 55px)' : 0};
  }
`;

export const Title = styled.p`
  margin: 14px 0 23px 20px;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.text[1]};
`;

export const NoNotifications = styled.p`
  // total distance to title is 45px where 23px comes from the title itself
  box-sizing: border-box;
  padding: 22px 20px 19px 20px;
  border-bottom: 1px solid rgba(123, 124, 129, 0.2);

  margin-right: 21px;

  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.text[1]};
`;

export const LoadMoreButton = styled(Button)`
  width: 128px;
  margin: 30px auto 34px auto;
`;
