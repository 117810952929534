import styled from 'styled-components';

export const Title = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #30596e;
  margin-bottom: 5px;
  grid-column: 1 / -1; // take all the columns

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
