import _ from 'lodash';

import {
  IUser,
  IScheduleRetrieveMeeting,
  INotificationListNotificationMeeting
} from 'libs/types';

// One of the participant and initiator is the logged user. We need to filter him out
export const getOtherMeetingParticipant = ({
  meeting,
  user
}: {
  user: IUser;
  meeting: IScheduleRetrieveMeeting | INotificationListNotificationMeeting;
}) =>
  _.find(
    [meeting.participant, meeting.initiator],
    (participant) => participant.id !== user?.id
  );
