import * as Sentry from '@sentry/browser';
import _ from 'lodash';

import { uploadFile } from 'libs/requests';
import { IFile, IResponse, ISignature } from 'libs/types';

interface IArgs {
  files: Array<File>;
  signatureProvider: ({
    data
  }: {
    data: { filename: string; file_type: string };
  }) => Promise<IResponse<ISignature>>;
}

interface IResult {
  data: Array<IFile>;
  success: boolean;
  error: string;
}

export const uploadFiles = async ({
  files,
  signatureProvider
}: IArgs): Promise<IResult> => {
  const result: IResult = {
    data: [],
    success: false,
    error: ''
  };

  // sign the files
  const signResponses = await Promise.all(
    files.map(
      async (file: File) =>
        await signatureProvider({
          data: {
            filename: file.name,
            file_type: file.type
          }
        })
    )
  );

  // check for errors during signing
  const allFilesHaveBeenSigned = signResponses.every((item) => item.success);

  if (!allFilesHaveBeenSigned) {
    const failedSigns = signResponses.filter((item) => !item.success);
    const errorMessages = _.flatMap(
      failedSigns,
      (sign) => sign.error.statusText
    );
    const error = _.uniq(errorMessages).join('. ');

    result.error = error;
    return result;
  }

  // upload the files
  await Promise.all(
    _.map(files, async (file: File, index) => {
      const postData = new FormData();
      const sign: ISignature | undefined = signResponses[index].data;

      if (!sign) {
        return;
      }

      _.each(sign.fields, (field, fieldName) =>
        postData.append(fieldName, field)
      );

      postData.append('file', file);
      result.data.push({
        filename: file.name,
        url: sign.create_key
      });

      const uploadFileResult = await uploadFile({
        url: sign.url,
        data: postData
      });

      if (uploadFileResult.success) {
        return uploadFileResult;
      }

      throw new Error(uploadFileResult.error.statusText);
    })
  )
    .then(() => {
      result.success = true;
    })
    .catch((error) => {
      Sentry.captureMessage(
        `A file upload has failed with this error message: ${error.message}`
      );
      result.error = error.message;
    });

  return result;
};
