import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Button, Container, Icon, Link, SearchInput } from 'components';

import { Notifications } from './components';

export const VisibleOnDesktop = styled.div`
  display: block;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const VisibleOnTabletAndBelow = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const Wrapper = styled.nav`
  top: 0;
  left: 0;
  z-index: 99;
  position: fixed;
  width: 100vw;
  background: white;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);
`;

export const StyledContainer = styled(Container.Large)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 5;
  height: 55px;
  position: relative; // we need position different than "static" for the z-index to take effect
  padding-left: 48px;
  padding-right: 30px;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    padding-left: 18px;
    padding-right: 20px;
  }
`;

export const Group = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled.div`
  margin-right: 30px;
`;

export const NavItem = styled(Link)`
  display: flex;
  align-items: center;

  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  transition: color 0.2s ease;
  color: ${({ active, theme }: { active: boolean; theme: ITheme }) =>
    active ? theme.primaryColor : colors.text[1]};

  &:hover {
    color: ${({ theme }: { active: boolean; theme: ITheme }) =>
      theme.primaryColor};
  }

  @media ${breakpoints.tablet} {
    font-size: 18px;
    line-height: 21px;
  }

  &:not(:first-of-type) {
    margin-left: 21px;

    @media ${breakpoints.tablet} {
      margin-left: unset;
    }
  }
`;

export const Divider = styled.div`
  margin-left: 21px;

  height: 36px;

  border-left: 2px solid rgba(123, 124, 129, 0.2);

  @media ${breakpoints.tablet} {
    height: 0;
    width: 50px;
    margin-left: unset;
    border-bottom: 2px solid rgba(123, 124, 129, 0.2);
  }
`;

export const NonLinkNavItem = styled.div`
  position: relative;
  padding-bottom: 15px;
  padding-top: 15px;

  display: flex;
  align-items: center;

  height: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  &:after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    display: block;
    bottom: -1px;
    border-radius: 10.7586px;
    background: ${({ active, theme }: { active: boolean; theme: ITheme }) =>
      active ? theme.primaryColor : 'transparent'};

    @media ${breakpoints.tablet} {
      display: none;
    }
  }

  @media ${breakpoints.tablet} {
    padding-top: unset;
    padding-bottom: unset;
    color: ${({ active, theme }: { active: boolean; theme: ITheme }) =>
      active ? theme.primaryColor : colors.text[1]};
  }

  &:not(:first-of-type) {
    margin-left: 17px;

    @media ${breakpoints.tablet} {
      margin-left: unset;
    }
  }
`;

export const StyledButtonText = styled(Button.Text)`
  justify-content: flex-start;
`;

export const SignUpButton = styled(Button)`
  width: 92px;
  margin-left: 17px;
`;

export const DotIcon = styled(Icon)`
  margin-right: 7px;
  font-size: 12px;
  color: ${colors.text[6]};
`;

export const MobileNavigationToggler = styled(Icon)`
  font-size: 19px;
  min-width: 19px; // to guarantee it won't change size on icon change;

  margin-left: 10px;
`;

export const MobileNavigationContentWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;

  overflow: hidden;
  max-height: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? '100vh' : '0px'};

  transition: max-height 0.5s ease;
`;

export const MobileNavigationContent = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 91px 25px; // 55px for the navigation + 36px

  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;

  background-color: white;

  ${SignUpButton} {
    @media ${breakpoints.tablet} {
      margin-left: unset;
    }
  }
`;

export const StyledNotifications = styled(Notifications)`
  margin-right: 17px;
`;

export const SearchTogglerIcon = styled(Icon)`
  font-size: 16px;
  min-width: 15px;
  margin-right: 17px;
  animation: flip 0.5s linear;

  cursor: pointer;
  user-select: none;

  @keyframes flip {
    0% {
      transform: scaleX(1);
    }
    25% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
`;

export const StyledMobileSearchInput = styled(SearchInput)`
  width: 100%;
`;
