import styled from 'styled-components';

import { colors, yellow } from 'styles/global';

import { Avatar } from 'components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 11px 20px 5px 20px;

  display: grid;
  grid-gap: 10px;
  align-items: flex-start;
  grid-template-columns: 28px auto;

  background: ${({ marked }: { marked: boolean }) =>
    marked ? '#EBECEE' : 'transparent'};
`;

export const Content = styled.div`
  color: ${colors.text[1]};
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

export const AuthorName = styled.div`
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;

  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const Mark = styled.span`
  width: 6px;
  height: 6px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: ${yellow};
`;

export const MessageContent = styled.p`
  color: inherit;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-line;
  word-break: break-word;
`;
