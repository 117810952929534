import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

export const Container = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 0px 2px;
  background-color: #ebecee;

  font-size: 13px;
  line-height: 16px;
  color: ${colors.text[1]};

  & > *:first-child {
    margin-right: 20px;
  }

  @media ${breakpoints.tablet} {
    left: 0;
    bottom: 0px;
    position: fixed;
    visibility: visible;
  }
`;
