import styled from 'styled-components';

import { yellow } from 'styles/global';

import { Icon, Button, Link } from 'components';

import lines from './assets/lines.png';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 42px 52px 27px 52px;

  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  background: linear-gradient(
    133.05deg,
    #0a1325 29.9%,
    rgba(31, 37, 55, 0.97) 166.83%
  );
  box-shadow: 0px 0px 5px rgba(123, 124, 129, 0.05);

  &:before {
    content: '';

    width: 100%;
    height: 100%;

    left: 0;
    bottom: 0;
    position: absolute;
    background: url(${lines}) no-repeat bottom center;
  }
`;

export const Description = styled.p`
  margin: 6px 0 26px 0;

  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
`;

export const SignUpButton = styled(Button)`
  // Because of before tag
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: 12px;
`;

export const LoginText = styled(Button.Text)`
  // Because of before tag
  position: relative;
  z-index: 2;
  opacity: 0.4;
  margin-bottom: 35px;
`;

export const StyledIcon = styled(Icon)`
  font-size: 22px;
  color: ${yellow};
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 28px;
  }
`;

export const FeatureExplanation = styled.p`
  color: white;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const BroughtToYou = styled.div`
  opacity: 0.4;
  color: white;
  font-size: 15px;
  line-height: 16px;

  margin-top: 103px;
`;

export const BoldLink = styled(Link)`
  color: inherit;
  font-weight: 600;
  text-decoration: none;
`;
