import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { emailIsValid } from 'libs/common';
import {
  fetchSpeakerSessionAttendeeInvitations,
  updateSpeakerSessionAttendeeInvitations
} from 'libs/requests';
import {
  ISessionSpeakerDetailSession,
  ISpeakerSessionAttendeeInvitationListAttendeeInvitation
} from 'libs/types';

import { TagInput } from 'components';

import {
  Title,
  Wrapper,
  Container,
  Emails,
  Email,
  Description,
  AddButton,
  SubmitButton,
  InputContainer
} from './styles';

interface IProps {
  session: ISessionSpeakerDetailSession;
  setSession: (session: ISessionSpeakerDetailSession) => void;
}

const AttendeeInvitationManager = ({ session }: IProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attendeeInvitationEmails, setAttendeeInvitationEmails] = useState<
    Array<string>
  >([]);
  const [
    emailsThatHaveNotBeenAddedYet,
    setEmailsThatHaveNotBeenAddedYet
  ] = useState<Array<string>>([]);
  const [
    initialAttendeeInvitationEmails,
    setInitialAttendeeInvitationEmails
  ] = useState<Array<string>>([]); // We use this to have an initial value to compare with so we can track changes.

  const getAttendeeInvitations = useCallback(async () => {
    setIsFetching(true);

    const { data, success } = await fetchSpeakerSessionAttendeeInvitations({
      sessionId: session.id
    });

    if (success && data) {
      const emails = data.map(
        (item: ISpeakerSessionAttendeeInvitationListAttendeeInvitation) =>
          item.email
      );

      setAttendeeInvitationEmails(emails);
      setInitialAttendeeInvitationEmails(emails);
    }

    setIsFetching(false);
  }, [session.id]);

  const submit = async () => {
    setIsSubmitting(true);

    const { data, success } = await updateSpeakerSessionAttendeeInvitations({
      sessionId: session.id,
      data: { invite_emails: attendeeInvitationEmails }
    });

    if (success && data) {
      const emails = data.map(
        (item: ISpeakerSessionAttendeeInvitationListAttendeeInvitation) =>
          item.email
      );

      setAttendeeInvitationEmails(emails);
      setInitialAttendeeInvitationEmails(emails);
      toast(`Session attendee invitations have been updated successfully!`, {
        type: 'success'
      });
    } else {
      toast(`Something went wrong.`, { type: 'error' });
    }

    setIsSubmitting(false);
  };

  const appendToEmailsThatHaveNotBeenAddedYet = (email: string) => {
    // We're doing everything in the setter itself so we can have the previous
    // value & avoid racing condition.
    setEmailsThatHaveNotBeenAddedYet((previousValue) => {
      // Check if the email is valid.
      if (!emailIsValid(email)) {
        toast(`${email} is not a valid email.`, { type: 'error' });
        return previousValue;
      }

      // Check if the email has already been added.
      const usedEmails = [...previousValue, ...attendeeInvitationEmails];

      if (_.includes(usedEmails, email)) {
        toast(`${email} has already been added.`, { type: 'error' });
        return previousValue;
      }

      // If all the checks have passed - proceed with the email.
      const newEmails = [...previousValue, email];

      return newEmails;
    });
  };

  const addEmailsThatHaveNotBeenAddedYet = () => {
    // We show emails in order opposite of their add order so we need to reverse the array.
    const reversedEmailsThatHaveNotBeenAddedYet = emailsThatHaveNotBeenAddedYet.reverse();

    const newAttendeeInvitationEmails = [
      ...reversedEmailsThatHaveNotBeenAddedYet,
      ...attendeeInvitationEmails
    ];

    setAttendeeInvitationEmails(newAttendeeInvitationEmails);
    setEmailsThatHaveNotBeenAddedYet([]);
  };

  const removeEmailThatHasNotBeenAddedYet = (email: string) => {
    setEmailsThatHaveNotBeenAddedYet((currentValue) =>
      currentValue.filter((item) => item !== email)
    );
  };

  useEffect(() => {
    getAttendeeInvitations();
  }, [getAttendeeInvitations]);

  // We're disabling the button if we're submitting or if there isn't a change in the emails.
  const isSubmitDisabled =
    isSubmitting ||
    _.isEqual(initialAttendeeInvitationEmails, attendeeInvitationEmails);

  return (
    <Wrapper>
      <Title>Invite Your Network</Title>
      <Description>
        Enter your friends' and colleagues' email addresses below. We will email
        them to invite them to watch your session. <br />
        <br />
        Add them one at a time or paste in a list. Each email address should be
        separated by a comma.
      </Description>

      <Container>
        <InputContainer>
          <TagInput
            placeholder=""
            delimiters={[',']}
            value={emailsThatHaveNotBeenAddedYet}
            addNewItem={appendToEmailsThatHaveNotBeenAddedYet}
            removeItem={removeEmailThatHasNotBeenAddedYet}
          />
          <AddButton
            height="short"
            fontSize={15}
            fontWeight={500}
            disabled={emailsThatHaveNotBeenAddedYet.length === 0}
            colorScheme={1}
            onClick={addEmailsThatHaveNotBeenAddedYet}>
            Add
          </AddButton>
        </InputContainer>
        {attendeeInvitationEmails.length > 0 && !isFetching && !isSubmitting && (
          <Emails>
            {attendeeInvitationEmails.map((email) => (
              <Email key={email}>{email}</Email>
            ))}
          </Emails>
        )}
        {(isFetching || isSubmitting) && (
          <Emails>
            {_.range(10).map((index) => (
              <Email key={`email-placeholder-${index}`}>
                <Skeleton />
              </Email>
            ))}
          </Emails>
        )}
      </Container>
      <SubmitButton
        colorScheme={1}
        height="medium"
        fontSize={16}
        fontWeight={600}
        disabled={isSubmitDisabled}
        onClick={submit}>
        Send
      </SubmitButton>
    </Wrapper>
  );
};

export default AttendeeInvitationManager;
