import _ from 'lodash';
import { useState, useEffect, useContext, FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AppContext } from 'pages/_app';

import PusherManager from 'libs/pusher';
import { setIsMobileChatOpen } from 'libs/reducer';
import { getOrCreateChat, getUnreadMessageCount } from 'libs/requests';
import {
  IPrivateChatGetOrCreateChat,
  IPrivateChatPreviewListChat,
  IUnreadMessagesCountResponse
} from 'libs/types';

import { useWithUser, useWithEvent, useToggleUserDetail } from 'hooks';

import { Icon, ExplanatorySignUpPrompt } from 'components';

import { UserDetail } from './components';
import { GLOBAL_TABS, SESSION_TABS, DIRECT_MESSAGES_TAB } from './constants';
import {
  Tab,
  Tabs,
  TabWrapper,
  Container,
  MobileChatToggle,
  StyledLoginPrompt,
  PromptWrapper,
  UnreadMessagesCount,
  StyledTicketPurchasePrompt,
  MobileChatToggleUnreadMessagesCount
} from './styles';

export interface IOpenedPrivateChat {
  isFetchingChat: boolean;
  chat: IPrivateChatGetOrCreateChat | IPrivateChatPreviewListChat | null;
  openNewChat: (receivers: number[]) => void;
  setChat: (
    chat: IPrivateChatGetOrCreateChat | IPrivateChatPreviewListChat | null
  ) => void;
}

export interface IChatTabProps {
  chatId?: number;
  unreadMessagesCount: number;
  openedPrivateChat: IOpenedPrivateChat;
}

interface IChatTab {
  title: string;
  index: number;
  component: FunctionComponent<IChatTabProps>;
  disabled: boolean;
  withUnreadCount: boolean;
}

export interface IProps {
  showSessionChat?: boolean; // some pages show Global chat and others show Session chat
  showExplanatorySignUpPrompt?: boolean; // some pages show LoginPrompt and others - ExplanatorySignUpPrompt
  chatId?: number; // for session chats we initialize the component with the respective chat id
}

const Chat = ({
  chatId,
  showSessionChat,
  showExplanatorySignUpPrompt
}: IProps) => {
  const tabs: Array<IChatTab> = showSessionChat ? SESSION_TABS : GLOBAL_TABS;
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const { component: ActiveTabComponent } = activeTab;

  const { event } = useWithEvent();
  const { user, isFetchingInitials } = useWithUser();
  const {
    close: closeUserDetail,
    userId: userDetailUserId
  } = useToggleUserDetail();
  // we store this in the store instead of the state to be able to control it from other places
  const { isMobileChatOpen, dispatch } = useContext(AppContext);

  // 1. New private chat can be opened in the Attendees tab.
  // 2. DM tab should open the last opened chat if possible.
  // We keep the opened chat in the base component state to allow both of those features:
  // saving the opened tab after the DM component has unmounted (so we can open it again),
  // and also controlling the opened chat from outside the DM component (e.g AttendeesTab)
  const [openedPrivateChat, setOpenedPrivateChat] = useState<
    IPrivateChatGetOrCreateChat | IPrivateChatPreviewListChat | null
  >(null);
  const [isFetchingPrivateChat, setIsFetchingPrivateChat] = useState(false);

  const openNewPrivateChat = async (receivers: number[]) => {
    setOpenedPrivateChat(null);
    setIsFetchingPrivateChat(true);
    setActiveTab(DIRECT_MESSAGES_TAB);

    const { data, success } = await getOrCreateChat({ receivers });

    if (success && data) {
      setOpenedPrivateChat(data);
      setIsFetchingPrivateChat(false);
    }
  };

  const privateChat = {
    chat: openedPrivateChat,
    isFetchingChat: isFetchingPrivateChat,
    setChat: setOpenedPrivateChat,
    openNewChat: openNewPrivateChat
  };

  const fetchUnreadMessagesCount = async () => {
    const { data, success } = await getUnreadMessageCount();

    if (success && data) {
      setUnreadMessagesCount(data.unread_messages_count);
    }
  };

  const changeActiveTab = (newTab: IChatTab) => {
    // We use this function instead of just setting the tab with `setActiveTab` in order to:
    // 1. Close the UserDetail once a user clicks on a tab(any of them) of the chat.
    // 2. Provide the functionality of closing an open Private Chat
    // when a user clicks onthe `DMs` tab, which is
    // considered to be an expected behavior.
    closeUserDetail();
    setActiveTab(newTab);

    if (activeTab === DIRECT_MESSAGES_TAB && newTab === DIRECT_MESSAGES_TAB) {
      setOpenedPrivateChat(null);
    }
  };

  useEffect(() => {
    if (user?.id && event?.user_has_ticket && event?.id) {
      fetchUnreadMessagesCount();

      return PusherManager.bindToUnreadMessageCountEvent({
        userId: user.id,
        eventId: event.id,
        eventHandler: (data: IUnreadMessagesCountResponse) => {
          setUnreadMessagesCount(data.unread_messages_count);
        }
      });
    }
  }, [user?.id, event?.user_has_ticket, event?.id]);

  const tabsComponent = (
    <Tabs>
      {tabs.map((tab) => {
        const isTabActive = tab === activeTab;
        const isTabDisabled =
          _.isNil(user) || tab.disabled || !event || !event.user_has_ticket;

        return (
          <TabWrapper
            active={isTabActive}
            disabled={isTabDisabled}
            key={`chat-tab-${tab.title}`}
            onClick={() => (isTabDisabled ? {} : changeActiveTab(tab))}>
            <Tab>
              {tab.title}
              {tab.withUnreadCount && (
                <UnreadMessagesCount visible={unreadMessagesCount > 0}>
                  {unreadMessagesCount}
                </UnreadMessagesCount>
              )}
            </Tab>
          </TabWrapper>
        );
      })}
    </Tabs>
  );

  if (isFetchingInitials || !event) {
    // return a placeholder if we're still fetching either the user or the event
    return (
      <Container>
        <Skeleton height={693} />
      </Container>
    );
  }

  if (!user) {
    // return a login/signup prompt if user is not logged in
    return showExplanatorySignUpPrompt ? (
      <ExplanatorySignUpPrompt />
    ) : (
      <Container>
        {tabsComponent}
        <PromptWrapper>
          <StyledLoginPrompt
            theme="dark"
            dataTestid="chat-login-prompt"
            text="Sign up to join live chat and see attendees in each session."
          />
        </PromptWrapper>
      </Container>
    );
  }

  if (!event.user_has_ticket) {
    return (
      <Container>
        {tabsComponent}
        <PromptWrapper>
          <StyledTicketPurchasePrompt />
        </PromptWrapper>
      </Container>
    );
  }

  return (
    <>
      <Container visible={isMobileChatOpen}>
        {tabsComponent}

        {/* Do not show the active tab component if there is a selected user id for the UserDetail */}
        {_.isNil(userDetailUserId) && (
          <ActiveTabComponent
            chatId={chatId}
            openedPrivateChat={privateChat}
            unreadMessagesCount={unreadMessagesCount}
          />
        )}

        <UserDetail
          startChat={({ userId }: { userId: number }) =>
            openNewPrivateChat([userId])
          }
        />
      </Container>
      <MobileChatToggle
        isSmall={isMobileChatOpen}
        onClick={() => dispatch?.(setIsMobileChatOpen(!isMobileChatOpen))}>
        <Icon icon={isMobileChatOpen ? 'times' : 'comments'} />
        <MobileChatToggleUnreadMessagesCount
          visible={unreadMessagesCount > 0 && !isMobileChatOpen}>
          {unreadMessagesCount}
        </MobileChatToggleUnreadMessagesCount>
      </MobileChatToggle>
    </>
  );
};

export default Chat;
