import { ReactNode } from 'react';

import { StyledLabel } from './styles';

export interface IProps {
  children: ReactNode;
  className?: string;
}

const FormLabel = ({ className, children }: IProps) => (
  <StyledLabel className={className}>{children}</StyledLabel>
);

export default FormLabel;
