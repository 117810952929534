import styled from 'styled-components';

import { colors, darkBlue } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon, Button } from 'components';

export const Container = styled.div`
  border-radius: 2px;
  box-sizing: border-box;
  border: 0.75px solid rgba(123, 124, 129, 0.2);

  padding-top: 8px;
  padding-bottom: 9px;
  margin-top: 12px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.text[1]};

  padding-left: 17px;
`;

export const InputContainer = styled.div`
  box-sizing: border-box;
  padding: 7px 24px 29px 16px;

  width: 100%;
  position: relative;
`;

export const InputPlaceholder = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  opacity: 0.8;
  position: absolute;

  top: 22px;
  left: 32px;
  transition: 0.2s;
`;

export const StyledCustomInput = styled.input`
  z-index: 1;
  position: relative;

  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  width: 100%;
  box-sizing: border-box;
  padding: 14px 15px 13px 15px;

  border-radius: 2px;
  border: 0.75px solid rgba(123, 124, 129, 0.2);

  &:focus {
    outline: none;
    border: 0.75px solid rgba(123, 124, 129, 0.2);
  }

  &:focus + ${InputPlaceholder} {
    top: 6px;
    left: 25px;
    z-index: 2;

    font-size: 13px;
    line-height: 16px;
    color: ${colors.text[2]};
  }

  &:placeholder-shown {
    background-color: transparent;
  }
`;

export const ParticipantAndInvitationLine = styled.div`
  display: flex;
  align-items: center;

  box-sizing: border-box;
  padding: 0 23px 0 16px;

  height: 36px;
  border-top: 0.75px solid rgba(123, 124, 129, 0.2);
`;

export const TrashIcon = styled(Icon)`
  color: ${colors.text[3]};
  cursor: pointer;
  font-size: 12px;
  margin-right: 9px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  margin-left: auto;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  opacity: ${({ accepted }: { accepted: boolean; theme: ITheme }) =>
    accepted ? 1 : 0.4};
  color: ${({ accepted, theme }: { accepted: boolean; theme: ITheme }) =>
    accepted ? theme.primaryColor : darkBlue};
`;

export const SubmitButton = styled(Button)`
  width: 180px;
  margin-top: 10px;
  margin-left: auto;
`;
