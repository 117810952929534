import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Breadcrumbs, File, OneDimensionImage } from 'components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 21px 39px 26px 0;
  border-bottom: 1px solid rgba(123, 124, 129, 0.2);

  display: flex;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  margin-top: 2px;

  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: ${colors.text[1]};
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 26px;
`;

export const Left = styled.div`
  margin-right: 30px;
  max-width: calc(100% - 200px);

  @media ${breakpoints.mobile} {
    max-width: 100%;
    margin-right: unset;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 25px;
  max-width: 180px;

  @media ${breakpoints.mobile} {
    max-width: unset;
    margin-top: 20px;
  }
`;

export const SponsorLogo = styled(OneDimensionImage)`
  max-width: 100%;
  object-fit: contain;
`;

export const BrochureLink = styled(File)`
  display: block;
  margin-top: 21px;
  white-space: nowrap;
`;
