import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';

interface IProps {
  items: Array<any>;
}

const useCarousel = ({ items }: IProps) => {
  const [activeItem, setActiveItem] = useState();

  const activeItemIndex = _.indexOf(items, activeItem);

  useEffect(() => {
    // set the first item to be active if the items change
    setActiveItem(items[0]);
  }, [items]);

  const changeActiveItem = useCallback(
    ({ newActiveItemIndex }: { newActiveItemIndex: number }) => {
      if (newActiveItemIndex >= items.length) {
        // Scroll to the first item if the index is more than the index of the last item.
        const firstItem = items[0];
        setActiveItem(firstItem);

        return;
      }

      if (newActiveItemIndex < 0) {
        // Scroll to the last item if the index is less than 0.
        const lastItem = _.last(items);
        setActiveItem(lastItem);

        return;
      }

      setActiveItem(items[newActiveItemIndex]);
    },
    [items]
  );

  useEffect(() => {
    // Every time the item is changed we start a 5 second timeout to change it again.
    const timeout = setInterval(
      () => changeActiveItem({ newActiveItemIndex: activeItemIndex + 1 }),
      8000 // 8 seconds
    );

    return () => clearTimeout(timeout);
  }, [activeItemIndex, changeActiveItem]);

  return {
    activeItem,
    switchToPrevious: () =>
      changeActiveItem({ newActiveItemIndex: activeItemIndex - 1 }),
    switchToNext: () =>
      changeActiveItem({ newActiveItemIndex: activeItemIndex + 1 })
  };
};

export default useCarousel;
