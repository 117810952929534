import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Icon, Link, Container } from 'components';

export const Wrapper = styled.div`
  padding: 40px 30px;
  box-sizing: border-box;
  background-color: #ebecee;
`;

export const StyledContainer = styled(Container.Large)`
  max-width: 100%;
  padding-left: 51px;
  padding-right: 58px;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    align-items: center;
    flex-direction: column;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media ${breakpoints.mobile} {
    margin-top: 20px;
    align-items: center;
  }
`;

export const TopRight = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-auto-flow: column;

  @media ${breakpoints.mobile} {
    grid-auto-flow: unset;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const BottomRight = styled.div`
  padding-top: 26px;

  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
`;

export const DarkLink = styled(Link)`
  color: ${colors.text[1]};

  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
`;

export const MediumLink = styled(Link)`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[2]};

  @media ${breakpoints.mobile} {
    text-align: center;

    grid-row: 1;
    grid-column: span 2;
  }
`;

export const ArrowIcon = styled(Icon)`
  font-size: 13px;
  margin-left: 5px;
  transform: rotate(-45deg);
`;

export const SmallLink = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.4;
  color: ${colors.text[1]};
`;
