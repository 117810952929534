import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Link, Image } from 'components';

export const Container = styled.div`
  padding: 23px 41px;
  box-sizing: border-box;

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);

  display: grid;
  grid-gap: 39px;
  grid-template-columns: 48px 1fr;

  @media ${breakpoints.mobile} {
    grid-gap: 15px;
    padding: 10px 5px;
  }
`;

export const Logo = styled(Image)`
  object-fit: contain;
`;

export const EventMisc = styled.div`
  margin-bottom: 2px;

  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[2]};
`;

export const OrganiserName = styled(Link)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: color 0.2s linear;

  &:hover {
    color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.text[1]};
`;
