import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { EAvatarSizes } from 'libs/enums';
import { IMessage } from 'libs/types';
import { getUserName } from 'libs/users';

import { Avatar, Linkify } from 'components';

import {
  Container,
  Content,
  AuthorName,
  MessageContent,
  Mark,
  StyledAvatar
} from './styles';

export interface IProps {
  marked?: boolean;
  className?: string;
  message: IMessage;
  onAuthorClick: () => void;
}

interface IPlaceholderProps {
  className?: string;
  count?: number;
}

const Placeholder = ({ className, count = 1 }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container
        key={`message-placeholder-${index}`}
        className={className}
        marked={false}>
        <Avatar.Placeholder size={EAvatarSizes.XSMALL} />
        <Content>
          <AuthorName>
            <Skeleton width="150px" />
          </AuthorName>
          <MessageContent>
            <Skeleton width="200px" />
          </MessageContent>
        </Content>
      </Container>
    ))}
  </>
);

const Message: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({ className, message, onAuthorClick, marked = false }: IProps) => (
  <Container className={className} marked={marked}>
    <StyledAvatar
      src={message.author.avatar}
      size={EAvatarSizes.XSMALL}
      onClick={onAuthorClick}
    />
    <Content>
      <AuthorName onClick={onAuthorClick}>
        {getUserName({ user: message.author })} {marked && <Mark />}
      </AuthorName>
      <MessageContent>
        <Linkify>{message.content}</Linkify>
      </MessageContent>
    </Content>
  </Container>
);

Message.Placeholder = Placeholder;

export default Message;
