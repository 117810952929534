import styled from 'styled-components';

import { lightBlueGrey, breakpoints } from 'styles/global';

import { Container as StyledContainer } from 'components';

export const Page = styled.div`
  padding-top: 55px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
  background-color: ${(props: { background?: string }) =>
    props.background === 'lightBlueGrey' ? lightBlueGrey : 'white'};
`;

export const PageWithChat = styled.div`
  max-width: 100vw;
  padding-top: 55px;
  box-sizing: border-box;
`;

export const Container = styled(StyledContainer.Large)`
  display: grid;
  // 55 for the navigation and 137 for the footer
  min-height: calc(100vh - 55px - 137px);
  grid-template-columns: 977px 463px;

  @media ${breakpoints.desktop} {
    grid-template-columns: calc(100vw - 405px) 400px;
  }

  @media ${breakpoints.tablet} {
    grid-template-columns: unset;
  }
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${(props: { background?: string }) =>
    props.background === 'lightBlueGrey' ? lightBlueGrey : 'white'};

  padding-top: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    max-width: 100vw;
  }
`;

export const SidebarContainer = styled.div`
  @media ${breakpoints.tablet} {
    position: absolute;
  }
`;

export const Sidebar = styled.div`
  height: calc(100vh - 55px);
  max-height: calc(100vh - 55px); // all the height minus the navigation height
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  padding: 20px;

  top: 55px; // just as tall as the navigation
  position: sticky;

  @media ${breakpoints.tablet} {
    z-index: 2;
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const ShareIconsContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 48px);

  margin-top: 32px;
  margin-bottom: 30px;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;
