import _ from 'lodash';
import { UIEventHandler } from 'react';

import {
  IPrivateChatPreviewListChat,
  IPrivateChatGetOrCreateChat
} from 'libs/types';

export const getOtherChatParticipant = ({
  chat
}: {
  chat: IPrivateChatPreviewListChat | IPrivateChatGetOrCreateChat;
}) => chat.participants[0];

export const scrollToZeroIfScrollIsBetweenZeroAndFive: UIEventHandler<HTMLDivElement> = _.debounce(
  (event) => {
    // We have issues with the Chrome browser being able to scroll
    // below the zero point by a single(or less) pixel. This causes issues
    // when we're trying to scroll to the bottom on new messages.
    // In order to avoid that we scroll to 0 if the user's scroll position is
    // anywhere between 0 and 5 pixels.
    const { scrollTop } = event.target;

    const absoluteScrollTop = Math.abs(scrollTop);

    if (absoluteScrollTop > 0 && absoluteScrollTop <= 5) {
      event.target.scroll({ top: 0 });
    }
  },
  500
);
