import { Image } from 'components';

import folderImage from './assets/folder.png';
import { Container, Title, Description } from './styles';

interface IProps {
  className?: string;
}

const NoSearchResults = ({ className }: IProps) => (
  <Container className={className}>
    <Image src={folderImage} width={180.54} height={168.71} />
    <Title>No Results Found</Title>
    <Description>
      It seems we can’t find any results based on your search.
    </Description>
  </Container>
);

export default NoSearchResults;
