import { useEffect, FunctionComponent } from 'react';

import PusherManager from 'libs/pusher';

import { useChat, useWithEvent, useToggleUserDetail, useWithUser } from 'hooks';

import { IChatTabProps } from 'components/Chat';
import { scrollToZeroIfScrollIsBetweenZeroAndFive } from 'components/Chat/utils';
import Message from 'components/Message';

import { Container, StyledMessageInput, LoadMoreButton } from './styles';

const SessionChat: FunctionComponent<IChatTabProps> = ({ chatId }) => {
  const chat = useChat({ chatId });
  const { user } = useWithUser();
  const { event } = useWithEvent();
  const { setUserDetailUserId } = useToggleUserDetail();

  useEffect(() => {
    if (user?.id && chatId && event?.id) {
      return PusherManager.subscribeToEventSpecificLiveChat({
        chatId,
        eventId: event.id
      });
    }
  }, [user?.id, chatId, event?.id]);

  useEffect(() => {
    if (user?.id && chat.id && event?.id) {
      return PusherManager.bindToLiveChatMessageEvent({
        chatId: chat.id,
        eventId: event.id,
        eventHandler: chat.addMessage
      });
    }
  }, [user?.id, chat.id, chat.addMessage, event?.id]);

  return (
    <>
      <Container onScroll={scrollToZeroIfScrollIsBetweenZeroAndFive}>
        {chat.messages.map((message) => (
          <Message
            message={message}
            key={`session-chat-message-${message.id}`}
            onAuthorClick={() => setUserDetailUserId(message.author.id)}
          />
        ))}
        {chat.isFetchingMessages && <Message.Placeholder count={10} />}
        {!chat.isFetchingMessages && chat.hasMoreMessages && (
          <div>
            <LoadMoreButton
              colorScheme={3}
              fontSize={14}
              height="short"
              fontWeight={400}
              onClick={chat.loadMoreMessages}>
              Load More
            </LoadMoreButton>
          </div>
        )}
      </Container>
      <StyledMessageInput
        chatId={chatId}
        maxLength={200}
        addMessageToChat={chat.addMessage}
      />
    </>
  );
};

export default SessionChat;
