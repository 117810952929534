import styled from 'styled-components';

import { colors, darkBlue, breakpoints } from 'styles/global';

import {
  Link,
  Button,
  Icon,
  Label,
  AverageRating,
  TimeWithLiveIndicator,
  SessionParticipants,
  ScheduleSessionToggleButton
} from 'components';

export const Container = styled.div`
  background: white;

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  padding: 23px 30px;
  box-sizing: border-box;

  &:not(:first-of-type) {
    margin-top: 21px;
  }
`;

export const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px 31px;

  grid-template-areas:
    'time . .'
    'title button toggler'
    'userLine . .';

  @media ${breakpoints.tablet} {
    grid-gap: 15px 0;
    align-items: center;
    grid-template-columns: 1fr 31px;
    grid-template-areas: 'time toggler' 'title title' 'userLine userLine' 'button button';
  }
`;

export const StyledTimeWithLiveIndicator = styled(TimeWithLiveIndicator)`
  grid-area: time;
`;

export const TitleWrapper = styled.div`
  grid-area: title;
  padding-right: 20px;
  padding-bottom: 20px;

  @media ${breakpoints.tablet} {
    padding-right: unset;
  }
`;

export const Title = styled(Link)`
  cursor: pointer;
  display: inline;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const Category = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${darkBlue};
  font-weight: normal;
  margin-bottom: 13px;
`;

export const ButtonWrapperLink = styled(Link)`
  grid-area: button;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledScheduleSessionToggleButton = styled(
  ScheduleSessionToggleButton
)`
  grid-area: button;
`;

export const Toggler = styled(Icon)`
  grid-area: toggler;

  cursor: pointer;
  margin-top: 13px;
  margin-left: 15px;

  @media ${breakpoints.tablet} {
    margin-top: unset;
  }
`;

export const HostContainer = styled.div`
  grid-area: userLine;
  display: flex;
`;

export const HostLabel = styled(Label)`
  margin-top: 3px;
  margin-left: 8px;
`;

export const Bottom = styled.div`
  margin-top: 15px;
  overflow: hidden;
  animation: expand 0.4s ease;

  display: grid;
  grid-template-columns: 490px 1fr 207px;
  grid-template-areas:
    'description . averageRating'
    'description . whoIsAttending'
    'sponsors . whoIsAttending';

  @media ${breakpoints.desktop} {
    grid-template-columns: 2fr 1fr 207px;
    grid-template-areas:
      'description . averageRating'
      'description . whoIsAttending'
      'sponsors . whoIsAttending';
  }

  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'description description' 'whoIsAttending averageRating' 'sponsors sponsors';
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: unset;
    grid-template-areas:
      'description' 'whoIsAttending'
      'averageRating' 'sponsors';
  }

  @keyframes expand {
    0% {
      max-height: 0px;
    }

    99% {
      max-height: 500px;
    }

    100% {
      max-height: unset;
    }
  }
`;

export const BottomWithParticipants = styled(Bottom)`
  grid-template-areas:
    'participants . averageRating'
    'description . whoIsAttending'
    'sponsors . whoIsAttending';

  @media ${breakpoints.desktop} {
    grid-template-areas:
      'participants participants averageRating'
      'description . whoIsAttending'
      'sponsors . whoIsAttending';
  }

  @media ${breakpoints.tablet} {
    grid-template-areas: 'participants participants' 'description description' 'whoIsAttending averageRating' 'sponsors sponsors';
  }

  @media ${breakpoints.mobile} {
    grid-template-areas:
      'participants' 'description' 'whoIsAttending'
      'averageRating' 'sponsors';
  }
`;

export const StyledAverageRating = styled(AverageRating)`
  grid-area: averageRating;
  padding-bottom: 10px;

  @media ${breakpoints.tablet} {
    align-self: flex-end;
    justify-self: flex-end;
  }

  @media ${breakpoints.mobile} {
    align-self: unset;
    justify-self: unset;
    margin-top: 28px;
  }
`;

export const StyledSessionParticipants = styled(SessionParticipants)`
  margin-bottom: 28px;
  grid-area: participants;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-bottom: 11px;
`;

export const Description = styled.div`
  grid-area: description;

  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
`;

export const WhoIsAttending = styled.div`
  grid-area: whoIsAttending;

  margin-right: 50px;

  @media ${breakpoints.tablet} {
    margin-top: 28px;
    margin-right: unset;
  }
`;

export const AvatarGroupWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 20px;
`;

export const SponsorsContainer = styled.div`
  grid-area: sponsors;
  margin-top: 24px;
`;

export const Sponsors = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 27px;
  margin-right: -25px; // to negate the right margin of the last logo
  margin-bottom: -30px; // to negate the bottom margin of the last row of logos

  img {
    max-width: 150px;
    max-height: 50px;
  }
`;

export const SponsorLink = styled(Link)`
  margin-right: 25px;
  margin-bottom: 30px;
`;
