import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  width: 100%;
  max-width: 260px;

  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 51px auto 100px auto;
`;

export const Title = styled.h1`
  font-size: 34px;
  font-weight: 500;
  line-height: 42px;
  color: ${colors.text[1]};

  margin-bottom: 25px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${colors.text[1]};

  margin-top: 37px;
  margin-bottom: 9px;
`;
