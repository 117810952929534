import styled from 'styled-components';

import { colors } from 'styles/global';

import { Link, AverageRating } from 'components';

export const Container = styled.div`
  overflow: hidden;
  border-radius: 2px;

  display: flex;
  flex-direction: column;
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background: linear-gradient(
    81.6deg,
    rgba(0, 29, 47, 0.6) 28.17%,
    rgba(0, 29, 47, 0) 127.03%
  );

  box-sizing: border-box;
  padding: 19px 21px 15px 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ImageContainer = styled(Link)`
  position: relative;
  height: 200px;
`;

export const ImageContainerPlaceholder = styled.div`
  position: relative;
  height: 200px;
  font-size: 200px;
  line-height: 1;
`;

export const EventLogo = styled.img`
  max-height: 40px;
  max-width: 150px;
  object-fit: contain;
  object-position: left center;
`;

export const SpeakerLine = styled.div`
  display: flex;
  align-items: center;
`;

export const SpeakerName = styled.p`
  margin-left: 11px;

  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

export const Title = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: ${colors.text[1]};

  margin-top: 16px;
  margin-bottom: 4px;
  max-height: 44px; // double the line height

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const TitlePlaceholder = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  margin-top: 16px;
  margin-bottom: 4px;
`;

export const EventName = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-top: auto; // so it can push itself down if there is space available

  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledAverageRating = styled(AverageRating)`
  align-self: flex-end;
  margin-top: 14px;
  margin-right: 20px;
`;
