import _ from 'lodash';
import { CSSProperties, FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getAvatarDimensions } from 'libs/avatar';
import { DEFAULT_AVATAR_PATH } from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';

import { RoundImage } from './styles';

interface IPlaceholderProps {
  size: EAvatarSizes;
  count?: number;
  className?: string;
}

export interface IProps {
  size: EAvatarSizes;
  src?: string;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const Placeholder = ({ size, className, count = 1 }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Skeleton
        className={className}
        {...getAvatarDimensions({ size })}
        key={`avatar-placeholder-${index}`}
        circle={true}
      />
    ))}
  </>
);

const Avatar: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({ size, src, ...props }) => (
  <RoundImage
    additionalURLArgs={{ fit: 'clamp' }}
    {...getAvatarDimensions({ size })}
    {...props}
    src={src || DEFAULT_AVATAR_PATH}
  />
);

Avatar.Placeholder = Placeholder;

export default Avatar;
