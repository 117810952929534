import _ from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ratingCreate } from 'libs/requests';

import { useWithUser } from 'hooks';

import { Container, Title, Stars, Star } from './styles';

interface IProps {
  className?: string;
  sessionId: number;
  initialRating: number | null;
}

const Rating = ({ className, sessionId, initialRating }: IProps) => {
  const { user, isFetchingInitials } = useWithUser();
  const [rating, setRating] = useState<number>(initialRating || 0);

  const updateRating = async (newRating: number) => {
    const oldValue = rating; // we need it in case the request fails

    setRating(newRating);

    const { success } = await ratingCreate({ sessionId, value: newRating });

    if (!success) {
      setRating(oldValue);
      toast('Something went wrong. Please try again later.', { type: 'error' });
    }
  };

  return (
    <Container
      className={className}
      withOverlay={isFetchingInitials || _.isNil(user)}>
      <Title>How is this session?</Title>
      <Stars>
        {_.range(5, 0, -1).map((index) => {
          // we display the stars in reverse - that's why we use reverse range too
          const color = rating >= index ? '#ffc22b' : '#ebecee';

          return (
            <Star
              icon="star"
              key={index}
              color={color}
              onClick={() => updateRating(index)}
            />
          );
        })}
      </Stars>
    </Container>
  );
};

export default Rating;
