import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Icon, Button } from 'components';

export const Container = styled.div`
  background: white;

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  box-sizing: border-box;
  padding: 18px 61px 22px 28px;

  display: grid;
  align-items: flex-start;
  grid-template-columns: auto 200px;
  grid-template-areas: 'left button' '. delete';

  cursor: ${({ cancelled }: { cancelled: boolean }) =>
    cancelled ? 'not-allowed' : 'initial'};
  opacity: ${({ cancelled }: { cancelled: boolean }) => (cancelled ? 0.4 : 1)};

  @media ${breakpoints.tablet} {
    padding: 23px 30px;

    grid-gap: 15px 0;
    grid-template-columns: auto 70px;
    grid-template-areas: 'left delete' 'button button';
  }

  @media ${breakpoints.mobile} {
    grid-template-columns: auto;
    grid-template-areas: 'left' 'button' 'delete';
  }

  &:not(:first-of-type) {
    margin-top: 21px;
  }
`;

export const Left = styled.div`
  grid-area: left;
`;

export const StyledButton = styled(Button)`
  width: 200px;
  grid-area: button;
  margin-top: 18px;

  @media ${breakpoints.tablet} {
    width: 100%;
    margin-top: unset;
  }
`;

export const Start = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[1]};

  margin-bottom: 19px;
`;

export const Delete = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${colors.text[3]};

  align-items: center;
  display: inline-flex;

  margin-top: 30px;

  grid-area: delete;
  grid-column-end: -1; // go to last available column

  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'not-allowed' : 'pointer'};

  @media ${breakpoints.tablet} {
    margin-top: unset;
    justify-self: flex-end;
  }

  @media ${breakpoints.mobile} {
    justify-self: center;
  }
`;

export const TrashIcon = styled(Icon)`
  font-size: 12px;

  margin-right: 7px;
`;
