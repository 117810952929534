import { HOST_YOUR_OWN_CONFERENCE_URL } from 'libs/constants';

import { UnissuLogo } from 'components';

import { TESTIMONIAL_ITEMS } from './constants';
import {
  Wrapper,
  Container,
  Left,
  Title,
  Description,
  StyledButton,
  Right,
  StyledLink,
  Testimonial,
  TestimonialIcon,
  TestimonialTitle,
  TestimonialDescription
} from './styles';

const Testimonials = () => (
  <Wrapper>
    <Container>
      <Left>
        <UnissuLogo width={102.93} height={26.17} />
        <Title>Host your events with Unissu</Title>
        <Description>
          Our unique platform is your video content gateway to a global audience
          of real estate professionals at various stages of learning and
          engagement about how technology is reshaping their careers and
          companies.
        </Description>
        <StyledLink external href={HOST_YOUR_OWN_CONFERENCE_URL}>
          <StyledButton height="tall" fontSize={16} colorScheme={1}>
            Host your own conference
          </StyledButton>
        </StyledLink>
      </Left>

      <Right>
        {TESTIMONIAL_ITEMS.map(({ icon, title, description }) => (
          <Testimonial key={title}>
            <TestimonialIcon icon={icon} />
            <TestimonialTitle>{title}</TestimonialTitle>
            <TestimonialDescription>{description}</TestimonialDescription>
          </Testimonial>
        ))}
      </Right>
    </Container>
  </Wrapper>
);

export default Testimonials;
