import styled from 'styled-components';

import { Button } from 'components';
import { MessageInput } from 'components/Chat/components';

export const Container = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  background-color: #ebecee66;
`;

export const LoadMoreButton = styled(Button)`
  width: 128px;
  margin: 30px auto 11px auto;
`;

export const StyledMessageInput = styled(MessageInput)`
  bottom: 0;
  position: sticky;
`;
