import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon, FormLabel, Button } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 11px;
  font-weight: normal;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 10px;

  font-size: 12px;
  color: ${colors.text[3]};
`;

export const StyledInput = styled.input`
  font-size: 14px;
  line-height: 22px;
  color: ${colors.text[1]};

  width: 100%;
  box-sizing: border-box;
  padding: 7px 11px;

  border-radius: 2px;
  border: 0.75px solid rgba(123, 124, 129, 0.2);

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  background-color: ${(props) =>
    props.disabled ? 'rgba(246, 247, 249, 0.8)' : 'white'};

  &:focus {
    outline: none;
    border: 0.75px solid rgba(123, 124, 129, 0.2);
  }
`;

export const StyledButton = styled(Button)`
  min-width: 92px;
  margin-left: 20px;
`;
