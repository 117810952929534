import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { Fragment } from 'react';

import { Container, Breadcrumb, Chevron, IconBreadcrumb } from './styles';

export interface IIconBreadcrumb {
  title: IconName;
  href: string;
  isIcon: true;
}

export interface IBreadcrumb {
  title: string;
  href: string;
  isIcon: false;
}

export interface IProps {
  breadcrumbs: Array<IIconBreadcrumb | IBreadcrumb>;
  className?: string;
}

const Breadcrumbs = ({ breadcrumbs, className }: IProps) => (
  <Container className={className}>
    {breadcrumbs.map((breadcrumb, index) => {
      const isLast = index === breadcrumbs.length - 1;

      return (
        <Fragment key={`breadcrumb-${breadcrumb.href}`}>
          <Breadcrumb href={breadcrumb.href} notOpaque={isLast}>
            {breadcrumb.isIcon ? (
              <IconBreadcrumb icon={breadcrumb.title} />
            ) : (
              breadcrumb.title
            )}
          </Breadcrumb>
          {!isLast && <Chevron icon="chevron-right" />}
        </Fragment>
      );
    })}
  </Container>
);

export default Breadcrumbs;
