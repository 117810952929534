import { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  Entity,
  Container,
  Grid,
  ResultCount,
  LoadMoreButton,
  EntityResultCountHeader
} from './styles';

interface IProps {
  hasMore: boolean;
  entityName: string;
  isFetching: boolean;
  fetchMore: () => void;
  withBottomBorder: boolean;
  renderedData: ReactElement; // We accept rendered data because we don't know the component we need to use to render it.
  renderedPlaceholder: ReactElement; // We accept rendered placeholder because we don't know the component we need to use to render it.

  entityCount?: number;
  className?: string;
}

const EntityGridSection = ({
  className,
  hasMore,
  fetchMore,
  entityName,
  entityCount,
  isFetching,
  withBottomBorder,
  renderedData,
  renderedPlaceholder
}: IProps) => (
  <Container withBottomBorder={withBottomBorder} className={className}>
    <EntityResultCountHeader>
      <Entity>{entityName}</Entity>
      <ResultCount>
        {isFetching ? <Skeleton width={10} /> : entityCount} result
        {entityCount !== 1 && 's'}
      </ResultCount>
    </EntityResultCountHeader>
    <Grid>
      {renderedData}

      {isFetching && renderedPlaceholder}
    </Grid>

    {hasMore && (
      <LoadMoreButton
        height="short"
        colorScheme={3}
        fontSize={14}
        fontWeight={400}
        onClick={fetchMore}>
        Load More
      </LoadMoreButton>
    )}
  </Container>
);

export default EntityGridSection;
