import styled from 'styled-components';

import { breakpoints, colors } from 'styles/global';

import { Image, Button } from 'components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 48px 50px 109px 53px;

  @media ${breakpoints.tablet} {
    padding: 40px 15px;
  }
`;

export const Container = styled.div`
  padding: 28px 88px;
  margin-top: 3px;
  margin-left: 70px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: white;
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  @media ${breakpoints.desktop} {
    padding: 28px 50px;
  }

  @media ${breakpoints.tablet} {
    padding: 23px 30px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column-reverse;

    padding: 23px 15px;
    margin-left: unset;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.desktop} {
    margin-right: 30px;
  }

  @media ${breakpoints.tablet} {
    margin-top: 17px;
    margin-right: unset;
  }
`;

export const Text = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: ${colors.text[1]};

  max-width: 200px;
  margin-bottom: 17px;

  @media ${breakpoints.mobile} {
    max-width: 100%;
  }
`;

export const StyledImage = styled(Image)`
  object-fit: contain;
  max-width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 200px;
`;
