import { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  fetchAdBanner,
  trackAdBannerViewImpression,
  trackAdBannerClickImpression
} from 'libs/requests';
import { IAdBanner } from 'libs/types';

import { OneDimensionImage } from 'components';

import { Container } from './styles';

const AdBanner = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [adBanner, setAdBanner] = useState<IAdBanner | null>(null);

  const getAdBanner = useCallback(async () => {
    setIsFetching(true);

    const { success, data } = await fetchAdBanner();

    if (success && data) {
      setAdBanner(data);
      trackAdBannerViewImpression({ adCampaignId: data.campaign_id });
    }

    setIsFetching(false);
  }, []);

  useEffect(() => {
    getAdBanner();
  }, [getAdBanner]);

  const openLinkAndTrackClickImpression = () => {
    if (!adBanner) {
      return;
    }

    const { url, campaign_id: adCampaignId } = adBanner;

    window.open(url);
    trackAdBannerClickImpression({ adCampaignId });
  };

  return isFetching ? (
    <Container>
      <Skeleton width={48} height={22} />
      <Skeleton width={200} />
    </Container>
  ) : adBanner ? (
    <Container onClick={openLinkAndTrackClickImpression}>
      <OneDimensionImage src={adBanner.image} height={22} />
      {adBanner.headline}
    </Container>
  ) : null;
};

export default AdBanner;
