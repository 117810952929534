import { ErrorMessageProps, ErrorMessage } from 'formik';
import _ from 'lodash';

import { StyledIcon, ErrorMessageContainer } from './styles';

const FieldError = ({ className, ...props }: ErrorMessageProps) => (
  <ErrorMessageContainer className={className}>
    <ErrorMessage {...props}>
      {(msg) =>
        _.isEmpty(msg) ? null : (
          <>
            <StyledIcon icon="exclamation-triangle" /> {msg}
          </>
        )
      }
    </ErrorMessage>
  </ErrorMessageContainer>
);

export default FieldError;
