import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon } from 'components';

export const Container = styled.div``;

export const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.text[2]};

  margin-bottom: 16px;
`;

export const StarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-of-type) {
    margin-left: ${({ reducedLeftMargin }: { reducedLeftMargin?: boolean }) =>
      reducedLeftMargin ? 4 : 10}px;
  }
`;

export const StarPiece = styled(Icon)`
  font-size: ${({ starFontSize }: { starFontSize: string; color: string }) =>
    starFontSize};
  color: ${({ color }: { starFontSize: string; color: string }) => color};

  &:first-of-type {
    // The first piece of a star is a half while the second is a full star.
    // We put the first half on top of the whole star. If only the half is active -
    // the other half comes from the inactive full star. If the whole star is active -
    // it lights the part the half star is missing.
    position: absolute;
  }
`;

export const RatingCount = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-top: 11px;
`;
