import _ from 'lodash';

import {
  CATEGORY_LIST_URL,
  CATEGORY_DETAIL_URL,
  HOME_URL,
  SESSIONS_URL,
  SESSION_DETAIL_URL
} from 'libs/constants';
import { ISessionDetailSession } from 'libs/types';

import type { IIconBreadcrumb, IBreadcrumb } from 'components/Breadcrumbs';

export const getBreadcrumbs = ({
  session,
  eventName,
  eventSlug
}: {
  session: ISessionDetailSession;
  eventSlug?: string;
  eventName?: string;
}) => {
  if (!eventSlug || !eventName) {
    // we can't proceed without an event slug
    return [];
  }

  const categories = session.categories_tree;

  // initially we have the home url, and the sessions url
  const breadcrumbs: Array<IIconBreadcrumb | IBreadcrumb> = [
    { title: 'home', href: HOME_URL, isIcon: true },
    { title: eventName, href: SESSIONS_URL({ eventSlug }), isIcon: false }
  ];

  const firstCategory = categories[0];
  if (firstCategory) {
    breadcrumbs.push({
      title: firstCategory.title,
      href: CATEGORY_LIST_URL({ eventSlug, category: firstCategory.slug }),
      isIcon: false
    });
  }

  // then we add the url of the last category on the path to the session
  // unless it's the same category as the first one
  const lastCategory = _.last(categories);
  if (lastCategory && lastCategory.slug !== firstCategory.slug) {
    breadcrumbs.push({
      title: lastCategory.title,
      href: CATEGORY_DETAIL_URL({ eventSlug, categorySlug: lastCategory.slug }),
      isIcon: false
    });
  }

  // lastly we add the session itself
  breadcrumbs.push({
    title: session.title,
    href: SESSION_DETAIL_URL({
      eventSlug,
      sessionSlug: session.slug,
      sessionId: session.id
    }),
    isIcon: false
  });

  return breadcrumbs;
};
