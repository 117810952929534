import { withFormik, FormikProps } from 'formik';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { formatFormErrors } from 'libs/common';
import { ESessionStatuses } from 'libs/enums';
import { uploadFiles } from 'libs/fileUpload';
import {
  sessionUpdate,
  sessionFileSign,
  sessionPrerecordedVideoSign
} from 'libs/requests';
import {
  IFile,
  ISessionSpeakerDetailSession,
  ISessionSpeakerDetailSessionPrerecordedVideo
} from 'libs/types';

import { Input, TextArea, Dropzone, AverageRating } from 'components';
import { PrerecordedVideoManager } from 'components/SessionControlCenter/components';

import { VALID_DOWNLOADS_FILE_EXTENSIONS } from './constants';
import {
  Overlay,
  CogIcon,
  OverlayText,
  StyledForm,
  SubmitButton,
  ProgressBar
} from './styles';
import { uploadVideoToMux } from './utils';

interface IProps {
  session: ISessionSpeakerDetailSession;
  setSession: (session: ISessionSpeakerDetailSession) => void;
}

interface IFormValues {
  title: string;
  description: string;
  files: Array<File | IFile>;
  prerecorded_video: ISessionSpeakerDetailSessionPrerecordedVideo | File | null;
}

const SessionUpdateForm = ({
  dirty,
  isValid,
  isSubmitting,
  session,
  status
}: IProps & FormikProps<IFormValues>) => (
  <StyledForm>
    <Input required name="title" label="Title*" />
    <TextArea required name="description" label="Description*" />
    <Dropzone
      multiple={true}
      name="files"
      label="Downloads"
      accept={VALID_DOWNLOADS_FILE_EXTENSIONS}
      description="Upload your company information and have it available for all attendees to download."
    />
    <PrerecordedVideoManager
      name="prerecorded_video"
      disabled={session.status !== ESessionStatuses.UPCOMING}
    />
    <AverageRating
      withTitle
      rating={session.average_rating}
      ratingCount={session.ratings_count}
    />
    <SubmitButton
      type="submit"
      colorScheme={1}
      height="medium"
      fontSize={16}
      fontWeight={600}
      disabled={!dirty || isSubmitting || !isValid}>
      Save
    </SubmitButton>
    {isSubmitting && (
      <Overlay>
        <CogIcon icon="cog" />
        <OverlayText>
          This might take a few minutes.
          <br />
          Please do not close the tab.
          {!_.isNil(status?.videoUploadProgress) && (
            <ProgressBar progress={status?.videoUploadProgress} />
          )}
        </OverlayText>
      </Overlay>
    )}
  </StyledForm>
);

export default withFormik<IProps, IFormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ session }) =>
    _.pick(session, ['title', 'description', 'files', 'prerecorded_video']),
  validationSchema: yup.object().shape({
    title: yup.string().label('Title').required(),
    description: yup.string().label('Description').required()
  }),

  handleSubmit: async (
    formData: IFormValues,
    {
      props: { session, setSession },
      setFieldError,
      resetForm,
      setErrors,
      setStatus
    }
  ) => {
    // handle new file uploads
    const newFiles = formData.files.filter(
      (file) => file instanceof File
    ) as Array<File>;
    const oldFiles = formData.files.filter(
      (file) => !_.includes(newFiles, file)
    ) as Array<IFile>;

    const {
      success: uploadSuccess,
      data: newFileData,
      error: uploadError
    } = await uploadFiles({
      files: newFiles,
      signatureProvider: sessionFileSign
    });

    if (!uploadSuccess) {
      setFieldError('files', uploadError);
      return;
    }

    // handle prerecorded video upload
    const prerecordedVideo = formData.prerecorded_video;
    // We store the id in a separate variable as we only pass it to the
    // Backend when submitting and it's either the id of the file we already have,
    // the file we're uploading or null - in which case we haven't got a file.
    let prerecordedVideoId = _.get(formData, 'prerecorded_video.id', null);

    // if we have a file - it's a new upload. We differentiate new files by their type
    if (prerecordedVideo instanceof File) {
      const {
        data: prerecordedVideoSignData,
        success: prerecordedVideoSignSuccess,
        error: prerecordedVideoSignError
      } = await sessionPrerecordedVideoSign({
        sessionId: session.id,
        data: { filename: prerecordedVideo.name }
      });

      if (!prerecordedVideoSignSuccess || !prerecordedVideoSignData) {
        setFieldError(
          'prerecorded_video',
          prerecordedVideoSignError.statusText ||
            'Something went wrong with the prerecorded video upload. Please try again later.'
        );
        return;
      }

      const {
        success: prerecordedVideoUploadSuccess,
        errorMessage: prerecordedVideoUploadErrorMessage
      } = await uploadVideoToMux({
        url: prerecordedVideoSignData.upload_url,
        video: prerecordedVideo,
        setVideoUploadProgress: (videoUploadProgress: number) =>
          setStatus({ videoUploadProgress })
      });

      if (!prerecordedVideoUploadSuccess) {
        setFieldError(
          'prerecorded_video',
          prerecordedVideoUploadErrorMessage ||
            'Something went wrong with the prerecorded video upload. Please try again later.'
        );
        return;
      }

      prerecordedVideoId = prerecordedVideoSignData.id;
    }

    const preparedFormData = {
      ...formData,
      files: [...oldFiles, ...newFileData],
      prerecorded_video: prerecordedVideoId
    };

    const { success, data, error } = await sessionUpdate({
      sessionId: session.id,
      data: preparedFormData
    });

    if (success && data) {
      toast('Session has been updated successfully!', { type: 'success' });
      resetForm({ values: data });
      setSession({ ...session, ...data });
    } else if (error.status === 400) {
      const formattedErrors = formatFormErrors(error.data);

      setErrors(formattedErrors);
    }

    // reset the status in which we store the videoUploadProgress
    setStatus();
  }
})(SessionUpdateForm);
