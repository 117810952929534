import _ from 'lodash';
import styled from 'styled-components';

import { yellow, darkBlue } from 'styles/global';

const backgroundColorMapping = {
  yellow,
  darkBlue
};

export const StyledLabel = styled.div`
  color: white;
  font-size: 13px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  padding: 0 9px;

  box-sizing: border-box;

  opacity: 0.6;
  border-radius: 2.6129px;
  background-color: ${(props: { color: string }) =>
    _.get(backgroundColorMapping, props.color, 'black')};
`;
