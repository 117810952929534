import { default as NextImage } from 'next/image';
import { FunctionComponent, CSSProperties } from 'react';
import { buildURL } from 'react-imgix';

export interface IProps {
  alt?: string;
  height?: number;
  width?: number;
  className?: string;
  style?: CSSProperties;
  additionalURLArgs?: {};
  unsized?: boolean;
  quality?: number;
  unoptimized?: boolean;
  src: string;
  [key: string]: any; // any other props
}

const Image: FunctionComponent<IProps> = ({
  height,
  width,
  src,
  additionalURLArgs = {},
  ...props
}) => {
  const getSrc = () => {
    if (!src) {
      return;
    }

    return buildURL(
      src,
      { w: width, h: height, ...additionalURLArgs },
      { src, width, height }
    );
  };

  // @ts-ignore + TODO: Extend ImageProps type for IProps once nextjs decides to actually export it.
  return <NextImage height={height} src={getSrc()} width={width} {...props} />;
};

Image.defaultProps = {
  alt: '',
  quality: 100,
  unoptimized: true // Until nextjs stops to override our width we need to pass the `unoptimized` prop.
};

export default Image;
