import { FunctionComponent, CSSProperties } from 'react';
import { buildURL } from 'react-imgix';

export interface IProps {
  alt?: string;
  height?: number;
  width?: number;
  className?: string;
  style?: CSSProperties;
  additionalURLArgs?: {};
  src: string;
  [key: string]: any; // any other props
}

// Use this if you can't pass both width and height of the image
// Can be used if you can't pass either.
const OneDimensionImage: FunctionComponent<IProps> = ({
  height,
  width,
  src,
  additionalURLArgs = {},
  ...props
}) => {
  const getSrc = () => {
    if (!src) {
      return;
    }

    return buildURL(
      src,
      { w: width, h: height, ...additionalURLArgs },
      { src, width, height }
    );
  };

  return <img alt="" height={height} src={getSrc()} width={width} {...props} />;
};

export default OneDimensionImage;
