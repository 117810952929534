import {
  UpdateForm,
  StreamKeys,
  ParticipantManager,
  Data,
  AttendeeInvitationManager
} from './components';
import type { ITab } from './components/TabView';

export const TABS: Array<ITab> = [
  {
    name: 'Basic Info',
    component: UpdateForm,
    title: 'Basic Info',
    description:
      'Name your session and give to good description into what you are going to be talking about. Add details that highlight what makes it unique.'
  },
  {
    name: 'Participants',
    component: ParticipantManager,
    title: 'Participants',
    description:
      'Invite others to join your session and take part in a discussion.'
  },
  {
    name: 'Attendees',
    component: AttendeeInvitationManager,
    title: 'Attendees',
    description: 'Invite your colleagues and network to view the session.'
  },
  {
    name: 'Stream Keys',
    component: StreamKeys,
    title: 'Live Video Content',
    description:
      'Add webinars, livestreams or anything else which best tells your story.'
  },
  {
    name: 'Data',
    component: Data,
    title: 'Session Data',
    description: 'Gain detailed insight into your session data. '
  }
];
