import styled from 'styled-components';

import { colors, lightBlue } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon } from 'components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: 100%;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  transition: border-color 0.3s linear;
  border-color: ${({ focused, theme }: { focused: boolean; theme: ITheme }) =>
    focused ? theme.primaryColor : 'rgba(123, 124, 129, 0.2)'};

  padding: 3px 3px 0 3px; // the bottom padding is handled by the tags and input themselves
  box-sizing: border-box;

  & > * {
    margin-right: 12px;
  }
`;

export const Tag = styled.div`
  border-radius: 2px;
  background-color: ${lightBlue};

  box-sizing: border-box;
  padding: 4px 25px 6px 9px;

  margin-bottom: 3px;

  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[1]};

  position: relative;

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DeleteIconContainer = styled.div`
  height: 100%;
  padding: 0 5px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  cursor: pointer;
  background-color: ${lightBlue};

  top: 0;
  right: 0;
  position: absolute;

  transition: background-color 0.3s linear;

  &:hover {
    background-color: #dce0e4;
  }
`;

export const DeleteIcon = styled(Icon)`
  font-size: 11px;
`;

export const Input = styled.input`
  margin-left: 3px;
  margin-bottom: 3px;

  border: 0;
  flex-grow: 1;
  min-width: 225px;

  &:focus {
    outline: none;
  }
`;
