import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getAvatarDimensions } from 'libs/avatar';
import { EAvatarSizes } from 'libs/enums';
import { getUserOccupation, getUserName } from 'libs/users';

import { useToggleUserDetail } from 'hooks';

import { Avatar } from 'components';

import { Name, Occupation, Container, Right, AvatarContainer } from './styles';

export interface IProps {
  size: EAvatarSizes;
  className?: string;
  openUserDetailOnClick?: boolean;
  withOccupation?: boolean;
  user: {
    position?: string;
    company?: string;
    id: number;
    avatar: string | undefined;
    first_name: string;
    last_name: string;
  };
}

interface IPlaceholderProps {
  size: EAvatarSizes;
  className?: string;
  count?: number;
  width?: string;
  withOccupation?: boolean;
}

const Placeholder = ({
  size,
  className,
  count = 1,
  withOccupation = false
}: IPlaceholderProps) => {
  const { width: avatarWidth } = getAvatarDimensions({ size });

  return (
    <>
      {_.range(count).map((index) => (
        <Container key={`user-line-placeholder-${index}`} className={className}>
          <AvatarContainer>
            <Avatar.Placeholder size={size} />
          </AvatarContainer>
          <Right avatarWidth={avatarWidth}>
            <Name>
              <Skeleton width="150px" />
            </Name>
            {withOccupation && (
              <Occupation size={size}>
                <Skeleton width="180px" />
              </Occupation>
            )}
          </Right>
        </Container>
      ))}
    </>
  );
};

const UserLine: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({
  user: { id, avatar, ...user },
  size,
  className,
  withOccupation = true,
  openUserDetailOnClick = true
}: IProps) => {
  const { setUserDetailUserId } = useToggleUserDetail();

  const name = getUserName({ user });
  const occupation = getUserOccupation({ user });
  const { width: avatarWidth } = getAvatarDimensions({ size });

  return (
    <Container
      className={className}
      onClick={
        openUserDetailOnClick ? () => setUserDetailUserId(id) : undefined
      }>
      <AvatarContainer>
        <Avatar src={avatar} size={size} />
      </AvatarContainer>

      <Right avatarWidth={avatarWidth}>
        <Name>{name}</Name>
        {withOccupation && occupation && (
          <Occupation title={occupation} size={size}>
            {occupation}
          </Occupation>
        )}
      </Right>
    </Container>
  );
};

UserLine.Placeholder = Placeholder;

export default UserLine;
