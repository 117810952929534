import _ from 'lodash';
import moment from 'moment';

import { DATETIME_FORMAT } from 'libs/constants';

export const getFiveThirtyMinuteIntervalTimes = ({
  start
}: {
  start: string;
}) => {
  const momentStart = moment(start, DATETIME_FORMAT);

  return _.range(5).map(
    () => momentStart.add(30, 'minutes').format(DATETIME_FORMAT) // this modifies the original value
  );
};
