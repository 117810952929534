import { HOLISTICS_IFRAME_URL } from 'libs/constants';

import { StyledIframe } from './styles';

export interface IProps {
  embedCode: string;
  token: string;
}

const HolisticsEmbed = ({ embedCode, token }: IProps) => {
  const embed_url = HOLISTICS_IFRAME_URL({
    embedCode,
    token
  });

  return <StyledIframe src={embed_url} />;
};

export default HolisticsEmbed;
