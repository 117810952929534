import styled from 'styled-components';

import { colors, red } from 'styles/global';

import { ITheme } from 'libs/types';

import { FormLabel, Button, Icon } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 6px;
`;

export const UploadTimeDisclaimer = styled.p`
  font-size: 14px;
  line-height: 17px;

  margin-bottom: 18px;
`;

export const Root = styled.div`
  outline: none;
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.text[1]};

  box-sizing: border-box;
  padding: 11px 24px 11px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadButton = styled(Button)`
  width: 96px;
`;

export const FileContainer = styled.div`
  min-height: 36px;

  display: flex;
  align-items: center;

  padding: 0 26px 0 16px;
  box-sizing: border-box;

  border-top: 1px solid rgba(123, 124, 129, 0.2);
`;

export const FileStatus = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  margin-left: auto;
  padding-left: 20px;

  color: ${({ redColor, theme }: { redColor: boolean; theme: ITheme }) =>
    redColor ? red : theme.primaryColor};
`;

export const StyledIcon = styled(Icon)`
  font-size: 12px;
  margin-right: 11px;

  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'not-allowed' : 'pointer'};
`;
