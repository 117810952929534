import _ from 'lodash';
import { FunctionComponent, useState, useEffect, useCallback } from 'react';

import { SESSION_DETAIL_URL } from 'libs/constants';
import { EAvatarSizes, ESessionStatuses } from 'libs/enums';
import { sessionDetail } from 'libs/requests';
import {
  ISessionDetailSession,
  ISessionDetailSessionAttendee,
  ICategoryDetailAndLiveListSession
} from 'libs/types';

import { useWithEvent } from 'hooks';

import { UserLine, AvatarGroup } from 'components';

import {
  Container,
  TitleWrapper,
  Title,
  Category,
  Top,
  Bottom,
  StyledButton,
  Toggler,
  Description,
  SectionTitle,
  WhoIsAttending,
  AvatarGroupWrapper,
  HostContainer,
  HostLabel,
  Sponsors,
  SponsorLink,
  SponsorsContainer,
  ButtonWrapperLink,
  StyledAverageRating,
  BottomWithParticipants,
  StyledSessionParticipants,
  StyledTimeWithLiveIndicator,
  StyledScheduleSessionToggleButton
} from './styles';
import { getVariableButtonProps } from './utils';

export interface IProps {
  session: ICategoryDetailAndLiveListSession;
}

const SessionBox: FunctionComponent<IProps> = ({ session }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [additionalInfo, setAdditionalInfo] = useState<ISessionDetailSession>();

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const getAdditionalInfo = useCallback(async () => {
    const { data, success } = await sessionDetail({
      sessionId: session.id
    });

    if (success) {
      setAdditionalInfo(data);
    }
  }, [session.id]);

  useEffect(() => {
    if (isOpen && _.isEmpty(additionalInfo)) {
      getAdditionalInfo();
    }
  }, [isOpen, session.id, getAdditionalInfo, additionalInfo]);

  // these props get used when the session is not upcoming and the user is not the speaker
  const variableButtonProps = eventSlug
    ? getVariableButtonProps({ session, eventSlug })
    : null;

  const BottomSectionComponent =
    additionalInfo && additionalInfo.participants.length > 0
      ? BottomWithParticipants
      : Bottom;

  return (
    <Container>
      <Top>
        <StyledTimeWithLiveIndicator
          isPast={
            session.status === ESessionStatuses.COMPLETED ||
            session.status === ESessionStatuses.NOT_COMPLETED
          }
          showIndicator={session.status === ESessionStatuses.LIVE}
          start={session.start}
          end={session.end}
        />
        <TitleWrapper>
          {eventSlug && (
            <Title
              href={SESSION_DETAIL_URL({
                sessionId: session.id,
                sessionSlug: session.slug,
                eventSlug
              })}>
              {session.title || 'Session Reserved'}
              {session.category && (
                <Category>{session.category.title}</Category>
              )}
            </Title>
          )}
        </TitleWrapper>
        {session.status === ESessionStatuses.UPCOMING && !session.is_speaker ? (
          <StyledScheduleSessionToggleButton
            fontSize={14}
            height="medium"
            fontWeight={500}
            sessionId={session.id}
            initialIsAddedToSchedule={session.is_added_to_user_schedule}
          />
        ) : (
          variableButtonProps && (
            <ButtonWrapperLink href={variableButtonProps.href}>
              <StyledButton
                height="tall"
                fontSize={15}
                fontWeight={600}
                colorScheme={variableButtonProps.colorScheme}>
                {variableButtonProps.children}
              </StyledButton>
            </ButtonWrapperLink>
          )
        )}
        <Toggler
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen(!isOpen)}
        />
        <HostContainer>
          <UserLine size={EAvatarSizes.LARGE} user={session.speaker} />
          <HostLabel color="yellow">Host</HostLabel>
        </HostContainer>
      </Top>
      {isOpen && additionalInfo && (
        <BottomSectionComponent>
          <StyledSessionParticipants
            participants={additionalInfo.participants}
          />

          <StyledAverageRating
            rating={additionalInfo.average_rating}
            ratingCount={additionalInfo.ratings_count}
          />

          <Description>
            <SectionTitle>Session Description</SectionTitle>
            {additionalInfo.description}
          </Description>

          {additionalInfo.attendee_count > 0 && (
            <WhoIsAttending>
              <SectionTitle>Who’s attending</SectionTitle>
              <AvatarGroupWrapper>
                <AvatarGroup
                  size={EAvatarSizes.SMALL}
                  avatars={additionalInfo.attendees
                    .slice(0, 3)
                    .map(
                      (attendee: ISessionDetailSessionAttendee) =>
                        attendee.avatar
                    )}
                />
              </AvatarGroupWrapper>
              {additionalInfo.attendee_count} people are attending this session.
            </WhoIsAttending>
          )}

          {event && additionalInfo.sponsors.length > 0 && (
            <SponsorsContainer>
              <SectionTitle>{event.session_sponsors_text}</SectionTitle>
              <Sponsors>
                {additionalInfo.sponsors.map((sponsor) => (
                  <SponsorLink external href={sponsor.url}>
                    <img
                      src={sponsor.light_background_logo}
                      alt="sponsor logo"
                    />
                  </SponsorLink>
                ))}
              </Sponsors>
            </SponsorsContainer>
          )}
        </BottomSectionComponent>
      )}
    </Container>
  );
};

export default SessionBox;
