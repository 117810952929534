import _ from 'lodash';
import { useState } from 'react';

import { SESSION_SPEAKER_DETAIL_URL } from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import { trackSessionFileDownload } from 'libs/requests';
import { ISessionDetailSession } from 'libs/types';

import { useWithUser, useWithEvent } from 'hooks';

import { Link, UserLine, BookAMeetingModal } from 'components';

import {
  StyledRating,
  Container,
  HostContainer,
  HostLabel,
  Description,
  SectionTitle,
  Sponsors,
  SponsorsContainer,
  StyledSessionParticipants,
  StyledSponsorBoxWithBrochure,
  Downloads,
  ButtonAndDownloads,
  StyledFile,
  VisibleOnTabletAndBelow,
  StyledButton
} from './styles';

interface IProps {
  className?: string;
  session: ISessionDetailSession;
}

const SessionData = ({ className, session }: IProps) => {
  const [
    bookAMeetingModalParticipantId,
    setBookAMeetingModalParticipantId
  ] = useState<null | number>(null);
  const { user, isFetchingInitials } = useWithUser();

  const { event } = useWithEvent();
  const eventSlug = event?.slug;
  const userHasTicket = event?.user_has_ticket;

  return (
    <Container className={className}>
      <HostContainer>
        <UserLine size={EAvatarSizes.XLARGE} user={session.speaker} />
        <HostLabel color="yellow">Host</HostLabel>
      </HostContainer>
      <StyledSessionParticipants participants={session.participants} />
      <Description>
        <SectionTitle>Session Description</SectionTitle>
        {session.description}
      </Description>
      {event && session.sponsors.length > 0 && (
        <SponsorsContainer>
          <SectionTitle>{event.session_sponsors_text}</SectionTitle>
          <Sponsors>
            {session.sponsors.map((sponsor) => (
              <StyledSponsorBoxWithBrochure sponsor={sponsor} />
            ))}
          </Sponsors>
        </SponsorsContainer>
      )}
      {userHasTicket && (
        <StyledRating
          sessionId={session.id}
          initialRating={session.user_rating}
        />
      )}
      <ButtonAndDownloads>
        {session.is_speaker ? (
          <VisibleOnTabletAndBelow>
            {eventSlug && (
              <Link
                href={SESSION_SPEAKER_DETAIL_URL({
                  eventSlug,
                  sessionSlug: session.slug,
                  sessionId: session.id
                })}>
                <StyledButton
                  outline
                  colorScheme={1}
                  fontSize={15}
                  fontWeight={600}
                  height="tall">
                  Admin Panel
                </StyledButton>
              </Link>
            )}
          </VisibleOnTabletAndBelow>
        ) : (
          <StyledButton
            height="tall"
            fontSize={15}
            colorScheme={1}
            title={
              user
                ? ''
                : 'You have to be logged in to book a meeting with the host.'
            }
            disabled={isFetchingInitials || !user}
            onClick={() =>
              setBookAMeetingModalParticipantId(session.speaker.id)
            }>
            Book Meeting with Host
          </StyledButton>
        )}

        {!_.isEmpty(session.files) && (
          <Downloads>
            <SectionTitle>Session Downloads</SectionTitle>
            {session.files.map((file) => (
              <StyledFile
                href={file.url}
                filename={_.truncate(file.filename, {
                  length: 25,
                  omission: '...'
                })}
                key={`file-${file.url}`}
                extraOnClickAction={() =>
                  trackSessionFileDownload({ sessionFileId: file.id })
                }
              />
            ))}
          </Downloads>
        )}
      </ButtonAndDownloads>

      <BookAMeetingModal
        participantId={bookAMeetingModalParticipantId}
        closeModal={() => setBookAMeetingModalParticipantId(null)}
      />
    </Container>
  );
};

export default SessionData;
