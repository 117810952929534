import styled from 'styled-components';

import { lightBlueGrey, colors } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon, UserLine, Button } from 'components';
import { ChatBubble, MessageInput } from 'components/Chat/components';

export const Container = styled.div`
  flex-grow: 1;

  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  padding: 11px 20px 13px 15px;

  background-color: ${lightBlueGrey};

  top: 0;
  position: sticky;
`;

export const BackIcon = styled(Icon)`
  font-size: 16px;
  cursor: pointer;
  color: ${colors.text[1]};

  margin-right: 20px;
`;

export const StyledUserLine = styled(UserLine)`
  flex-grow: 1;
  cursor: pointer;
  max-width: calc(100% - 70px);
`;

// we need this for the icon background
export const VideoChatIconContainer = styled.div`
  height: 32px;
  min-width: 32px;

  cursor: pointer;
  border-radius: 50%;
  background-color: #ebecee;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; // in order to push it right
`;

export const VideoChatIcon = styled(Icon)`
  font-size: 11px;
`;

export const LoadMoreButton = styled(Button)`
  width: 128px;
  margin: 11px auto;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  padding: 5.5px 0;
  box-sizing: border-box;
`;

export const DateDivider = styled.div`
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  color: ${colors.text[1]};

  display: flex;
  align-items: center;

  &:before,
  &:after {
    content: '';
    height: 0;
    width: 100%;
    border-bottom: 1px solid rgba(123, 124, 129, 0.2);
  }

  &:before {
    margin-right: 15px;
  }

  &:after {
    margin-left: 15px;
  }
`;

export const UnreadDivider = styled.div`
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  margin-right: 23px;
  color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};

  display: flex;
  align-items: center;

  &:before {
    content: '';
    height: 0;
    width: 100%;
    margin-right: 13px;
    border-bottom: 1px solid
      ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  }
`;

export const StyledChatBubble = styled(ChatBubble)`
  margin: 4.5px 20px;

  margin-left: ${({ received }: { received: boolean }) =>
    received ? '20px' : 'auto'};
`;

export const StyledChatBubblePlaceholder = styled(ChatBubble.Placeholder)`
  margin: 4.5px 20px;

  margin-left: ${({ received }: { received: boolean }) =>
    received ? '20px' : 'auto'};
`;

export const StyledMessageInput = styled(MessageInput)`
  bottom: 0;
  position: sticky;
`;
