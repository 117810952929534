import _ from 'lodash';
import { useRouter } from 'next/router';
import { FunctionComponent, useState, useEffect, useCallback } from 'react';

import {
  ROUND_TABLE_DETAIL_URL,
  EVENT_TICKET_PURCHASE_URL
} from 'libs/constants';
import { EAvatarSizes, ERoundTableStatuses } from 'libs/enums';
import { roundTableDetail } from 'libs/requests';
import {
  ICategoryDetailRoundTable,
  IRoundTableDetailRoundTable,
  IRoundTableDetailRoundTableAttendee
} from 'libs/types';
import { getUserName, getUserOccupation } from 'libs/users';

import { useWithEvent, useWithUser, useToggleUserDetail } from 'hooks';

import { UserLine, Avatar } from 'components';

import {
  Bold,
  CapacityLeft,
  ChairIcon,
  AttendeeCircle,
  TitleLink,
  AttendeeCirclePlaceholder,
  AttendeeOccupation,
  AttendeeTooltip,
  AttendeeName,
  Attendees,
  Container,
  TitleWrapper,
  Title,
  Top,
  Bottom,
  Toggler,
  Description,
  SectionTitle,
  WhoIsAttending,
  StyledSessionParticipants,
  HostContainer,
  HostLabel,
  Sponsors,
  SponsorLink,
  SponsorsContainer,
  ButtonAndCapacityLeft,
  StyledTimeWithLiveIndicator
} from './styles';
import { getButton } from './utils';

export interface IProps {
  roundTable: ICategoryDetailRoundTable;
}

const NUMBER_OF_ATTENDEE_CIRCLES_TO_BE_SHOWN = 6;

const RoundTableBox: FunctionComponent<IProps> = ({
  roundTable: initialRoundTable
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [
    detailedRoundTableInfo,
    setDetailedRoundTableInfo
  ] = useState<IRoundTableDetailRoundTable>();

  const router = useRouter();
  const { user, isFetchingInitials } = useWithUser();
  const { event } = useWithEvent();
  const { setUserDetailUserId } = useToggleUserDetail();

  const eventSlug = event?.slug;
  const roundTable = detailedRoundTableInfo
    ? detailedRoundTableInfo
    : initialRoundTable;
  const capacityLeft = roundTable.capacity_left;

  const getDetailedInfo = useCallback(async () => {
    const { data, success } = await roundTableDetail({
      roundTableId: roundTable.id
    });

    if (success && data) {
      setDetailedRoundTableInfo(data);
    }
  }, [roundTable?.id]);

  useEffect(() => {
    if (isOpen && _.isEmpty(detailedRoundTableInfo)) {
      getDetailedInfo();
    }
  }, [isOpen, roundTable.id, getDetailedInfo, detailedRoundTableInfo]);

  if (!eventSlug) {
    return null;
  }

  const roundTableUrl = ROUND_TABLE_DETAIL_URL({
    roundTableJitsiRoomName: roundTable.jitsi_room_name,
    eventSlug,
    next: router.asPath
  });

  const purchaseEventTicketUrl = EVENT_TICKET_PURCHASE_URL({
    eventSlug,
    next: router.asPath
  });

  return (
    <Container isExpanded={isOpen}>
      <Top>
        <StyledTimeWithLiveIndicator
          isPast={false} // We don't show past round tables for now
          showIndicator={roundTable.status === ERoundTableStatuses.LIVE}
          start={roundTable.start}
          end={roundTable.end}
        />
        <TitleWrapper>
          {roundTable.can_user_join ? (
            <TitleLink href={roundTableUrl}>{roundTable.title}</TitleLink>
          ) : (
            <Title> {roundTable.title}</Title>
          )}
        </TitleWrapper>
        <ButtonAndCapacityLeft>
          {getButton({
            user,
            event,
            roundTable,
            roundTableUrl,
            isFetchingInitials,
            purchaseEventTicketUrl
          })}
          <CapacityLeft>
            {capacityLeft > 0 ? (
              <>
                <Bold>{roundTable.capacity_left}</Bold> seats available
              </>
            ) : (
              <Bold>Room Full</Bold>
            )}
          </CapacityLeft>
        </ButtonAndCapacityLeft>
        <Toggler
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsOpen(!isOpen)}
        />
        <HostContainer>
          <UserLine size={EAvatarSizes.LARGE} user={roundTable.host} />
          <HostLabel color="yellow">Host</HostLabel>
        </HostContainer>
      </Top>
      {isOpen && detailedRoundTableInfo && (
        <Bottom>
          <StyledSessionParticipants
            participants={detailedRoundTableInfo.participants}
          />
          <Description>
            <SectionTitle>Topic Description</SectionTitle>
            {detailedRoundTableInfo.description}
          </Description>

          <WhoIsAttending>
            <SectionTitle>Who’s in the room</SectionTitle>
            <Attendees>
              {detailedRoundTableInfo.attendees
                .slice(0, NUMBER_OF_ATTENDEE_CIRCLES_TO_BE_SHOWN)
                .map((attendee: IRoundTableDetailRoundTableAttendee) => (
                  <AttendeeCircle key={`attendee-circle-${attendee.id}`}>
                    <Avatar
                      src={attendee.avatar}
                      size={EAvatarSizes.LARGE}
                      onClick={() => setUserDetailUserId(attendee.id)}
                    />
                    <AttendeeTooltip>
                      <AttendeeName>
                        {getUserName({ user: attendee })}
                      </AttendeeName>
                      <AttendeeOccupation>
                        {getUserOccupation({ user: attendee })}
                      </AttendeeOccupation>
                    </AttendeeTooltip>
                  </AttendeeCircle>
                ))}
              {/* If there are less than 6 attendees we show circles with seats */}
              {_.range(
                0,
                NUMBER_OF_ATTENDEE_CIRCLES_TO_BE_SHOWN -
                  detailedRoundTableInfo.attendees.length,
                1
              ).map((index) => (
                <AttendeeCirclePlaceholder
                  key={`attendee-circle-placeholder-${index}`}>
                  <ChairIcon icon="chair" />
                </AttendeeCirclePlaceholder>
              ))}
            </Attendees>
          </WhoIsAttending>

          {event && detailedRoundTableInfo.sponsors.length > 0 && (
            <SponsorsContainer>
              <SectionTitle>
                {detailedRoundTableInfo.sponsors_text}
              </SectionTitle>
              <Sponsors>
                {detailedRoundTableInfo.sponsors.map((sponsor) => (
                  <SponsorLink external href={sponsor.url}>
                    <img
                      src={sponsor.light_background_logo}
                      alt="sponsor logo"
                    />
                  </SponsorLink>
                ))}
              </Sponsors>
            </SponsorsContainer>
          )}
        </Bottom>
      )}
    </Container>
  );
};

export default RoundTableBox;
