import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { EAvatarSizes } from 'libs/enums';
import { getMessageTime } from 'libs/messaging';
import { IPrivateChatPreviewListChat } from 'libs/types';

import { UserLine } from 'components';
import { getOtherChatParticipant } from 'components/Chat/utils';

import {
  Top,
  Bottom,
  Wrapper,
  Message,
  Container,
  CreationDate,
  StyledUserLine,
  UnreadMessagesCount
} from './styles';

export interface IProps {
  onClick: () => void;
  privateChat: IPrivateChatPreviewListChat;
}

interface IPlaceholderProps {
  count?: number;
}

const Placeholder = ({ count = 1 }) => (
  <>
    {_.range(count).map((index) => (
      <Wrapper key={`private-chat-preview-placeholde-${index}`} seen={true}>
        <Container>
          <Top>
            <UserLine.Placeholder size={EAvatarSizes.MEDIUM} />
          </Top>
          <Bottom>
            <Message>
              <Skeleton width="200px" />
            </Message>
          </Bottom>
        </Container>
      </Wrapper>
    ))}
  </>
);

const PrivateChatPreview: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({ onClick, privateChat }: IProps) => {
  const hasUnreadMessages = privateChat.unread_messages_count > 0;
  const otherParticipant = getOtherChatParticipant({ chat: privateChat });

  return (
    <Wrapper seen={!hasUnreadMessages} onClick={onClick}>
      <Container>
        <Top>
          <StyledUserLine
            openUserDetailOnClick={false}
            size={EAvatarSizes.MEDIUM}
            user={otherParticipant}
          />
          <CreationDate>
            {getMessageTime({ time: privateChat.last_message_created_at })}
          </CreationDate>
        </Top>

        <Bottom>
          <Message>{privateChat.last_message_content}</Message>
          <UnreadMessagesCount visible={hasUnreadMessages}>
            {privateChat.unread_messages_count}
          </UnreadMessagesCount>
        </Bottom>
      </Container>
    </Wrapper>
  );
};

PrivateChatPreview.Placeholder = Placeholder;

export default PrivateChatPreview;
