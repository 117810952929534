import { SESSIONS_URL } from 'libs/constants';

import { useWithEvent } from 'hooks';

import { Link, TimestampDivider } from 'components';

import image from './assets/image.png';
import {
  Left,
  Text,
  Wrapper,
  Container,
  StyledImage,
  StyledButton
} from './styles';

const NoLiveSessions = () => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  return (
    <Wrapper>
      {event ? (
        <TimestampDivider time={event.start} />
      ) : (
        <TimestampDivider.Placeholder />
      )}

      <Container>
        <Left>
          <Text>No live sessions taking place</Text>
          {eventSlug && (
            <Link href={SESSIONS_URL({ eventSlug })}>
              <StyledButton
                colorScheme={1}
                height="medium"
                fontSize={15}
                fontWeight={500}>
                Browse Sessions
              </StyledButton>
            </Link>
          )}
        </Left>

        <StyledImage width={252.01} height={242.95} src={image} />
      </Container>
    </Wrapper>
  );
};
export default NoLiveSessions;
