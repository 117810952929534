import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Button } from 'components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  border-radius: 2px;
  border: 0.75px solid rgba(123, 124, 129, 0.2);

  padding: 20px 0;
  box-sizing: border-box;
`;

export const Title = styled.h5`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[1]};

  margin-top: 6px;
  margin-bottom: 16px;
`;

export const InputContainer = styled.div`
  width: 100%;
  min-height: 36px;

  padding-left: 16px;
  padding-right: 26px;
  box-sizing: border-box;

  display: flex;

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const AddButton = styled(Button)`
  width: 76px;
  margin-left: 20px;

  @media ${breakpoints.mobile} {
    width: 100%;
    margin-top: 20px;
    margin-left: unset;
  }
`;

export const Emails = styled.div`
  margin-top: 30px;

  display: grid;
  grid-gap: 7px;

  max-height: 261px;
  overflow-y: auto;
`;

export const Email = styled.div`
  padding: 4px 24px 0 17px;
  box-sizing: border-box;

  border-top: 0.75px solid rgba(123, 124, 129, 0.2);

  font-size: 15px;
  line-height: 21px;
  color: ${colors.text[1]};
`;

export const SubmitButton = styled(Button)`
  width: 180px;
  margin-top: 44px;

  align-self: flex-end;
`;
