import styled from 'styled-components';

import { breakpoints } from 'styles/global';

export const LargeContainer = styled.div`
  margin: 0 auto;

  @media ${breakpoints.wide} {
    width: 1440px;
  }

  @media ${breakpoints.desktop} {
    width: 100%;
  }
`;
