import styled from 'styled-components';

import { colors, yellow, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

export const Wrapper = styled.div`
  overflow: hidden;

  background: white;
  border-radius: 2px;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  border: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? '1px' : 0)}
    solid rgba(123, 124, 129, 0.2);
  margin-bottom: ${({ isVisible }: { isVisible: boolean }) =>
    isVisible ? '40px' : 0};
  max-height: ${({ isVisible }: { isVisible: boolean }) =>
    isVisible ? '550px' : 0};
  opacity: ${({ isVisible }: { isVisible: boolean }) => (isVisible ? 1 : 0)};

  transition: all 0.5s ease;
`;

export const Container = styled.div`
  box-sizing: border-box;
  padding: 21px 60px 36px 22px;
`;

export const Checkbox = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  display: inline-flex;
  align-items: center;

  cursor: pointer;
`;

export const Indicator = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 14px;

  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid rgba(123, 124, 129, 0.2);

  filter: drop-shadow(0px 4px 5px rgba(123, 124, 129, 0.05));

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 11px;

  transition: background-color 0.2s ease;
  background-color: ${({
    theme,
    selected
  }: {
    theme: ITheme;
    selected: boolean;
  }) => (selected ? theme.primaryColor : 'white')};
`;

export const PaddedLeft = styled.div`
  padding-left: 34px;
`;

export const Price = styled.div`
  color: ${yellow};
  margin-top: 2px;
  margin-bottom: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};

  margin-bottom: 13px;
`;

export const Content = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;

  @media ${breakpoints.mobile} {
    grid-template-columns: unset;
  }

  span {
    font-size: 14px;
    line-height: 30px;
    color: ${colors.text[1]};
    white-space: nowrap;

    @media ${breakpoints.tablet} {
      white-space: unset;
    }

    &:before {
      content: '+ ';
      color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
      font-size: 14px;
      line-height: 30px;
    }
  }
`;
