import _ from 'lodash';
import { useRef, useState, useEffect, useCallback } from 'react';

import PusherManager from 'libs/pusher';
import {
  fetchNotifications,
  fetchNextNotifications,
  markNotificationsSeen
} from 'libs/requests';
import { INotificationListNotification } from 'libs/types';

import { useWithUser, useWithEvent } from 'hooks';

import { Icon } from 'components';
import { Notification } from 'components/Navigation/components';

import {
  Title,
  Wrapper,
  Container,
  LoadMoreButton,
  ToggleContainer,
  NoNotifications,
  NewNotificationsCount
} from './styles';

interface IProps {
  className?: string;
}

const Notifications = ({ className }: IProps) => {
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [notifications, setNotifications] = useState<
    Array<INotificationListNotification>
  >([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { user } = useWithUser();
  const { event } = useWithEvent();

  const getNotifications = useCallback(async () => {
    setIsFetching(true);

    const { success, data } = await fetchNotifications();

    if (success && data) {
      setNextUrl(data.next);
      setNotifications(data.results);
    }

    setIsFetching(false);
  }, []);

  const getMoreNotifications = useCallback(async () => {
    if (!nextUrl) {
      return;
    }

    setIsFetching(true);

    const { success, data } = await fetchNextNotifications({ nextUrl });

    if (success && data) {
      setNextUrl(data.next);
      setNotifications((previousNotifications) => [
        ...previousNotifications,
        ...data.results
      ]);
    }

    setIsFetching(false);
  }, [nextUrl]);

  const handleClickOutsideDropdown = (event: Event) => {
    if (dropdownRef.current && event.target instanceof Node) {
      const clickIsInsideDropdown = dropdownRef.current.contains(event.target);

      if (!clickIsInsideDropdown) {
        setIsDropdownOpen(false);
      }
    }
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    // we use this to close the dropdown once the user clicks outside it
    document.addEventListener('click', handleClickOutsideDropdown);

    return () =>
      document.removeEventListener('click', handleClickOutsideDropdown);
  }, []);

  useEffect(() => {
    if (user?.id && event?.id) {
      return PusherManager.bindToNewNotificationEvent({
        userId: user.id,
        eventId: event.id,
        eventHandler: (newNotification: INotificationListNotification) => {
          setNotifications((oldNotifications) => [
            newNotification,
            ...oldNotifications
          ]);
        }
      });
    }
  }, [user?.id, event?.id]);

  useEffect(() => {
    // If the user is opening the notifications panel we need to mark
    // all his notifications as seen on the backend. Once he closes the
    // panel we need to mark them as seen in the state too, so that next
    // time he opens the panel the information shown is correct.

    if (isDropdownOpen) {
      markNotificationsSeen();
    } else {
      setNotifications((notifications) =>
        notifications.map((notification) => ({
          ...notification,
          is_seen: true
        }))
      );
    }
  }, [isDropdownOpen]);

  const newNotificationsCount = notifications.filter(
    (notification) => !notification.is_seen
  ).length;

  return (
    <Wrapper className={className} ref={dropdownRef}>
      <ToggleContainer onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <Icon icon="bell" weight="far" />
        <NewNotificationsCount visible={newNotificationsCount > 0}>
          {newNotificationsCount}
        </NewNotificationsCount>
      </ToggleContainer>
      <Container visible={isDropdownOpen}>
        <Title>Notifications</Title>
        {_.isEmpty(notifications) && !isFetching && (
          <NoNotifications>
            You have no notifications at this time
          </NoNotifications>
        )}

        {notifications.map((notification) => (
          <div onClick={() => setIsDropdownOpen(false)} key={notification.id}>
            <Notification notification={notification} />
          </div>
        ))}

        {nextUrl && !isFetching && (
          <LoadMoreButton
            colorScheme={3}
            fontSize={14}
            height="short"
            fontWeight={400}
            onClick={getMoreNotifications}>
            Load More
          </LoadMoreButton>
        )}
      </Container>
    </Wrapper>
  );
};
export default Notifications;
