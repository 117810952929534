import { useField } from 'formik';

import { FieldError } from 'components';

import { StyledInput, StyledLabel } from './styles';

export interface IProps {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  validate?:
    | ((arg0?: string) => string | undefined)
    | ((arg0?: string) => Promise<string | undefined>);
}

const Input = ({ label, ...props }: IProps) => {
  const [field, { error, touched }] = useField(props);

  const isFieldInvalid = Boolean(touched && error);

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput invalid={isFieldInvalid} {...field} {...props} />
      <FieldError name={props.name} />
    </div>
  );
};

export default Input;
