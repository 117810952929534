import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState, useCallback, FunctionComponent } from 'react';

import { getSessionAttendees } from 'libs/requests';
import { ISessionDetailSessionAttendee } from 'libs/types';

import { useToggleUserDetail } from 'hooks';

import { BookAMeetingModal } from 'components';
import { IChatTabProps } from 'components/Chat';
import { AttendeeLine } from 'components/Chat/components';

const Attendees: FunctionComponent<IChatTabProps> = ({ openedPrivateChat }) => {
  const [attendees, setAttendees] = useState<
    Array<ISessionDetailSessionAttendee>
  >();
  const [
    bookAMeetingModalParticipantId,
    setBookAMeetingModalParticipantId
  ] = useState<null | number>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const router = useRouter();
  const { setUserDetailUserId } = useToggleUserDetail();

  const fetchAttendees = useCallback(async () => {
    const { sessionId } = router.query;

    if (!sessionId) {
      return;
    }

    setIsFetching(true);
    const { data, success } = await getSessionAttendees({ sessionId });

    if (success && data) {
      const { results } = data;
      setAttendees(results);
    }

    setIsFetching(false);
  }, [router.query]);

  useEffect(() => {
    fetchAttendees();
  }, [fetchAttendees]);

  return isFetching ? (
    <AttendeeLine.Placeholder count={12} />
  ) : (
    <>
      {_.map(attendees, (attendee, index) => (
        <AttendeeLine
          attendee={attendee}
          key={`attendee-line-${index}`}
          onClick={() => setUserDetailUserId(attendee.id)}
          openBookAMeetingModal={setBookAMeetingModalParticipantId}
          openNewPrivateChat={openedPrivateChat.openNewChat}
        />
      ))}

      <BookAMeetingModal
        participantId={bookAMeetingModalParticipantId}
        closeModal={() => setBookAMeetingModalParticipantId(null)}
      />
    </>
  );
};

export default Attendees;
