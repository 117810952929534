import { trackSponsorBrochureDownload } from 'libs/requests';
import { ISessionDetailSessionSponsor } from 'libs/types';

import {
  SponsorBox,
  SponsorLogoContainer,
  SponsorLink,
  SponsorBrochureLink
} from './styles';

interface IProps {
  sponsor: ISessionDetailSessionSponsor;
  className?: string;
}

const SponsorBoxWithBrochure = ({ sponsor, className }: IProps) => (
  <SponsorBox className={className}>
    <SponsorLogoContainer>
      <SponsorLink external href={sponsor.url}>
        <img src={sponsor.light_background_logo} alt="sponsor logo" />
      </SponsorLink>
    </SponsorLogoContainer>

    {sponsor.brochure && (
      <SponsorBrochureLink
        extraOnClickAction={() =>
          trackSponsorBrochureDownload({ sponsorId: sponsor.id })
        }
        href={sponsor.brochure}
        filename="Brochure"
      />
    )}
  </SponsorBox>
);
export default SponsorBoxWithBrochure;
