import _ from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { fetchNextEvents } from 'libs/requests';
import { IEventListEvent } from 'libs/types';

import { EventBox } from 'components';

import {
  Title,
  Events,
  Wrapper,
  TitleRow,
  Container,
  EventCount,
  LoadMoreButton
} from './styles';

interface IProps {
  title: string;
  maximumColumnCount: number;
  events: Array<IEventListEvent>;
  eventCount?: number;
  nextUrl?: null | string;
  withDarkerBackground?: boolean;
  withReducedEventBoxSize?: boolean;
}

const EventGrid = ({
  title,
  nextUrl: initialNextUrl,
  events: initialEvents,
  eventCount = 0,
  maximumColumnCount,
  withDarkerBackground = false,
  withReducedEventBoxSize = false
}: IProps) => {
  const [events, setEvents] = useState(initialEvents);
  const [nextUrl, setNextUrl] = useState(initialNextUrl);
  const [isFetching, setIsFetching] = useState(false);

  const fetchMoreEvents = async () => {
    if (!nextUrl) {
      return;
    }

    setIsFetching(true);

    const { data, success } = await fetchNextEvents({ nextUrl });

    if (success && data) {
      setNextUrl(data.next);
      setEvents((previousEvents) => [...previousEvents, ...data.results]);
    } else {
      toast('Something went wrong.', { type: 'error' });
    }

    setIsFetching(false);
  };

  return _.isEmpty(events) ? null : (
    <Wrapper withDarkerBackground={withDarkerBackground}>
      <Container>
        <TitleRow>
          <Title>{title}</Title>
          <EventCount>
            {eventCount} event{eventCount !== 1 && 's'}
          </EventCount>
        </TitleRow>
        <Events maximumColumnCount={maximumColumnCount}>
          {events.map((event) => (
            <EventBox
              key={event.start}
              event={event}
              reducedSize={withReducedEventBoxSize}
            />
          ))}
          {isFetching && (
            <EventBox.Placeholder
              count={3}
              reducedSize={withReducedEventBoxSize}
            />
          )}
        </Events>

        {nextUrl && (
          <LoadMoreButton
            fontSize={14}
            colorScheme={2}
            height="short"
            fontWeight={400}
            onClick={fetchMoreEvents}>
            Load More
          </LoadMoreButton>
        )}
      </Container>
    </Wrapper>
  );
};

export default EventGrid;
