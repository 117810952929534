import _ from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
  FAQ_URL,
  LIVE_URL,
  SEARCH_URL,
  SESSIONS_URL,
  SCHEDULE_URL,
  VIDEO_LIBRARY_URL,
  SESSION_PURCHASE_URL,
  UNISSU_EDIT_PROFILE_URL,
  HOME_URL
} from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import PusherManager from 'libs/pusher';
import { logout } from 'libs/requests';
import { unissuLogin, unissuSignUp } from 'libs/unissuAuth';

import { useWithUser, useWithEvent } from 'hooks';

import { Avatar, AvatarDropdown, UnissuLogo } from 'components';

import { DesktopSearchInput } from './components';
import {
  SearchTogglerIcon,
  StyledMobileSearchInput,
  Wrapper,
  Group,
  NavItem,
  SignUpButton,
  StyledContainer,
  DotIcon,
  Divider,
  NonLinkNavItem,
  LogoContainer,
  VisibleOnDesktop,
  StyledButtonText,
  VisibleOnTabletAndBelow,
  MobileNavigationToggler,
  MobileNavigationContent,
  MobileNavigationContentWrapper,
  StyledNotifications
} from './styles';

interface IProps {
  withNotifications: boolean;
}

const Navigation = ({ withNotifications }: IProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  const router = useRouter();
  const { event } = useWithEvent();
  const { user, isFetchingInitials } = useWithUser();

  const eventSlug = event?.slug;
  const activePath = router && router.route;

  const logoutAndRefreshPage = async () => {
    await logout();
    PusherManager.disconnect();
    router.reload();
  };

  const closeMobileNavigation = () => {
    setIsMobileNavigationOpen(false);
  };

  const leftGroupItems = isSearchActive ? (
    <VisibleOnDesktop>
      <DesktopSearchInput />
    </VisibleOnDesktop>
  ) : (
    <>
      <NavItem
        href={HOME_URL}
        onClick={closeMobileNavigation}
        active={activePath === HOME_URL}>
        Events
      </NavItem>
      <NavItem
        href={VIDEO_LIBRARY_URL}
        onClick={closeMobileNavigation}
        active={activePath === VIDEO_LIBRARY_URL}>
        Video Library
      </NavItem>
      <NavItem
        external
        active={false}
        href={FAQ_URL}
        onClick={closeMobileNavigation}>
        FAQs
      </NavItem>

      {eventSlug && (
        <>
          <Divider />
          {event?.is_live && (
            <NavItem
              href={LIVE_URL({ eventSlug })}
              onClick={closeMobileNavigation}
              active={activePath === LIVE_URL({ eventSlug })}>
              <DotIcon icon="dot-circle" />
              Live Now
            </NavItem>
          )}
          <NavItem
            href={SESSIONS_URL({ eventSlug })}
            active={_.startsWith(activePath, SESSIONS_URL({ eventSlug }))}
            onClick={closeMobileNavigation}>
            Browse Sessions
          </NavItem>
          <NavItem
            href={SCHEDULE_URL({ eventSlug })}
            active={activePath === SCHEDULE_URL({ eventSlug })}
            onClick={closeMobileNavigation}>
            My Schedule
          </NavItem>
          {event?.is_shop_enabled && (
            <NavItem
              href={SESSION_PURCHASE_URL({ eventSlug })}
              active={activePath === SESSION_PURCHASE_URL({ eventSlug })}
              onClick={closeMobileNavigation}>
              Purchase Session
            </NavItem>
          )}
        </>
      )}
    </>
  );

  const buttonsForUnloggedUsers = (
    <>
      <StyledButtonText
        colorScheme={3}
        fontSize={14}
        fontWeight={600}
        onClick={unissuLogin}
        data-testid="nav-login-button">
        Log in
      </StyledButtonText>
      <SignUpButton
        colorScheme={1}
        fontSize={14}
        height="short"
        fontWeight={600}
        onClick={unissuSignUp}
        data-testid="nav-sign-up-button">
        Sign Up
      </SignUpButton>
    </>
  );

  return (
    <Wrapper>
      <StyledContainer>
        <Group>
          <NavItem
            active={false}
            href={HOME_URL}
            onClick={closeMobileNavigation}>
            <LogoContainer>
              <UnissuLogo width={99} height={25.17} />
            </LogoContainer>
          </NavItem>

          <VisibleOnDesktop>
            <Group>{leftGroupItems}</Group>
          </VisibleOnDesktop>
        </Group>
        <Group>
          <VisibleOnDesktop>
            {/* We need the "key" so we can toggle the animation */}
            <SearchTogglerIcon
              key={isSearchActive ? 1 : 0}
              icon={isSearchActive ? 'times' : 'search'}
              onClick={() => setIsSearchActive(!isSearchActive)}
            />
          </VisibleOnDesktop>
          {!isFetchingInitials &&
            (user ? (
              <>
                {withNotifications && <StyledNotifications />}

                <VisibleOnDesktop>
                  <AvatarDropdown />
                </VisibleOnDesktop>

                <VisibleOnTabletAndBelow>
                  <Avatar size={EAvatarSizes.MEDIUM} src={user.avatar} />
                </VisibleOnTabletAndBelow>
              </>
            ) : (
              buttonsForUnloggedUsers
            ))}
          <VisibleOnTabletAndBelow>
            <MobileNavigationToggler
              icon={isMobileNavigationOpen ? 'times' : 'bars'}
              onClick={() =>
                setIsMobileNavigationOpen(
                  (oldIsMobileNavigationOpenValue) =>
                    !oldIsMobileNavigationOpenValue
                )
              }
            />
          </VisibleOnTabletAndBelow>
        </Group>
      </StyledContainer>

      <MobileNavigationContentWrapper isOpen={isMobileNavigationOpen}>
        <MobileNavigationContent>
          <StyledMobileSearchInput
            placeholder="Events, Videos and People"
            buttonAction={(search) => {
              router.push(SEARCH_URL({ search }), undefined, { shallow: true });
              setIsMobileNavigationOpen(false);
            }}
          />
          {leftGroupItems}
          {user ? (
            <>
              <NavItem external active={false} href={UNISSU_EDIT_PROFILE_URL}>
                Edit Profile
              </NavItem>
              <NonLinkNavItem active={false} onClick={logoutAndRefreshPage}>
                Log out
              </NonLinkNavItem>
            </>
          ) : (
            buttonsForUnloggedUsers
          )}
        </MobileNavigationContent>
      </MobileNavigationContentWrapper>
    </Wrapper>
  );
};

export default Navigation;
