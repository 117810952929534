import { LIVE_URL, SESSIONS_URL } from 'libs/constants';

import { useWithEvent } from 'hooks';

import { Link, TimestampDivider } from 'components';

import image from './assets/image.png';
import {
  Wrapper,
  Container,
  Left,
  Text,
  StyledButton,
  StyledImage,
  TimestampDividerWithBottomMargin,
  TimestampDividerPlaceholderWithBottomMargin,
  Gradient
} from './styles';
import { getFiveThirtyMinuteIntervalTimes } from './utils';

const EmptySchedule = () => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  return (
    <Wrapper>
      {event ? (
        <TimestampDivider time={event.start} />
      ) : (
        <TimestampDivider.Placeholder />
      )}
      <Container>
        <Left>
          <Text>You haven’t added any sessions to your schedule.</Text>
          {eventSlug &&
            (event?.is_live ? (
              <Link href={LIVE_URL({ eventSlug })}>
                <StyledButton
                  colorScheme={1}
                  height="medium"
                  fontSize={15}
                  fontWeight={500}>
                  See what’s on
                </StyledButton>
              </Link>
            ) : (
              <Link href={SESSIONS_URL({ eventSlug })}>
                <StyledButton
                  colorScheme={1}
                  height="medium"
                  fontSize={15}
                  fontWeight={500}>
                  Browse Sessions
                </StyledButton>
              </Link>
            ))}
        </Left>

        <StyledImage width={340} height={253} src={image} />
      </Container>
      {event ? (
        getFiveThirtyMinuteIntervalTimes({ start: event.start }).map((time) => (
          <TimestampDividerWithBottomMargin
            time={time}
            key={`empty-schedule-divider-${time}`}
          />
        ))
      ) : (
        <TimestampDividerPlaceholderWithBottomMargin count={5} />
      )}
      <Gradient />
    </Wrapper>
  );
};
export default EmptySchedule;
