import styled from 'styled-components';

import { red, colors } from 'styles/global';

import { FormLabel } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 11px;
`;

export const StyledInput = styled.input`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  width: 100%;
  box-sizing: border-box;
  padding: 9px 15px 8px 15px;

  border-radius: 2px;
  border-style: solid;
  border-width: 0.75px;
  border-color: ${(props: { invalid: boolean }) =>
    props.invalid ? red : 'rgba(123, 124, 129, 0.2)'};
  transition: border-color 0.2s linear;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
  background-color: ${(props) =>
    props.disabled ? 'rgba(246, 247, 249, 0.8)' : 'white'};

  &:focus {
    outline: none;
  }
`;
