import styled from 'styled-components';

import { colors, lightBlueGrey } from 'styles/global';

import { Button, Link } from 'components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  box-sizing: border-box;
  padding: 31px 25px 26px 26px;

  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: linear-gradient(${lightBlueGrey} 96px, white 96px 100%);
`;

export const Name = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};

  margin-bottom: 3px;
  margin-top: 15px;
`;

export const NamePlaceholder = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};

  margin-bottom: 3px;
  margin-top: 15px;
`;

export const Occupation = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colors.text[2]};
`;

export const ButtonContainerLink = styled(Link)`
  margin-top: auto;
`;

export const ButtonContainerLinkPlaceholder = styled.div`
  margin-top: auto;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
