import styled from 'styled-components';

import { colors } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon } from 'components';

export const Container = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(123, 124, 129, 0.2);
`;

export const Toggler = styled.div`
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
  color: inherit;
  font-size: 11px;

  transform: ${({ flipped }: { flipped: boolean }) =>
    flipped ? 'rotateX(180deg)' : 'rotateX(0deg)'};
  transition: transform 0.2s linear;
`;

export const Description = styled.div`
  margin-top: 13px;

  font-size: 16px;
  line-height: 23px;

  a {
    color: ${({ theme }: { visible: boolean; theme: ITheme }) =>
      theme.primaryColor};
  }

  overflow-y: hidden;

  animation: ${({ visible }: { visible: boolean; theme: ITheme }) =>
      visible ? 'expand' : ''}
    0.3s linear;
  max-height: ${({ visible }: { visible: boolean; theme: ITheme }) =>
    visible ? 'unset' : '0px'};

  @keyframes expand {
    0% {
      max-height: 0px;
    }

    99% {
      max-height: 200px;
    }

    100% {
      max-height: unset;
    }
  }
`;
