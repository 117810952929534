import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

import { UNISSU_USER_DETAIL_URL } from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import { IGlobalSearchUserListUser } from 'libs/types';
import { getUserName, getUserOccupation } from 'libs/users';

import { Avatar, Link } from 'components';

import {
  Container,
  Content,
  Name,
  NamePlaceholder,
  Occupation,
  ButtonContainerLink,
  ButtonContainerLinkPlaceholder,
  StyledButton
} from './styles';

interface IProps {
  user: IGlobalSearchUserListUser;
  className?: string;
}

interface IPlaceholderProps {
  count?: number;
  className?: string;
}

const UserBox = ({ user, className }: IProps) => {
  const detailUrl = UNISSU_USER_DETAIL_URL({
    userId: user.unissu_id
  });

  return (
    <Container className={className}>
      <Content>
        <Link external href={detailUrl}>
          <Avatar size={EAvatarSizes.XXLARGE} src={user.avatar} />
        </Link>
        <Name external href={detailUrl}>
          {getUserName({ user })}
        </Name>
        <Occupation>{getUserOccupation({ user })}</Occupation>
      </Content>

      <ButtonContainerLink external href={detailUrl}>
        <StyledButton
          colorScheme={1}
          fontSize={16}
          fontWeight={500}
          height="medium">
          View Profile
        </StyledButton>
      </ButtonContainerLink>
    </Container>
  );
};

const Placeholder = ({ count = 1, className }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container className={className} key={`user-box-placeholder-${index}`}>
        <Content>
          <Avatar.Placeholder size={EAvatarSizes.XXLARGE} />
          <NamePlaceholder>
            <Skeleton />
          </NamePlaceholder>
          <Occupation>
            <Skeleton />
          </Occupation>
        </Content>

        <ButtonContainerLinkPlaceholder>
          <StyledButton
            colorScheme={1}
            fontSize={16}
            fontWeight={500}
            height="medium">
            View Profile
          </StyledButton>
        </ButtonContainerLinkPlaceholder>
      </Container>
    ))}
  </>
);

UserBox.Placeholder = Placeholder;

export default UserBox;
