import { unissuLogin } from 'libs/unissuAuth';

import { Image, Button } from 'components';

import failImage from './assets/fail_image.png';
import progressImage from './assets/progress_image.png';
import { Container, Title, Description } from './styles';

interface IProps {
  failed: boolean;
}

const LoginInProgress = ({ failed }: IProps) =>
  failed ? (
    <Container>
      <Title>Login Failed</Title>
      <Image src={failImage} height={342} width={256} key="fail image" />
      <Description>Opps something went wrong</Description>
      <Button.Text
        colorScheme={1}
        fontSize={18}
        fontWeight={500}
        onClick={unissuLogin}>
        Try again
      </Button.Text>
    </Container>
  ) : (
    <Container>
      <Title>Logging In</Title>
      <Image src={progressImage} height={342} width={256} key="success image" />
      <Description>This will only take a few moments</Description>
    </Container>
  );

export default LoginInProgress;
