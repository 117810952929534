import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Image, Button, TimestampDivider } from 'components';

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 48px 50px 109px 53px;

  @media ${breakpoints.tablet} {
    padding: 40px 15px 60px 15px;
  }
`;

export const Container = styled.div`
  padding: 23px 15px;
  margin-top: 3px;
  margin-left: 70px;
  box-sizing: border-box;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 300px);

  background: white;
  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  @media ${breakpoints.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoints.tablet} {
    padding: 23px 15px;

    display: flex;
    flex-direction: column-reverse;
  }

  @media ${breakpoints.mobile} {
    margin-left: unset;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: ${colors.text[1]};

  max-width: 290px;
  margin-bottom: 17px;

  @media ${breakpoints.tablet} {
    max-width: unset;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 340px;

  @media ${breakpoints.tablet} {
    margin-bottom: 17px;
  }
`;

export const StyledButton = styled(Button)`
  width: 200px;
`;

export const TimestampDividerWithBottomMargin = styled(TimestampDivider)`
  margin-bottom: 34px;
`;

export const TimestampDividerPlaceholderWithBottomMargin = styled(
  TimestampDivider.Placeholder
)`
  margin-bottom: 34px;
`;

export const Gradient = styled.div`
  left: 0;
  bottom: 134px; // as much as the height is + the margin of the last divider
  position: absolute;

  width: 100%;
  height: 100px;

  background: linear-gradient(
    180deg,
    rgba(246, 247, 249, 0) -122.5%,
    #f6f7f9 121%
  );
`;
