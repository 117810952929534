import { FunctionComponent } from 'react';

import { Image } from 'components';

import {
  Card as StyledCard,
  Content,
  Label,
  Title,
  SponsorLogoContainer,
  StyledImage
} from './styles';

export interface IProps {
  image: string;
  sessionCount: number | string;
  title: string;
  sponsorLogo?: string;
  onClick?: () => void;
}

const Card: FunctionComponent<IProps> = ({
  image,
  sessionCount,
  title,
  sponsorLogo,
  onClick
}) => (
  <StyledCard onClick={onClick}>
    <StyledImage
      src={image}
      height={168}
      width={58}
      additionalURLArgs={{ fit: 'min' }}
    />
    <Content>
      <Label>
        {sessionCount} Session{sessionCount === 1 ? '' : 's'}
      </Label>
      <Title>{title}</Title>
      {sponsorLogo && (
        <SponsorLogoContainer>
          <Image
            width={90}
            height={40}
            src={sponsorLogo}
            additionalURLArgs={{
              fit: 'fill',
              fillColor: 'white'
            }}
          />
        </SponsorLogoContainer>
      )}
    </Content>
  </StyledCard>
);

export default Card;
