import _ from 'lodash';
import { FunctionComponent } from 'react';

import { ECategoryDetailAndLiveListItemTypes } from 'libs/enums';
import {
  ICategoryDetailAndLiveListSessionItem,
  ICategoryDetailAndLiveListRoundTableItem
} from 'libs/types';

import { SessionBox, RoundTableBox } from 'components';

import { Container, Title } from './styles';
import { groupItems } from './utils';

export interface IProps {
  withTitles?: boolean;
  items: Array<
    | ICategoryDetailAndLiveListSessionItem
    | ICategoryDetailAndLiveListRoundTableItem
  >;
}

const Timeline: FunctionComponent<IProps> = ({
  items = [],
  withTitles = false
}) => {
  const itemGroups = groupItems({ items });

  const getAppropriateComponent = (
    item:
      | ICategoryDetailAndLiveListSessionItem
      | ICategoryDetailAndLiveListRoundTableItem
  ) =>
    item.item_type === ECategoryDetailAndLiveListItemTypes.SESSION ? (
      <SessionBox session={item.session} key={`session-${item.session.id}`} />
    ) : (
      <RoundTableBox
        roundTable={item.round_table}
        key={`round-table-${item.round_table.id}`}
      />
    );

  return (
    <Container>
      {itemGroups.past.map(
        (
          item:
            | ICategoryDetailAndLiveListSessionItem
            | ICategoryDetailAndLiveListRoundTableItem
        ) => getAppropriateComponent(item)
      )}
      {withTitles && !_.isEmpty(itemGroups.live) && <Title>Live Now</Title>}
      {itemGroups.live.map(
        (
          item:
            | ICategoryDetailAndLiveListSessionItem
            | ICategoryDetailAndLiveListRoundTableItem
        ) => getAppropriateComponent(item)
      )}
      {withTitles && !_.isEmpty(itemGroups.upcoming) && <Title>Up Next</Title>}
      {itemGroups.upcoming.map(
        (
          item:
            | ICategoryDetailAndLiveListSessionItem
            | ICategoryDetailAndLiveListRoundTableItem
        ) => getAppropriateComponent(item)
      )}
    </Container>
  );
};

export default Timeline;
