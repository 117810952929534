import styled from 'styled-components';

import { breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon, Button } from 'components';

import enquiryPromptBackground from './assets/background.jpg';

export const Container = styled.div`
  display: grid;
  grid-gap: 17px;
  padding-bottom: 100px;
  min-height: 800px;
`;

export const EnquiryPromptWrapper = styled.div`
  width: 100%;

  background-image: url(${enquiryPromptBackground});
`;

export const EnquiryPromptContainer = styled.div`
  margin-top: 30px;

  position: relative;

  &:before {
    content: '';
    opacity: 0.8;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      81.6deg,
      #001d2f 39.05%,
      rgba(0, 29, 47, 0.854084) 100.37%,
      rgba(0, 29, 47, 0) 127.03%
    );
  }
`;

export const EnquiryPrompt = styled.div`
  color: white;

  z-index: 2;
  position: relative;

  padding: 40px 20px 50px 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledIcon = styled(Icon)`
  font-size: 24px;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  margin-top: 8.8px;
  margin-bottom: 23px;
`;

export const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  margin-bottom: 12px;
`;

export const DescriptionPoints = styled.div`
  display: grid;
  grid-gap: 0 36px;
  grid-template-columns: auto auto;

  @media ${breakpoints.mobile} {
    grid-template-columns: unset;
  }

  & > span {
    font-size: 14px;
    line-height: 30px;

    &:before {
      content: '+ ';

      color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
      font-weight: 500;
    }
  }
`;

export const EnquireButton = styled(Button)`
  width: 236px;

  margin-top: 40px;
`;
