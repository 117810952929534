import { useRouter } from 'next/router';
import { useEffect, useContext, useCallback } from 'react';

import { AppContext } from 'pages/_app';

import { setUserDetailUser, setUserDetailUserId } from 'libs/reducer';
import { IUserRetrieveUser } from 'libs/types';

const useToggleUserDetail = () => {
  const { userDetailUser, userDetailUserId, dispatch } = useContext(AppContext);
  const router = useRouter();

  const setNewUser = useCallback(
    (user: IUserRetrieveUser | null) => dispatch?.(setUserDetailUser(user)),
    [dispatch]
  );

  const setNewId = useCallback(
    (userId: number | null) => {
      // reset the current state
      setNewUser(null);
      dispatch?.(setUserDetailUserId(userId));
    },
    [dispatch, setNewUser]
  );

  const close = useCallback(() => {
    setNewId(null);
    setNewUser(null);
  }, [setNewId, setNewUser]);

  useEffect(() => {
    // Clear the user detail data on route change.
    router.events.on('routeChangeComplete', close);

    // If the hook is "unmounted", unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', close);
    };
  }, [router.events, close]);

  return {
    close,
    user: userDetailUser,
    userId: userDetailUserId,
    setUserDetailUser: setNewUser,
    setUserDetailUserId: setNewId
  };
};

export default useToggleUserDetail;
