import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ISessionPurchaseFormSlot } from 'libs/types';

import { Icon, TimestampDivider } from 'components';

import { Container, Price, AddToBasket, Indicator } from './styles';

export interface IProps {
  className?: string;
  slot: ISessionPurchaseFormSlot;
  selected: boolean;
  onClick: () => void;
}

interface IPlaceholderProps {
  count?: number;
  className?: string;
}

const Placeholder: FunctionComponent<IPlaceholderProps> = ({
  className,
  count = 1
}) => (
  <>
    {_.range(count).map((index) => (
      <div className={className} key={`slot-placeholder-${index}`}>
        <TimestampDivider.Placeholder />
        <Container disabled>
          <AddToBasket>
            <Indicator selected={false} />
            Add to basket
          </AddToBasket>
          <Price>
            £<Skeleton width={50} />
          </Price>
        </Container>
      </div>
    ))}
  </>
);

const Slot: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({
  slot: { start, price, reserved },
  selected = false,
  onClick,
  className
}) => (
  <div onClick={reserved ? undefined : onClick} className={className}>
    <TimestampDivider withTimezone time={start} />
    <Container disabled={reserved}>
      <AddToBasket>
        <Indicator selected={selected}>
          <Icon icon="check" />
        </Indicator>
        Add to basket
      </AddToBasket>
      <Price>£{price}</Price>
    </Container>
  </div>
);

Slot.Placeholder = Placeholder;

export default Slot;
