import { Image } from 'components';

import unissuLogo from './assets/unissuLogo.png';

interface IProps {
  width: number;
  height: number;
  [key: string]: any;
}

const UnissuLogo = (props: IProps) => (
  <Image unoptimized={true} priority="eager" src={unissuLogo} {...props} />
);

export default UnissuLogo;
