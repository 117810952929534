import styled from 'styled-components';

import { breakpoints, lightBlueGrey, colors } from 'styles/global';

import { OneDimensionImage } from 'components';

export const Container = styled.div`
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
`;

export const HeaderRow = styled.div`
  padding-right: 82px;
  margin-bottom: 17px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    padding-left: 70px;
    padding-right: 43px;
  }

  @media ${breakpoints.mobile} {
    padding-left: unset;
    padding-right: unset;

    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;

export const CategoryAndSubCategoryContainer = styled.div`
  width: 100%;
`;

export const Category = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  margin-bottom: -7px;
`;

export const SubCategory = styled.h3`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
`;

export const SponsorLogo = styled(OneDimensionImage)`
  @media ${breakpoints.mobile} {
    align-self: flex-end;
  }
`;

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: calc(100% - 80px);
  padding-right: 82px;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    max-height: unset;
    padding-right: 43px;
  }

  @media ${breakpoints.mobile} {
    padding-right: unset;
  }
`;

export const DateDivider = styled.div`
  height: 50px;
  background-color: ${lightBlueGrey};

  border-top: 2px dashed rgba(123, 124, 129, 0.2);

  padding-top: 14px;
  padding-bottom: 10px;

  padding-left: 70px;
  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: ${colors.text[3]};

  z-index: 2;
  top: -14px; // 12 for the margin and 2 for the border
  position: sticky;

  @media ${breakpoints.mobile} {
    padding-left: unset;
  }
`;
