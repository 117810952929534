import { Form } from 'formik';
import styled from 'styled-components';

import { red, lightBlueGrey, breakpoints, colors } from 'styles/global';

import { Container, Select, FieldError, Button, Icon } from 'components';

import { SlotPicker, NoSubCategorySelected } from './components';

export const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
`;

export const StyledContainer = styled(Container.Large)`
  min-height: 700px;
  height: calc(100vh - 315px);

  display: grid;
  grid-template-columns: 604px 836px;

  @media ${breakpoints.desktop} {
    grid-template-columns: 1fr 2fr;
  }

  @media ${breakpoints.tablet} {
    height: unset;
    grid-template-columns: unset;

    display: flex;
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  z-index: 3;
  width: 100%;
  height: 100%;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;

  padding-top: 31px;
  box-sizing: border-box;

  @media ${breakpoints.desktop} {
    padding-left: 20px;
    padding-right: 30px;
  }

  @media ${breakpoints.tablet} {
    height: auto;
    padding: 22px 130px 40px 130px;
  }

  @media ${breakpoints.mobile} {
    flex-shrink: 0;
    padding: 25px 24px 40px 21px;
  }
`;

export const Left = styled.div`
  width: 100%;
  height: 100%;
  max-width: 430px;

  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    max-width: unset;
  }
`;

export const StyledSelect = styled(Select)`
  margin-top: 16px;

  &:first-of-type {
    margin-top: 37px;
  }
`;

export const StyledFieldError = styled(FieldError)`
  margin-bottom: 6px;
`;

export const OpenBasketButton = styled(Button)`
  width: 100%;
  margin-top: auto;
  margin-bottom: 90px;

  position: relative;

  @media ${breakpoints.tablet} {
    width: 58px;
    height: 58px;
    margin-bottom: unset;

    background: white;
    border-radius: 50%;
    box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    bottom: 60px;
    position: fixed;

    font-size: 21px;
    color: ${colors.text[3]};
  }
`;

export const DesktopOpenBasketButtonContent = styled.span`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;

export const MobileOpenBasketButtonContent = styled.span`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const BasketItemCount = styled.div`
  height: 25px;
  width: 25px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid white;
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};

  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  border-radius: 50%;
  background-color: ${red};

  top: -12.5px;
  right: -12.5px;
  position: absolute;

  animation: ${({ visible }: { visible: boolean }) =>
      visible ? 'pop 0.5s' : 'disappear 0.25s'}
    linear;

  @media ${breakpoints.tablet} {
    top: -5px;
    right: -5px;
  }

  @keyframes pop {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }
  }

  @keyframes disappear {
    0%,
    99% {
      font-size: 0; // this is in order to hide the text(in this case - a zero)
      visibility: visible;
    }

    100% {
      visibility: hidden;
      transform: scale(0);
    }
  }
`;

export const Right = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  background-color: ${lightBlueGrey};

  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    max-height: unset;
    padding-bottom: 66px;
    box-sizing: border-box;
  }
`;

export const StyledSlotPickerPlaceholder = styled(SlotPicker.Placeholder)`
  padding-top: 31px;
  padding-left: 47px;
  box-sizing: border-box;

  overflow-y: hidden;
  max-height: 100%;

  @media ${breakpoints.tablet} {
    max-height: unset;
    padding-left: 60px;
  }

  @media ${breakpoints.mobile} {
    padding-left: 21px;
    padding-right: 24px;
  }
`;

export const StyledSlotPicker = styled(SlotPicker)`
  padding-top: 31px;
  padding-left: 47px;
  box-sizing: border-box;

  overflow-y: hidden;
  max-height: 100%;

  @media ${breakpoints.tablet} {
    max-height: unset;
    padding-left: 60px;
  }

  @media ${breakpoints.mobile} {
    padding-left: 21px;
    padding-right: 24px;
  }
`;

export const StyledNoSubCategorySelected = styled(NoSubCategorySelected)`
  padding-top: 93px;
  box-sizing: border-box;
`;

export const Heading = styled.h2`
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
`;

export const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;

  background-color: #30596e99;

  top: 0;
  left: 0;
  z-index: 100; // navigation is 99
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: wait;
`;

export const LoadingIcon = styled(Icon)`
  color: white;
  font-size: 30px;
  animation: rotateClockWise 2s infinite;

  @keyframes rotateClockWise {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
