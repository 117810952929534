import _ from 'lodash';
import { useRouter } from 'next/router';

import { SESSIONS_URL } from 'libs/constants';
import { EVENT_TICKET_PURCHASE_PAYMENT_DETAILS_URL } from 'libs/constants';
import { IEventTicketTypeRetrieveEventTicketType } from 'libs/types';

import { useWithEvent } from 'hooks';

import { Image } from 'components';

import {
  Wrapper,
  Container,
  Title,
  TicketName,
  StyledButton,
  StyledEventLine,
  PaymentStatusTitle,
  PaymentStatusDescription
} from './styles';
import { getVariables } from './utils';

interface IProps {
  success: boolean;
  eventTicketType: IEventTicketTypeRetrieveEventTicketType;
  isFree: boolean;
}

const EventTicketPurchaseStatusBlock = ({
  success,
  eventTicketType,
  isFree
}: IProps) => {
  const router = useRouter();
  const { event } = useWithEvent();
  const variables = getVariables({ success, isFree });

  const handleButtonClick = () => {
    if (!event) {
      return;
    }

    // - take the next page url from the url "next" param
    // - use event home page if the "next" param is not present
    const nextUrl = _.get(
      router,
      'query.next',
      SESSIONS_URL({ eventSlug: event.slug })
    );

    if (success) {
      // - if the purchase was successful we redirect to the next url
      return router.push(nextUrl);
    }

    // - otherwise we have to redirect back to event ticket purchase with the next url we already have
    return router.push(
      EVENT_TICKET_PURCHASE_PAYMENT_DETAILS_URL({
        eventSlug: event.slug,
        next: nextUrl,
        eventTicketTypeId: eventTicketType.id
      })
    );
  };

  return (
    <Wrapper>
      <Container>
        <Title>Ticket Purchase</Title>
        <StyledEventLine />
        <TicketName>{eventTicketType.name}</TicketName>
        <Image {...variables.image} />
        <PaymentStatusTitle isRed={variables.isTitleRed}>
          {variables.title}
        </PaymentStatusTitle>
        <PaymentStatusDescription>
          {variables.description}
        </PaymentStatusDescription>
        {event && (
          <StyledButton
            {...variables.button}
            height="tall"
            onClick={handleButtonClick}
            fontSize={15}
            fontWeight={500}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default EventTicketPurchaseStatusBlock;
