import styled from 'styled-components';

import { colors, lightGrey, lightBlueGrey } from 'styles/global';

import { ITheme } from 'libs/types';

export const Container = styled.div`
  width: 100%;
  max-width: 282px;

  border-radius: 6px;

  padding: 5px 10px;
  box-sizing: border-box;
  background-color: ${({
    received,
    theme
  }: {
    received: boolean;
    theme: ITheme;
  }) => (received ? theme.primaryColor : lightBlueGrey)};

  display: flex;
  flex-direction: column;
`;

export const Content = styled.p`
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  word-break: break-word;
  color: ${({ received }: { received: boolean }) =>
    received ? 'white' : colors.text[1]};

  a {
    color: ${({ received }: { received: boolean }) =>
      received ? 'white' : colors.text[4]};
  }
`;

export const CreationDate = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: ${({ received }: { received: boolean }) =>
    received ? 'white' : lightGrey};

  align-self: flex-end;
`;
