import {
  EVENT_LOGO_PLACEHOLDER,
  SESSION_DETAIL_URL,
  SESSION_THUMBNAIL_PLACEHOLDER
} from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import { IVideoLibraryFeaturedSessionListSession } from 'libs/types';
import { getUserName } from 'libs/users';

import { useCarousel } from 'hooks';

import { Link, Image, Avatar } from 'components';

import {
  EventName,
  EventLogoContainer,
  EventLogo,
  SpeakerLine,
  SpeakerName,
  Arrow,
  Wrapper,
  Container,
  Left,
  Right,
  StyledButton,
  Title,
  StyledAverageRating,
  ThumbnailContainer
} from './styles';

interface IProps {
  sessions: Array<IVideoLibraryFeaturedSessionListSession>;
}

const SessionCarousel = ({ sessions }: IProps) => {
  const {
    activeItem: activeSession,
    switchToPrevious,
    switchToNext
  } = useCarousel({ items: sessions });

  return (
    <Wrapper>
      <Arrow icon="chevron-left" onClick={switchToPrevious} />
      {sessions.map((session) => {
        const sessionDetailUrl = SESSION_DETAIL_URL({
          eventSlug: session.event.slug,
          sessionSlug: session.slug,
          sessionId: session.id
        });

        return (
          <Container key={session.slug} active={activeSession === session}>
            <Left>
              <EventLogoContainer>
                <EventLogo
                  src={
                    session.event.light_background_logo ||
                    EVENT_LOGO_PLACEHOLDER
                  }
                />
              </EventLogoContainer>
              <EventName>{session.event.name}</EventName>
              <Title href={sessionDetailUrl}>{session.title}</Title>
              <SpeakerLine>
                <Avatar
                  size={EAvatarSizes.SMALL}
                  src={session.speaker.avatar}
                />
                <SpeakerName>
                  {getUserName({ user: session.speaker })}
                </SpeakerName>
              </SpeakerLine>
              <StyledAverageRating
                rating={session.average_rating}
                starFontSize="14px"
              />
              <Link href={sessionDetailUrl}>
                <StyledButton
                  colorScheme={1}
                  height="tall"
                  fontSize={18}
                  fontWeight={500}>
                  View Video
                </StyledButton>
              </Link>
            </Left>
            <Right>
              <ThumbnailContainer href={sessionDetailUrl}>
                <Image
                  layout="fill"
                  src={session.thumbnail || SESSION_THUMBNAIL_PLACEHOLDER}
                />
              </ThumbnailContainer>
            </Right>
          </Container>
        );
      })}
      <Arrow icon="chevron-right" onClick={switchToNext} />
    </Wrapper>
  );
};

export default SessionCarousel;
