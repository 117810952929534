import _ from 'lodash';
import { Fragment, useEffect, useCallback, useRef, useState } from 'react';

import { EAvatarSizes } from 'libs/enums';
import PusherManager from 'libs/pusher';

import { useChat, useWithUser, useWithEvent } from 'hooks';

import { UserLine, BookAMeetingModal } from 'components';
import { IOpenedPrivateChat } from 'components/Chat';
import { getOtherChatParticipant } from 'components/Chat/utils';

import {
  Header,
  BackIcon,
  Container,
  DateDivider,
  UnreadDivider,
  VideoChatIcon,
  LoadMoreButton,
  StyledUserLine,
  StyledChatBubble,
  MessagesContainer,
  StyledMessageInput,
  VideoChatIconContainer,
  StyledChatBubblePlaceholder
} from './styles';
import { getDateToShow, getShowUnread } from './utils';

interface IProps {
  openedPrivateChat: IOpenedPrivateChat;
}
const PrivateChatDetail = ({ openedPrivateChat }: IProps) => {
  const [
    bookAMeetingModalParticipantId,
    setBookAMeetingModalParticipantId
  ] = useState<null | number>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const { user } = useWithUser();
  const { event } = useWithEvent();

  const chat = useChat({ chatId: openedPrivateChat.chat?.id });
  const { addMessage, messages } = chat;
  const latestMessage = messages[0];

  const scrollToUnreadDivider = useCallback(
    (ref: HTMLDivElement) => {
      const unseenMessages = _.filter(
        chat.messages,
        (message) => !message.is_seen
      );

      if (!_.isEmpty(unseenMessages)) {
        ref.scrollIntoView({ block: 'center' });
      }
    },
    [chat.messages]
  );

  const scrollContainerToBottom = useCallback(() => {
    if (openedPrivateChat.isFetchingChat || containerRef.current === null) {
      return; // do not scroll if fetching or ref is not yet available
    }

    const { scrollHeight: topOffset } = containerRef.current;

    containerRef.current.scrollTo({ top: topOffset, behavior: 'smooth' });
  }, [containerRef, openedPrivateChat?.isFetchingChat]);

  // This is a callback ref https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const unreadMessagesDividerRef = useCallback(
    (ref: null | HTMLDivElement) => {
      if (ref !== null) {
        scrollToUnreadDivider(ref);
      }
    },
    [scrollToUnreadDivider]
  );

  useEffect(() => {
    if (user?.id && chat.id && event?.id) {
      return PusherManager.bindToNewPrivateMessageEvent({
        userId: user.id,
        eventId: event.id,
        eventHandler: addMessage
      });
    }
  }, [user?.id, addMessage, chat.id, event?.id]);

  useEffect(() => {
    // scroll down every time there is a new message

    // the latest message check is just so we can use it as a dependency
    if (!latestMessage) {
      return;
    }

    scrollContainerToBottom();
  }, [latestMessage, scrollContainerToBottom]);

  const renderedMessages = _.map(
    chat.messages,
    (newerMessage, index, collection) => {
      const received = user?.id !== newerMessage.author.id;

      const nextMessage = collection[index + 1];

      const showUnread = getShowUnread({
        olderMessage: nextMessage,
        newerMessage
      });
      const dateToShow = getDateToShow({
        olderMessage: nextMessage,
        newerMessage
      });

      return (
        <Fragment key={`private-message-${newerMessage.id}`}>
          <>
            <StyledChatBubble message={newerMessage} received={received} />
            <>{dateToShow && <DateDivider>{dateToShow}</DateDivider>}</>
            {showUnread && (
              <UnreadDivider ref={unreadMessagesDividerRef}>
                Unread
              </UnreadDivider>
            )}
          </>
        </Fragment>
      );
    }
  );

  const otherParticipant = openedPrivateChat.chat
    ? getOtherChatParticipant({
        chat: openedPrivateChat.chat
      })
    : null;

  return (
    <>
      <Container ref={containerRef} onClick={chat.markMessagesSeen}>
        <Header>
          <BackIcon
            onClick={() => openedPrivateChat.setChat(null)}
            icon="chevron-left"
          />
          {openedPrivateChat.isFetchingChat || !otherParticipant ? (
            <UserLine.Placeholder withOccupation size={EAvatarSizes.LARGE} />
          ) : (
            <>
              <StyledUserLine
                size={EAvatarSizes.LARGE}
                user={otherParticipant}
              />
              <VideoChatIconContainer
                onClick={() =>
                  setBookAMeetingModalParticipantId(otherParticipant.id)
                }>
                <VideoChatIcon icon="video" />
              </VideoChatIconContainer>
            </>
          )}
        </Header>
        <MessagesContainer>
          {/* everything in here is displayed in reverse */}
          {renderedMessages}

          {chat.isFetchingMessages &&
            _.range(7).map((index) => (
              // we do it with range instead of the count prop in order to be able
              // to pass different values for "received" while keeping the styles
              <StyledChatBubblePlaceholder
                received={index % 2 === 0}
                key={`chat-bubble-placeholder-${index}`}
              />
            ))}

          {!chat.isFetchingMessages && chat.hasMoreMessages && (
            <LoadMoreButton
              colorScheme={3}
              fontSize={14}
              height="short"
              fontWeight={400}
              onClick={chat.loadMoreMessages}>
              Load More
            </LoadMoreButton>
          )}
        </MessagesContainer>
      </Container>
      <StyledMessageInput
        chatId={chat.id}
        onClick={chat.markMessagesSeen}
        addMessageToChat={chat.addMessage}
        additionalOnChange={chat.markMessagesSeen}
      />
      <BookAMeetingModal
        participantId={bookAMeetingModalParticipantId}
        closeModal={() => setBookAMeetingModalParticipantId(null)}
      />
    </>
  );
};

export default PrivateChatDetail;
