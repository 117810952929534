import { FunctionComponent } from 'react';

import { EAvatarSizes } from 'libs/enums';

import { Avatar } from 'components';

import { Container, AvatarContainer } from './styles';

export interface IProps {
  avatars: string[];
  size: EAvatarSizes;
}

const AvatarGroup: FunctionComponent<IProps> = ({ avatars, size }) => (
  <Container>
    {avatars.map((avatar, index) => (
      <AvatarContainer
        style={{ zIndex: avatars.length - index }}
        key={`member-of-avatar-group-${index}-${avatar}`}>
        <Avatar src={avatar} size={size} />
      </AvatarContainer>
    ))}
  </Container>
);

export default AvatarGroup;
