import { ReactNode } from 'react';

import { SESSION_DETAIL_URL, SESSION_SPEAKER_DETAIL_URL } from 'libs/constants';
import { ESessionStatuses } from 'libs/enums';
import type { ICategoryDetailAndLiveListSession } from 'libs/types';

export const getVariableButtonProps = ({
  session,
  eventSlug
}: {
  session: ICategoryDetailAndLiveListSession;
  eventSlug: string;
}): {
  colorScheme: number;
  children: ReactNode;
  href: string;
} => {
  // these cases are handled by ScheduleSessionToggleButton:
  // - session is future and the user has not logged in
  // - session is future(as it's neither past or live) and the user is not a speaker and he has added the session to his schedule
  // - session is future and the user is not a speaker and he has NOT added the session to his schedule

  const { status, is_speaker: isSpeaker } = session;

  if (isSpeaker) {
    // user is speaker
    return {
      colorScheme: 6,
      children: 'Admin Panel',
      href: SESSION_SPEAKER_DETAIL_URL({
        sessionId: session.id,
        sessionSlug: session.slug,
        eventSlug
      })
    };
  }

  if (status === ESessionStatuses.LIVE) {
    // session is live and the user is not a speaker
    return {
      colorScheme: 4,
      children: 'Join Session',
      href: SESSION_DETAIL_URL({
        sessionId: session.id,
        sessionSlug: session.slug,
        eventSlug
      })
    };
  }

  // session is not live and the user is not a speaker
  return {
    colorScheme: 1,
    children: 'View Session',
    href: SESSION_DETAIL_URL({
      sessionId: session.id,
      sessionSlug: session.slug,
      eventSlug
    })
  };
};
