import _ from 'lodash';
import moment from 'moment-timezone';

import { DATETIME_FORMAT } from 'libs/constants';

export const applyFormattingToDateTime = ({
  date,
  fromFormat = DATETIME_FORMAT,
  toFormat,
  utc = false
}: {
  utc?: boolean;
  fromFormat?: string;
  date: string;
  toFormat: string;
}) => {
  let originalDate = moment(date, fromFormat);

  if (utc) {
    originalDate = originalDate.utc();
  }

  return originalDate.format(toFormat);
};

export const getUserTimezone = () => moment().tz(moment.tz.guess()).format('z');

export const isInTimeRange = ({
  start,
  end,
  fromFormat = DATETIME_FORMAT
}: {
  start: string;
  end: string;
  fromFormat?: string;
}) => {
  const now = moment();
  const momentStart = moment(start, fromFormat);
  const momentEnd = moment(end, fromFormat);

  return now >= momentStart && now <= momentEnd;
};

export const isSameDay = ({
  date,
  otherDate,
  format = DATETIME_FORMAT
}: {
  date: string;
  otherDate: string;
  format?: string;
}) => {
  const dateMoment = moment(date, format);
  const otherDateMoment = moment(otherDate, format);

  return dateMoment.isSame(otherDateMoment, 'day');
};

export const getTimeDifference = ({
  time,
  format = DATETIME_FORMAT
}: {
  time: string;
  format?: string;
}) => {
  const momentNow = moment();
  const momentTime = moment(time, format);

  // calculate total difference
  const difference = moment.duration(momentTime.diff(momentNow));

  // difference in days
  const days = _.toInteger(difference.asDays());

  // difference in hours
  const hours = _.toInteger(difference.asHours()) % 24;

  // difference in minutes
  const minutes = _.toInteger(difference.asMinutes()) % 60;

  // difference in seconds
  const seconds = _.toInteger(difference.asSeconds()) % 60;

  return { days, hours, minutes, seconds };
};
