import { useField } from 'formik';
import { useRef, useState } from 'react';

import { FieldError } from 'components';

import {
  StyledIcon,
  StyledInput,
  StyledLabel,
  InputContainer,
  StyledButton
} from './styles';

export interface IProps {
  label?: string;
  name: string;
  disabled?: boolean;
  className?: string;
}

const CopyInput = ({ label, ...props }: IProps) => {
  const [field] = useField(props);
  const [copied, setCopied] = useState(false);

  const fieldRef = useRef<HTMLInputElement | null>(null);

  const copyToClipboard = () => {
    const fieldToCopyFrom = fieldRef.current;

    if (fieldToCopyFrom) {
      fieldToCopyFrom.disabled = false;
      fieldToCopyFrom.select();
      document.execCommand('copy');
      fieldToCopyFrom.disabled = true;

      // deselect the input
      fieldToCopyFrom.blur();
      const sel = document.getSelection();
      sel?.removeAllRanges();

      setCopied(true);
    }
  };

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <InputContainer>
        <StyledIcon icon="link" />
        <StyledInput type="input" {...field} {...props} ref={fieldRef} />
        <StyledButton
          colorScheme={2}
          height="short"
          fontSize={15}
          fontWeight={500}
          icon={copied ? 'check' : undefined}
          onClick={copyToClipboard}>
          {copied ? 'Copied' : 'Copy'}
        </StyledButton>
      </InputContainer>
      <FieldError name={props.name} />
    </div>
  );
};

export default CopyInput;
