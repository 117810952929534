import { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { UNISSU_USER_DETAIL_URL } from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import {
  userRetrieve,
  createUserViewedProfileNotification
} from 'libs/requests';
import { getUserName, getUserOccupation } from 'libs/users';

import { useWithUser, useToggleUserDetail } from 'hooks';

import { Avatar, BookAMeetingModal } from 'components';

import {
  Wrapper,
  Container,
  BackIcon,
  Content,
  Name,
  Occupation,
  StyledButton,
  Action,
  Actions,
  ActionIcon,
  ActionIconContainer,
  ActionName,
  ActionDescription,
  StyledLink
} from './styles';

interface IProps {
  startChat: ({ userId }: { userId: number }) => void;
}

const Placeholder = ({ close }: { close: () => void }) => (
  <Wrapper>
    <Container>
      <BackIcon icon="chevron-left" onClick={close}>
        close
      </BackIcon>
      <Content>
        <Avatar.Placeholder size={EAvatarSizes.XXXLARGE} />
        <Name>
          <Skeleton width={150} />
        </Name>
        <Occupation>
          <Skeleton width={250} />
        </Occupation>
        <StyledButton
          outline
          disabled
          fontSize={15}
          fontWeight={500}
          height="medium"
          colorScheme={1}>
          View Unissu Profile
        </StyledButton>
        <Actions>
          <Action>
            <ActionIconContainer>
              <ActionIcon icon="video" />
            </ActionIconContainer>

            <div>
              <ActionName>Book Meeting</ActionName>
              <ActionDescription>Arrange 1-1 call</ActionDescription>
            </div>
          </Action>

          <Action>
            <ActionIconContainer>
              <ActionIcon icon="edit" />
            </ActionIconContainer>

            <div>
              <ActionName>Message</ActionName>
              <ActionDescription>Send a private message</ActionDescription>
            </div>
          </Action>
        </Actions>
      </Content>
    </Container>
  </Wrapper>
);

const UserDetail = ({ startChat }: IProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [
    bookAMeetingModalParticipantId,
    setBookAMeetingModalParticipantId
  ] = useState<null | number>(null);

  const { user: loggedUser } = useWithUser();
  const {
    user,
    close,
    userId,
    setUserDetailUser: setUser
  } = useToggleUserDetail();

  const fetchUser = useCallback(async () => {
    if (!userId || user) {
      // if we have nothing to fetch or have already fetched - return and make sure the user detail has been closed
      setBookAMeetingModalParticipantId(null);
      return;
    }

    setIsFetching(true);

    const { data, success } = await userRetrieve({ userId });

    if (success && data) {
      setUser(data);

      // If the logged user opens another user's profile we create a notification for the other user.
      // We don't need to worry about the loggedUser being fetched as the Chat is only shown when the
      // logged user is fetched and this is a sub-component of the Chat.
      if (userId && loggedUser && loggedUser.id !== userId) {
        createUserViewedProfileNotification({ userId });
      }
    }

    setIsFetching(false);
  }, [user, userId, loggedUser, setUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (!userId) {
    return null;
  }

  return !isFetching && user ? (
    <>
      <Wrapper>
        <Container>
          <BackIcon icon="chevron-left" onClick={close}>
            close
          </BackIcon>
          <Content>
            <Avatar src={user.avatar} size={EAvatarSizes.XXXLARGE} />
            <Name>{getUserName({ user })}</Name>
            <Occupation>{getUserOccupation({ user })}</Occupation>
            <StyledLink
              external
              href={UNISSU_USER_DETAIL_URL({
                userId: user.unissu_id
              })}>
              <StyledButton
                outline
                fontSize={15}
                fontWeight={500}
                height="medium"
                colorScheme={1}>
                View Unissu Profile
              </StyledButton>
            </StyledLink>
            {loggedUser?.id !== user.id && (
              <Actions>
                <Action
                  onClick={() => setBookAMeetingModalParticipantId(user.id)}>
                  <ActionIconContainer>
                    <ActionIcon icon="video" />
                  </ActionIconContainer>

                  <div>
                    <ActionName>Book Meeting</ActionName>
                    <ActionDescription>Arrange 1-1 call</ActionDescription>
                  </div>
                </Action>

                <Action
                  onClick={() => {
                    close();
                    startChat({ userId: user.id });
                  }}>
                  <ActionIconContainer>
                    <ActionIcon icon="edit" />
                  </ActionIconContainer>

                  <div>
                    <ActionName>Message</ActionName>
                    <ActionDescription>
                      Send a private message
                    </ActionDescription>
                  </div>
                </Action>
              </Actions>
            )}
          </Content>
        </Container>
      </Wrapper>
      <BookAMeetingModal
        participantId={bookAMeetingModalParticipantId}
        closeModal={() => setBookAMeetingModalParticipantId(null)}
      />
    </>
  ) : (
    <Placeholder close={close} />
  );
};

export default UserDetail;
