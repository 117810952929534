import { useState } from 'react';
import { toast } from 'react-toastify';

import { MEETING_URL } from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import { EAvatarSizes } from 'libs/enums';
import { getOtherMeetingParticipant } from 'libs/meeting';
import { meetingCancel } from 'libs/requests';
import { IScheduleRetrieveMeeting } from 'libs/types';

import { useWithUser, useWithEvent } from 'hooks';

import { UserLine, Link } from 'components';

import {
  Left,
  Start,
  Delete,
  Container,
  TrashIcon,
  StyledButton
} from './styles';

interface IProps {
  meeting: IScheduleRetrieveMeeting;
}

const MeetingBox = ({ meeting }: IProps) => {
  const [cancelled, setCancelled] = useState(false);

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const { user, isFetchingInitials } = useWithUser();

  const cancelMeeting = async () => {
    if (cancelled) {
      return;
    }

    const { id: meetingId } = meeting;

    const { success } = await meetingCancel({ meetingId });

    if (success) {
      toast('Meeting has been cancelled successfully!', { type: 'success' });
      setCancelled(true);
    } else {
      toast('Something went wrong. Please try again later.', { type: 'error' });
    }
  };

  const otherParticipant =
    user && getOtherMeetingParticipant({ meeting, user });

  return (
    <Container cancelled={cancelled}>
      <Left>
        <Start>
          {applyFormattingToDateTime({
            date: meeting.start,
            toFormat: 'HH:mm, Do MMM YYYY'
          })}
        </Start>
        {isFetchingInitials || !otherParticipant ? (
          <UserLine.Placeholder size={EAvatarSizes.LARGE} />
        ) : (
          <UserLine size={EAvatarSizes.LARGE} user={otherParticipant} />
        )}
      </Left>

      {eventSlug && (
        <Link
          href={MEETING_URL({
            eventSlug,
            meetingUUID: meeting.room_name
          })}>
          <StyledButton
            fontSize={15}
            fontWeight={600}
            height="medium"
            colorScheme={4}
            disabled={cancelled}>
            Join Call
          </StyledButton>
        </Link>
      )}

      <Delete onClick={cancelMeeting} disabled={cancelled}>
        <TrashIcon icon="trash-alt" weight="far" />
        Delete
      </Delete>
    </Container>
  );
};

export default MeetingBox;
