import styled from 'styled-components';

import { red, breakpoints, colors, yellow, lightBlueGrey } from 'styles/global';

import { Icon, Button } from 'components';

import { TabView } from './components';

export const Container = styled.div`
  margin-top: 15px;
  margin-bottom: 80px;

  @media ${breakpoints.tablet} {
    padding: 0 20px;
  }
`;

export const StyledTabView = styled(TabView)`
  margin-top: 34px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-top: 5px;

  @media ${breakpoints.mobile} {
    align-items: stretch;
    flex-direction: column;
  }
`;

export const Left = styled.div`
  display: grid;
  grid-gap: 0 8px;
  grid-template-columns: 48px auto;
`;

export const AvatarContainer = styled.div`
  grid-row: span 2;
`;

export const HeaderTitle = styled.p`
  font-size: 24px;
  color: ${colors.text[1]};
`;

export const Slash = styled.span`
  color: ${yellow};
`;

export const Bold = styled.b`
  font-weight: 600;
`;

export const HeaderDescription = styled.p`
  font-size: 14px;
  line-height: 32px;
  color: ${colors.text[2]};
`;

export const HoverablePopup = styled.div`
  display: none;

  right: 0px;
  bottom: 65px;
  position: absolute;

  box-sizing: border-box;
  padding: 16px 13px 16px 17px;

  color: ${colors.text[1]};
  background-color: ${lightBlueGrey};
  font-weight: unset; // we don't want to inherit the Button font weight

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);

  animation: appear 0.2s linear;

  &:after {
    content: '';
    width: 9px;
    height: 9px;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: rgba(123, 124, 129, 0.2);

    right: 15px;
    bottom: -6px;
    position: absolute;

    transform: rotate(45deg);
    background-color: inherit;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Right = styled.div`
  padding-left: 30px;

  @media ${breakpoints.mobile} {
    width: 100%;
    margin-top: 20px;
    margin-left: unset;
    padding-left: unset;
  }
`;

export const VisibleOnTabletAndBelow = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const LiveViewButton = styled(Button)`
  width: 200px;
  margin-top: 5px;
  margin-bottom: -30px;

  @media ${breakpoints.mobile} {
    width: 100%;
    margin-bottom: unset;
  }
`;

export const GoLiveButtonContainer = styled.div`
  position: relative;

  &:hover > ${HoverablePopup} {
    display: block;
  }
`;

export const PrerecordedVideoGoLiveDisclaimer = styled.p`
  color: ${red};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: right;

  margin-top: 35px;
  margin-left: 30px;

  @media ${breakpoints.mobile} {
    text-align: left;
    margin-top: 20px;
    margin-left: unset;
  }
`;

export const GoLiveButton = styled(Button)`
  width: 200px;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const HoverablePopupTitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;

  margin-bottom: 17px;
`;

export const HoverablePopupItemIcon = styled(Icon)`
  color: inherit;
  font-size: 12px;

  margin-top: 3px;
  margin-right: 8px;
`;

export const HoverablePopupItem = styled.p`
  font-size: 14px;
  line-height: 17px;
  text-align: left;

  display: flex;

  color: ${({ active }: { active: boolean }) =>
    active ? colors.text[4] : colors.text[1]};

  &:not(:first-of-type) {
    padding-top: 6px;
    margin-top: 10px;
    border-top: 0.75px solid rgba(123, 124, 129, 0.2);
  }

  & > ${HoverablePopupItemIcon} {
    opacity: ${({ active }: { active: boolean }) => (active ? 1 : 0.2)};
  }
`;
