import styled from 'styled-components';

import { colors } from 'styles/global';

import { Link, File } from 'components';

export const SponsorBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SponsorLogoContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const SponsorLink = styled(Link)`
  img {
    max-height: 50px;
    max-width: 150px;
  }
`;

export const SponsorBrochureLink = styled(File)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text[3]};

  display: block;
  margin-top: 20px;
`;
