import _ from 'lodash';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';

import { SESSIONS_URL } from 'libs/constants';

import { useWithEvent } from 'hooks';

import { Image } from 'components';

import image from './assets/image.png';
import {
  Wrapper,
  BackLink,
  StyledIcon,
  Container,
  Title,
  Description,
  StyledButton,
  ButtonContainer
} from './styles';

interface IProps {
  title: string | ReactElement;
  description: string;
}

const RoundTableErrorTemplate = ({ title, description }: IProps) => {
  const router = useRouter();
  const { event } = useWithEvent();

  const backUrl = event?.slug
    ? _.get(router, 'query.next', SESSIONS_URL({ eventSlug: event.slug }))
    : router.asPath; // basically the same url

  return (
    <Wrapper>
      <BackLink href={backUrl}>
        <StyledIcon icon="chevron-left" />
        Back
      </BackLink>

      <Container>
        <Title>{title}</Title>
        <Image src={image} height={198.09} width={383.87} />
        <Description>{description}</Description>
        {event && (
          <ButtonContainer href={SESSIONS_URL({ eventSlug: event.slug })}>
            <StyledButton
              height="tall"
              colorScheme={1}
              fontSize={15}
              fontWeight={500}>
              Browse Sessions
            </StyledButton>
          </ButtonContainer>
        )}
      </Container>
    </Wrapper>
  );
};

export default RoundTableErrorTemplate;
