import _ from 'lodash';
import styled from 'styled-components';

import { lightGrey } from 'styles/global';

export const Container = styled.div`
  display: flex;
  align-items: center;

  max-width: 100%;
  cursor: pointer;
`;

export const AvatarContainer = styled.div`
  flex-shrink: 0;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
  // we remove the avatar width and the left margin from the 100%
  max-width: calc(
    100% - 10px - ${({ avatarWidth }: { avatarWidth: number }) => avatarWidth}px
  );
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Occupation = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${lightGrey};
  font-size: ${({ size }: { size: string }) =>
    _.includes(['large', 'xlarge'], size) ? '14px' : '12px'};
  line-height: ${({ size }: { size: string }) =>
    _.includes(['large', 'xlarge'], size) ? '17px' : '14px'};
`;
