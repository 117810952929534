import styled from 'styled-components';

import { breakpoints } from 'styles/global';

export const Container = styled.div`
  display: grid;
  grid-gap: 10px;

  box-sizing: border-box;
  padding: 48px 55px 55px 53px;

  @media ${breakpoints.tablet} {
    padding-left: 15px;
  }

  @media ${breakpoints.mobile} {
    padding: 30px 15px 80px 15px;
  }
`;

export const PaddedLeft = styled.div`
  padding-left: 70px;

  @media ${breakpoints.mobile} {
    padding-left: unset;
  }
`;
