import styled from 'styled-components';

import { colors, lightBlueGrey, breakpoints } from 'styles/global';

import { Icon, Button, Link } from 'components';

export const Wrapper = styled.div`
  background-color: ${lightBlueGrey};

  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Container = styled.div`
  box-sizing: border-box;
  padding: 21px 42px 20px 21px;

  display: grid;
  grid-gap: 17px;
  grid-template-columns: 7px auto;
`;

export const BackIcon = styled(Icon)`
  font-size: 10px;
  color: ${colors.text[1]};

  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Name = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};

  margin-top: 11px;
  margin-bottom: 2px;
`;

export const Occupation = styled.p`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colors.text[2]};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledButton = styled(Button)`
  width: 186px;
  margin-top: 18px;
  margin-bottom: 33px;
`;

export const Actions = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media ${breakpoints.tablet} {
    width: auto;

    display: grid;
    grid-gap: 15px;
  }
`;

export const Action = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ActionIconContainer = styled.div`
  height: 32px;
  min-width: 32px;
  margin-right: 7px;

  cursor: pointer;
  border-radius: 50%;
  background-color: #ebecee;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionIcon = styled(Icon)`
  font-size: 11px;
  color: ${colors.text[3]};
`;

export const ActionName = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.text[3]};
`;

export const ActionDescription = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: ${colors.text[2]};

  margin-top: 1px;
`;
