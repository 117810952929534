import { HOME_URL } from 'libs/constants';

import { Navigation } from 'components';

import image from './assets/image.png';
import { STATUS_CODE_TO_MESSAGE_MAPPING } from './constants';
import {
  Wrapper,
  Container,
  StyledLink,
  Title,
  Description,
  StyledOneDimensionImage
} from './styles';

export interface IProps {
  statusCode: 403 | 404 | 500;
}

const ErrorTemplate = ({ statusCode }: IProps) => (
  <Wrapper>
    <Navigation withNotifications={false} />
    <Container>
      <StyledOneDimensionImage src={image} />
      <Title>{statusCode}</Title>
      <Description>{STATUS_CODE_TO_MESSAGE_MAPPING[statusCode]}</Description>
      <StyledLink href={HOME_URL}>Return to landing page</StyledLink>
    </Container>
  </Wrapper>
);

export default ErrorTemplate;
