import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';

import {
  SESSIONS_URL,
  EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER,
  ORGANISER_DETAIL_URL
} from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import { EEventStatusValues } from 'libs/enums';
import { getEventButtonProps } from 'libs/events';
import { formatPrice } from 'libs/prices';
import {
  IEventListEvent,
  IOrganiserEventListEvent,
  IGlobalSearchEventListEvent
} from 'libs/types';

import { useWithUser } from 'hooks';

import {
  Top,
  TopPlaceholder,
  Container,
  StyledImage,
  Content,
  Label,
  Title,
  TitlePlaceholder,
  Description,
  Price,
  StyledButton,
  LiveLabel,
  DotIcon,
  ButtonLink,
  OrganiserName
} from './styles';

interface IProps {
  reducedSize: boolean;
  event:
    | IEventListEvent
    | IOrganiserEventListEvent
    | IGlobalSearchEventListEvent;
}

const START_DATE_FORMAT = 'Do MMMM, YYYY';

const EventBox = ({
  event,
  event: {
    slug,
    name,
    start,
    status,
    description,
    card_image: cardImage,
    lowest_ticket_price: lowestTicketPrice,
    location,
    organiser: { name: organiserName, slug: organiserSlug }
  },
  reducedSize = false
}: IProps) => {
  const { user } = useWithUser();

  const isLive = status.value === EEventStatusValues.LIVE;

  const buttonProps = getEventButtonProps({ user, event });
  const eventHref = SESSIONS_URL({ eventSlug: slug });

  return (
    <Container>
      <Top href={eventHref}>
        {isLive && (
          <LiveLabel>
            <DotIcon icon="dot-circle" />
            LIVE NOW
          </LiveLabel>
        )}

        <StyledImage
          layout="fill"
          src={cardImage || EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER}
        />
      </Top>
      <Content>
        <Label>
          {applyFormattingToDateTime({
            date: start,
            toFormat: START_DATE_FORMAT
          })}
          , {location} ·{' '}
          <OrganiserName href={ORGANISER_DETAIL_URL({ organiserSlug })}>
            {organiserName}
          </OrganiserName>
        </Label>
        <Title reducedSize={reducedSize} href={eventHref}>
          {name}
        </Title>
        <Description reducedSize={reducedSize}>{description}</Description>
        <Price>
          {parseFloat(lowestTicketPrice) > 0
            ? `£${formatPrice(lowestTicketPrice)}`
            : 'Free'}
        </Price>
        <ButtonLink href={eventHref}>
          <StyledButton
            height="medium"
            fontSize={15}
            fontWeight={500}
            {...buttonProps}
          />
        </ButtonLink>
      </Content>
    </Container>
  );
};

const Placeholder = ({
  count = 1,
  reducedSize
}: {
  count: number;
  reducedSize: boolean;
}) => (
  <>
    {_.range(count).map((index) => (
      <Container key={`event-box-placeholder-${index}`}>
        <TopPlaceholder>
          <Skeleton width="100%" height="100%" />
        </TopPlaceholder>
        <Content>
          <Label>
            <Skeleton width={100} /> · <Skeleton width={50} />
          </Label>
          <TitlePlaceholder reducedSize={reducedSize}>
            <Skeleton />
          </TitlePlaceholder>
          <Description reducedSize={reducedSize}>
            <Skeleton count={3} />
          </Description>
          <Price>
            <Skeleton width={50} />
          </Price>
          <Skeleton height={44} width="100%" />
        </Content>
      </Container>
    ))}
  </>
);

EventBox.Placeholder = Placeholder;

export default EventBox;
