import _ from 'lodash';
import { useState, useEffect } from 'react';

import { getTimeDifference } from 'libs/datetime';

import {
  Container,
  StyledIcon,
  Text,
  Time,
  Item,
  Bold,
  Regular
} from './styles';

interface IProps {
  time: string;
}

const Countdown = ({ time }: IProps) => {
  const [timeLeft, setTimeLeft] = useState(getTimeDifference({ time }));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeDifference({ time }));
    }, 100);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  const formatTimeFragment = (timeFragment: number) => {
    // so that we never have negative values
    timeFragment = Math.max(timeFragment, 0);

    // so that 9 turns into 09
    return _.padStart(_.toString(timeFragment), 2, '0');
  };

  // if this sum is above 0 the start has not yet been reached.
  const sumOfNonNegativeValues = _.sumBy(
    [days, hours, minutes, seconds],
    (item) => Math.max(item, 0)
  );
  const startTimeHasBeenReached = sumOfNonNegativeValues === 0;

  return (
    <Container>
      <StyledIcon icon="clock" weight="far" />
      <Text>
        {startTimeHasBeenReached
          ? 'Session Starting Soon'
          : 'This session will begin in:'}
      </Text>
      {!startTimeHasBeenReached && (
        <Time>
          <Item>
            <Bold>{formatTimeFragment(days)}</Bold>
            <Regular>Days</Regular>
          </Item>

          <Item>
            <Bold>:</Bold>
          </Item>

          <Item>
            <Bold>{formatTimeFragment(hours)}</Bold>
            <Regular>Hours</Regular>
          </Item>

          <Item>
            <Bold>:</Bold>
          </Item>

          <Item>
            <Bold>{formatTimeFragment(minutes)}</Bold>
            <Regular>Minutes</Regular>
          </Item>

          <Item>
            <Bold>:</Bold>
          </Item>

          <Item>
            <Bold>{formatTimeFragment(seconds)}</Bold>
            <Regular>Seconds</Regular>
          </Item>
        </Time>
      )}
    </Container>
  );
};

export default Countdown;
