import { useRouter } from 'next/router';
import { useRef, useEffect, useState } from 'react';

import {
  SCHEDULE_URL,
  SESSION_PURCHASE_URL,
  UNISSU_EDIT_PROFILE_URL
} from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import PusherManager from 'libs/pusher';
import { logout } from 'libs/requests';

import { useWithUser, useWithEvent } from 'hooks';

import { Avatar } from 'components';

import {
  Toggler,
  Wrapper,
  Content,
  TogglerIcon,
  DropdownItem,
  DropdownItemIcon,
  NonLinkDropdownItem
} from './styles';

interface IProps {
  withEventSpecificLinks?: boolean;
}

const AvatarDropdown = ({ withEventSpecificLinks = true }: IProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const router = useRouter();
  const { user } = useWithUser();

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const logoutAndRefreshPage = async () => {
    await logout();
    PusherManager.disconnect();
    router.reload();
  };

  const handleClickOutsideDropdown = (event: Event) => {
    if (dropdownRef.current && event.target instanceof Node) {
      const clickIsInsideDropdown = dropdownRef.current.contains(event.target);

      if (!clickIsInsideDropdown) {
        setIsDropdownOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);

    return () =>
      document.removeEventListener('click', handleClickOutsideDropdown);
  }, []);

  return (
    <Wrapper ref={dropdownRef}>
      <Toggler onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <Avatar src={user?.avatar} size={EAvatarSizes.MEDIUM} />
        <TogglerIcon icon="chevron-down" reversed={isDropdownOpen} />
      </Toggler>
      <Content visible={isDropdownOpen}>
        {withEventSpecificLinks && eventSlug && (
          <>
            <DropdownItem href={SCHEDULE_URL({ eventSlug })}>
              <DropdownItemIcon icon="calendar-alt" /> My Schedule
            </DropdownItem>
            {event?.is_shop_enabled && (
              <DropdownItem href={SESSION_PURCHASE_URL({ eventSlug })}>
                <DropdownItemIcon icon="shopping-bag" /> Purchase Session
              </DropdownItem>
            )}
          </>
        )}
        <DropdownItem external href={UNISSU_EDIT_PROFILE_URL}>
          <DropdownItemIcon icon="user-edit" /> Edit Profile
        </DropdownItem>
        <NonLinkDropdownItem onClick={logoutAndRefreshPage}>
          <DropdownItemIcon icon="sign-out-alt" /> Log out
        </NonLinkDropdownItem>
      </Content>
    </Wrapper>
  );
};

export default AvatarDropdown;
