import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h6`
  margin-top: 32px;
  margin-bottom: 9px;

  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};
`;
