import styled from 'styled-components';

import { Button } from 'components';
import { EmptyPrivateChats } from 'components/Chat/components';

export const StyledEmptyPrivateChats = styled(EmptyPrivateChats)`
  margin-top: 48px;
`;

export const LoadMoreButton = styled(Button)`
  width: 128px;
  margin: 30px auto 34px auto;
`;
