import highFiveImage from './assets/high-five.png';
import posImage from './assets/pos-image.png';

export const getVariables = ({
  success,
  isFree
}: {
  success: boolean;
  isFree: boolean;
}) => {
  if (success && isFree) {
    return {
      title: 'Ticket Confirmation',
      isTitleRed: false,
      description: 'Enjoy the event!',
      image: {
        src: highFiveImage,
        height: 167,
        width: 231.27
      },
      button: { colorScheme: 1, children: 'Join Event' }
    };
  }

  if (success && !isFree) {
    return {
      title: 'Your Payment was Successful',
      isTitleRed: false,
      description:
        "Thank you for your payment. We'll send your invoice by email.",
      image: {
        src: posImage,
        height: 116,
        width: 158.14
      },
      button: { colorScheme: 1, children: 'Join Event' }
    };
  }

  return {
    title: 'Your Payment was Unsuccessful',
    isTitleRed: true,
    description: 'Something went wrong. Please try again',
    image: {
      src: posImage,
      height: 116,
      width: 158.14
    },
    button: { colorScheme: 4, children: 'Try Again' }
  };
};
