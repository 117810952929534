import { useState } from 'react';

import { getUserTimezone, applyFormattingToDateTime } from 'libs/datetime';
import { ISessionPurchaseFormSlot } from 'libs/types';

import { DataPackage } from 'components/SessionPurchaseForm/components';

import {
  Left,
  Right,
  Wrapper,
  Container,
  SpaceBetween,
  Date,
  TrashIcon,
  TitleRow,
  Title,
  SlotPrice,
  Duration,
  DataPackageToggler,
  HideDataPackage,
  DataPackageIndicator
} from './styles';

interface IProps {
  slot: ISessionPurchaseFormSlot;
  removeSlot: () => void;
  toggleDataPackage: () => void;
}

const BasketItem = ({ slot, removeSlot, toggleDataPackage }: IProps) => {
  const [showDataPackage, setShowDataPackage] = useState(false);

  return (
    <Wrapper>
      <Container>
        <SpaceBetween>
          <Date>
            {applyFormattingToDateTime({
              date: slot.start,
              toFormat: 'Do MMMM YYYY'
            })}
          </Date>

          <TrashIcon icon="trash-alt" weight="far" onClick={removeSlot} />
        </SpaceBetween>

        <TitleRow>
          <Left>
            <Title>{slot.category.title}</Title>
            <Duration>
              {`${applyFormattingToDateTime({
                date: slot.start,
                toFormat: 'HH:mm'
              })} - ${applyFormattingToDateTime({
                date: slot.end,
                toFormat: 'HH:mm'
              })} ${getUserTimezone()}`}
            </Duration>
          </Left>
          <Right>
            <SlotPrice>£{slot.price}</SlotPrice>
            <DataPackageIndicator isVisible={slot.with_data_package}>
              + Data Package
            </DataPackageIndicator>
          </Right>
        </TitleRow>
        <DataPackage
          isVisible={showDataPackage}
          selected={slot.with_data_package}
          toggleDataPackage={toggleDataPackage}
        />
      </Container>
      <DataPackageToggler
        isOpen={showDataPackage}
        onClick={() => setShowDataPackage(!showDataPackage)}>
        + Add Data Package
        {showDataPackage && <HideDataPackage />}
      </DataPackageToggler>
    </Wrapper>
  );
};
export default BasketItem;
