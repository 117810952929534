import _ from 'lodash';

interface IUserWithFirstAndLastName {
  first_name?: string;
  surname?: string;
  [key: string]: any;
}

interface IUserWithCompanyAndPosition {
  company?: string;
  position?: string;
  [key: string]: any;
}

export const getUserName = ({
  user
}: {
  user: IUserWithFirstAndLastName | undefined | null;
}) => (user ? `${user.first_name} ${user.last_name}` : '');

export const getUserOccupation = ({
  user
}: {
  user: IUserWithCompanyAndPosition | undefined;
}) => {
  if (!user) {
    return '';
  }

  const { position, company } = user;

  return _.join(_.reject([position, company], _.isEmpty), ', ');
};
