import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  SESSIONS_URL,
  EVENT_TICKET_PURCHASE_PAYMENT_DETAILS_URL,
  EVENT_TICKET_PURCHASE_FREE_TICKET_SUCCESS_URL
} from 'libs/constants';
import { claimFreeEventTicket } from 'libs/requests';
import { IEventTicketTypeListTicketType } from 'libs/types';

import { useWithEvent } from 'hooks';

import {
  Wrapper,
  BackLink,
  Container,
  StyledIcon,
  StyledTicketTypeLine,
  StyledEventLine,
  StyledButton,
  Title
} from './styles';

interface IProps {
  title: string;
  ticketTypes: Array<IEventTicketTypeListTicketType>;
}

const TicketPicker = ({ title, ticketTypes }: IProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState(ticketTypes[0]);

  const router = useRouter();
  const { event } = useWithEvent();

  const nextUrl = event
    ? _.get(router, 'query.next', SESSIONS_URL({ eventSlug: event.slug }))
    : '';
  const backUrl = nextUrl;

  const handleGetTicketButtonClick = async () => {
    if (!event) {
      return;
    }

    setSubmitting(true);

    if (selectedTicketType.is_free) {
      const { success, error } = await claimFreeEventTicket({
        eventTicketTypeId: selectedTicketType.id
      });

      if (success) {
        router.push(
          EVENT_TICKET_PURCHASE_FREE_TICKET_SUCCESS_URL({
            eventSlug: event.slug,
            eventTicketTypeId: selectedTicketType.id,
            next: nextUrl
          })
        );
        return;
      } else {
        const originalErrorMessage = _.get(error, 'data.0');

        toast(
          originalErrorMessage ||
            'Something went wrong. Please try again later.',
          { type: 'error' }
        );

        Sentry.captureMessage(
          `Free ticket claim failed. Ticket id: ${selectedTicketType.id}. Original error message: ${originalErrorMessage}`
        );
      }
    } else {
      router.push(
        EVENT_TICKET_PURCHASE_PAYMENT_DETAILS_URL({
          eventSlug: event.slug,
          eventTicketTypeId: selectedTicketType.id,
          next: nextUrl
        })
      );
      return;
    }

    setSubmitting(false);
  };

  return (
    <Wrapper>
      <BackLink href={backUrl}>
        <StyledIcon icon="chevron-left" />
        Back
      </BackLink>
      <Container>
        <Title>{title}</Title>
        <StyledEventLine />
        {ticketTypes.map((ticketType) => (
          <StyledTicketTypeLine
            key={ticketType.id}
            ticketType={ticketType}
            selected={selectedTicketType === ticketType}
            onClick={() => setSelectedTicketType(ticketType)}
          />
        ))}
        <StyledButton
          height="tall"
          colorScheme={6}
          fontSize={15}
          fontWeight={500}
          disabled={submitting}
          onClick={handleGetTicketButtonClick}>
          Get Ticket
        </StyledButton>
      </Container>
    </Wrapper>
  );
};

export default TicketPicker;
