import { ReactNode, FunctionComponent, useEffect } from 'react';

import {
  Chat,
  Footer,
  Navigation,
  ShareIcons,
  AdBanner,
  QuickGuideModal
} from 'components';
import { IProps as IChatProps } from 'components/Chat';

import {
  Page as StyledPage,
  PageWithChat as StyledPageWithChat,
  Sidebar,
  SidebarContainer,
  Content,
  Container,
  ShareIconsContainer
} from './styles';

interface IPageProps {
  className?: string;
  withQuickGuideModal?: boolean;
  background?: 'white' | 'lightBlueGrey';
  children: ReactNode;
}

interface IPageWithChatProps extends IPageProps {
  additionalChatProps?: IChatProps;
  chatSidebarAddition?: ReactNode;
}

const Page: FunctionComponent<IPageProps> & {
  WithChat: FunctionComponent<IPageWithChatProps>;
} = ({
  children,
  className,
  withQuickGuideModal = true,
  background = 'white'
}) => {
  useEffect(() => {
    // scroll to top on page change
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <StyledPage className={className} background={background}>
        <Navigation withNotifications={false} />
        {children}
        {withQuickGuideModal && <QuickGuideModal />}
      </StyledPage>
      <Footer />
    </>
  );
};

const PageWithChat = ({
  children,
  background,
  className,
  additionalChatProps,
  chatSidebarAddition,
  withQuickGuideModal = true
}: IPageWithChatProps) => {
  useEffect(() => {
    // scroll to top on page change
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <StyledPageWithChat className={className}>
        <Navigation withNotifications={true} />

        <Container>
          <Content background={background}>{children}</Content>
          {/* we need this for the sticky-ness */}
          <SidebarContainer>
            <Sidebar>
              <Chat {...additionalChatProps} />
              <AdBanner />
              <ShareIconsContainer>
                <ShareIcons />
              </ShareIconsContainer>
              {chatSidebarAddition}
            </Sidebar>
          </SidebarContainer>
        </Container>
        {withQuickGuideModal && <QuickGuideModal />}
      </StyledPageWithChat>
      <Footer />
    </>
  );
};

Page.WithChat = PageWithChat;

export default Page;
