import { EVENT_LOGO_PLACEHOLDER, ORGANISER_DETAIL_URL } from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';

import { useWithEvent } from 'hooks';

import { Logo, Container, EventMisc, OrganiserName, Title } from './styles';

const START_DATE_FORMAT = 'Do MMMM, YYYY';

interface IProps {
  className?: string;
}

const EventLine = ({ className }: IProps) => {
  const { event } = useWithEvent();

  return event ? (
    <Container className={className}>
      <div>
        <Logo
          width={48}
          height={48}
          src={event.light_background_logo || EVENT_LOGO_PLACEHOLDER}
        />
      </div>

      <div>
        <EventMisc>
          {applyFormattingToDateTime({
            date: event.start,
            toFormat: START_DATE_FORMAT
          })}
          , {event.location} ·{' '}
          <OrganiserName
            href={ORGANISER_DETAIL_URL({
              organiserSlug: event.organiser.slug
            })}>
            {event.organiser.name}
          </OrganiserName>
        </EventMisc>
        <Title>{event.name}</Title>
      </div>
    </Container>
  ) : null;
};

export default EventLine;
