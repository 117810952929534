import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { ReactNode, FunctionComponent } from 'react';

import { Button as StyledButton, StyledIcon, ButtonText } from './styles';

export interface IButtonTextProps {
  colorScheme: number;
  fontSize: string | number;
  type?: string;
  children?: ReactNode;
  title?: string;
  fontWeight?: string | number;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export interface IButtonProps extends IButtonTextProps {
  icon?: IconName;
  height: 'short' | 'medium' | 'tall' | 'custom';
  outline?: boolean;
}

const Button: FunctionComponent<IButtonProps> & {
  Text: FunctionComponent<IButtonTextProps>;
} = ({ children, icon, ...props }) => (
  <StyledButton {...props}>
    {icon && <StyledIcon icon={icon} />}
    {children}
  </StyledButton>
);

Button.Text = ButtonText;

Button.defaultProps = {
  fontWeight: 600,
  type: 'button'
};

export default Button;
