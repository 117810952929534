import _ from 'lodash';
import { useEffect, useState, useCallback } from 'react';

import {
  privateChatPreviewList,
  nextPrivateChatPreviewList
} from 'libs/requests';
import { IPrivateChatPreviewListChat } from 'libs/types';

import { PrivateChatPreview } from 'components/Chat/components';

import { StyledEmptyPrivateChats, LoadMoreButton } from './styles';

interface IProps {
  unreadMessagesCount: number;
  setOpenedPrivateChat: (chat: IPrivateChatPreviewListChat) => void;
}

const PrivateChatPreviewList = ({
  unreadMessagesCount,
  setOpenedPrivateChat
}: IProps) => {
  const [isFetching, setIsFetching] = useState(true);
  const [nextUrl, setNextUrl] = useState<null | string>(null);
  const [privateChats, setPrivateChats] = useState<
    Array<IPrivateChatPreviewListChat>
  >([]);

  const fetchPrivateChatPreviewList = useCallback(async () => {
    setIsFetching(true);

    const { data, success } = await privateChatPreviewList({ limit: 10 });

    if (success && data) {
      const { results, next } = data;

      setNextUrl(next);
      setPrivateChats((oldChats) => {
        const newChats = _.uniqBy([...results, ...oldChats], (chat) => chat.id);

        return newChats;
      });
    }

    setIsFetching(false);
  }, []);

  const fetchNextPrivateChatPreviewList = async () => {
    if (!nextUrl) {
      return;
    }

    setIsFetching(true);

    const { data, success } = await nextPrivateChatPreviewList({ nextUrl });

    if (success && data) {
      const { results, next } = data;

      setNextUrl(next);
      setPrivateChats((previousPrivateChats) => [
        ...previousPrivateChats,
        ...results
      ]);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    fetchPrivateChatPreviewList();
  }, [unreadMessagesCount, fetchPrivateChatPreviewList]);

  return (
    <>
      {_.isEmpty(privateChats) && !isFetching ? (
        <StyledEmptyPrivateChats />
      ) : (
        <>
          {privateChats.map((privateChat) => (
            <PrivateChatPreview
              privateChat={privateChat}
              onClick={() => setOpenedPrivateChat(privateChat)}
              key={`private-chat-preview-${privateChat.id}`}
            />
          ))}
          {nextUrl && !isFetching && (
            <LoadMoreButton
              colorScheme={3}
              fontSize={14}
              height="short"
              fontWeight={400}
              onClick={fetchNextPrivateChatPreviewList}>
              Load More
            </LoadMoreButton>
          )}
        </>
      )}
      {isFetching && <PrivateChatPreview.Placeholder count={10} />}
    </>
  );
};

export default PrivateChatPreviewList;
