import styled from 'styled-components';

import { colors, breakpoints, lightBlueGrey } from 'styles/global';

import { ITheme } from 'libs/types';

export const Container = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px 27px 20px 20px;
  background-color: ${lightBlueGrey};

  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.4s ease;
  border-color: ${({ selected }: { selected: boolean }) =>
    selected ? 'rgba(0, 149, 212, 0.6)' : 'rgba(123, 124, 129, 0.2)'};

  display: grid;
  grid-gap: 3px 10px;
  grid-template-columns: 18px 1fr 70px;
  grid-template-areas: 'indicator name price' 'indicator description price';

  @media ${breakpoints.mobile} {
    grid-template-areas: 'indicator name price' '. description description';
  }

  &:hover {
    border-color: rgba(0, 149, 212, 0.6);
  }
`;

export const Indicator = styled.span`
  grid-area: indicator;

  width: 18px;
  height: 18px;
  margin-right: 12px;

  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid rgba(123, 124, 129, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 11px;

  transition: background-color 0.2s ease;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: background-color 0.3s linear;

    background-color: ${({
      selected,
      theme
    }: {
      selected: boolean;
      theme: ITheme;
    }) => (selected ? theme.primaryColor : 'transparent')};
  }
`;

export const Name = styled.div`
  grid-area: name;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[1]};
`;

export const Description = styled.div`
  grid-area: description;

  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};
`;

export const Price = styled.div`
  grid-area: price;

  font-size: 15px;
  font-weight: 500;
  text-align: right;
  line-height: 18px;
  color: ${colors.text[1]};
`;
