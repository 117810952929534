import { useRouter } from 'next/router';
import { SkeletonTheme } from 'react-loading-skeleton';

import {
  M3U8_CONTENT_TYPE,
  SESSIONS_URL,
  EVENT_TICKET_PURCHASE_URL
} from 'libs/constants';
import {
  ESessionStatuses,
  ESessionTypeLabels,
  EPlayerPageTypes,
  EPlayerVideoLanguageCodes,
  EPlayerVideoStreamTypes
} from 'libs/enums';
import { formatPrice } from 'libs/prices';
import {
  ISessionDetailSession,
  IEventDetailEventSponsor,
  ISessionSpeakerDetailSession
} from 'libs/types';
import { unissuLogin, unissuSignUp } from 'libs/unissuAuth';

import { useWithUser, useWithEvent } from 'hooks';

import { Link, Image, Countdown } from 'components';

import { Player, IPlayerProps } from './components';
import { SESSION_PLAYER_CONTENT_CHOICES } from './constants';
import {
  Container,
  WatchingNow,
  LiveIndicator,
  EyeIcon,
  SponsorRow,
  SponsorLink,
  StyledLoginPrompt,
  CountdownAndMessageBoxWrapper,
  CountdownContainer,
  HostingThisSession,
  SignUpButton,
  LoginText,
  StyledSkeleton,
  StyledScheduleSessionToggleButton,
  MessageBox,
  MessageBoxIcon,
  MessageBoxTitle,
  MessageBoxSubTitle,
  MessageBoxStartingFrom,
  BrowseSessionsButton,
  PurchaseEventTicketButton
} from './styles';
import { getSessionPlayerContent } from './utils';

export interface IProps extends IPlayerProps {
  isOnAdminPage?: boolean;
  sponsors: Array<IEventDetailEventSponsor>;
  session: ISessionDetailSession | ISessionSpeakerDetailSession;
}

const SessionPlayer = ({
  session,
  sponsors,
  isOnAdminPage = false,
  ...props
}: IProps) => {
  const { user, isFetchingInitials } = useWithUser();

  const router = useRouter();
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const playerProps = {
    ...props,
    key: session.streaming_playback_url, // we do this to rerender the component upon source change
    sources: [
      {
        src: session.streaming_playback_url || '',
        type: M3U8_CONTENT_TYPE
      }
    ],
    controlBar: {
      playToggle: true,
      captionsButton: false,
      chaptersButton: false,
      subtitlesButton: false,
      remainingTimeDisplay: false,
      fullscreenToggle: true,
      playbackRateMenuButton:
        session.status === ESessionStatuses.VIDEO_AVAILABLE
    },
    enableMuxData: true,
    muxDataMetadata: {
      /* For viewer_user_id you should not use any value that is personally identifiable on its own (such as email address, username, etc). Instead, you should supply an anonymized viewer ID which you have stored within your own system. */
      viewer_user_id: user?.id,
      page_type: EPlayerPageTypes.WATCHPAGE,
      video_id: session.id,
      video_title: session.title,
      video_content_type: EPlayerVideoLanguageCodes.EVENT,
      video_stream_type:
        session.session_type === ESessionTypeLabels.STREAM
          ? EPlayerVideoStreamTypes.LIVE
          : EPlayerVideoStreamTypes.ON_DEMAND
    }
  };

  const getDisplayComponent = () => {
    if (isFetchingInitials) {
      // Fetching user data.
      // The theme is for custom colors of the loading animation.
      return (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <StyledSkeleton />
        </SkeletonTheme>
      );
    }

    const {
      status,
      start: startTime,
      is_speaker: isSpeaker,
      id: sessionId,
      is_added_to_user_schedule: sessionIsAddedToSchedule,
      is_receiving_stream: isReceivingStream
    } = session;

    const contentToBeShown = getSessionPlayerContent({
      user,
      event,
      status,
      isSpeaker,
      isOnAdminPage,
      isReceivingStream
    });

    const eventIsPaid = event
      ? parseFloat(event.lowest_ticket_price) > 0
      : false;

    switch (contentToBeShown) {
      case SESSION_PLAYER_CONTENT_CHOICES.LOGIN_PROMPT_WITH_COUNTDOWN:
        return (
          <CountdownAndMessageBoxWrapper>
            <CountdownContainer>
              <Countdown time={startTime} />
              <SignUpButton
                colorScheme={1}
                height="medium"
                fontSize={16}
                fontWeight={500}
                onClick={unissuSignUp}>
                Sign Up
              </SignUpButton>
              <LoginText
                colorScheme={5}
                fontSize={13}
                fontWeight={500}
                onClick={unissuLogin}>
                Log in
              </LoginText>
            </CountdownContainer>
          </CountdownAndMessageBoxWrapper>
        );
      case SESSION_PLAYER_CONTENT_CHOICES.LOGIN_PROMPT:
        return (
          <StyledLoginPrompt
            theme="light"
            text="Please sign up to view this session"
          />
        );

      case SESSION_PLAYER_CONTENT_CHOICES.EVENT_TICKET_PURCHASE_PROMPT:
        return (
          <CountdownAndMessageBoxWrapper>
            <MessageBox>
              <MessageBoxIcon icon="lock" />
              <MessageBoxTitle>Uh oh!</MessageBoxTitle>
              {event && (
                <MessageBoxSubTitle>
                  {eventIsPaid && (
                    <MessageBoxStartingFrom>
                      Starting from £{formatPrice(event.lowest_ticket_price)}
                    </MessageBoxStartingFrom>
                  )}
                  Gain full access to all sessions, global chat and networking
                  for this event
                </MessageBoxSubTitle>
              )}
              {event && (
                <Link
                  href={EVENT_TICKET_PURCHASE_URL({
                    eventSlug: event.slug,
                    next: router.asPath
                  })}>
                  <PurchaseEventTicketButton
                    colorScheme={6}
                    height="medium"
                    fontSize={15}
                    fontWeight={500}>
                    Get Ticket
                  </PurchaseEventTicketButton>
                </Link>
              )}
            </MessageBox>
          </CountdownAndMessageBoxWrapper>
        );

      case SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_WITH_LIVE_INDICATOR_AND_VIEWER_COUNT:
        return <Player {...playerProps} />;

      case SESSION_PLAYER_CONTENT_CHOICES.COUNTDOWN_FOR_LOGGED_USER:
        return (
          <CountdownAndMessageBoxWrapper>
            <CountdownContainer>
              <Countdown time={startTime} />
              {isSpeaker ? (
                <HostingThisSession>
                  You are hosting this session
                </HostingThisSession>
              ) : (
                <StyledScheduleSessionToggleButton
                  fontSize="14"
                  height="medium"
                  fontWeight={500}
                  sessionId={sessionId}
                  initialIsAddedToSchedule={sessionIsAddedToSchedule}
                />
              )}
            </CountdownContainer>
          </CountdownAndMessageBoxWrapper>
        );

      case SESSION_PLAYER_CONTENT_CHOICES.PLAY_VIDEO_FROM_THE_PAST:
        return <Player {...playerProps} />;

      case SESSION_PLAYER_CONTENT_CHOICES.THERE_IS_NO_RECORDING:
        return (
          <CountdownAndMessageBoxWrapper>
            <MessageBox>
              <MessageBoxTitle>This session has Expired</MessageBoxTitle>
              <MessageBoxSubTitle>Session not taking place</MessageBoxSubTitle>
              {eventSlug && (
                <Link href={SESSIONS_URL({ eventSlug })}>
                  <BrowseSessionsButton
                    colorScheme={1}
                    fontSize={15}
                    fontWeight={500}
                    height="medium">
                    Browse Sessions
                  </BrowseSessionsButton>
                </Link>
              )}
            </MessageBox>
          </CountdownAndMessageBoxWrapper>
        );

      case SESSION_PLAYER_CONTENT_CHOICES.RECORDING_COMING_SOON:
        return (
          <CountdownAndMessageBoxWrapper>
            <MessageBox>
              <MessageBoxTitle>
                Recording of Session Coming Soon
              </MessageBoxTitle>
              <MessageBoxSubTitle>
                Thank you for your patience
              </MessageBoxSubTitle>
            </MessageBox>
          </CountdownAndMessageBoxWrapper>
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      <WatchingNow>
        {session.status === ESessionStatuses.LIVE && (
          <>
            <LiveIndicator icon="dot-circle" />
            <EyeIcon icon="eye" weight="far" />
            {session.live_count}
          </>
        )}
      </WatchingNow>
      {getDisplayComponent()}
      <SponsorRow>
        {sponsors.map((sponsor) => (
          <SponsorLink
            external
            href={sponsor.url}
            key={`sponsor-${sponsor.name}`}>
            <Image height={40} width={85} src={sponsor.dark_background_logo} />
          </SponsorLink>
        ))}
      </SponsorRow>
    </Container>
  );
};

SessionPlayer.defaultProps = {
  sponsors: []
};

export default SessionPlayer;
