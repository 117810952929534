import _ from 'lodash';

import { applyFormattingToDateTime } from 'libs/datetime';
import { IMessage } from 'libs/types';

export const getDateToShow = ({
  olderMessage,
  newerMessage
}: {
  olderMessage: IMessage | null;
  newerMessage: IMessage;
}) => {
  const messageDate = applyFormattingToDateTime({
    date: newerMessage.created_at,
    toFormat: 'DD MMM'
  });

  if (_.isNil(olderMessage)) {
    return messageDate;
  }

  const previousMessageDate = applyFormattingToDateTime({
    date: olderMessage.created_at,
    toFormat: 'DD MMM'
  });

  return messageDate !== previousMessageDate ? messageDate : null;
};

export const getShowUnread = ({
  olderMessage,
  newerMessage
}: {
  olderMessage: IMessage | null;
  newerMessage: IMessage;
}) => {
  if (olderMessage) {
    return olderMessage.is_seen && !newerMessage.is_seen;
  }

  return !newerMessage.is_seen;
};
