import styled from 'styled-components';

import { colors, lightBlueGrey } from 'styles/global';

import { Link, Icon, Image, Button } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
  border-radius: 1.99692px 1.99692px 0px 0px;
`;

export const Top = styled(Link)`
  height: 98px;
  object-fit: cover;
  position: relative;
`;

export const TopPlaceholder = styled.div`
  height: 98px;
  position: relative;
`;

export const Content = styled.div`
  flex-grow: 1;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[2]};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const OrganiserName = styled(Link)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: color 0.2s linear;

  &:hover {
    color: ${colors.text[4]};
  }
`;

export const Title = styled(Link)`
  font-weight: 500;
  color: ${colors.text[1]};

  margin-top: 3px;
  margin-bottom: 8px;

  font-size: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '18px' : '22px'};
  line-height: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '22px' : '26px'}; ;
`;

export const TitlePlaceholder = styled.div`
  font-weight: 500;
  color: ${colors.text[1]};

  margin-top: 3px;
  margin-bottom: 8px;

  font-size: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '18px' : '22px'};
  line-height: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '22px' : '26px'}; ;
`;

export const Description = styled.p`
  margin-bottom: 14px;
  color: ${colors.text[1]};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;

  height: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '54px' : '59px'};
  font-size: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '15px' : '16px'};
  line-height: ${({ reducedSize }: { reducedSize: boolean }) =>
    reducedSize ? '18px' : '19px'}; ;
`;

export const Price = styled.div`
  margin-top: auto;
  margin-bottom: 25px;

  padding: 8px 12px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 3.99384px;
  background: ${lightBlueGrey};
  border: 0.49923px solid rgba(123, 124, 129, 0.2);
  font-size: 13px;
`;

export const ButtonLink = styled(Link)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const DotIcon = styled(Icon)`
  margin-right: 5px;
`;

export const LiveLabel = styled.div`
  height: 28px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 9px;
  left: 9px;
  z-index: 2;
  position: absolute;

  color: white;
  font-weight: 500;
  line-height: 17px;
  font-size: 13.7778px;
  background-color: #ff625c;
`;
