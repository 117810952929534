import NextLink from 'next/link';

import { StyledAnchor } from './styles';

interface IProps {
  // Prop reference: https://nextjs.org/docs/api-reference/next/link
  href: string;
  [key: string]: any;
  external?: boolean;
}

const PROPS_FOR_EXTERNAL_LINKS = {
  target: '_blank',
  rel: 'noopener noreferrer'
};

const Link = ({
  children,
  className,
  external,
  onClick,
  href,
  ...props
}: IProps) => (
  <NextLink passHref href={href} {...props}>
    <StyledAnchor
      onClick={onClick}
      className={className}
      {...(external ? PROPS_FOR_EXTERNAL_LINKS : {})}>
      {children}
    </StyledAnchor>
  </NextLink>
);

export default Link;
