import { unissuLogin, unissuSignUp } from 'libs/unissuAuth';

import {
  Container,
  LockIcon,
  Text,
  StyledButton,
  StyledButtonText
} from './styles';

export interface IProps {
  theme: 'light' | 'dark';
  text: string;
  className?: string;
  dataTestid?: string;
}

// sizing and background should come from the outside
const LoginPrompt = ({ theme, className, text, dataTestid }: IProps) => (
  <Container className={className} theme={theme} data-testid={dataTestid}>
    <LockIcon icon="lock" />
    <Text>{text}</Text>
    <StyledButton
      fontSize={16}
      height="medium"
      colorScheme={1}
      fontWeight={600}
      onClick={unissuSignUp}>
      Sign Up
    </StyledButton>
    <StyledButtonText
      fontSize={13}
      colorScheme={theme === 'light' ? 5 : 3}
      fontWeight={500}
      onClick={unissuLogin}>
      Login
    </StyledButtonText>
  </Container>
);

export default LoginPrompt;
