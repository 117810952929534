import { StyledLink, StyledIcon, Container } from './styles';

export interface IProps {
  href?: string;
  withIcon?: boolean;
  className?: string;
  extraOnClickAction?: () => void;
  filename: string;
}

const File = ({
  extraOnClickAction,
  href,
  filename,
  className,
  withIcon = true
}: IProps) =>
  href ? (
    <span onClick={extraOnClickAction}>
      <StyledLink external href={href} className={className}>
        {withIcon && <StyledIcon icon="download" />}
        {filename}
      </StyledLink>
    </span>
  ) : (
    <Container className={className}>
      {withIcon && <StyledIcon icon="download" />}
      {filename}
    </Container>
  );

export default File;
