import _ from 'lodash';

export const turnTextIntoTags = ({
  text,
  delimiters
}: {
  text: string;
  delimiters: Array<string>;
}): Array<string> => {
  let tags = text;

  // First we turn each delimiter into a comma.
  delimiters.forEach((delimiter) => {
    tags = tags.split(delimiter).join(',');
  });

  // Once all the delimiters are converted into commas we can split on the comma.
  const splitTags = tags.split(',');
  const trimmedTags = splitTags.map((tag) => _.trim(tag));

  // Now we need to remove all the empty tags.
  const filteredTags = trimmedTags.filter((tag) => tag !== '');

  return filteredTags;
};
