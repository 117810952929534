import { FunctionComponent, ReactNode } from 'react';

import { StyledLabel } from './styles';

export interface IProps {
  color: 'yellow' | 'darkBlue';
  children: ReactNode;
  className?: string;
}

const Label: FunctionComponent<IProps> = ({ color, children, className }) => (
  <StyledLabel color={color} className={className}>
    {children}
  </StyledLabel>
);

export default Label;
