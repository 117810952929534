export enum SESSION_PLAYER_CONTENT_CHOICES {
  LOGIN_PROMPT_WITH_COUNTDOWN = 'Login prompt with countdown',
  LOGIN_PROMPT = 'Login prompt',
  PLAY_VIDEO_WITH_LIVE_INDICATOR_AND_VIEWER_COUNT = 'Play video with live indicator and viewer count',
  COUNTDOWN_FOR_LOGGED_USER = 'Countdown for logged user',
  PLAY_VIDEO_FROM_THE_PAST = 'Play video from the past',
  THERE_IS_NO_RECORDING = 'There is no recording',
  RECORDING_COMING_SOON = 'Recording coming soon',
  EVENT_TICKET_PURCHASE_PROMPT = 'Event ticket purchase prompt'
}
