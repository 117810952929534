import { useContext } from 'react';

import { AppContext } from 'pages/_app';

const useWithUser = () => {
  const { user, isFetchingInitials } = useContext(AppContext);

  return { user, isFetchingInitials };
};

export default useWithUser;
