import ReactSelect from 'react-select';
import styled from 'styled-components';

import { colors } from 'styles/global';

import { ITheme } from 'libs/types';

import { FormLabel } from 'components';

export const StyledLabel = styled(FormLabel)`
  margin-bottom: 11px;
`;

export const StyledSelect = styled(ReactSelect)`
  div[class*='control'] {
    font-size: 16px;
    line-height: 19px;
    color: ${colors.text[1]};

    width: 100%;
    box-sizing: border-box;
    padding: 9px 15px 8px 15px;

    border-radius: 2px;
    border: 0.75px solid rgba(123, 124, 129, 0.2);

    &:focus {
      outline: none;
      border: 0.75px solid rgba(123, 124, 129, 0.2);
    }
  }

  span[class*='indicatorSeparator'] {
    display: none;
  }

  div[class*='menu'] {
    padding: 0;
    border-radius: 2px;
    border: 1px solid rgba(123, 124, 129, 0.2);
    box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

    & > div[class*='MenuList'] {
      padding: 0;
    }
  }

  div[class*='option'] {
    color: ${colors.text[1]};
    font-size: 15px;
    line-height: 18px;
    padding: 16px 22px;
    background-color: white;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
    }
  }
`;
