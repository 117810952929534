import { Fragment } from 'react';

import { CATEGORY_DETAIL_URL } from 'libs/constants';
import { ICategoryListCategory } from 'libs/types';

import { useWithEvent } from 'hooks';

import { Card, Link } from 'components';

import { Title } from './styles';

interface IProps {
  categories: Array<ICategoryListCategory>;
}

const SubCategories = ({ categories }: IProps) => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  return eventSlug ? (
    <>
      {categories.map((category: ICategoryListCategory) =>
        category.is_final_category ? (
          <Link
            key={`card-${category.category.slug}`}
            href={CATEGORY_DETAIL_URL({
              eventSlug,
              categorySlug: category.category.slug
            })}>
            <Card
              sessionCount={category.category.sessions_count}
              title={category.category.title}
              image={category.category.image}
              sponsorLogo={
                category.category?.main_sponsor?.light_background_logo
              }
            />
          </Link>
        ) : (
          <Fragment
            key={`category-${category.category.slug}-with-${category.category.sessions_count}-sessions`}>
            <Title>{category.category.title}</Title>
            <SubCategories categories={category.subcategories} />
          </Fragment>
        )
      )}
    </>
  ) : null;
};

export default SubCategories;
