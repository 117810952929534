import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Button, Link, Icon } from 'components';

export const Wrapper = styled.div`
  padding: 54px 73px;

  @media ${breakpoints.mobile} {
    padding: 30px 15px;
  }
`;

export const BackLink = styled(Link)`
  align-self: flex-start;

  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};
`;

export const StyledIcon = styled(Icon)`
  margin-right: 14px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: ${colors.text[1]};

  max-width: 190px;
  margin-top: 76px;
  margin-bottom: 61px;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: ${colors.text[1]};

  max-width: 325px;
  margin-top: 39px;
  margin-bottom: 67px;
`;

export const ButtonContainer = styled(Link)`
  width: 100%;
  max-width: 500px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
