import styled from 'styled-components';

import { colors, lightBlueGrey, yellow, breakpoints } from 'styles/global';

import { Link, Icon, Button } from 'components';

import background from './assets/gradient.jpg';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 51px;

  padding-top: 43px;
  box-sizing: border-box;

  background: url(${background});
  background-repeat: no-repeat;
  background-size: 100% 398px;

  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${breakpoints.mobile} {
    padding: 0 30px;
    grid-template-columns: 1fr;
  }
`;

export const Left = styled.div`
  background-color: white;

  box-sizing: border-box;
  padding: 60px 92px 0 48px;

  @media ${breakpoints.tablet} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    padding: 20px 30px;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: ${colors.text[1]};

  margin-top: 35px;
  margin-bottom: 21px;
  max-width: 365px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.text[1]};

  max-width: 400px;
  margin-bottom: 43px;
`;

export const StyledLink = styled(Link)`
  align-self: stretch;
`;

export const StyledButton = styled(Button)`
  width: 220px;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const Right = styled.div`
  margin-top: 44px;

  box-sizing: border-box;
  padding: 21px 51px 47px 40px;
  background-color: ${lightBlueGrey};

  display: grid;
  grid-gap: 35px 10px;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media ${breakpoints.tablet} {
    grid-column: span 2;
  }
`;

export const Testimonial = styled.div``;

export const TestimonialIcon = styled(Icon)`
  font-size: 16px;
  color: ${yellow};
`;

export const TestimonialTitle = styled.h6`
  margin-bottom: 13px;
  margin-top: 17.75px;

  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: ${colors.text[1]};
`;

export const TestimonialDescription = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[2]};
`;
