import { withFormik, Form, FormikProps } from 'formik';
import _ from 'lodash';
import { useRef, useState, useEffect, useCallback, Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  formatFormErrors,
  disableBodyScroll,
  enableBodyScroll
} from 'libs/common';
import { applyFormattingToDateTime } from 'libs/datetime';
import { EAvatarSizes } from 'libs/enums';
import { meetingCreate, meetingSlotsRetrieve } from 'libs/requests';
import {
  IMeetingSlotsRetrieveSlot,
  IMeetingSlotsRetrieveParticipant
} from 'libs/types';

import { UserLine } from 'components';

import {
  Spacer,
  Wrapper,
  Container,
  Close,
  Header,
  Footer,
  Title,
  Description,
  ParticipantLineContainer,
  SlotGroupHeader,
  Slot,
  SlotContainer,
  Submit,
  Warning,
  NoSlots,
  ParticipantLine,
  StyledFieldError
} from './styles';
import { groupSlotsByDate } from './utils';

interface IProps {
  participantId: number | null;
  closeModal: () => void;
}

interface IFormValues {
  start: string;
  participant: number;
}

const ContentPlaceholder = () => (
  <>
    <Header>
      <Title>Book Meeting</Title>
      <Description>Arrange a 1-1 call</Description>
      <ParticipantLineContainer>
        <UserLine.Placeholder withOccupation size={EAvatarSizes.MEDIUM} />
      </ParticipantLineContainer>
    </Header>
    <SlotGroupHeader>
      <Skeleton width="60%" />
    </SlotGroupHeader>
    <SlotContainer>
      {_.range(60).map((index) => (
        <Slot
          active={false}
          withRedBorder={false}
          key={`book-a-meeting-modal-slot-placeholder-${index}`}>
          <Skeleton width="50px" />
        </Slot>
      ))}
    </SlotContainer>
    <Footer>
      <Submit
        colorScheme={1}
        fontSize={15}
        fontWeight={500}
        height="medium"
        disabled={true}>
        Confirm
      </Submit>
    </Footer>
  </>
);

const BookAMeetingModal = ({
  participantId,
  closeModal,
  values,
  setFieldValue,
  dirty,
  isValid,
  isSubmitting,
  resetForm
}: IProps & FormikProps<IFormValues>) => {
  const [meetingSlotGroups, setMeetingSlotGroups] = useState<{
    [key: string]: Array<IMeetingSlotsRetrieveSlot>;
  }>({});
  const [
    participantData,
    setParticipantData
  ] = useState<IMeetingSlotsRetrieveParticipant | null>(null);
  const [alreadyHaveAMeeting, setAlreadyHaveAMeeting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const isOpen = !_.isNil(participantId);
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchMeetingSlots = useCallback(async () => {
    if (!participantId) {
      // closed state
      resetForm();
      setIsFetching(true);
      setParticipantData(null);
      setMeetingSlotGroups({});
      setAlreadyHaveAMeeting(false);
      containerRef?.current?.scroll?.({ top: 0 });

      return;
    }

    setIsFetching(true);
    const { data, success } = await meetingSlotsRetrieve({ participantId });

    if (success && data) {
      const slotGroups = groupSlotsByDate(data.slots);

      setIsFetching(false);
      setMeetingSlotGroups(slotGroups);
      setParticipantData(data.participant);
      setAlreadyHaveAMeeting(data.already_have_a_meeting);
    } else {
      closeModal();
      toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }
  }, [participantId, resetForm, closeModal]);

  useEffect(() => {
    fetchMeetingSlots();
  }, [fetchMeetingSlots]);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();

      return enableBodyScroll;
    }
  }, [isOpen]);

  return (
    <Form>
      <Wrapper visible={isOpen} onClick={closeModal}>
        <Container
          visible={isOpen}
          ref={containerRef}
          onClick={(e) => e.stopPropagation()}>
          {!isFetching && participantData ? (
            <>
              <Header>
                <Close icon="times" onClick={closeModal} />
                <Title>Book Meeting</Title>
                <Description>Arrange a 1-1 call</Description>
                <ParticipantLineContainer>
                  <ParticipantLine
                    size={EAvatarSizes.MEDIUM}
                    user={participantData}
                    openUserDetailOnClick={false}
                  />
                </ParticipantLineContainer>
              </Header>

              {_.isEmpty(meetingSlotGroups) ? (
                <NoSlots>
                  {alreadyHaveAMeeting
                    ? 'You have already booked a meeting with this user.'
                    : 'No time slots available.'}
                </NoSlots>
              ) : (
                <>
                  {Object.keys(meetingSlotGroups).map((date) => (
                    <Fragment key={`meeting-slot-group-${date}`}>
                      <SlotGroupHeader>{date}</SlotGroupHeader>
                      <SlotContainer>
                        {meetingSlotGroups[date].map((slot) => (
                          <Slot
                            active={values.start === slot.start}
                            onClick={() => setFieldValue('start', slot.start)}
                            withRedBorder={!slot.in_working_hours}
                            title={
                              slot.in_working_hours
                                ? ''
                                : 'This slot is outside working hours.'
                            }>
                            {applyFormattingToDateTime({
                              date: slot.start,
                              toFormat: 'HH:mm'
                            })}
                          </Slot>
                        ))}
                      </SlotContainer>
                    </Fragment>
                  ))}
                  <Spacer />
                </>
              )}

              <Footer>
                {!_.get(values, 'slot.in_working_hours', true) && (
                  <Warning>Time selected is outside working hours</Warning>
                )}
                <StyledFieldError name="start" />
                <StyledFieldError name="participant" />
                <Submit
                  colorScheme={1}
                  fontSize={15}
                  fontWeight={500}
                  height="medium"
                  type="submit"
                  disabled={!dirty || isSubmitting || !isValid}>
                  Confirm
                </Submit>
              </Footer>
            </>
          ) : (
            <ContentPlaceholder />
          )}
        </Container>
      </Wrapper>
    </Form>
  );
};

export default withFormik<IProps, IFormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ participantId }) => ({
    start: '',
    participant: participantId ? participantId : 0 // this way it's always a number
  }),
  validationSchema: yup.object().shape({
    participant: yup.number().required(),
    start: yup.string().required('Please select a slot.')
  }),
  handleSubmit: async (data, { props, setErrors }) => {
    const { success, error } = await meetingCreate({ data });

    if (success) {
      toast('Your meeting request has been sent!', { type: 'success' });
      props.closeModal();
    } else {
      const formattedErrors = formatFormErrors(error.data);

      setErrors(formattedErrors);
    }
  }
})(BookAMeetingModal);
