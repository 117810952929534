import { EAvatarSizes } from 'libs/enums';
import { ISessionSlotListSlotSession } from 'libs/types';

import { UserLine, TimestampDivider } from 'components';

import { Container, Title, HostLine, HostLabel } from './styles';

export interface IProps {
  className?: string;
  session: ISessionSlotListSlotSession;
}

const ReservedSlot = ({
  className,
  session: { start, title, speaker }
}: IProps) => (
  <div className={className}>
    <TimestampDivider withTimezone time={start} />
    <Container>
      <Title>{title || 'Reserved'}</Title>
      <HostLine>
        <UserLine size={EAvatarSizes.LARGE} user={speaker} />
        <HostLabel color="yellow">Host</HostLabel>
      </HostLine>
    </Container>
  </div>
);

export default ReservedSlot;
