import _ from 'lodash';

import { ICategoryListCategory, ISelectOption } from 'libs/types';

export const categoriesToSelectOptions = (
  categories: Array<ICategoryListCategory>
): Array<ISelectOption> =>
  categories.map((category) => ({
    label: category.category.title,
    value: category
  }));

export const categoryToLeafSubCategoriesOptions = (
  category: ICategoryListCategory
): Array<ISelectOption> =>
  _.flatMap(category.subcategories, (subCategory) => {
    if (subCategory.is_final_category) {
      return {
        label: subCategory.category.title,
        value: subCategory
      };
    }

    return categoryToLeafSubCategoriesOptions(subCategory);
  });
