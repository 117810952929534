import noResultsImage from './assets/no-results.png';
import { Container, StyledImage } from './styles';

interface IProps {
  className?: string;
}

const NoSubCategorySelected = ({ className }: IProps) => (
  <Container className={className}>
    <StyledImage height={352} width={472} src={noResultsImage} />
    <p>
      We have hundreds of available session slots. Pick the one which works for
      you
    </p>
  </Container>
);

export default NoSubCategorySelected;
