import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { red, breakpoints } from 'styles/global';

import {
  Icon,
  Link,
  LoginPrompt,
  Button,
  ScheduleSessionToggleButton
} from 'components';

import background from './assets/background.png';

export const Container = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  box-sizing: border-box;
  padding: 9px 0 20px 0;

  background: url(${background}) no-repeat;
  background-size: cover;
`;

export const StyledSkeleton = styled(Skeleton)`
  padding-top: 56.25%; // this equals 16:9 aspect ratio
`;

export const WatchingNow = styled.div`
  color: white;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;

  margin-left: 25px;
  margin-bottom: 10px;
`;

export const LiveIndicator = styled(Icon)`
  color: ${red};
  font-size: 17px;
  margin-right: 10px;
`;

export const EyeIcon = styled(Icon)`
  margin-right: 4px;
`;

export const SponsorRow = styled.div`
  height: 50px;
  margin-top: 19px;
  align-self: center;

  display: flex;
`;

export const SponsorLink = styled(Link)`
  display: flex;
  justify-content: center;

  img {
    object-fit: contain;
  }

  &:not(:first-of-type) {
    margin-left: 38px;
  }
`;

export const StyledLoginPrompt = styled(LoginPrompt)`
  padding-top: 100px;
  padding-bottom: 140px;
`;

export const CountdownAndMessageBoxWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 75px;
  padding-bottom: 140px;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    padding-bottom: 100px;
  }

  @media ${breakpoints.mobile} {
    padding-bottom: 30px;
  }
`;

export const CountdownContainer = styled.div`
  width: 100%;
  max-width: 532px;
  min-height: 285px;
  background-color: rgba(255, 255, 255, 0.03);

  border-radius: 2px;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  box-sizing: border-box;
  padding: 33px 46px 40px 46px;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: ${({ absolute }: { absolute?: boolean }) =>
    absolute ? 'absolute' : 'initial'};
  transform: ${({ absolute }: { absolute?: boolean }) =>
    absolute ? 'translate(-50%, -50%)' : ''};
  top: 50%;
  left: 50%;
`;

export const HostingThisSession = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  margin-top: 41px;
`;

export const SignUpButton = styled(Button)`
  width: 186px;
  margin-top: 30px;
  margin-bottom: 12px;
`;

export const LoginText = styled(Button.Text)`
  opacity: 0.4;
`;

export const StyledScheduleSessionToggleButton = styled(
  ScheduleSessionToggleButton
)`
  width: 201px;
  margin-top: 30px;
`;

export const MessageBox = styled.div`
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  width: 100%;
  max-width: 532px;
  min-height: 285px;

  padding-top: 55px;
  padding-bottom: 51px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
`;

export const MessageBoxIcon = styled(Icon)`
  color: white;
  opacity: 0.2;
  font-size: 26px;
  margin-bottom: 9px;
`;

export const MessageBoxTitle = styled.p`
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  max-width: 282px;
`;

export const MessageBoxSubTitle = styled.p`
  max-width: 244px;
  margin-top: 14px;
  margin-bottom: 31px;

  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.4);
`;

export const MessageBoxStartingFrom = styled.p`
  margin-bottom: 14px;

  color: white;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
`;

export const PurchaseEventTicketButton = styled(Button)`
  width: 420px;

  @media ${breakpoints.tablet} {
    width: 250px;
  }
`;

export const BrowseSessionsButton = styled(Button)`
  width: 186px;
`;
