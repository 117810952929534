import _ from 'lodash';

import { EAvatarSizes } from 'libs/enums';
import { ISessionDetailSessionParticipant } from 'libs/types';

import { UserLine } from 'components';

import { Container, ParticipantsLabel } from './styles';

interface IProps {
  className?: string;
  participants: Array<ISessionDetailSessionParticipant>;
}

const SessionParticipants = ({ participants, className }: IProps) =>
  _.isEmpty(participants) ? null : (
    <Container className={className}>
      <ParticipantsLabel color="darkBlue">Participants</ParticipantsLabel>
      {_.map(
        participants,
        (participant: ISessionDetailSessionParticipant, index: number) => (
          <UserLine
            size={EAvatarSizes.LARGE}
            user={participant}
            key={`participant-${index}`}
          />
        )
      )}
    </Container>
  );

export default SessionParticipants;
