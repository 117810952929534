import { FormikProps, withFormik, Form } from 'formik';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { FAQ_URL } from 'libs/constants';
import { EAvatarSizes } from 'libs/enums';
import { createMessage } from 'libs/requests';
import type { IMessage } from 'libs/types';

import { useWithUser } from 'hooks';

import { UserLine } from 'components';

import {
  Submit,
  Container,
  StyledTextArea,
  SubmitContainer,
  CharacterLimit,
  FAQLink,
  Right
} from './styles';

interface IFormValues {
  content: string;
}

interface IProps {
  chatId?: number;
  className?: string;
  onClick?: () => void;
  additionalOnChange?: () => void;
  maxLength?: number;
  addMessageToChat: (data: IMessage) => void;
}

export const handleMessageCreate = async ({
  values: { content },
  chatId,
  addMessageToChat
}: {
  values: { content: string };
  chatId: number;
  addMessageToChat: (data: IMessage) => void;
}) => {
  const { data, success, error } = await createMessage({ content, chatId });

  if (success && data) {
    addMessageToChat(data);
  }

  return { success, error };
};

const MessageInput = ({
  chatId,
  values,
  isValid,
  isSubmitting,
  maxLength,
  submitForm,
  className,
  onClick,
  additionalOnChange = () => {}
}: FormikProps<IFormValues> & IProps) => {
  const { user, isFetchingInitials } = useWithUser();

  const submitIfEnterIsPressed = (event: KeyboardEvent) => {
    additionalOnChange();

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      submitForm();
    }
  };

  return !isFetchingInitials && user ? (
    <Container className={className} onClick={onClick}>
      <UserLine
        size={EAvatarSizes.XSMALL}
        user={user}
        withOccupation={false}
        openUserDetailOnClick={false}
      />
      <Form>
        <StyledTextArea
          name="content"
          withFieldError={false}
          placeholder="Say Something..."
          onKeyPress={submitIfEnterIsPressed}
        />
      </Form>

      <SubmitContainer>
        <FAQLink external href={FAQ_URL}>
          Visit FAQ for support requests
        </FAQLink>

        <Right>
          {maxLength && (
            <CharacterLimit>{`${values.content.length}/${maxLength}`}</CharacterLimit>
          )}
          <Submit
            icon="paper-plane"
            disabled={!isValid || isSubmitting || !chatId}
            onClick={submitForm}
          />
        </Right>
      </SubmitContainer>
    </Container>
  ) : null;
};

export default withFormik<IProps, IFormValues>({
  enableReinitialize: true,

  mapPropsToValues: () => ({
    content: ''
  }),

  validationSchema: (props: IProps) =>
    props.maxLength
      ? yup.object().shape({
          content: yup
            .string()
            .required('Message content is required.')
            .max(props.maxLength)
        })
      : yup.object().shape({
          content: yup.string().required('Message content is required.')
        }),

  handleSubmit: async (
    values: IFormValues,
    { resetForm, props: { chatId, addMessageToChat } }
  ) => {
    if (chatId) {
      const { success, error } = await handleMessageCreate({
        values,
        chatId,
        addMessageToChat
      });

      if (success) {
        resetForm();
      } else {
        const errorMessage = _.get(
          error,
          'data.content.0',
          'Something went wrong. Please try again later.'
        );

        toast(errorMessage, { type: 'error' });
      }
    }
  }
})(MessageInput);
