import _ from 'lodash';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { colors } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon } from 'components';

import { IButtonProps, IButtonTextProps } from '.';

interface IThemedButtonProps extends IButtonProps {
  theme: ITheme;
}

interface IThemedButtonTextProps extends IButtonTextProps {
  theme: ITheme;
}

const heightToPixelMapping = {
  short: '38px',
  medium: '44px',
  tall: '48px'
};

export const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

export const ButtonText = styled.button`
  ouline: none;
  padding: 0;
  font-size: ${(props: IThemedButtonTextProps) => props.fontSize}px;
  font-weight: ${(props: IThemedButtonTextProps) => props.fontWeight};
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;

  // we do as if it was an outline button - we use the border color for text color
  color: ${(props: IThemedButtonTextProps): string =>
    colors.buttons({ theme: props.theme })[props.colorScheme].border};

  opacity: ${(props: IThemedButtonTextProps): number =>
    props.disabled ? 0.2 : 1};
  cursor: ${(props: IThemedButtonTextProps): string =>
    props.disabled ? 'not-allowed' : 'pointer'};

  &:focus {
    outline: none;
  }
`;

export const Button: FunctionComponent = styled(ButtonText)`
  height: ${(props: IThemedButtonProps) =>
    _.get(heightToPixelMapping, props.height, 'auto')};
  min-height: ${(props: IThemedButtonProps) =>
    _.get(heightToPixelMapping, props.height, 'auto')};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${(props: IThemedButtonProps): string =>
    props.outline
      ? colors.buttons({ theme: props.theme })[props.colorScheme].border
      : colors.buttons({ theme: props.theme })[props.colorScheme].color};
  background: ${(props: IThemedButtonProps): string =>
    props.outline
      ? 'transparent'
      : colors.buttons({ theme: props.theme })[props.colorScheme].background};
  border: 1px solid
    ${(props: IThemedButtonProps): string =>
      colors.buttons({ theme: props.theme })[props.colorScheme].border};
  transition: all 0.2s linear;
`;
