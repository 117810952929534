import styled from 'styled-components';

import { colors } from 'styles/global';

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.text[1]};

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
