import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

export const Container = styled.div`
  height: 85px;
  margin-left: 70px;
  margin-bottom: 2px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 32px 41px 32px 15px;

  background-color: white;
  color: ${colors.text[1]};
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);

  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.4 : 1)};

  @media ${breakpoints.mobile} {
    margin-top: 11px;
    margin-left: unset;
    margin-bottom: 9px;
  }
`;

export const AddToBasket = styled.span`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
`;

export const Indicator = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 12px;

  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid rgba(123, 124, 129, 0.2);

  filter: drop-shadow(0px 4px 5px rgba(123, 124, 129, 0.05));

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 11px;

  transition: background-color 0.2s ease;
  background-color: ${({
    selected,
    theme
  }: {
    selected: boolean;
    theme: ITheme;
  }) => (selected ? theme.primaryColor : 'white')};
`;

export const Price = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;
