import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding-left: 7px; // to negate the negative left margin of the first avatar
`;

export const AvatarContainer = styled.div`
  margin-left: -7px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid white;
`;
