import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon, Button } from 'components';

export const Container = styled.div`
  border-radius: 2px;
  box-sizing: border-box;

  color: ${({ theme }: { theme: 'light' | 'dark' }) =>
    theme === 'light' ? 'white' : colors.text[3]};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LockIcon = styled(Icon)`
  color: inherit;
  font-size: 24px;

  margin-bottom: 9px;
`;

export const Text = styled.p`
  color: inherit;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  width: 100%;
  max-width: 292px;
`;

export const StyledButton = styled(Button)`
  margin-top: 26px;
  margin-bottom: 12px;

  width: 100%;
  max-width: 186px;
`;

export const StyledButtonText = styled(Button.Text)`
  opacity: 0.4;
`;
