import { useWithEvent } from 'hooks';

import { Icon } from 'components';

import {
  Wrapper,
  Container,
  Checkbox,
  Indicator,
  PaddedLeft,
  Price,
  Description,
  Content
} from './styles';

interface IProps {
  selected?: boolean;
  isVisible: boolean;
  toggleDataPackage: () => void;
}

const DataPackage = ({
  isVisible,
  toggleDataPackage,
  selected = false
}: IProps) => {
  const { event } = useWithEvent();

  const dataPackagePrice = event?.data_package_price;

  return (
    <Wrapper isVisible={isVisible}>
      <Container>
        <Checkbox onClick={toggleDataPackage}>
          <Indicator selected={selected}>
            <Icon icon="check" />
          </Indicator>
          Data Package
        </Checkbox>
        <PaddedLeft>
          {dataPackagePrice && <Price>£{dataPackagePrice}</Price>}
          <Description>Data you will have access to.</Description>
          <Content>
            <span>Profile Links to all User Impressions</span>
            <span>User Type Breakdown</span>
            <span>Total Impressions</span>
            <span>Job Position Breakdown</span>
            <span>Unique Impressions</span>
            <span>Country Breakdown</span>
            <span>Sector Breakdown</span>
            <span>Session Ratings</span>
          </Content>
        </PaddedLeft>
      </Container>
    </Wrapper>
  );
};

export default DataPackage;
