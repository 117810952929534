import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getMessageTime } from 'libs/messaging';
import { IMessage } from 'libs/types';

import { Linkify } from 'components';

import { Container, Content, CreationDate } from './styles';

export interface IProps {
  className?: string;
  received: boolean; // if it's not received it's considered to be sent.
  message: IMessage;
}

interface IPlaceholderProps {
  className?: string;
  count?: number;
  received?: boolean;
}

const Placeholder = ({
  className,
  received = true,
  count = 1
}: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container
        received={received}
        className={className}
        key={`chat-bubble-placeholder-${index}`}>
        <Content received={received}>
          <Skeleton width="100%" count={2} />
        </Content>
        <CreationDate received={received}>
          <Skeleton width="40px" />
        </CreationDate>
      </Container>
    ))}
  </>
);

const ChatBubble: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({ received, message, className }) => (
  <Container received={received} className={className}>
    <Content received={received}>
      <Linkify>{message.content}</Linkify>
    </Content>
    <CreationDate received={received}>
      {getMessageTime({ time: message.created_at })}
    </CreationDate>
  </Container>
);

ChatBubble.Placeholder = Placeholder;

export default ChatBubble;
