import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Image } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoints.tablet} {
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  & > p {
    max-width: 376px;
    margin-top: 47px;

    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: ${colors.text[1]};

    @media ${breakpoints.mobile} {
      max-width: 100%;
    }
  }
`;

export const StyledImage = styled(Image)`
  @media ${breakpoints.tablet} {
    height: auto;
    max-width: 100%;
  }
`;
