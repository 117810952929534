import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FormikProps, withFormik } from 'formik';
import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { formatFormErrors } from 'libs/common';
import {
  STRIPE_PUBLIC_KEY,
  SESSION_PURCHASE_SUCCESS_URL,
  SESSION_PURCHASE_CANCEL_URL
} from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import {
  categoryList,
  sessionSlotList,
  sessionSlotPurchaseSessionCreate
} from 'libs/requests';
import { ICategoryListCategory, ISessionPurchaseFormSlot } from 'libs/types';
import { getEventSlugFromUrl } from 'libs/urls';

import { useWithEvent } from 'hooks';

import { Icon } from 'components';

import { BasketSidebar } from './components';
import {
  StyledForm,
  StyledContainer,
  LeftWrapper,
  Left,
  Right,
  Heading,
  StyledSelect,
  StyledSlotPicker,
  StyledFieldError,
  OpenBasketButton,
  BasketItemCount,
  StyledSlotPickerPlaceholder,
  StyledNoSubCategorySelected,
  DesktopOpenBasketButtonContent,
  MobileOpenBasketButtonContent,
  LoadingOverlay,
  LoadingIcon
} from './styles';
import {
  categoriesToSelectOptions,
  categoryToLeafSubCategoriesOptions
} from './utils';

interface IFormValues {
  category: ICategoryListCategory | null;
  subCategory: ICategoryListCategory | null;
  slots: [];
}

let stripePromise: Stripe | null = null;

const getStripe = async () => {
  if (!STRIPE_PUBLIC_KEY) {
    throw new Error('Stripe public key is not set!');
  }

  if (_.isNil(stripePromise)) {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
    stripePromise = await loadStripe(STRIPE_PUBLIC_KEY);
  }

  return stripePromise;
};

const SessionPurchaseForm = ({
  dirty,
  values,
  setFieldValue,
  isSubmitting,
  isValid
}: FormikProps<IFormValues>) => {
  const { event } = useWithEvent();
  const [isFetchingSlots, setIsFetchingSlots] = useState(false);
  const [slots, setSlots] = useState<Array<ISessionPurchaseFormSlot>>([]);
  const [categories, setCategories] = useState<Array<ICategoryListCategory>>(
    []
  );
  const [isBasketSidebarOpen, setIsBasketSidebarOpen] = useState(false);
  const isSubmissionDisabled = isSubmitting || !isValid || !dirty;

  const fetchCategories = async () => {
    const { success, data } = await categoryList();

    if (success) {
      const results = _.get(data, 'results', []);

      setCategories(results);
    }
  };

  const fetchSlots = useCallback(
    async ({ categorySlug }: { categorySlug: string }) => {
      setIsFetchingSlots(true);
      const { success, data } = await sessionSlotList({
        params: { category_slug: categorySlug }
      });

      if (success && data) {
        const slotPrice = event?.session_slot_price;

        const newSlots: Array<ISessionPurchaseFormSlot> = data.map((item) => ({
          ...item,
          with_data_package: false,
          price: _.isNil(slotPrice) ? '0' : slotPrice
        }));

        setSlots(newSlots);
      }
      setIsFetchingSlots(false);
    },
    [event]
  );

  useEffect(() => {
    // component did mount
    fetchCategories();
  }, []);

  useEffect(() => {
    // subCategory changes
    const subCategory = values.subCategory;

    // reset the slots
    setSlots([]);

    // fetch new slots
    if (!_.isNil(subCategory)) {
      fetchSlots({ categorySlug: subCategory.category.slug });
    }
  }, [values.subCategory, fetchSlots]);

  const formattedStart =
    event &&
    applyFormattingToDateTime({
      date: event.start,
      toFormat: 'Do MMM'
    });

  const formattedEnd =
    event &&
    applyFormattingToDateTime({
      date: event.end,
      toFormat: 'Do MMM'
    });

  return (
    <StyledForm>
      <StyledContainer>
        <LeftWrapper>
          <Left>
            <Heading>
              {event &&
                `${formattedStart}${
                  formattedStart === formattedEnd ? '' : ` - ${formattedEnd}`
                } ${applyFormattingToDateTime({
                  date: event.end,
                  toFormat: 'YYYY'
                })}`}
            </Heading>
            <StyledSelect
              name="category"
              isMulti={false}
              label="Select a category"
              onChange={(selectedOptionValue) => {
                if (values.category !== selectedOptionValue) {
                  // if the category changes we reset the subCategory
                  setFieldValue('subCategory', null);
                }
              }}
              options={categoriesToSelectOptions(categories)}
            />
            {!_.isNil(values.category) && (
              <StyledSelect
                isMulti={false}
                name="subCategory"
                label="Select a channel"
                options={categoryToLeafSubCategoriesOptions(values.category)}
              />
            )}
            {/* there isn't an actual field for this field so we just display the errors */}
            <StyledFieldError name="slots" />
            <OpenBasketButton
              colorScheme={1}
              height="tall"
              fontSize={15}
              fontWeight={600}
              onClick={() => setIsBasketSidebarOpen(true)}>
              <DesktopOpenBasketButtonContent>
                View Basket
              </DesktopOpenBasketButtonContent>
              <MobileOpenBasketButtonContent>
                <Icon icon={isBasketSidebarOpen ? 'times' : 'shopping-cart'} />
              </MobileOpenBasketButtonContent>
              <BasketItemCount visible={values.slots.length > 0}>
                {values.slots.length}
              </BasketItemCount>
            </OpenBasketButton>
          </Left>
        </LeftWrapper>
        <Right>
          {values.subCategory ? (
            isFetchingSlots ? (
              <StyledSlotPickerPlaceholder />
            ) : (
              <StyledSlotPicker
                slots={slots}
                selectedSlots={values.slots}
                sponsorLogo={
                  values.subCategory?.category.main_sponsor
                    ?.light_background_logo
                }
                setSelectedSlots={(newValue) =>
                  setFieldValue('slots', newValue)
                }
                categoryTitle={_.get(values, 'category.category.title', '')}
                subCategoryTitle={_.get(
                  values,
                  'subCategory.category.title',
                  ''
                )}
              />
            )
          ) : (
            <StyledNoSubCategorySelected />
          )}
        </Right>
      </StyledContainer>
      <BasketSidebar
        items={values.slots}
        isOpen={isBasketSidebarOpen}
        isSubmissionDisabled={isSubmissionDisabled}
        close={() => setIsBasketSidebarOpen(false)}
        slotFieldValueSetter={(newValue: Array<ISessionPurchaseFormSlot>) =>
          setFieldValue('slots', newValue)
        }
      />
      {isSubmitting && (
        <LoadingOverlay>
          <LoadingIcon icon="cog" />
        </LoadingOverlay>
      )}
    </StyledForm>
  );
};

export default withFormik<{}, IFormValues>({
  enableReinitialize: true,

  mapPropsToValues: () => ({
    category: null,
    subCategory: null,
    slots: []
  }),

  validationSchema: yup.object().shape({
    slots: yup.array().required()
  }),

  handleSubmit: async (values: IFormValues, { setErrors }) => {
    const eventSlug = getEventSlugFromUrl();

    if (!eventSlug) {
      // If there isn't a slug we can't submit the form.
      return;
    }

    // Get Stripe.js instance
    const stripe = await getStripe();

    if (!stripe) {
      return toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }

    // prepare form data
    const formData: Array<{
      slot: number;
      with_data_package: boolean;
    }> = values.slots.map((slot: ISessionPurchaseFormSlot) => ({
      slot: slot.id,
      with_data_package: _.get(slot, 'with_data_package', false)
    }));

    // get stripe checkout session id
    const { data, success, error } = await sessionSlotPurchaseSessionCreate({
      data: {
        slot_inputs: formData,
        success_url: `${window.location.origin}${SESSION_PURCHASE_SUCCESS_URL({
          eventSlug
        })}`,
        cancel_url: `${window.location.origin}${SESSION_PURCHASE_CANCEL_URL({
          eventSlug
        })}`
      }
    });

    if (success && data) {
      const { stripe_session_id: sessionId } = data;

      // Redirect to Checkout.
      const redirectResponse = await stripe.redirectToCheckout({
        sessionId
      });

      if (redirectResponse.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        toast(redirectResponse.error.message, { type: 'error' });
      }
    } else {
      try {
        const formattedErrors = formatFormErrors(error.data);
        toast(formattedErrors, { type: 'error' });
        setErrors(formattedErrors);
      } catch (formatError) {
        // `formatFormErrors` may fail to format the error.
        // In that case always show the first error message.
        toast(error.data[0], { type: 'error' });
      }
    }
  }
})(SessionPurchaseForm);
