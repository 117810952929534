import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  messageList,
  nextMessagesList,
  markUnreadMessagesSeen
} from 'libs/requests';
import { IMessage } from 'libs/types';

import { useWithUser } from 'hooks';

/*
This hook is used by chat components to handle the chat state.
It handles fetching of chat messages, adding a new message to the chat, and fetching of more messages.

It exposes a chat object that contains the chat data and methods to manipulate the data.

The hook receives an initial chat.
If the initial chat id value is changed the new chat data is loaded.
 */

// Interface of the chat object returned by the hook.
export interface IChat {
  // fields
  id?: number;
  messages: Array<IMessage>;
  hasMoreMessages: boolean;
  isFetchingMessages: boolean;

  // methods
  addMessage: (data: IMessage) => void;
  loadMoreMessages: () => void;
  markMessagesSeen: () => void;
}

interface IHookArgs {
  chatId?: number;
}

const useChat = ({ chatId }: IHookArgs): IChat => {
  const [nextUrl, setNextUrl] = useState<string>();
  const [messages, setMessages] = useState<Array<IMessage>>([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState<boolean>(false);

  // Used to track if first call has been made, to avoid repeating it.
  const [loadedInitialState, setLoadedInitialState] = useState<boolean>(false);

  const { user } = useWithUser();

  const fetchMessages = useCallback(async () => {
    if (!chatId) {
      return;
    }

    setIsFetchingMessages(true);

    const { data, success } = await messageList({ chatId });

    if (success && data) {
      setNextUrl(data.next);
      setMessages((prevMessages) => [...prevMessages, ...data.results]);
    } else {
      toast('Something in the Chat went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setLoadedInitialState(true);
    setIsFetchingMessages(false);
  }, [chatId]);

  const loadMoreMessages = useCallback(async () => {
    if (!nextUrl) {
      return;
    }

    setIsFetchingMessages(true);

    const { data, success } = await nextMessagesList({ nextUrl });

    if (success && data) {
      setNextUrl(data.next);
      setMessages((prevMessages) => [...prevMessages, ...data.results]);
    } else {
      toast('Something in the Chat went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setLoadedInitialState(true);
    setIsFetchingMessages(false);
  }, [nextUrl]);

  const addMessage = useCallback(
    (message: IMessage) => {
      if (chatId === message.chat.id) {
        setMessages((prevMessages) => {
          // Make sure there aren't any duplicates.
          const messages = _.uniqBy([message, ...prevMessages], 'id');

          // If the new message is from the viewing user - mark all the previous messages as "seen".
          if (user?.id === message.author.id) {
            return messages.map((message) => ({ ...message, is_seen: true }));
          }

          return messages;
        });
      }
    },
    [chatId, user?.id]
  );

  const markMessagesSeen = useCallback(() => {
    const unreadMessages = _.filter(messages, (message) => !message.is_seen);

    if (chatId && !_.isEmpty(unreadMessages)) {
      markUnreadMessagesSeen({ chatId });

      setMessages((prevMessages) =>
        _.map(prevMessages, (message) => ({ ...message, is_seen: true }))
      );
    }
  }, [messages, chatId]);

  useEffect(() => {
    if (loadedInitialState || isFetchingMessages) {
      return;
    }

    fetchMessages();
  }, [fetchMessages, isFetchingMessages, loadedInitialState]);

  useEffect(() => {
    setMessages([]);
    setLoadedInitialState(false);
  }, [chatId]);

  return {
    id: chatId,
    messages,
    addMessage,
    loadMoreMessages,
    markMessagesSeen,
    isFetchingMessages,
    hasMoreMessages: !_.isNil(nextUrl)
  };
};

export default useChat;
