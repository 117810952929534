import styled from 'styled-components';

import { breakpoints, colors } from 'styles/global';

import { Label, SessionParticipants, Button, File, Rating } from 'components';

import { SponsorBoxWithBrochure } from './components';

export const Container = styled.div`
  padding-top: 35px;
  padding-bottom: 115px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 546px 1fr 203px;
  grid-template-areas:
    'host . buttonAndDownloads'
    'participants . buttonAndDownloads'
    'description . buttonAndDownloads'
    'sponsors . buttonAndDownloads'
    'rating . buttonAndDownloads';

  @media ${breakpoints.desktop} {
    grid-gap: 0 20px;
    grid-template-columns: minmax(365px, 3fr) 1fr 200px;
    grid-template-areas:
      'host . buttonAndDownloads'
      'participants . buttonAndDownloads'
      'description . buttonAndDownloads'
      'sponsors . buttonAndDownloads'
      'rating . buttonAndDownloads';
  }

  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 200px;
    grid-template-areas: 'host buttonAndDownloads' 'participants buttonAndDownloads' 'description description' 'sponsors sponsors' 'rating rating';
  }

  @media ${breakpoints.mobile} {
    grid-gap: 25px;
    grid-template-columns: unset;
    grid-template-areas: 'host' 'buttonAndDownloads' 'participants' 'description' 'sponsors' 'rating';
  }
`;

export const HostContainer = styled.div`
  grid-area: host;
  display: flex;
`;

export const HostLabel = styled(Label)`
  margin-top: 9px;
  margin-left: 9px;
`;

export const StyledSessionParticipants = styled(SessionParticipants)`
  grid-area: participants;

  margin-top: 18px;

  @media ${breakpoints.mobile} {
    margin-top: unset;
  }
`;

export const Description = styled.div`
  grid-area: description;
  margin-top: 29px;

  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;

  @media ${breakpoints.mobile} {
    margin-top: unset;
  }
`;

export const SponsorsContainer = styled.div`
  grid-area: sponsors;
  margin-top: 33px;
`;

export const Sponsors = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-right: -25px; // to negate the right margin of the logos
  margin-bottom: -30px; // to negate the bottom margin of the last row of sponsor logos
`;

export const StyledSponsorBoxWithBrochure = styled(SponsorBoxWithBrochure)`
  margin-right: 25px;
  margin-bottom: 30px;
`;

export const StyledRating = styled(Rating)`
  grid-area: rating;

  margin-top: 36px;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  color: ${colors.text[2]};
`;

export const ButtonAndDownloads = styled.div`
  grid-area: buttonAndDownloads;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 28px;

  @media ${breakpoints.mobile} {
    margin-bottom: 20px;
  }
`;

export const VisibleOnTabletAndBelow = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const Downloads = styled.div``;

export const StyledFile = styled(File)`
  display: block;
  margin-top: 13px;
`;
