import styled from 'styled-components';

import { darkBlue, breakpoints } from 'styles/global';

export const Container = styled.div`
  overflow-y: auto;
  box-sizing: border-box;
  padding: 25px 55px 109px 53px;

  @media ${breakpoints.tablet} {
    padding: 25px 15px 60px 15px;
  }
`;

export const Title = styled.h4`
  margin-top: 28px;
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: ${darkBlue};
`;
