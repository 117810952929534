import { Dispatch, SetStateAction, ComponentType } from 'react';

import { openInNewTab } from 'libs/common';
import { FAQ_URL } from 'libs/constants';
import type { ISessionSpeakerDetailSession } from 'libs/types';

import { Tab, Tabs, Title, Description, FakeTab } from './styles';

interface IComponentProps {
  session: ISessionSpeakerDetailSession;
  setSession: (session: ISessionSpeakerDetailSession) => void;
}

export interface ITab {
  name: string;
  component: ComponentType<IComponentProps>;
  title: string;
  description: string;
}

interface IProps {
  tabs: Array<ITab>;
  activeTab: ITab;
  setActiveTab: Dispatch<SetStateAction<ITab>>;
  activeComponentProps: IComponentProps;
  className?: string;
}

const TabView = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  activeComponentProps
}: IProps) => {
  const ActiveComponent = activeTab.component;

  return (
    <div className={className}>
      <Tabs>
        {tabs.map((tab) => (
          <Tab
            onClick={() => setActiveTab(tab)}
            active={activeTab === tab}
            key={`tab-${tab.name}`}>
            {tab.name}
          </Tab>
        ))}
        {/* Here are the tabs that don't have components but rather a URL */}
        <Tab onClick={() => openInNewTab(FAQ_URL)} active={false}>
          FAQs
        </Tab>
        {/* The fake tab is used for the border to continue even where there arent any tabs */}
        <FakeTab active={false} />
      </Tabs>
      <Title>{activeTab.title}</Title>
      <Description>{activeTab.description}</Description>
      <ActiveComponent {...activeComponentProps} />
    </div>
  );
};

export default TabView;
