import _ from 'lodash';
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { IResponse, PaginatedList } from 'libs/types';

interface IProps<IObject> {
  initialData: Array<IObject>;
  initialNextUrl: string | null;
  fetchNext: (arg0: {
    nextUrl: string;
  }) => Promise<IResponse<PaginatedList<IObject>>>;
  initialCount: number;
}

const usePagination = <IObject,>({
  initialData,
  initialNextUrl,
  fetchNext,
  initialCount
}: IProps<IObject>) => {
  const [data, setData] = useState(initialData);
  const [count, setCount] = useState(initialCount);
  const [nextUrl, setNextUrl] = useState(initialNextUrl);
  const [isFetching, setIsFetching] = useState(false);

  const fetchMoreData = useCallback(async () => {
    if (!nextUrl || isFetching) {
      return;
    }

    setIsFetching(true);

    const { data, success } = await fetchNext({ nextUrl });

    if (success && data) {
      setNextUrl(data.next);
      setCount(data.count);
      setData((prevData) => [...prevData, ...data.results]);
    } else {
      toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setIsFetching(false);
  }, [isFetching, nextUrl, fetchNext]);

  return {
    data,
    count,
    isFetching,
    fetchMoreData,
    hasMore: !_.isNil(nextUrl)
  };
};

export default usePagination;
