import _ from 'lodash';
import { ChangeEvent, KeyboardEvent, ClipboardEvent, useState } from 'react';

import {
  Container,
  Tag,
  Input,
  DeleteIconContainer,
  DeleteIcon
} from './styles';
import { turnTextIntoTags } from './utils';

interface IProps {
  value: Array<string>;
  addNewItem: (arg0: string) => void;
  removeItem: (arg0: string) => void;
  delimiters: Array<string>;
  className?: string;
  placeholder?: string;
}

const TagInput = ({
  className,
  value,
  delimiters,
  placeholder,
  addNewItem,
  removeItem
}: IProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && event.currentTarget.value === '') {
      // If the user is pressing "Backspace" and the input is empty we remove the most recent(last) tag.
      const lastItem = _.last(value);

      if (lastItem) {
        removeItem(lastItem);
      }
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedContent = event.clipboardData.getData('text');

    // We're preventing so that the paste doesn't actually work. If the user pastes we'll have to clear the input afterwards.
    event.preventDefault();

    const tags = turnTextIntoTags({ text: pastedContent, delimiters });

    tags.map((tag) => addNewItem(tag));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const lastSymbol = _.last(value);

    if (_.includes(delimiters, lastSymbol)) {
      const newTag = value.slice(0, value.length - 1);

      if (!_.isEmpty(newTag)) {
        addNewItem(newTag);
      }

      event.target.value = '';
    }
  };

  return (
    <Container className={className} focused={isInputFocused}>
      {value.map((item) => (
        <Tag key={item}>
          {item}
          <DeleteIconContainer onClick={() => removeItem(item)}>
            <DeleteIcon icon="times" />
          </DeleteIconContainer>
        </Tag>
      ))}

      <Input
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
    </Container>
  );
};
export default TagInput;
