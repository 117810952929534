import styled from 'styled-components';

import { blue, colors, breakpoints } from 'styles/global';

import { Icon } from 'components';

export const MagnifyingGlassIcon = styled(Icon)`
  cursor: pointer;
  font-size: 16px;
  color: ${colors.text[4]};
  transition: opacity 0.2s linear;
`;

export const Container = styled.div`
  height: 40px;
  min-width: 770px;

  opacity: 0.7;
  border-radius: 2px;

  display: flex;
  align-items: center;
  background-color: #ebecee;

  padding-right: 20px;
  box-sizing: border-box;
  transition: border 0.2s linear;

  border: 1px solid
    ${({ focused }: { focused: boolean }) => (focused ? blue : '#ebecee')};

  & > ${MagnifyingGlassIcon} {
    opacity: ${({ focused }: { focused: boolean }) => (focused ? 1 : 0.4)};
  }

  @media ${breakpoints.desktop} {
    min-width: 650px;
  }
`;

export const StyledInput = styled.input`
  border: 0;
  width: 100%;
  height: 100%;

  padding: 11px 20px;
  box-sizing: border-box;
  background-color: transparent;

  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[1]};

  &:focus {
    outline: 0;
  }

  &::placeholder {
    opacity: 0.4;
    color: ${colors.text[1]};
  }
`;
