import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon, UserLine } from 'components';

export const IconContainer = styled.div`
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ebecee;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.2;
  transition: opacity 0.3s ease;

  &:not(:first-of-type) {
    margin-left: 8px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 20px;
  box-sizing: border-box;

  flex-shrink: 0;
  cursor: pointer;
  background-color: #ebecee66;

  &:hover ${IconContainer} {
    opacity: 1;
  }
`;

export const Actions = styled.div`
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledIcon = styled(Icon)`
  font-size: 12px;
  color: ${colors.text[2]};
`;

export const StyledUserLine = styled(UserLine)`
  width: 100%;
  flex-grow: 1;
  max-width: calc(100% - 85px);
`;

export const StyledUserLinePlaceholder = styled(UserLine.Placeholder)`
  flex-grow: 1;
  max-width: calc(100% - 85px);
`;
