import _ from 'lodash';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { IResponse, PaginatedList } from 'libs/types';

interface IProps {
  initialFilters: {
    [key: string]: any;
  };
  initialData: Array<any>;
  initialNextUrl: string | null;
  fetch: (arg0: {
    params: {
      [key: string]: any;
    };
    context?: GetServerSidePropsContext;
  }) => Promise<IResponse<PaginatedList<any>>>;
  fetchNext: (arg0: {
    nextUrl: string;
  }) => Promise<IResponse<PaginatedList<any>>>;
  initialCount?: number;
  pageSize?: number;
}

const useFilters = ({
  initialFilters,
  initialData,
  initialNextUrl,
  fetch,
  fetchNext,
  initialCount,
  pageSize = 100
}: IProps) => {
  const [data, setData] = useState(initialData);
  const [count, setCount] = useState(initialCount);
  const [filters, setFilters] = useState(initialFilters);
  const [nextUrl, setNextUrl] = useState(initialNextUrl);
  const [isFetching, setIsFetching] = useState(false);

  const router = useRouter();

  const refetchData = useCallback(
    async ({ filters }) => {
      setData([]);
      setIsFetching(true);

      const { data, success } = await fetch({
        params: {
          limit: pageSize,
          ...filters
        }
      });

      if (success && data) {
        setData(data.results);
        setCount(data.count);
        setNextUrl(data.next);
      }

      setIsFetching(false);
    },
    [fetch, pageSize]
  );

  const fetchMoreData = useCallback(async () => {
    if (!nextUrl || isFetching) {
      return;
    }

    setIsFetching(true);

    const { data, success } = await fetchNext({ nextUrl });

    if (success && data) {
      setNextUrl(data.next);
      setCount(data.count);
      setData((prevData) => [...prevData, ...data.results]);
    } else {
      toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setIsFetching(false);
  }, [isFetching, nextUrl, fetchNext]);

  const updateFilterValue = ({ name, value }: { name: string; value: any }) => {
    // This method only updates the query string. There's an effect that listens for changes
    // in it and updates the state variables & triggers fetches.
    const newFiltersValue = { ...filters, [name]: value };

    router.push(
      `${router.route}?${queryString.stringify(newFiltersValue)}`,
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    // We need to set the new filter value and refetch the data
    // if the query in the URL is actually different from the filters we're storing.
    if (!_.isEqual(router.query, filters)) {
      setFilters(router.query);
      refetchData({ filters: router.query });
    }
  }, [filters, router.query, refetchData]);

  return {
    data,
    count,
    filters,
    isFetching,
    fetchMoreData,
    updateFilterValue,
    hasMore: !_.isNil(nextUrl)
  };
};

export default useFilters;
