import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { Label } from 'components';

export const Container = styled.div`
  background-color: white;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);

  box-sizing: border-box;
  padding: 12px 30px 24px 30px;

  margin-left: 70px;
  margin-bottom: 2px;

  cursor: not-allowed;

  @media ${breakpoints.mobile} {
    margin-top: 11px;
    margin-left: unset;
    margin-bottom: 9px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: ${colors.text[1]};

  margin-bottom: 17px;
`;

export const HostLine = styled.div`
  display: flex;
`;

export const HostLabel = styled(Label)`
  margin-top: 3px;
  margin-left: 8px;
`;
