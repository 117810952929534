import _ from 'lodash';
import { useEffect } from 'react';

import { enableBodyScroll, disableBodyScroll } from 'libs/common';
import { ISessionPurchaseFormSlot } from 'libs/types';

import { useWithEvent } from 'hooks';

import { BasketItem } from 'components/SessionPurchaseForm/components';

import {
  Container,
  Wrapper,
  Title,
  ItemCount,
  Header,
  CloseIcon,
  Content,
  Footer,
  SubmitButton,
  Total
} from './styles';

interface IProps {
  items: Array<ISessionPurchaseFormSlot>;
  isOpen: boolean;
  close: () => void;
  isSubmissionDisabled: boolean;
  slotFieldValueSetter: (arg0: Array<ISessionPurchaseFormSlot>) => void;
}

const BasketSidebar = ({
  items,
  isOpen,
  close,
  isSubmissionDisabled,
  slotFieldValueSetter
}: IProps) => {
  const { event } = useWithEvent();
  const dataPackagePrice = event?.data_package_price;

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();

      return enableBodyScroll;
    }
  }, [isOpen]);

  const totalSum = _.sumBy(items, 'price');

  const itemsWithDataPackage = items.filter((item) => item.with_data_package);
  const totalSumWithDataPackages = dataPackagePrice
    ? totalSum + itemsWithDataPackage.length * parseFloat(dataPackagePrice)
    : 0;

  const removeSlot = ({ slotId }: { slotId: number }) => {
    const newItems = items.filter((item) => item.id !== slotId);

    slotFieldValueSetter(newItems);
  };

  const toggleDataPackage = ({ slotId }: { slotId: number }) => {
    const newItems = items.map((item) =>
      item.id === slotId
        ? { ...item, with_data_package: !item.with_data_package }
        : item
    );

    slotFieldValueSetter(newItems);
  };

  return (
    <Wrapper visible={isOpen} onClick={close}>
      {/* we use stop propagation in order to now close the basket on Container click */}
      <Container visible={isOpen} onClick={(e) => e.stopPropagation()}>
        <Header>
          <div>
            <Title>Your Basket</Title>
            <ItemCount>
              {items.length} Item{items.length === 1 ? '' : 's'}
            </ItemCount>
          </div>
          <CloseIcon icon="times" onClick={close} />
        </Header>

        <Content>
          {items.map((item) => (
            <BasketItem
              slot={item}
              key={`basket-item-for-session-${item.id}`}
              removeSlot={() => removeSlot({ slotId: item.id })}
              toggleDataPackage={() => toggleDataPackage({ slotId: item.id })}
            />
          ))}
        </Content>

        <Footer>
          <Total>
            Checkout Total:
            <span>£{totalSumWithDataPackages}</span>
          </Total>
          <SubmitButton
            type="submit"
            fontSize={15}
            fontWeight={600}
            colorScheme={1}
            height="tall"
            onClick={close}
            disabled={isSubmissionDisabled}>
            Checkout
          </SubmitButton>
        </Footer>
      </Container>
    </Wrapper>
  );
};

export default BasketSidebar;
