import _ from 'lodash';

import {
  Container,
  Title,
  Star,
  StarPiece,
  StarContainer,
  RatingCount
} from './styles';

interface IProps {
  className?: string;
  withTitle?: boolean;
  ratingCount?: number;
  starFontSize?: string;
  withReducedSpacingBetweenStars?: boolean;
  rating: number | null;
}

const AverageRating = ({
  rating,
  ratingCount,
  className,
  starFontSize = '20px',
  withTitle = false,
  withReducedSpacingBetweenStars = false
}: IProps) => (
  <Container className={className}>
    {withTitle && <Title>Average Session Rating</Title>}
    <StarContainer>
      {_.range(1, 6).map((index) => {
        const isFirstHalfActive = !_.isNil(rating) && rating >= index - 0.5;
        const isSecondHalfActive = !_.isNil(rating) && rating >= index;

        const firstHalfColor = isFirstHalfActive ? '#ffc22b' : '#ebecee';
        const secondHalfColor = isSecondHalfActive ? '#ffc22b' : '#ebecee';

        return (
          <Star key={index} reducedLeftMargin={withReducedSpacingBetweenStars}>
            {/* every star is made of 2 halves */}
            <StarPiece
              icon="star-half"
              color={firstHalfColor}
              starFontSize={starFontSize}
            />
            <StarPiece
              icon="star"
              color={secondHalfColor}
              starFontSize={starFontSize}
            />
          </Star>
        );
      })}
    </StarContainer>
    {ratingCount !== undefined && (
      <RatingCount>
        {ratingCount} rating{ratingCount === 1 ? '' : 's'}
      </RatingCount>
    )}
  </Container>
);

export default AverageRating;
