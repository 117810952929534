import { useContext } from 'react';

import { AppContext } from 'pages/_app';

const useWithEvent = () => {
  const { event } = useContext(AppContext);

  return { event };
};

export default useWithEvent;
