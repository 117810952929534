import styled from 'styled-components';

import { red } from 'styles/global';

import { Icon } from 'components';

export const ErrorMessageContainer = styled.div`
  color: ${red};
  font-size: 12px;
  line-height: 16px;

  margin-top: 6px;
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 4.5px;

  font-size: 11px;
`;
