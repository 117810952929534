import streamingPlatformsImage from './assets/logos.png';
import {
  StyledContainer,
  Title,
  Description,
  StyledImage,
  StreamingPlatforms,
  StreamingPlatformsTitle,
  StreamingPlatformsAddition
} from './styles';

const SessionPurchaseHeader = () => (
  <StyledContainer>
    <div>
      <Title>Book Session</Title>
      <Description>
        Choose the channel that is most appropriate for your content and book
        your session immediately. Create any type of content that you want.
        Stream it live or pre-record and upload - the choice is yours!
      </Description>
    </div>

    <StreamingPlatforms>
      <StreamingPlatformsTitle>
        Stream Using Any Tool of Your Choice
      </StreamingPlatformsTitle>
      <StyledImage src={streamingPlatformsImage} width={515.89} height={98.9} />
      <StreamingPlatformsAddition>and more!</StreamingPlatformsAddition>
    </StreamingPlatforms>
  </StyledContainer>
);

export default SessionPurchaseHeader;
