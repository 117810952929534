import _ from 'lodash';

import { applyFormattingToDateTime } from 'libs/datetime';
import { IMeetingSlotsRetrieveSlot } from 'libs/types';

export const groupSlotsByDate = (
  slots: Array<IMeetingSlotsRetrieveSlot>
): {
  [key: string]: Array<IMeetingSlotsRetrieveSlot>;
} =>
  _.groupBy(slots, (slot) =>
    applyFormattingToDateTime({
      date: slot.start,
      toFormat: 'dddd, DD MMMM YYYY'
    })
  );
