import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoints.mobile} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Time = styled.div`
  min-width: 70px;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text[1]};

  @media ${breakpoints.mobile} {
    margin-bottom: 8px;
  }
`;

export const Divider = styled.div`
  height: 0;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px dashed rgba(123, 124, 129, 0.1);
`;
