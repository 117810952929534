import styled from 'styled-components';

import {
  red,
  colors,
  lightBlue,
  lightBlueGrey,
  breakpoints
} from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon, Button, FieldError, UserLine } from 'components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  position: fixed;
  background-color: #30596e99;
  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.5s ease;

  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};
  backdrop-filter: ${({ visible }: { visible: boolean }) =>
    visible ? 'blur(2px)' : 'blur(0px)'};
  animation: ${({ visible }: { visible: boolean }) =>
      visible ? 'fadeIn' : 'fadeOut'}
    0.5s ease;
  opacity: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)};
  display: ${({ visible }: { visible: boolean }) =>
    visible ? 'flex' : 'none'};

  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: flex;
      backdrop-filter: blur(0px);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      display: flex;
      visibility: visible;
    }
    99% {
      display: flex;
      visibility: visible;
    }
    100% {
      display: none;
      visibility: hidden;
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 472px;
  max-width: 100vw;
  max-height: 745px;
  overflow-y: auto;

  border-radius: 2px;
  background-color: white;
  border: 1px solid rgba(123, 124, 129, 0.2);

  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  position: relative;

  display: flex;
  flex-direction: column;

  transition: transform 0.5s ease;
  transform: ${({ visible }: { visible: boolean }) =>
    visible ? 'scale(1)' : 'scale(0)'};

  @media ${breakpoints.tablet} {
    max-height: calc(100vh - 230px);
  }
`;

export const Header = styled.div`
  width: 100%;

  box-sizing: border-box;
  padding: 19px 40px 7px 40px;
  background-color: white;
`;

export const Title = styled.h4`
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: ${colors.text[4]};

  margin-top: -2px;
  margin-bottom: 27px;
`;

export const ParticipantLineContainer = styled.div`
  border-radius: 2px;
  background-color: ${lightBlueGrey};

  padding: 26px 10px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ParticipantLine = styled(UserLine)`
  width: 100%;
  justify-content: center;
`;

export const Close = styled(Icon)`
  cursor: pointer;
  font-size: 16px;

  z-index: 2;

  top: 10px;
  right: 13px;
  position: absolute;
`;

export const NoSlots = styled.div`
  color: ${red};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  flex-grow: 1;
  max-width: 260px;
  margin-top: 11px;
  align-self: center;
`;

export const SlotGroupHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: ${colors.text[1]};

  box-sizing: border-box;
  padding: 18px 10px 27px 10px;

  background-color: white;

  top: 0;
  position: sticky;
`;

export const SlotContainer = styled.div`
  display: grid;
  grid-gap: 10px 11px;
  grid-template-columns: repeat(3, 1fr);

  flex-grow: 1;
  padding: 0 40px;
  box-sizing: border-box;
`;

export const Slot = styled.div`
  font-size: 15px;
  line-height: 18px;

  height: 38px;
  cursor: pointer;
  user-select: none;
  border-radius: 4.5px;
  transition: all 0.1s linear;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ active }: { active: boolean; withRedBorder: boolean }) =>
    active ? 'white' : colors.text[4]};
  background-color: ${({
    active,
    theme
  }: {
    active: boolean;
    theme: ITheme;
    withRedBorder: boolean;
  }) => (active ? theme.primaryColor : lightBlue)};
  border: 1px solid
    ${({
      withRedBorder
    }: {
      active: boolean;
      theme: ITheme;
      withRedBorder: boolean;
    }) =>
      withRedBorder ? 'rgba(255, 61, 58, 0.4)' : 'rgba(123, 124, 129, 0.2)'};
`;

export const Spacer = styled.div`
  padding-top: 100px;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 98px;

  left: 0;
  bottom: 0;
  position: sticky;

  box-sizing: border-box;
  padding: 16px 40px 30px 40px;

  background-color: white;

  display: flex;
  flex-direction: column;
`;

export const Warning = styled.p`
  color: #fc9402;
  font-size: 13px;
  line-height: 16px;

  text-align: right;
  margin-top: -10px;
  margin-bottom: 14px;
`;

export const StyledFieldError = styled(FieldError)`
  font-size: 13px;
  line-height: 16px;

  margin-top: -10px;
  margin-bottom: 14px;
  align-self: flex-end;
`;

export const Submit = styled(Button)`
  width: 100%;
`;
