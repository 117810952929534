import Linkifier from 'react-linkify';

import { StyledLink } from './styles';

interface IProps {
  children: string;
  [key: string]: any;
}

const Linkify = (props: IProps) => <Linkifier {...props} />;

const ComponentDecorator = (decoratedHref: string, decoratedText: string) => (
  <StyledLink external href={decoratedHref} key={decoratedHref}>
    {decoratedText}
  </StyledLink>
);

Linkify.defaultProps = {
  // We use this instead of properties because the "properties" prop is no longer supported.
  // https://github.com/tasti/react-linkify/issues/96#issuecomment-628472955
  componentDecorator: ComponentDecorator
};

export default Linkify;
