import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import {
  signSpeakerSessionAnalytics,
  enquireSpeakerSessionAnalytics
} from 'libs/requests';
import { IHolisticsSign, ISessionSpeakerDetailSession } from 'libs/types';

import { HolisticsEmbed } from 'components';

import {
  Title,
  Description,
  Container,
  EnquiryPrompt,
  EnquiryPromptContainer,
  EnquiryPromptWrapper,
  StyledIcon,
  DescriptionPoints,
  EnquireButton
} from './styles';

interface IProps {
  session: ISessionSpeakerDetailSession;
}

const Data = ({ session }: IProps) => {
  const [holisticsSign, setHolisticsSign] = useState<
    IHolisticsSign | undefined
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [enquirySent, setEnquirySent] = useState(false);

  const signHolisticsEmbed = useCallback(async () => {
    setIsFetching(true);

    const { success, data } = await signSpeakerSessionAnalytics({
      sessionId: session.id
    });

    if (success) {
      setHolisticsSign(data);
    } else {
      toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setIsFetching(false);
  }, [session.id]);

  const enquiryAnalytics = async () => {
    if (enquirySent) {
      return;
    }

    const { success } = await enquireSpeakerSessionAnalytics({
      sessionId: session.id
    });

    if (!success) {
      toast('Something went wrong. Please try again later.', {
        type: 'error'
      });
    }

    setEnquirySent(success);
  };

  useEffect(() => {
    if (session.speaker_has_data_access) {
      signHolisticsEmbed();
    }
  }, [session, signHolisticsEmbed]);

  return (
    <Container>
      {!session.speaker_has_data_access && (
        <EnquiryPromptWrapper>
          <EnquiryPromptContainer>
            <EnquiryPrompt>
              <StyledIcon icon="lock" />
              <Title>This content is only available with a Data Package</Title>
              <Description>Data you will have access to:</Description>

              <DescriptionPoints>
                <span>Profile Links to all User Impressions</span>
                <span>User Type Breakdown</span>
                <span>Total Impressions</span>
                <span>Job Position Breakdown</span>
                <span>Unique Impressions</span>
                <span>Country Breakdown</span>
                <span>Sector Breakdown</span>
                <span>Session Ratings</span>
              </DescriptionPoints>

              <EnquireButton
                colorScheme={1}
                height="medium"
                fontSize={16}
                fontWeight={500}
                onClick={enquiryAnalytics}
                icon={enquirySent ? 'check' : undefined}>
                {enquirySent ? 'Enquiry Sent' : 'Enquire Now'}
              </EnquireButton>
            </EnquiryPrompt>
          </EnquiryPromptContainer>
        </EnquiryPromptWrapper>
      )}

      {session.speaker_has_data_access && isFetching && (
        <Skeleton height={800} />
      )}

      {session.speaker_has_data_access && !isFetching && holisticsSign && (
        <HolisticsEmbed
          embedCode={holisticsSign.embed_code}
          token={holisticsSign.token}
        />
      )}
    </Container>
  );
};

export default Data;
