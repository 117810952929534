import styled from 'styled-components';

import { colors } from 'styles/global';

import { Icon } from 'components';

export const Container = styled.div`
  position: relative;

  &:before {
    content: '';

    height: 100%;
    width: ${({ withOverlay }: { withOverlay: boolean }) =>
      withOverlay ? '100%' : '0'};

    top: 0;
    left: 0;
    z-index: 2;
    position: absolute;

    opacity: 0.4;
    cursor: not-allowed;
    background-color: white;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  line-height: 32px;
  color: ${colors.text[2]};

  margin-bottom: 12px;
`;

export const Star = styled(Icon)`
  font-size: 26px;
  color: ${({ color }: { color: string }) => color};

  cursor: pointer;
  transition: color 0.2s linear;

  &:hover,
  // icons are svg
  &:hover ~ svg {
    color: #ffc22b !important;
  }

  &:not(:first-of-type) {
    margin-right: 13px;
  }
`;

export const Stars = styled.div`
  height: 0; // we do this so that we won't reset the color between the stars
  overflow: visible;
  display: inline-flex;
  flex-direction: row-reverse;

  &:hover > ${Star} {
    color: #ebecee; // this way when the user selects new rating his old one is not visible
  }
`;
