import styled from 'styled-components';

import { colors } from 'styles/global';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h5`
  margin-top: 27px;
  margin-bottom: 1px;

  font-size: 28px;
  font-weight: 500;
  line-height: 42px;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: ${colors.text[2]};
`;
