import { withFormik, FormikProps } from 'formik';
import { FunctionComponent } from 'react';

import type { ISessionSpeakerDetailSession } from 'libs/types';

import { CopyInput, FormLabel } from 'components';

import {
  StyledForm,
  FieldsContainer,
  Description,
  PrerecordedVideoDisclaimer
} from './styles';

interface IProps {
  session: ISessionSpeakerDetailSession;
  setSession: (session: ISessionSpeakerDetailSession) => void;
}

interface IFormValues {
  streaming_key: string;
  streaming_server_url: string;
}

const StreamKeys: FunctionComponent<IProps & FormikProps<IFormValues>> = ({
  session
}) => (
  <StyledForm>
    <FormLabel>Streaming Keys</FormLabel>
    <FieldsContainer>
      <CopyInput disabled name="streaming_server_url" label="Streaming URL" />
      <CopyInput disabled name="streaming_key" label="Streaming Key" />
      {session.session_type === 'Prerecorded' && (
        <PrerecordedVideoDisclaimer>
          You have already uploaded content to stream
        </PrerecordedVideoDisclaimer>
      )}
    </FieldsContainer>
    <Description>
      Once you have made a copy of your unique key, open your preferred
      streaming host and paste it there.
    </Description>
  </StyledForm>
);

export default withFormik<IProps, IFormValues>({
  enableReinitialize: true,

  mapPropsToValues: ({ session: { streaming_server_url, streaming_key } }) => ({
    streaming_server_url,
    streaming_key
  }),

  handleSubmit: async () => {
    // this form does not have a submit
  }
})(StreamKeys);
