import styled from 'styled-components';

import { colors } from 'styles/global';

import { ITheme } from 'libs/types';

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 30px;

  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.div`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: ${colors.text[1]};

  cursor: pointer;
  padding: 0 11px 8px 11px;
  font-weight: ${({ active }: { active: boolean; theme: ITheme }) =>
    active ? 500 : 'normal'};
  border-bottom: 2px solid
    ${({ active, theme }: { active: boolean; theme: ITheme }) =>
      active ? theme.primaryColor : 'rgba(123, 124, 129, 0.2)'};

  transition: all 0.3s linear;
`;

export const FakeTab = styled(Tab)`
  // we use this tab for the bottom border of the whole Tabs section
  width: 100%;
  cursor: initial;

  padding-left: 0;
  padding-right: 0;
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.text[2]};

  width: 100%;
  max-width: 630px;

  margin-top: 3px;
  margin-bottom: 26px;
`;
