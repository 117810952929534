import styled from 'styled-components';

import { breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Image } from 'components';

export const Card = styled.div`
  display: flex;
  cursor: pointer;
  background: white;
  border: 1px solid rgba(123, 124, 129, 0.2);
  border-radius: 2px;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.05));

  height: 100%;
  transition: border-color 0.2s linear;

  &:hover {
    border-color: ${({ theme }: { theme: ITheme }) =>
      theme.primaryColor}99; // 60% opacity
  }
`;

export const StyledImage = styled(Image)`
  @media ${breakpoints.desktop} {
    height: 149px;
    width: 43px;
  }
`;

export const Content = styled.div`
  width: 100%;
  flex-grow: grow;
  box-sizing: border-box;
  padding: 20px 10px 10px 15px;

  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 10px;

  @media ${breakpoints.desktop} {
<    font-size: 12px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;

  @media ${breakpoints.desktop} {
    font-size: 16px;
    line-height: 23px;
  }
`;

export const SponsorLogoContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;
`;
