import styled from 'styled-components';

import { colors, breakpoints, red } from 'styles/global';

import { ITheme } from 'libs/types';

import { LoginPrompt } from 'components';

import loginPromptBackground from './assets/unlooged_user_placeholder_background.png';
import { TicketPurchasePrompt } from './components';

export const Container = styled.div`
  width: 100%;
  height: 694px;
  overflow-y: auto;
  box-sizing: border-box;

  background-color: white;
  border: 1px solid rgba(123, 124, 129, 0.2);

  display: flex;
  flex-direction: column;

  position: relative;

  @media ${breakpoints.tablet} {
    left: 0;
    top: 55px; // as much as navigation
    border-radius: 0 0 4px 4px;

    height: ${({ visible }: { visible?: boolean }) =>
      visible
        ? 'calc(100% - 105px)' // 55px go for the navigation and 50px for the ads on the bottom
        : 0};
    max-height: ${({ visible }: { visible?: boolean }) =>
      visible ? '100%' : 0};
    position: ${({ visible }: { visible?: boolean }) =>
      visible ? 'fixed' : 'static'};
    visibility: ${({ visible }: { visible?: boolean }) =>
      visible ? 'visible' : 'hidden'};

    transition: height 0.5s linear;
    animation: ${({ visible }: { visible?: boolean }) =>
        visible ? 'slideDown' : 'slideUp'}
      0.5s linear;

    @keyframes slideDown {
      0% {
        max-height: 0;
        position: fixed;
        visibility: visible;
      }

      100% {
        position: fixed;
        visibility: visible;
        max-height: 100%;
      }
    }

    @keyframes slideUp {
      0% {
        max-height: 100%;
        position: fixed;
        visibility: visible;
      }

      99% {
        position: fixed;
        visibility: visible;
      }

      100% {
        max-height: 0;
        position: static;
        visibility: hidden;
      }
    }
  }
`;

export const Tabs = styled.div`
  width: 100%;
  height: 55px;
  min-height: 55px;
  background-color: white;
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  top: 0;
  z-index: 2;
  position: sticky;

  display: flex;
`;

export const TabWrapper = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;

  height: 100%;
  flex-basis: 33%;
  padding-top: 17px;
  box-sizing: border-box;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: ${colors.text[1]};

  transition: border-color 0.5s ease;
  border-bottom: 2px solid
    ${({
      active,
      theme
    }: {
      active: boolean;
      disabled: boolean;
      theme: ITheme;
    }) => (active ? theme.primaryColor : 'transparent')};

  opacity: ${({ disabled }: { active: boolean; disabled: boolean }) =>
    disabled ? 0.2 : 1};
`;

export const Tab = styled.div`
  position: relative;
`;

export const UnreadMessagesCount = styled.div`
  width: 20px;
  height: 20px;
  padding-bottom: 1px;
  box-sizing: border-box;

  top: -5px;
  right: -23.5px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid white;
  visibility: ${({ visible }: { visible: boolean }) =>
    visible ? 'visible' : 'hidden'};

  color: white;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  border-radius: 50%;
  background-color: ${red};

  transform: scale(${({ visible }: { visible: boolean }) => (visible ? 1 : 0)});
  animation: ${({ visible }: { visible: boolean }) =>
      visible ? 'pop' : 'disappear'}
    0.5s linear;

  @keyframes pop {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes disappear {
    0%,
    99% {
      font-size: 0; // this is in order to hide the text(in this case - a zero)
      visibility: visible;
    }

    100% {
      visibility: hidden;
      transform: scale(0);
    }
  }
`;

export const PromptWrapper = styled.div`
  // animation variables
  // 441 is the height of the image we're using as a background
  --offset: calc(441px / 8);

  width: 100%;
  height: calc(100% - 55px); // removing 55px for the tabs

  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${loginPromptBackground}) repeat-y;
  background-position-x: center;

  animation: infiniteScroll 10s linear infinite;

  position: relative;

  &:before {
    // backdrop
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;

    opacity: 0.8;
    background-color: white;
  }

  @keyframes infiniteScroll {
    0%,
    6.25% {
      background-position-y: calc(100% - calc(var(--offset) * 0));
    }

    12.5%,
    18.75% {
      background-position-y: calc(100% - calc(var(--offset) * 1));
    }

    25%,
    31.25% {
      background-position-y: calc(100% - calc(var(--offset) * 2));
    }

    37.5%,
    43.75% {
      background-position-y: calc(100% - calc(var(--offset) * 3));
    }

    50%,
    56.25% {
      background-position-y: calc(100% - calc(var(--offset) * 4));
    }

    62.5%,
    68.75% {
      background-position-y: calc(100% - calc(var(--offset) * 5));
    }

    75%,
    81.25% {
      background-position-y: calc(100% - calc(var(--offset) * 6));
    }

    87.5%,
    93.75% {
      background-position-y: calc(100% - calc(var(--offset) * 7));
    }

    100% {
      background-position-y: calc(100% - calc(var(--offset) * 8));
    }
  }
`;

export const StyledLoginPrompt = styled(LoginPrompt)`
  z-index: 2;
  max-width: 250px;

  padding: 31px;
  box-sizing: border-box;

  border-radius: 2px;
  background: #30596e33;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);
`;

export const StyledTicketPurchasePrompt = styled(TicketPurchasePrompt)`
  z-index: 2;
`;

export const MobileChatToggle = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    width: ${({ isSmall }: { isSmall: boolean }) =>
      isSmall ? '30px' : '58px'};
    height: ${({ isSmall }: { isSmall: boolean }) =>
      isSmall ? '30px' : '58px'};
    margin-bottom: unset;

    background: white;
    border-radius: 50%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
    left: 20px;
    bottom: 60px;
    position: fixed;
    visibility: visible;

    color: ${colors.text[3]};
    font-size: ${({ isSmall }: { isSmall: boolean }) =>
      isSmall ? '15px' : '21px'};

    transition: all 0.3s ease;

    animation: slideIn 0.5s ease;

    @keyframes slideIn {
      0% {
        left: -60px;
      }

      100% {
        left: 20px;
      }
    }
  }
`;

export const MobileChatToggleUnreadMessagesCount = styled(UnreadMessagesCount)`
  top: 0;
  right: 0px;
`;
