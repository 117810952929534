import { useField } from 'formik';
import _ from 'lodash';

import { ISelectOption } from 'libs/types';

import { FieldError } from 'components';

import { StyledSelect, StyledLabel } from './styles';

export interface IProps {
  name: string;
  onChange: (value: any) => void;
  options: Array<ISelectOption>;
  label?: string;
  isMulti?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

const Select = ({
  label,
  disabled: isDisabled,
  className,
  onChange,
  ...props
}: IProps) => {
  const [field, , helpers] = useField(props);

  const value = _.find(props.options, (option) => option.value === field.value);

  return (
    <div className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        {...field}
        {...props}
        value={value || null} // if value changes from not-undefined to undefined it does not change in the select itself
        isDisabled={isDisabled}
        onChange={({ value: selectedOption }: ISelectOption) => {
          helpers.setValue(selectedOption);
          onChange(selectedOption);
        }}
      />
      <FieldError name={props.name} />
    </div>
  );
};

Select.defaultProps = {
  isMulti: false,
  onChange: (value: any) => {
    return value;
  }
};

export default Select;
