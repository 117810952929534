import { ESessionStatuses } from 'libs/enums';
import { trackSponsorBrochureDownload } from 'libs/requests';
import { ISessionDetailSession } from 'libs/types';

import { useWithEvent } from 'hooks';

import { TimeWithLiveIndicator, Link } from 'components';

import {
  Container,
  Title,
  Left,
  Right,
  StyledBreadcrumbs,
  BrochureLink,
  SponsorLogo
} from './styles';
import { getBreadcrumbs } from './utils';

interface IProps {
  className?: string;
  session: ISessionDetailSession;
}

const SessionHeader = ({ className, session }: IProps) => {
  const { event } = useWithEvent();

  const breadcrumbs = getBreadcrumbs({
    session,
    eventSlug: event?.slug,
    eventName: event?.name
  });
  const sponsor = session.main_sponsor;

  return (
    <Container className={className}>
      <Left>
        <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
        <TimeWithLiveIndicator
          start={session.start}
          end={session.end}
          isPast={
            session.status === ESessionStatuses.COMPLETED ||
            session.status === ESessionStatuses.NOT_COMPLETED
          }
          showIndicator={session.status === ESessionStatuses.LIVE}
        />
        <Title>{session.title || 'Session Reserved'}</Title>
      </Left>

      <Right>
        {sponsor && (
          <Link external href={sponsor.url}>
            <SponsorLogo height={35} src={sponsor.light_background_logo} />
          </Link>
        )}
        {sponsor?.brochure && (
          <BrochureLink
            extraOnClickAction={() =>
              trackSponsorBrochureDownload({ sponsorId: sponsor.id })
            }
            href={sponsor.brochure}
            filename="Sponsor Brochure"
          />
        )}
      </Right>
    </Container>
  );
};

export default SessionHeader;
