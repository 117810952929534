import {
  FAQ_URL,
  HOME_URL,
  PRIVACY_POLICY_URL,
  UNISSU_FE_URL,
  TERMS_AND_CONDITIONS_URL
} from 'libs/constants';

import { UnissuLogo } from 'components';
import { Link } from 'components';

import {
  MediumLink,
  Wrapper,
  DarkLink,
  StyledContainer,
  Right,
  ArrowIcon,
  TopRight,
  BottomRight,
  SmallLink
} from './styles';

const Footer = () => (
  <Wrapper>
    <StyledContainer>
      <Link href={HOME_URL}>
        <UnissuLogo height={25.17} width={99} />
      </Link>

      <Right>
        <TopRight>
          <DarkLink href={HOME_URL}>Events</DarkLink>
          {/* TODO: Add this link once the Schedule becomes global */}
          {/* <DarkLink href={SCHEDULE_URL}>My Schedule</DarkLink> */}
          <DarkLink external href={FAQ_URL}>
            FAQs
          </DarkLink>
          <MediumLink external href={UNISSU_FE_URL}>
            unissu.com
            <ArrowIcon icon="long-arrow-alt-right" />
          </MediumLink>
        </TopRight>

        <BottomRight>
          <SmallLink external href={PRIVACY_POLICY_URL}>
            Privacy Policy
          </SmallLink>
          <SmallLink external href={TERMS_AND_CONDITIONS_URL}>
            Terms & Conditions
          </SmallLink>
        </BottomRight>
      </Right>
    </StyledContainer>
  </Wrapper>
);

export default Footer;
