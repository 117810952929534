import { useField } from 'formik';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';

import { IFile } from 'libs/types';

import {
  FieldError,
  File as FileLine //to avoid confusion with the File interface
} from 'components';

import {
  Root,
  StyledLabel,
  Content,
  UploadButton,
  FileContainer,
  StyledIcon,
  Description
} from './styles';

interface IProps {
  name: string;
  multiple: boolean;
  label?: string;
  description?: string;
  urlFieldName?: string;
  [key: string]: any;
}

const Dropzone = ({
  label,
  description,
  urlFieldName = 'url',
  ...props
}: IProps) => {
  // Errors for this field must be in external validation schema.
  const [field, , { setTouched, setValue }] = useField(props);
  let files = field.value; // this is not an iterable if "multiple" prop is false

  const { multiple } = props;

  const addFile = (acceptedFiles: Array<File | IFile>) => {
    setTouched(true);
    if (multiple) {
      setValue([...files, ...acceptedFiles], false);
    } else {
      setValue(acceptedFiles[0]);
    }
  };

  const removeFile = (fileToRemove: File | IFile) => {
    setTouched(true);
    if (multiple) {
      setValue(files.filter((file: File | IFile) => file !== fileToRemove));
    } else {
      setValue(null);
    }
  };

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: addFile,
    noClick: true,
    ...props
  });

  const countableFile = `file${multiple ? 's' : ''}`;

  // if the multiple param is false the files is not iterable so we need to make it
  if (!multiple) {
    if (files) {
      // if the singular file is not null - put it into an array
      files = [files];
    } else {
      // otherwise - there are no files to be visualized
      files = [];
    }
  }

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      {description && <Description>{description}</Description>}
      <Root {...getRootProps()}>
        <input {...getInputProps()} />
        <Content>
          {isDragActive ? (
            <p>Drop the {countableFile} here...</p>
          ) : (
            <p>Drag and drop your {countableFile} here to upload, or</p>
          )}
          <UploadButton
            type="button"
            onClick={open}
            colorScheme={2}
            height="short"
            fontSize={15}
            fontWeight={500}>
            Upload
          </UploadButton>
        </Content>
        {!_.isEmpty(files) && (
          <div>
            {files.map((file: File | IFile, index: number) => {
              const filename: string =
                'name' in file ? file.name : file.filename;
              const fileUrl: string | undefined = _.get(file, urlFieldName);

              return (
                <FileContainer key={`file-${index}`}>
                  <StyledIcon
                    icon="trash-alt"
                    weight="far"
                    onClick={() => removeFile(file)}
                  />
                  <FileLine
                    withIcon={false}
                    filename={filename}
                    href={fileUrl}
                  />
                </FileContainer>
              );
            })}
          </div>
        )}
      </Root>
      <FieldError name={props.name} />
    </div>
  );
};

export default Dropzone;
