import styled from 'styled-components';

import { lightBlue, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Link } from 'components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 31px 63px 60px 41px;

  @media ${breakpoints.desktop} {
    padding-left: 20px;
    padding-right: 13px;
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${breakpoints.tablet} {
    display: grid;
    grid-gap: 9px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const Tab = styled(Link)`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  box-sizing: border-box;
  transition: background-color 0.3s linear, border-color 0.3s linear,
    color 0.3s linear;

  font-size: 17px;

  width: ${({
    isTheOnlyTabShown
  }: {
    active: boolean;
    isTheOnlyTabShown: boolean;
  }) => (isTheOnlyTabShown ? 'auto' : '100%')};

  padding: 0
    ${({
      isTheOnlyTabShown
    }: {
      active: boolean;
      isTheOnlyTabShown: boolean;
    }) => (isTheOnlyTabShown ? '50px' : 0)};

  font-weight: ${({
    active
  }: {
    active: boolean;
    isTheOnlyTabShown: boolean;
  }) => (active ? 600 : 500)};

  color: ${({ active, theme }: { active: boolean; theme: ITheme }) =>
    active ? 'white' : theme.primaryColor};

  background-color: ${({
    active,
    theme
  }: {
    active: boolean;
    theme: ITheme;
    isTheOnlyTabShown: boolean;
  }) => (active ? theme.primaryColor : lightBlue)};

  border-color: ${({
    active,
    theme
  }: {
    active: boolean;
    theme: ITheme;
    isTheOnlyTabShown: boolean;
  }) => (active ? theme.primaryColor : 'rgba(123, 124, 129, 0.2)')};

  &:hover {
    border-color: ${({ theme }: { theme: ITheme }) =>
      theme.primaryColor}99; // 60% opacity
  }

  &:not(:first-of-type) {
    margin-left: 9px;

    @media ${breakpoints.tablet} {
      margin-left: unset;
    }
  }
`;

export const Content = styled.div`
  padding-top: 40px;
  box-sizing: border-box;

  height: 100%;
  display: grid;
  grid-gap: 20px 13px;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));

  @media ${breakpoints.tablet} {
    padding-top: 40px;
    max-width: 100%;
  }
`;
