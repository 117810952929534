import * as UpChunk from '@mux/upchunk';

export const uploadVideoToMux = async ({
  url,
  video,
  setVideoUploadProgress
}: {
  url: string;
  video: File;
  setVideoUploadProgress: (progress: number) => void;
}): Promise<{ success: boolean; errorMessage?: string }> => {
  const uploadPromise = new Promise((resolve, reject) => {
    let highestVideoUploadProgress = 0;
    const upload = UpChunk.createUpload({ endpoint: url, file: video });

    upload.on('error', (err) => {
      reject(err.detail);
    });

    upload.on('progress', (progress) => {
      const roundedProgress = Math.round(progress.detail);
      highestVideoUploadProgress = Math.max(
        roundedProgress,
        highestVideoUploadProgress
      );
      setVideoUploadProgress(highestVideoUploadProgress);
    });

    upload.on('success', () => {
      resolve();
    });
  });

  return uploadPromise.then(
    () => ({ success: true }),
    (errorMessage) => ({ success: false, errorMessage })
  );
};
