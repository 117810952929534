import {
  EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER,
  EVENT_LOGO_PLACEHOLDER,
  SESSIONS_URL,
  ORGANISER_DETAIL_URL
} from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import { EEventStatusValues } from 'libs/enums';
import { getEventButtonProps } from 'libs/events';
import { IFeaturedEventListEvent } from 'libs/types';

import { useCarousel, useWithUser } from 'hooks';

import { Image } from 'components';

import {
  Logo,
  LogoContainer,
  Arrow,
  Wrapper,
  Container,
  Left,
  Right,
  LiveLabel,
  DotIcon,
  StyledLink,
  EventMisc,
  Title,
  Description,
  StyledButton,
  HeroImageContainer,
  OrganiserName
} from './styles';

const START_DATE_FORMAT = 'Do MMMM, YYYY';

interface IProps {
  events: Array<IFeaturedEventListEvent>;
}

const HomeCarousel = ({ events }: IProps) => {
  const {
    activeItem: activeEvent,
    switchToPrevious,
    switchToNext
  } = useCarousel({ items: events });
  const { user } = useWithUser();

  return (
    <Wrapper>
      <Arrow icon="chevron-left" onClick={switchToPrevious} />
      {events.map((event) => {
        const {
          slug,
          name,
          light_background_logo: logo,
          description,
          hero_image: heroImage,
          start,
          location,
          status,
          organiser: { slug: organiserSlug, name: organiserName }
        } = event;

        const isLive = status.value === EEventStatusValues.LIVE;

        const eventHref = SESSIONS_URL({ eventSlug: slug });
        const buttonProps = getEventButtonProps({ user, event });

        return (
          <Container key={slug} active={activeEvent === event}>
            <Left>
              <LogoContainer>
                <Logo src={logo || EVENT_LOGO_PLACEHOLDER} />
              </LogoContainer>
              <EventMisc>
                {applyFormattingToDateTime({
                  date: start,
                  toFormat: START_DATE_FORMAT
                })}
                , {location} ·{' '}
                <OrganiserName
                  href={ORGANISER_DETAIL_URL({
                    organiserSlug
                  })}>
                  {organiserName}
                </OrganiserName>
              </EventMisc>
              <Title href={eventHref}>{name}</Title>
              <Description>{description}</Description>
              <StyledLink href={eventHref}>
                <StyledButton
                  height="medium"
                  fontSize={15}
                  fontWeight={500}
                  {...buttonProps}
                />
              </StyledLink>
            </Left>
            <Right>
              <HeroImageContainer href={eventHref}>
                {isLive && (
                  <LiveLabel>
                    <DotIcon icon="dot-circle" />
                    LIVE NOW
                  </LiveLabel>
                )}
                <Image
                  layout="fill"
                  src={heroImage || EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER}
                />
              </HeroImageContainer>
            </Right>
          </Container>
        );
      })}
      <Arrow icon="chevron-right" onClick={switchToNext} />
    </Wrapper>
  );
};

export default HomeCarousel;
