import styled from 'styled-components';

import { colors, darkGrey, lightBlueGrey } from 'styles/global';

import { Icon, Link } from 'components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Toggler = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const TogglerIcon = styled(Icon)`
  font-size: 10px;
  color: ${darkGrey};

  margin-left: 11px;

  transition: transform 0.3s linear;

  transform: ${({ reversed }: { reversed: boolean }) =>
    reversed ? 'rotateX(180deg)' : 'rotateX(0deg)'};
`;

export const Content = styled.div`
  right: 0px;
  top: calc(100% + 7px);
  position: absolute;

  box-sizing: border-box;
  filter: drop-shadow(0px 4px 5px rgba(123, 124, 129, 0.05));

  border-radius: 2px;
  border-style: solid;
  border-color: rgba(123, 124, 129, 0.2);

  overflow: hidden;
  background-color: white;

  transition: max-height 0.3s linear, border 0.3s linear;
  max-height: ${({ visible }: { visible: boolean }) => (visible ? 200 : 0)}px;
  border-width: ${({ visible }: { visible: boolean }) => (visible ? 1 : 0)}px;
`;

export const DropdownItem = styled(Link)`
  display: flex;
  align-items: center;

  padding: 4px 10px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  color: ${colors.text[1]};

  cursor: pointer;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${lightBlueGrey};
  }
`;

export const NonLinkDropdownItem = styled.div`
  display: flex;
  align-items: center;

  padding: 4px 10px;
  box-sizing: border-box;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  color: ${colors.text[1]};

  cursor: pointer;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${lightBlueGrey};
  }
`;

export const DropdownItemIcon = styled(Icon)`
  font-size: 14px;
  margin-right: 8px;
`;
