import styled from 'styled-components';

import { colors, breakpoints } from 'styles/global';

import { ITheme } from 'libs/types';

import { Icon, Link } from 'components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  color: ${colors.text[2]};

  @media ${breakpoints.tablet} {
    flex-wrap: wrap;
  }
`;

export const Breadcrumb = styled(Link)`
  font-size: 13px;
  line-height: 16px;

  cursor: pointer;
  white-space: nowrap;
  opacity: ${({ notOpaque }: { notOpaque: boolean }) => (notOpaque ? 0.4 : 1)};

  &:last-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const IconBreadcrumb = styled(Icon)`
  color: ${({ theme }: { theme: ITheme }) => theme.primaryColor};
  font-size: 15px;
`;

export const Chevron = styled(Icon)`
  margin: 3px 5px 0 5px;
  font-size: 6px;
`;
