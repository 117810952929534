import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { SCHEDULE_URL, SESSION_DETAIL_URL, MEETING_URL } from 'libs/constants';
import { EAvatarSizes, ENotificationTypes } from 'libs/enums';
import { getOtherMeetingParticipant } from 'libs/meeting';
import {
  INotificationListNotification,
  INotificationListSessionIsStartingNotification,
  INotificationListMeetingIsStartingNotification,
  INotificationListSessionIsAboutToStartNotification,
  INotificationListMeetingIsAboutToStartNotification,
  INotificationListYourSessionIsAddedToScheduleNotification,
  INotificationListSomeoneScheduledAMeetingWithYouNotification,
  INotificationListMeetingCancelledNotification,
  INotificationListSomeoneViewedYourProfileNotification
} from 'libs/types';

import { useWithEvent, useWithUser, useToggleUserDetail } from 'hooks';

import { UserLine, Link } from 'components';

import {
  Container,
  Title,
  TitleRow,
  FooterRow,
  AdditionalText,
  CreationDateDelta,
  SessionNotificationIcon,
  SessionNotificationTitle,
  SessionNotificationContent,
  SessionNotificationCategory,
  SessionNotificationIconWrapper
} from './styles';
import {
  getNotificationCreationDateDelta,
  formatNotificationRelatedDatetime
} from './utils';

interface IProps {
  notification: INotificationListNotification;
}

const SessionIsStartingNowOrAboutToStartNotification = ({
  notification
}: {
  notification:
    | INotificationListSessionIsStartingNotification
    | INotificationListSessionIsAboutToStartNotification;
}) => {
  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const properties: { title: string; icon: IconName; iconWeight: IconPrefix } =
    notification.type === ENotificationTypes.SESSION_IS_STARTING
      ? {
          title: 'Session now live',
          icon: 'dot-circle',
          iconWeight: 'fas'
        }
      : {
          title: 'Session starting in less than 10 minutes',
          icon: 'clock',
          iconWeight: 'far'
        };

  return eventSlug ? (
    <Link
      href={SESSION_DETAIL_URL({
        eventSlug,
        sessionSlug: notification.session.slug,
        sessionId: notification.session.id
      })}>
      <Container lightBlueBackground={!notification.is_seen}>
        <TitleRow>
          <Title>{properties.title}</Title>
          <CreationDateDelta>
            {getNotificationCreationDateDelta(notification)}
          </CreationDateDelta>
        </TitleRow>
        <SessionNotificationContent>
          <SessionNotificationIconWrapper>
            <SessionNotificationIcon
              icon={properties.icon}
              weight={properties.iconWeight}
            />
          </SessionNotificationIconWrapper>
          <div>
            <SessionNotificationTitle>
              {notification.session.title}
            </SessionNotificationTitle>
            <SessionNotificationCategory>
              {notification.session.category.title}
            </SessionNotificationCategory>
          </div>
        </SessionNotificationContent>
        <FooterRow>Join Session</FooterRow>
      </Container>
    </Link>
  ) : null;
};

const MeetingIsStartingNowOrAboutToStartNotification = ({
  notification
}: {
  notification:
    | INotificationListMeetingIsStartingNotification
    | INotificationListMeetingIsAboutToStartNotification;
}) => {
  const { user } = useWithUser();
  const otherParticipant =
    user &&
    getOtherMeetingParticipant({
      meeting: notification.meeting,
      user
    });

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const title =
    notification.type === ENotificationTypes.MEETING_IS_STARTING
      ? 'Meeting is starting'
      : 'Meeting starting in less than 10 minutes';

  return eventSlug ? (
    <Link
      href={MEETING_URL({
        eventSlug,
        meetingUUID: notification.meeting.room_name
      })}>
      <Container lightBlueBackground={!notification.is_seen}>
        <TitleRow>
          <Title>{title}</Title>
          <CreationDateDelta>
            {getNotificationCreationDateDelta(notification)}
          </CreationDateDelta>
        </TitleRow>
        {otherParticipant ? (
          <UserLine
            size={EAvatarSizes.SMALL}
            user={otherParticipant}
            openUserDetailOnClick={false}
          />
        ) : (
          <UserLine.Placeholder size={EAvatarSizes.SMALL} />
        )}
        <AdditionalText>
          {formatNotificationRelatedDatetime(notification.meeting.start)}
        </AdditionalText>
        <FooterRow>Join Meeting</FooterRow>
      </Container>
    </Link>
  ) : null;
};

const MeetingScheduledOrCancelledNotification = ({
  notification
}: {
  notification:
    | INotificationListSomeoneScheduledAMeetingWithYouNotification
    | INotificationListMeetingCancelledNotification;
}) => {
  const { user } = useWithUser();
  const otherParticipant =
    user &&
    getOtherMeetingParticipant({
      meeting: notification.meeting,
      user
    });

  const { event } = useWithEvent();
  const eventSlug = event?.slug;

  const title =
    notification.type === ENotificationTypes.MEETING_CANCELLED
      ? 'Meeting cancelled'
      : 'Meeting added to schedule';

  return eventSlug ? (
    <Link href={SCHEDULE_URL({ eventSlug })}>
      <Container lightBlueBackground={!notification.is_seen}>
        <TitleRow>
          <Title>{title}</Title>
          <CreationDateDelta>
            {getNotificationCreationDateDelta(notification)}
          </CreationDateDelta>
        </TitleRow>
        {otherParticipant ? (
          <UserLine
            size={EAvatarSizes.SMALL}
            openUserDetailOnClick={false}
            user={otherParticipant}
          />
        ) : (
          <UserLine.Placeholder size={EAvatarSizes.SMALL} />
        )}
        <AdditionalText>
          {formatNotificationRelatedDatetime(notification.meeting.start)}
        </AdditionalText>
        <FooterRow>View Schedule</FooterRow>
      </Container>
    </Link>
  ) : null;
};

const YourSessionIsAddedToScheduleOrSomeoneViewedYourProfileNotification = ({
  notification
}: {
  notification:
    | INotificationListYourSessionIsAddedToScheduleNotification
    | INotificationListSomeoneViewedYourProfileNotification;
}) => {
  const { setUserDetailUserId } = useToggleUserDetail();

  const title =
    notification.type === ENotificationTypes.YOUR_SESSION_IS_ADDED_TO_SCHEDULE
      ? 'Someone added your session to their schedule'
      : 'Someone viewed your profile';

  return (
    <Container
      lightBlueBackground={!notification.is_seen}
      onClick={() => setUserDetailUserId(notification.user.id)}>
      <TitleRow>
        <Title>{title}</Title>
        <CreationDateDelta>
          {getNotificationCreationDateDelta(notification)}
        </CreationDateDelta>
      </TitleRow>
      <UserLine
        size={EAvatarSizes.SMALL}
        openUserDetailOnClick={false}
        user={notification.user}
      />
      <FooterRow>View Profile</FooterRow>
    </Container>
  );
};

const Notification = ({ notification }: IProps) => {
  switch (notification.type) {
    case ENotificationTypes.SESSION_IS_STARTING:
    case ENotificationTypes.SESSION_IS_ABOUT_TO_START:
      return (
        <SessionIsStartingNowOrAboutToStartNotification
          notification={notification}
        />
      );
    case ENotificationTypes.MEETING_IS_STARTING:
    case ENotificationTypes.MEETING_IS_ABOUT_TO_START:
      return (
        <MeetingIsStartingNowOrAboutToStartNotification
          notification={notification}
        />
      );
    case ENotificationTypes.SOMEONE_VIEWED_YOU_PROFILE:
    case ENotificationTypes.YOUR_SESSION_IS_ADDED_TO_SCHEDULE:
      return (
        <YourSessionIsAddedToScheduleOrSomeoneViewedYourProfileNotification
          notification={notification}
        />
      );
    case ENotificationTypes.MEETING_CANCELLED:
    case ENotificationTypes.SOMEONE_SCHEDULED_A_MEETING_WITH_YOU:
      return (
        <MeetingScheduledOrCancelledNotification notification={notification} />
      );
    default:
      throw new Error('Unknown notification type encountered.');
  }
};

export default Notification;
