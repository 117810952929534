import _ from 'lodash';
import moment from 'moment';

import { DATETIME_FORMAT } from 'libs/constants';
import { applyFormattingToDateTime } from 'libs/datetime';
import { INotificationListNotification } from 'libs/types';

export const getNotificationCreationDateDelta = (
  notification: INotificationListNotification
) => {
  /*
     If less than a minute has passed we return "Just now".
     If less than an hour has passed - we return the minutes.
     If less than a day has passed - we return the hours.
     Otherwise we return the number of days that have passed.

     N.B. If an hour and 30 minutes have passed we say that an hour has passed.
  */
  const momentNow = moment();
  const momentCreationDate = moment(notification.created_at, DATETIME_FORMAT);

  const difference = moment.duration(momentNow.diff(momentCreationDate));

  // time difference in minutes
  const minutes = _.toInteger(difference.asMinutes()) % 60;

  // time difference in hours
  const hours = _.toInteger(difference.asHours()) % 24;

  // difference in days
  const days = _.toInteger(difference.asDays());

  if (days > 0) {
    return `${days}d`;
  }

  if (hours > 0) {
    return `${hours}h`;
  }

  if (minutes === 0) {
    return 'Just now';
  }

  return `${minutes}m`;
};

export const formatNotificationRelatedDatetime = (datetime: string) =>
  applyFormattingToDateTime({ date: datetime, toFormat: 'HH:mm, Do MMM YYYY' });
