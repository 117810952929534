import _ from 'lodash';
import { useRef, useState, useEffect, useCallback } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-mux';

import { MANDATORY_MUX_DATA_METADATA } from 'libs/constants';

import { IMuxDataMetadata } from './types';

/*
 `videojs-mux` is a private library available in the package manager.
 We must use it if we want to collect Mux Data. Sadly there're no
 type declarations and we don't know how exactly it adds the `.mux`
 method on the videojs instance. Therefore we need to redeclare the
 VideoJsPlayer in order to add the .mux method. Extending doesn't work.
 For reference check: https://www.typescriptlang.org/docs/handbook/declaration-merging.html
*/
declare module 'video.js' {
  interface VideoJsPlayer {
    mux: (x: object) => void;
  }
}

export interface IProps extends VideoJsPlayerOptions {
  currentTime?: number;
  enableMuxData?: boolean;
  muxDataMetadata?: IMuxDataMetadata;
}

const Player = ({
  currentTime,
  enableMuxData,
  muxDataMetadata,
  ...props
}: IProps) => {
  const [player, setPlayer] = useState<VideoJsPlayer>();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const attemptAutoplay = useCallback(
    ({ retryCount }: { retryCount: number }) => {
      // Reference: https://blog.videojs.com/autoplay-best-practices-with-video-js/
      if (!player || retryCount < 1) {
        return;
      }

      // The catch is to avoid the "NotAllowedError" by browsers when a user has not yet interacted with the page.
      // Reference: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      player.play?.()?.catch?.(() => {
        setTimeout(() => attemptAutoplay({ retryCount: retryCount - 1 }), 500);
      });
    },
    [player]
  );

  useEffect(() => {
    // initialize the player
    if (player) {
      return; // do not reinitialize
    }

    const newPlayer = videojs(videoRef.current, props);

    if (!newPlayer) {
      return;
    }

    setPlayer(newPlayer);
  }, [player, props]);

  useEffect(() => {
    // enable mux data
    if (!player || !enableMuxData) {
      return;
    }

    const metadata = {
      ...muxDataMetadata,
      ...MANDATORY_MUX_DATA_METADATA
    };

    player.mux({
      debug: false,
      data: metadata
    });
  }, [player, enableMuxData, muxDataMetadata]);

  useEffect(() => {
    // set the current time
    if (player && !_.isNil(currentTime) && _.isInteger(currentTime)) {
      player.currentTime(currentTime);
    }
  }, [player, currentTime]);

  useEffect(() => {
    // dispose the player
    return () => {
      player?.dispose();
    };
  }, [player]);

  useEffect(() => {
    attemptAutoplay({ retryCount: 10 });
  }, [attemptAutoplay]);

  /*
     Wrap the player in a div with a `data-vjs-player` attribute
     so videojs won't create additional wrapper in the DOM. For
     reference see https:github.com/videojs/video.js/pull/3856
   */
  return (
    <div>
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </div>
  );
};

Player.defaultProps = {
  controls: true,
  preload: 'none',
  responsive: true,
  aspectRatio: '16:9',
  html5: {
    vhs: {
      overrideNative: true
    }
  },
  controlBar: {
    playToggle: true,
    captionsButton: false,
    chaptersButton: false,
    subtitlesButton: false,
    remainingTimeDisplay: false,
    fullscreenToggle: true,
    playbackRateMenuButton: false
  },
  /*
     Reference for playsinline: https://github.com/videojs/video.js/issues/3762
     We use it to avoid double controls on mobile.
   */
  playsinline: true,
  enableMuxData: true,
  playbackRates: [0.5, 1, 1.5, 2]
};

export default Player;
