import _ from 'lodash';
import { Fragment, useCallback } from 'react';

import { ESessionStatuses, EScheduleItemTypes } from 'libs/enums';
import {
  IScheduleRetrieveMeetingItem,
  IScheduleRetrieveSessionItem
} from 'libs/types';

import { MeetingBox, SessionBox, TimestampDivider } from 'components';

import { Container, PaddedLeft } from './styles';
import { getTimestampDividerTime } from './utils';

interface IProps {
  items: Array<IScheduleRetrieveMeetingItem | IScheduleRetrieveSessionItem>;
}

const ScheduleTimeline = ({ items }: IProps) => {
  const firstLiveOrFutureSessionItem = _.head(
    items.filter(
      (item) =>
        item.session?.status === ESessionStatuses.LIVE ||
        item.session?.status === ESessionStatuses.UPCOMING
    )
  );

  const scrollToFirstLiveOrFutureSessionItemRef = useCallback((node) => {
    if (node !== null && window) {
      // removing 100 so that it's a bit lower and the navigation doesn't overlap it
      const topOffset = node.offsetTop - 100;

      window.scroll({ top: topOffset, behavior: 'smooth' });
    }
  }, []);

  return (
    <Container>
      {items.map((item, index) => (
        <Fragment key={`schedule-timeline-item-${index}`}>
          <TimestampDivider
            time={getTimestampDividerTime({
              currentItem: item,
              previousItem: items[index - 1]
            })}
          />
          <PaddedLeft
            ref={
              item === firstLiveOrFutureSessionItem
                ? scrollToFirstLiveOrFutureSessionItemRef
                : null
            }>
            {item.item_type === EScheduleItemTypes.MEETING ? (
              <MeetingBox meeting={item.meeting} />
            ) : (
              <SessionBox session={item.session} />
            )}
          </PaddedLeft>
        </Fragment>
      ))}
    </Container>
  );
};
export default ScheduleTimeline;
