import Skeleton from 'react-loading-skeleton';

import {
  IMeUser,
  IEventDetailEvent,
  ICategoryDetailRoundTable,
  IRoundTableDetailRoundTable
} from 'libs/types';
import { unissuLogin } from 'libs/unissuAuth';

import { Link } from 'components';

import { StyledButton } from './styles';

export const getButton = ({
  user,
  event,
  roundTable,
  roundTableUrl,
  isFetchingInitials,
  purchaseEventTicketUrl
}: {
  user?: IMeUser;
  roundTableUrl: string;
  event?: IEventDetailEvent;
  isFetchingInitials: boolean;
  purchaseEventTicketUrl: string;
  roundTable: ICategoryDetailRoundTable | IRoundTableDetailRoundTable;
}) => {
  if (isFetchingInitials) {
    return (
      <StyledButton
        height="tall"
        fontSize={15}
        fontWeight={600}
        onClick={unissuLogin}
        colorScheme={4}
        disabled={true}>
        <Skeleton width={100} />
      </StyledButton>
    );
  }

  if (user) {
    if (event?.user_has_ticket) {
      return (
        <Link href={roundTableUrl}>
          <StyledButton
            height="tall"
            fontSize={15}
            fontWeight={600}
            disabled={!roundTable.can_user_join}
            colorScheme={4}>
            Join Discussion
          </StyledButton>
        </Link>
      );
    } else {
      return (
        <Link href={purchaseEventTicketUrl}>
          <StyledButton
            height="tall"
            fontSize={15}
            fontWeight={600}
            colorScheme={4}>
            Get a Ticket to Join Discussion
          </StyledButton>
        </Link>
      );
    }
  }

  return (
    <StyledButton
      height="tall"
      fontSize={15}
      fontWeight={600}
      onClick={unissuLogin}
      colorScheme={4}>
      Login to Join Discussion
    </StyledButton>
  );
};
