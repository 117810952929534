import { useRouter } from 'next/router';
import { useState } from 'react';

import { SEARCH_URL } from 'libs/constants';

import { Container, StyledInput, MagnifyingGlassIcon } from './styles';

const DesktopSearchInput = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const router = useRouter();

  const search = () => {
    router.push(SEARCH_URL({ search: searchTerm }), undefined, {
      shallow: true
    });
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Execute the search on "Enter"
    if (event.key === 'Enter') {
      search();
    }
  };

  return (
    <Container focused={isFocused}>
      <StyledInput
        value={searchTerm}
        onKeyUp={handleKeyUp}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search Events, Videos and People"
      />
      <MagnifyingGlassIcon icon="search" onClick={search} />
    </Container>
  );
};

export default DesktopSearchInput;
