import styled from 'styled-components';

import { colors } from 'styles/global';

import { Button } from 'components';

export const Container = styled.div`
  width: 100%;

  padding-bottom: 42px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: column;

  border-bottom: ${({ withBottomBorder }: { withBottomBorder: boolean }) =>
      withBottomBorder ? '1px' : 0}
    solid rgba(123, 124, 129, 0.2);
`;

export const Grid = styled.div`
  margin-top: 26px;

  width: 100%;
  display: grid;
  grid-gap: 40px 9px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

export const LoadMoreButton = styled(Button)`
  width: 122px;
  margin-top: 41px;
`;

export const EntityResultCountHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Entity = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: ${colors.text[1]};
`;

export const ResultCount = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${colors.text[2]};
`;
