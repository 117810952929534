import { useField } from 'formik';
import _ from 'lodash';
import { KeyboardEvent } from 'react';

import { FieldError } from 'components';

import {
  StyledTextArea,
  StyledLabel,
  BottomRow,
  CharacterLimit
} from './styles';

interface IProps {
  label?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  withFieldError?: boolean;
  name: string;
  [key: string]: any;
}

const TextArea = ({
  label,
  className,
  maxLength,
  withFieldError = true,
  ...props
}: IProps) => {
  const [field, { error, touched }] = useField(props);

  const isFieldInvalid = Boolean(touched && error);

  return (
    <div className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledTextArea invalid={isFieldInvalid} {...field} {...props} />
      {(maxLength || withFieldError) && (
        <BottomRow>
          <span>{withFieldError && <FieldError name={props.name} />}</span>
          {maxLength && (
            <CharacterLimit>{`${_.get(
              field,
              'value.length',
              0
            )}/${maxLength}`}</CharacterLimit>
          )}
        </BottomRow>
      )}
    </div>
  );
};

export default TextArea;
