import React, { RefObject, useState } from 'react';

import { Icon } from 'components';

import { StyledInput, InputContainer, StyledButton } from './styles';

export interface IProps {
  className?: string;
  placeholder?: string;
  initialValue?: string;
  executeButtonActionOnEnter?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  buttonAction: (arg0: string) => void;
}

/* ⚠️ Not meant to be used in formik forms! It won't handle what's being passed from formik. */
const SearchInput = ({
  initialValue = '',
  placeholder = 'Search',
  className,
  buttonAction,
  executeButtonActionOnEnter = true,
  inputRef
}: IProps) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (executeButtonActionOnEnter && event.key === 'Enter') {
      buttonAction(value);
    }
  };

  return (
    <InputContainer className={className}>
      <StyledInput
        type="input"
        value={value}
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      {/* Height is overridden in the styles */}
      <StyledButton
        colorScheme={1}
        height="short"
        fontSize={16}
        fontWeight={500}
        onClick={() => buttonAction(value)}>
        <Icon icon="search" />
      </StyledButton>
    </InputContainer>
  );
};

export default SearchInput;
