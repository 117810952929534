import styled from 'styled-components';

import { colors, lightBlueGrey } from 'styles/global';

import { Button } from 'components';

export const Container = styled.div`
  width: 100%;
  max-width: 323px;
  background-color: ${lightBlueGrey};

  border-radius: 2px;
  border: 1px solid rgba(123, 124, 129, 0.2);
  box-shadow: 0px 4px 5px rgba(123, 124, 129, 0.05);

  box-sizing: border-box;
  padding: 35px 33px 52px 34px;
`;

export const Title = styled.h6`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${colors.text[1]};

  margin-bottom: 13px;
`;

export const StartingFrom = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${colors.text[1]};
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${colors.text[1]};

  margin-top: 17px;
  margin-bottom: 29px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
