import { useEffect } from 'react';

import { enableBodyScroll, disableBodyScroll } from 'libs/common';

import {
  Wrapper,
  Container,
  Title,
  Description,
  Actions,
  CancelButton,
  ConfirmButton
} from './styles';

interface IProps {
  close: () => void;
  confirmAction: () => void;
}

const PrerecordedVideoRemovalConfirmationModal = ({
  close,
  confirmAction
}: IProps) => {
  useEffect(() => {
    disableBodyScroll();

    return () => {
      enableBodyScroll();
    };
  });

  return (
    <Wrapper>
      <Container>
        <Title>
          <span role="img" aria-label="warning">
            ⚠️
          </span>
          WARNING
        </Title>

        <Description>
          Deleting your video may mean that there is not enough time to upload
          another video to replace it.
          <br />
          <br />
          We do not advise deleting your video any less than <b>6 hours</b>{' '}
          before your scheduled session start time.
          <br />
          <br />
          Factors affecting your ability to upload another video before the
          session start time include:
          <br />
          <br />
          - Size of file <br />
          - Speed of your internet connection <br />
          - Third party processing (performed by MUX) <br />
          <br />
          If no video is uploaded and processed before the session start time,
          no users will be able to view your session. <br />
          <br />
          Are you sure you want to delete the video?
        </Description>
        <Actions>
          <CancelButton
            colorScheme={2}
            height="tall"
            fontWeight={500}
            fontSize={15}
            onClick={close}>
            Cancel
          </CancelButton>
          <ConfirmButton
            colorScheme={4}
            height="tall"
            fontWeight={500}
            fontSize={15}
            onClick={() => {
              close();
              confirmAction();
            }}>
            Yes, I’m sure
          </ConfirmButton>
        </Actions>
      </Container>
    </Wrapper>
  );
};
export default PrerecordedVideoRemovalConfirmationModal;
