import _ from 'lodash';
import { useState, useEffect } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'libs/common';
import { setQuickGuideModalOpened } from 'libs/requests';
import { unissuLogin } from 'libs/unissuAuth';

import { useWithEvent, useWithUser } from 'hooks';

import {
  Close,
  Wrapper,
  Container,
  Header,
  Content,
  ContentWrapper,
  StyledButton
} from './styles';

const SESSION_STORAGE_FIELD_NAME =
  'idsOfEventsThatTheUserHasSeenTheQuickGuideModalOf';

const QuickGuideModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { user, isFetchingInitials } = useWithUser();
  const { event } = useWithEvent();

  const getIdsOfEventsThatTheUserHasSeenTheQuickGuideModalOfFromSessionStorage = () => {
    const sessionStorageData = sessionStorage.getItem(
      SESSION_STORAGE_FIELD_NAME
    );

    if (sessionStorageData) {
      return JSON.parse(sessionStorageData);
    }

    return [];
  };

  const close = () => {
    setIsOpen(false);

    if (window && event?.id) {
      // first we retrieve the old ids
      const oldIds = getIdsOfEventsThatTheUserHasSeenTheQuickGuideModalOfFromSessionStorage();

      // then we set the new ids, which is the union of the old ones and the new event id
      sessionStorage.setItem(
        SESSION_STORAGE_FIELD_NAME,
        JSON.stringify([...oldIds, event.id])
      );
    }

    if (user) {
      setQuickGuideModalOpened();
    }
  };

  const handleButtonClick = () => {
    if (user) {
      close();
    } else {
      unissuLogin();
    }
  };

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isFetchingInitials || !event?.id) {
      // We're still fetching
      return;
    }

    // Open modal if the modal is enabled for a particular event, user is not logged in and he's not seen it yet in this session and this event
    // or if the modal is enabled for a particular event, the user is logged in but has not yet seen this modal as a user in this event.

    const idsOfEventsThatTheUserHasSeenTheQuickGuideModalOf = getIdsOfEventsThatTheUserHasSeenTheQuickGuideModalOfFromSessionStorage();

    const userHasSeenTheModal =
      event.user_has_seen_quick_guide_modal ||
      _.includes(idsOfEventsThatTheUserHasSeenTheQuickGuideModalOf, event.id);

    if (userHasSeenTheModal || !event.is_quick_guide_modal_enabled) {
      return;
    }

    setIsOpen(true);
  }, [
    isFetchingInitials,
    event?.id,
    event?.is_quick_guide_modal_enabled,
    event?.user_has_seen_quick_guide_modal
  ]);

  return event ? (
    <Wrapper visible={isOpen}>
      <Container background={event.quick_guide_modal_content_background_color}>
        <Header background={event.quick_guide_modal_title_background_color}>
          {event?.quick_guide_modal_title}
          <Close icon="times" onClick={close} />
        </Header>

        <ContentWrapper>
          {isOpen && (
            <Content
              dangerouslySetInnerHTML={{
                __html: event.quick_guide_modal_content
              }}
            />
          )}

          <StyledButton
            colorScheme={2}
            fontSize={15}
            fontWeight={500}
            height="medium"
            onClick={handleButtonClick}>
            {user ? 'Browse Channels' : 'Login'}
          </StyledButton>
        </ContentWrapper>
      </Container>
    </Wrapper>
  ) : null;
};

export default QuickGuideModal;
