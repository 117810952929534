import _ from 'lodash';

import { ISelectOption } from 'libs/types';

import { StyledSelect } from './styles';

export interface IProps {
  isMulti?: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (value: any) => void;
  options: Array<ISelectOption>;
  value: string;
}

const Dropdown = ({
  value,
  disabled: isDisabled,
  className,
  ...props
}: IProps) => {
  const selectedValue = _.find(
    props.options,
    (option) => option.value === value
  );

  return (
    <StyledSelect
      {...props}
      className={className}
      value={selectedValue || null} // if value changes from not-undefined to undefined it does not change in the select itself
      isDisabled={isDisabled}
    />
  );
};

export default Dropdown;
