import _ from 'lodash';
import { FunctionComponent } from 'react';
import Skeleton from 'react-loading-skeleton';

import { applyFormattingToDateTime, getUserTimezone } from 'libs/datetime';

import { Container, Time, Divider } from './styles';

interface IPlaceholderProps {
  count?: number;
  className?: string;
}

export interface IProps {
  time?: string;
  className?: string;
  withTimezone?: boolean;
  [key: string]: any;
}

const Placeholder = ({ className, count = 1 }: IPlaceholderProps) => (
  <>
    {_.range(count).map((index) => (
      <Container
        className={className}
        key={`timestamp-divider-placeholder-${index}`}>
        <Time>
          <Skeleton width="60%" />
        </Time>
        <Divider />
      </Container>
    ))}
  </>
);

const TimestampDivider: FunctionComponent<IProps> & {
  Placeholder: FunctionComponent<IPlaceholderProps>;
} = ({ time, className, withTimezone = false, ...props }) => (
  <Container className={className} {...props}>
    <Time>
      {time
        ? `${applyFormattingToDateTime({
            date: time,
            toFormat: 'HH:mm'
          })} ${withTimezone ? getUserTimezone() : ''}`
        : ''}
    </Time>
    <Divider />
  </Container>
);

TimestampDivider.Placeholder = Placeholder;

export default TimestampDivider;
